import { SlotMapGroup } from '@tovala/browser-apis-combinedapi'
import Table, { THead, TableRow, TH, TBody, TD } from 'components/common/Table'
import { ReactNode } from 'react'

const SlotMapGroupsTable = ({ children }: { children: ReactNode }) => {
  return (
    <Table>
      <THead>
        <TableRow>
          <TH>Title</TH>
          <TH>Associated Meal Tag</TH>
          <TH>Slot Map Letter</TH>
          <TH></TH>
        </TableRow>
      </THead>
      <TBody>{children}</TBody>
    </Table>
  )
}

export default SlotMapGroupsTable

export const SlotMapGroupRow = ({
  children,
  slotMapGroup,
}: {
  children: ReactNode
  slotMapGroup: SlotMapGroup
}) => {
  return (
    <TableRow>
      <TD>
        <h3 className="font-bold">{slotMapGroup.title}</h3>
      </TD>
      <TD>{slotMapGroup.associatedMealTag}</TD>
      <TD>{slotMapGroup.slotMapLetter ?? ''}</TD>
      <TD>{children}</TD>
    </TableRow>
  )
}
