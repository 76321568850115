import { Input } from '@tovala/component-library'
import { InputHTMLAttributes } from 'react'
import { WrappedFieldProps } from 'redux-form'

const InputRFCompLib = ({
  input,
  ...rest
}: InputHTMLAttributes<HTMLInputElement> & WrappedFieldProps): JSX.Element => {
  return <Input {...input} {...rest} />
}

export default InputRFCompLib
