import { Button } from '@tovala/component-library'
import { clsx } from 'clsx'
import { ReactNode } from 'react'

const ToggleButton = ({
  children,
  isFirst = false,
  isSelected,
  onClick,
}: {
  children: ReactNode
  isFirst?: boolean
  isSelected: boolean
  onClick(): void
}) => {
  const marginClass = isFirst ? '-mr-12' : '-ml-12'
  const paddingClass = isFirst ? 'pr-8' : 'pl-8'

  return (
    <div
      className={clsx({
        [`z-10 ${marginClass}`]: isSelected,
      })}
    >
      <Button
        buttonStyle={isSelected ? 'dark' : 'gray'}
        onClick={() => {
          onClick()
        }}
        size="medium"
      >
        <span
          className={clsx({
            [`${paddingClass}`]: !isSelected,
          })}
        >
          {children}
        </span>
      </Button>
    </div>
  )
}

export default ToggleButton
