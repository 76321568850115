import { APIErrorDisplay } from '@tovala/component-library'
import { useParams } from 'react-router-dom'
import {
  ErrorCodeMessageMapCombinedAPI,
  useTerm,
} from '@tovala/browser-apis-combinedapi'

import MenuLayout from './MenuLayout'
import { useEffect } from 'react'

const LOAD_TERM_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please reload the page to try again.',
    whatHappened: 'Unable to Load Term',
    why: "We couldn't load the term due to a technical issue on our end.",
  },
}

const MenuLayoutPage = () => {
  const { termid: termIDParam } = useParams()

  const termID = termIDParam ? Number.parseInt(termIDParam, 10) : undefined

  const {
    data: term,
    error: loadTermError,
    isError: hasLoadTermError,
    isLoading: isLoadingTerm,
  } = useTerm({ termID })

  useEffect(() => {
    document.title = `Glaze | Menu Display Orders`
  }, [])

  if (hasLoadTermError) {
    return (
      <APIErrorDisplay
        display="page"
        error={loadTermError}
        errorCodeMessageMap={LOAD_TERM_ERRORS}
      />
    )
  }

  if (isLoadingTerm) {
    return null
  }

  return <MenuLayout term={term} />
}

export default MenuLayoutPage
