import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import {
  EditUserReferralCode,
  editUserReferralCode,
  EditUserReferralCodeResponse,
} from 'services/combinedAPI/referralCodes'
import { getUserInfo } from 'actions/auth'

import { useAppDispatch } from 'hooks'

export function useEditUserReferralCode(
  opts?: Omit<
    UseMutationOptions<
      EditUserReferralCodeResponse,
      Error,
      EditUserReferralCode
    >,
    'mutationFn'
  >
) {
  const dispatch = useAppDispatch()

  return useMutation({
    ...opts,
    mutationFn: (opts: EditUserReferralCode) => {
      return editUserReferralCode(opts)
    },
    onSuccess: (...args) => {
      dispatch(getUserInfo(args[1].userID))

      opts?.onSuccess?.(...args)
    },
  })
}
