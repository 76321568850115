import { clsx } from 'clsx'
import { MouseEventHandler, ReactNode } from 'react'

export const IMAGE_CONTAINER_CLASSES = 'h-[200px] md:aspect-5/4 md:h-auto'

const UserMenuItem = ({
  image,
  onClickMenuItem,
  stepper,
  subtitle,
  surcharge = '',
  tags,
  title,
}: {
  image: ReactNode
  onClickMenuItem?: MouseEventHandler<HTMLDivElement>
  stepper?: ReactNode
  subtitle?: string
  surcharge?: string | null
  tags?: string[]
  title: string
}) => {
  return (
    <div className="space-y-6">
      <div className="space-y-4">
        <div className={`relative ${IMAGE_CONTAINER_CLASSES}`}>
          <div
            className={clsx('h-full w-full overflow-hidden rounded-lg', {
              'cursor-pointer': !!onClickMenuItem,
            })}
            onClick={onClickMenuItem}
          >
            {image}
          </div>

          <div className="absolute top-4 left-4">
            {tags && tags.length > 0 && <Tags tags={tags} />}
          </div>
          {stepper && (
            <div className="absolute bottom-4 right-4">{stepper}</div>
          )}
        </div>
        <div className="space-y-2">
          {surcharge && <p className="text-k/14_120">{surcharge}</p>}

          <div
            className={clsx('space-y-2', {
              'cursor-pointer': !!onClickMenuItem,
            })}
            onClick={onClickMenuItem}
          >
            <p className="text-k/20_125 md:text-k/24_120">{title}</p>
            <p className="text-k/16_125 md:text-k/18_120">{subtitle}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserMenuItem

const Tags = ({ tags }: { tags: string[] }) => {
  return (
    <div className="flex items-center space-x-1">
      {tags.map((tag, index) => {
        return (
          <div key={index} className="shrink-0">
            <div className="flex h-6 items-center justify-start whitespace-nowrap rounded-[4px] bg-white px-2 text-h/11_120 font-semibold uppercase">
              {tag}
            </div>
          </div>
        )
      })}
    </div>
  )
}
