import { clsx } from 'clsx'
import { forwardRef, TextareaHTMLAttributes } from 'react'

export type Props = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  hasError?: boolean
}

export const TEXTAREA_CLASSES =
  'w-full rounded border bg-white-900 p-2 disabled:bg-grey-904 disabled:cursor-not-allowed'

const Textarea = forwardRef<HTMLTextAreaElement, Props>(function Textarea(
  { hasError = false, ...rest },
  ref
) {
  return (
    <textarea
      ref={ref}
      className={clsx(TEXTAREA_CLASSES, {
        'border-red-902': hasError,
        'border-grey-901': !hasError,
      })}
      {...rest}
    />
  )
})

export default Textarea
