import { AnyAction, configureStore, ThunkAction } from '@reduxjs/toolkit'
import reducer from './reducers'

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
  // We're disabling devTools for now because it causes a memory crash (I think due to Redux Form). We can revisit
  // re-enabling this later (we just want to move forward with modernizing the architecture: https://tovala.atlassian.net/browse/WAT-72).
  devTools: false,
})

export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>
export type RootState = ReturnType<typeof store.getState>
