import { MenuComponentStandardizedTwoMealPicker } from '@tovala/browser-apis-menu-components'
import { TwoMealPicker as TwoMealPickerComponent } from '@tovala/component-library'

const TwoMealPicker = ({
  properties,
}: {
  properties: MenuComponentStandardizedTwoMealPicker['properties']
}) => {
  const { meals } = properties
  return (
    <TwoMealPickerComponent
      mealOptions={[
        {
          ...meals[0],
          quantity: 0,
        },
        {
          ...meals[1],
          quantity: 0,
        },
      ]}
    />
  )
}

export default TwoMealPicker
