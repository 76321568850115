import { debounce } from 'lodash-es'
import { parse, ParseResult } from 'papaparse'
import {
  ErrorCodeMessageMapCombinedAPI,
  Term,
  useCreateTermWorkingItems,
  useInvalidateListingsAdmin,
  useTerm,
} from '@tovala/browser-apis-combinedapi'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import AlertInline from 'components/common/AlertInline'
import FileDropzone from '../common/FileDropzone'
import H1 from 'components/common/H1'
import H4 from 'components/common/H4'
import Loader from 'components/common/Loader'
import {
  APIErrorDisplay,
  ButtonLoading,
  Checkbox,
} from '@tovala/component-library'

/*
  Example meal doc
  https://docs.google.com/spreadsheets/d/1IlQmqASIdWO5fkIpdw1x7NBWW8_FRiyrOki9OZ9kcPE/edit#gid=1177790863
*/

interface ImportedMealData {
  expirationDate: string
  facilityNetworks: string[]
  menuIDs: string[]
  misevalaMealVersionID: string
  productionCode: number
  termid: number
  type: string
  workingTitle: string
  version: string
}

interface MealData {
  expirationDate: string
  menuIDs: string[]
  misevalaMealVersionID: string
  productionCode: number
  termid: number
  title: string
}

const TermMealsDropzone = ({
  term,
  handleImportMeals,
}: {
  term: Term
  handleImportMeals: (meals: ImportedMealData[]) => void
}): JSX.Element => {
  const getMealData = (data: string[]) => {
    const meal = {
      misevalaMealVersionID: data[9],
      productionCode: Number.parseInt(data[1], 10),
      type: data[24],
      workingTitle: data[2],
      version: data[8],
    }
    return meal
  }

  const handleDrop = (files: File[]) => {
    parse(files[0], {
      skipEmptyLines: false,
      complete: (results: ParseResult<string[]>) => {
        const rowIndexes = {
          chicago: {
            start: 9, // Row 10
            end: 107, // Row 108
          },
          slc: {
            start: 111, // Row 112
            end: 209, // Row 210
          },
        }

        const chicago = results.data.slice(
          rowIndexes.chicago.start,
          rowIndexes.chicago.end + 1
        )
        const slc = results.data.slice(
          rowIndexes.slc.start,
          rowIndexes.slc.end + 1
        )

        const chicagoMeals = chicago
          .map((data) => getMealData(data))
          .filter((meal) => meal.workingTitle)
        const slcMeals = slc
          .map((data) => getMealData(data))
          .filter((meal) => meal.workingTitle)

        const uniqueChicagoMeals: ImportedMealData[] = []
        const uniqueSLCMeals: ImportedMealData[] = []
        const otherMeals: ImportedMealData[] = []

        const chicagoMenus = term.subTerms
          .filter((subTerm) => subTerm.facilityNetwork === 'chicago')
          .map((subTerm) => {
            return subTerm.defaultMenu.id
          })

        const slcMenus = term.subTerms
          .filter((subTerm) => subTerm.facilityNetwork === 'slc')
          .map((subTerm) => {
            return subTerm.defaultMenu.id
          })

        chicagoMeals.forEach((m) => {
          const matchingMeal = slcMeals.find(
            (meal) => meal.workingTitle === m.workingTitle
          )

          const meal: ImportedMealData = {
            ...m,
            expirationDate: '0001-01-01T00:00:00Z',
            facilityNetworks: ['CHI'],
            menuIDs: [...chicagoMenus],
            termid: term.id,
          }

          if (
            !matchingMeal ||
            (matchingMeal && matchingMeal.version !== meal.version)
          ) {
            uniqueChicagoMeals.push(meal)
          } else {
            meal.facilityNetworks.push('SLC')
            meal.menuIDs = [...meal.menuIDs, ...slcMenus]
            otherMeals.push(meal)
          }
        })

        slcMeals.forEach((m) => {
          const matchingMeal = chicagoMeals.find(
            (meal) => meal.workingTitle === m.workingTitle
          )
          const meal: ImportedMealData = {
            ...m,
            expirationDate: '0001-01-01T00:00:00Z',
            facilityNetworks: ['SLC'],
            menuIDs: [...slcMenus],
            termid: term.id,
          }

          if (
            !matchingMeal ||
            (matchingMeal && matchingMeal.version !== meal.version)
          ) {
            uniqueSLCMeals.push(meal)
          }
        })

        const mealsToCreate = [
          ...otherMeals,
          ...uniqueChicagoMeals,
          ...uniqueSLCMeals,
        ].sort((a, b) => a.productionCode - b.productionCode)

        handleImportMeals(mealsToCreate)
      },
    })
  }

  return (
    <div>
      <FileDropzone
        accept={{ 'text/csv': ['.csv'] }}
        maxFiles={1}
        onDrop={handleDrop}
      />
    </div>
  )
}

const LOAD_TERM_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please reload the page to try again.',
    whatHappened: 'Unable to Load Term',
    why: "We couldn't load the term due to a technical issue on our end.",
  },
}

const ImportTermMeals = () => {
  const navigate = useNavigate()

  const { termid: termIDParam } = useParams()

  const termID = termIDParam ? Number.parseInt(termIDParam, 10) : undefined

  const { invalidateListings } = useInvalidateListingsAdmin()

  const {
    isLoading: isLoadingTerm,
    error: loadTermError,
    isError: hasLoadTermError,
    data: term,
  } = useTerm({ termID })

  const { isLoading, mutate } = useCreateTermWorkingItems({
    onSuccess: () => {
      invalidateListings()

      navigate('/meals')
    },
  })
  const createTermWorkingItems = debounce(mutate, 250)

  const [meals, setMeals] = useState<ImportedMealData[]>()
  const [selectedItems, setSelectedItems] = useState(new Set<number>())

  const handleImportMeals = (meals: ImportedMealData[]) => {
    setMeals(meals)
  }

  const createMeals = (meals: ImportedMealData[]) => {
    let allMeals: MealData[] = meals.map((meal) => {
      return {
        title: `WT: ${meal.workingTitle}`,
        productionCode: meal.productionCode,
        termid: meal.termid,
        expirationDate: meal.expirationDate,
        menuIDs: meal.menuIDs,
        misevalaMealVersionID: meal.misevalaMealVersionID,
      }
    })

    if (selectedItems.size > 0) {
      allMeals = allMeals.filter((_meal, index) => {
        return selectedItems.has(index)
      })
    }

    const data = {
      meals: allMeals,
    }
    createTermWorkingItems({ data })
  }

  if (isLoadingTerm) {
    return <Loader />
  }

  if (hasLoadTermError) {
    return (
      <APIErrorDisplay
        display="page"
        error={loadTermError}
        errorCodeMessageMap={LOAD_TERM_ERRORS}
      />
    )
  }

  const missingDefaultMenus =
    term && term.subTerms.some((subTerm) => subTerm.defaultMenu === null)

  return (
    <div className="mb-5">
      <H1>
        Term #{term?.id && <span>{term.id}</span>} - Create Meals and Extras
      </H1>

      {term && (
        <div className="space-y-1">
          <p>1. Drop Term Planning SoT CSV file into box below.</p>
          <p>
            2. Review data - working titles, production codes, and facility
            networks will be displayed.
          </p>
          <p>
            3. Click &quot;Create Meals and Extras&quot; to create the meals and
            extras in the database and added to the appropriate menus.
          </p>

          {missingDefaultMenus ? (
            <AlertInline alertStyle="danger">
              <strong>Missing Default Menus</strong> Default menus are required
              to create Term Meals and Extras. Please contact #help-backend.
            </AlertInline>
          ) : (
            <TermMealsDropzone
              handleImportMeals={handleImportMeals}
              term={term}
            />
          )}
        </div>
      )}

      {meals && (
        <div>
          <H4>Meals and Extras</H4>

          <div className="space-y-2">
            {term.meals.length > 0 ? (
              <>
                {meals.map((meal, index) => {
                  return (
                    <Checkbox
                      key={`${meal.workingTitle}${meal.version}`}
                      checked={selectedItems.has(index)}
                      label={
                        <MenuItem
                          key={`${meal.workingTitle}${meal.version}`}
                          {...meal}
                        />
                      }
                      name={`${meal.workingTitle}${meal.version}`}
                      onChange={() => {
                        setSelectedItems((items) => {
                          const newItems = new Set(items)

                          if (newItems.has(index)) {
                            newItems.delete(index)
                          } else {
                            newItems.add(index)
                          }

                          return newItems
                        })
                      }}
                      value={index}
                    />
                  )
                })}
              </>
            ) : (
              <>
                {meals.map((meal) => {
                  return (
                    <MenuItem
                      key={`${meal.workingTitle}${meal.version}`}
                      {...meal}
                    />
                  )
                })}
              </>
            )}
          </div>

          <div className="mt-5">
            <ButtonLoading
              isLoading={isLoading}
              onClick={() => createMeals(meals)}
              size="large"
            >
              Create Meals and Extras
            </ButtonLoading>
          </div>
        </div>
      )}
    </div>
  )
}

export default ImportTermMeals

const MenuItem = ({
  facilityNetworks,
  productionCode,
  version,
  workingTitle,
}: {
  facilityNetworks: string[]
  productionCode: number
  version: string
  workingTitle: string
}) => {
  return (
    <div key={`${workingTitle}${version}`}>
      <span>
        WT: {workingTitle} (Production Code: {productionCode}
      </span>
      {facilityNetworks.length === 1 && <span> - {facilityNetworks}</span>})
    </div>
  )
}
