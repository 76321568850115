import axios from 'axios'
import { hideLoading, showLoading } from 'react-redux-loading-bar'
import {
  MealCashRecord,
  RemainingOrderBalance,
  SubscriptionPreferences,
} from '@tovala/browser-apis-combinedapi'
import { PayloadAction } from '@reduxjs/toolkit'

import * as types from './types'
import { API_URL_V1, getHeaders } from './index'
import { AppThunk } from 'store'
import { errorHandler, successHandler } from './notifications'
import { getUserInfo } from './auth'

// PUT https://api.tovala.com/v0/users/:userID/subscription/preferences
export function updatePlanPreferences(
  userid: string,
  preferences: SubscriptionPreferences
): AppThunk<
  Promise<PayloadAction<
    SubscriptionPreferences,
    typeof types.SET_PLAN_PREFERENCES
  > | void>
> {
  return function (dispatch) {
    dispatch(showLoading())
    return axios
      .put(
        `${API_URL_V1}/users/${userid}/subscriptionPreferences`,
        preferences,
        getHeaders()
      )
      .then((response) => {
        dispatch(hideLoading())

        if (response) {
          return dispatch({
            type: types.SET_PLAN_PREFERENCES,
            payload: preferences,
          })
        }
      })
      .catch((error) => {
        dispatch(hideLoading())
        errorHandler(dispatch, error)
      })
  }
}

// PUT v1/users/:userID/subscription/:subscriptionStatus
export function updateSubscription(
  userID: string,
  subscription: unknown
): AppThunk<
  Promise<{ type: typeof types.CHANGE_SUBSCRIPTION_SUCCESS } | void>
> {
  return function (dispatch) {
    dispatch(showLoading())
    return axios
      .put(
        `${API_URL_V1}/users/${userID}/subscription/activate`,
        subscription,
        getHeaders()
      )
      .then(() => {
        dispatch(hideLoading())

        successHandler(
          dispatch,
          `Success! Meal plan updated for User #${userID}`
        )

        return dispatch({
          type: types.CHANGE_SUBSCRIPTION_SUCCESS,
        })
      })
      .catch((error) => {
        dispatch(hideLoading())
        errorHandler(dispatch, error)
      })
  }
}

export function changeSubscriptionStatus(
  userID: number,
  subscriptionStatus: string
): AppThunk<
  Promise<{ type: typeof types.CHANGE_SUBSCRIPTION_SUCCESS } | void>
> {
  return function (dispatch, getState) {
    const user = getState().auth.user
    if (!user) {
      return Promise.reject(
        new Error('No user loaded to check subscription details')
      )
    }

    // Include the current subscription info so the API isn't sad
    const subscription = {
      subscriptionTypeID: user.subscription.subscriptionType?.id,
      defaultShipPeriod: user.subscription.defaultShipPeriod,
    }
    dispatch(showLoading())
    return axios
      .put(
        `${API_URL_V1}/users/${userID}/subscription/${subscriptionStatus}`,
        subscription,
        getHeaders()
      )
      .then(() => {
        dispatch(hideLoading())

        successHandler(
          dispatch,
          `Success! Meal plan ${
            subscriptionStatus === 'pause' ? 'paused' : 'canceled'
          } for User #${userID}`
        )

        dispatch(getUserInfo(userID))

        return dispatch({
          type: types.CHANGE_SUBSCRIPTION_SUCCESS,
        })
      })
      .catch((error) => {
        dispatch(hideLoading())
        errorHandler(dispatch, error)
      })
  }
}

// POST /v1/tools/users/:userID/mealCash
export function addMealCash(
  userID: number | string,
  payload: unknown
): AppThunk<
  Promise<PayloadAction<
    MealCashRecord,
    typeof types.REFUND_ORDER_SUCCESS
  > | void>
> {
  return function (dispatch) {
    return axios
      .post(
        `${API_URL_V1}/tools/users/${userID}/mealCash/add`,
        payload,
        getHeaders()
      )
      .then((response) => {
        return dispatch({
          type: types.REFUND_ORDER_SUCCESS,
          payload: response.data,
        })
      })

      .catch((error) => {
        dispatch(hideLoading())
        errorHandler(dispatch, error)
      })
  }
}

// POST /tools/users/:userID/userTermOrders/:orderID/fullRefund/mealCash
export function refundOrderWithMealCash(
  userID: number | string,
  orderID: string,
  payload: unknown
): AppThunk<
  Promise<PayloadAction<true, typeof types.REFUND_ORDER_SUCCESS> | void>
> {
  return function (dispatch) {
    return axios
      .post(
        `${API_URL_V1}/tools/users/${userID}/userTermOrders/${orderID}/fullRefund/mealCash`,
        payload,
        getHeaders()
      )
      .then(() => {
        successHandler(
          dispatch,
          `Success! Order refunded to Tovala Cash balance.`
        )

        return dispatch({
          type: types.REFUND_ORDER_SUCCESS,
          payload: true as const,
        })
      })

      .catch((error) => {
        dispatch(hideLoading())
        errorHandler(dispatch, error)
      })
  }
}

// POST /tools/users/:userID/userTermOrders/:orderID/partialRefund/mealCash
export function partialRefundOrderWithMealCash(
  userID: number | string,
  orderID: string,
  payload: unknown
): AppThunk<
  Promise<PayloadAction<true, typeof types.REFUND_ORDER_SUCCESS> | void>
> {
  return function (dispatch) {
    return axios
      .post(
        `${API_URL_V1}/tools/users/${userID}/userTermOrders/${orderID}/partialRefund/mealCash`,
        payload,
        getHeaders()
      )
      .then(() => {
        successHandler(
          dispatch,
          `Success! Order partially refunded to Tovala Cash balance.`
        )

        return dispatch({
          type: types.REFUND_ORDER_SUCCESS,
          payload: true as const,
        })
      })

      .catch((error) => {
        dispatch(hideLoading())
        errorHandler(dispatch, error)
      })
  }
}

// PUT  v1/users/:userID/orders/:orderID/shipCycle
export function updateUTOShipCycle(
  userID: number,
  orderID: string,
  payload: unknown
): AppThunk<Promise<PayloadAction<unknown, typeof types.UPDATE_UTO> | void>> {
  return function (dispatch) {
    return axios
      .put(
        `${API_URL_V1}/users/${userID}/orders/${orderID}/shipCycle`,
        payload,
        getHeaders()
      )
      .then((response) => {
        successHandler(dispatch, `Success! Delivery day updated.`)

        return dispatch({
          type: types.UPDATE_UTO,
          payload: response.data,
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

// PUT  v1/users/:userID/orders/:orderID/fulfillmentStatus
export function updateUTOFulfillmentStatus(
  userID: number,
  orderID: string,
  payload: unknown
): AppThunk<Promise<PayloadAction<unknown, typeof types.UPDATE_UTO> | void>> {
  return function (dispatch) {
    return axios
      .put(
        `${API_URL_V1}/users/${userID}/orders/${orderID}/fulfillmentStatus`,
        payload,
        getHeaders()
      )
      .then((response) => {
        successHandler(dispatch, `Success! Order status updated.`)

        return dispatch({
          type: types.UPDATE_UTO,
          payload: response.data,
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

// PUT  v1/users/:userID/orders/:orderID/shippingAddress
export function updateUTOShippingAddress(
  userID: number,
  orderID: string,
  payload: unknown
): AppThunk<Promise<PayloadAction<unknown, typeof types.UPDATE_UTO> | void>> {
  return function (dispatch) {
    return axios
      .put(
        `${API_URL_V1}/users/${userID}/orders/${orderID}/shippingAddress`,
        payload,
        getHeaders()
      )
      .then((response) => {
        successHandler(dispatch, `Success! Shipping address updated.`)

        return dispatch({
          type: types.UPDATE_UTO,
          payload: response.data,
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

// GET v1/tools/orders/:orderID/remainingBalance
export function getRemainingOrderBalance(
  orderID: string
): AppThunk<
  Promise<PayloadAction<
    RemainingOrderBalance,
    typeof types.GET_REMAINING_ORDER_BALANCE
  > | void>
> {
  return function (dispatch) {
    return axios
      .get<RemainingOrderBalance>(
        `${API_URL_V1}/tools/orders/${orderID}/remainingBalance`,
        getHeaders()
      )
      .then((response) => {
        return dispatch({
          type: types.GET_REMAINING_ORDER_BALANCE,
          payload: response.data,
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

// POST v1/tools/users/:userID/userTermOrders/:orderID/refundShipping/mealCash
export function refundShippingToMealCash(
  userID: number | string,
  orderID: string,
  data: unknown
): AppThunk<
  Promise<PayloadAction<true, typeof types.SHIPPING_CHARGE_REFUND> | void>
> {
  return function (dispatch) {
    return axios
      .post(
        `${API_URL_V1}/tools/users/${userID}/userTermOrders/${orderID}/refundShipping/mealCash`,
        data,
        getHeaders()
      )
      .then(() => {
        return dispatch({
          type: types.SHIPPING_CHARGE_REFUND,
          payload: true as const,
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

// GET https://api.tovala.com/v1/users/:userID/orders/:orderID/mealOrderReceipt
export function sendMealOrderReceipt(
  userID: number,
  orderID: string
): AppThunk<Promise<void>> {
  return function (dispatch) {
    return axios
      .get(
        `${API_URL_V1}/users/${userID}/orders/${orderID}/mealOrderReceipt`,
        getHeaders()
      )
      .then(() => {
        successHandler(dispatch, 'Success! Meal Order Receipt sent.')
      })
      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}
