import { DropzoneOptions, useDropzone } from 'react-dropzone'
import { ReactNode } from 'react'

function FileDropzone({
  children = 'Drop file here',
  ...rest
}: DropzoneOptions & { children?: ReactNode }): JSX.Element {
  const { getRootProps, getInputProps } = useDropzone(rest)

  const style = {
    border: '2px dashed #C7C7C7',
    borderRadius: '5px',
    cursor: 'pointer',
    textAlign: 'center',
    padding: '48px 0',
  } as const

  return (
    <section>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <div>{children}</div>
      </div>
    </section>
  )
}

export default FileDropzone
