import { clsx } from 'clsx'
import { Field, WrappedFieldInputProps } from 'redux-form'
import { useEffect } from 'react'

import normalizePhone from '../../utils/normalizePhone'
import { SHIPPING_STATE_OPTIONS } from 'utils/users'

import { useAppSelector } from 'hooks'
import FormLabel from 'components/common/FormLabel'
import InputRF from '../common/InputRF'
import { SELECT_CLASSES } from 'components/common/Select'

export interface FormData {
  city: string
  name: string
  line1: string
  line2: string
  phone: string
  postal_code: string
  state: string
}

export interface Props {
  initialize(formData: FormData): void
  readOnly?: boolean
  twoColumn?: boolean
}

const ShippingForm = ({
  initialize,
  readOnly,
  twoColumn,
}: Props): JSX.Element => {
  const user = useAppSelector((state) => state.auth.user)

  const shippingAddress = user?.shippingAddresses[0] ?? null

  useEffect(() => {
    if (shippingAddress) {
      initialize({
        name: shippingAddress.userName,
        line1: shippingAddress.line1,
        line2: shippingAddress.line2,
        city: shippingAddress.city,
        state: shippingAddress.state,
        postal_code: shippingAddress.zipCode,
        phone: shippingAddress.phone,
      })
    }
  }, [initialize, shippingAddress])

  return (
    <div
      className={clsx('grid gap-4', {
        'grid-cols-2': twoColumn,
      })}
    >
      <Field
        component={InputRF}
        disabled={readOnly}
        label="Full Name"
        name="name"
        placeholder="First and Last Name"
      />

      <Field
        component={InputRF}
        disabled={readOnly}
        label="Phone"
        name="phone"
        normalize={normalizePhone}
        placeholder="Phone"
        type="tel"
      />

      <Field
        component={InputRF}
        disabled={readOnly}
        label="Address Line 1"
        name="line1"
        placeholder="Address Line 1"
      />

      <Field
        component={InputRF}
        disabled={readOnly}
        label="Address Line 2"
        name="line2"
        placeholder="Address Line 2"
      />

      <Field
        component={InputRF}
        disabled={readOnly}
        label="City"
        name="city"
        placeholder="City"
      />

      <Field component={StateSelect} name="state" readOnly={readOnly} />

      <Field
        component={InputRF}
        disabled={readOnly}
        label="Postal Code"
        name="postal_code"
        placeholder="Postal Code"
      />
    </div>
  )
}

export default ShippingForm

const StateSelect = ({
  input,
  readOnly,
}: {
  input: WrappedFieldInputProps
  readOnly: boolean
}): JSX.Element => {
  return (
    <div>
      <FormLabel>State</FormLabel>
      <select
        className={SELECT_CLASSES}
        disabled={readOnly}
        placeholder="State"
        {...input}
      >
        <option value=""></option>
        {SHIPPING_STATE_OPTIONS.map((option) => {
          return (
            <option key={option} value={option}>
              {option}
            </option>
          )
        })}
      </select>
    </div>
  )
}
