import { ErrorDisplay } from '@tovala/component-library'
import { findKey, sortBy } from 'lodash-es'
import { MealImage } from '@tovala/browser-apis-combinedapi'
import { reduxForm } from 'redux-form'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import {
  SaveMealRequestBody,
  deleteImageForMeal,
  duplicateMeal,
  getMealInfo,
  removeMealTagFromMeal,
  updateMeal,
} from '../../actions/meals'
import { getAdminScope, MEALS_WRITE } from '../../utils/getAdminScope'
import { getAllMealTags } from '../../actions/meal-tags'
import { showSuccessNotification } from '../../actions/notifications'
import { getMealBarcode, getMealCameraUrl, isMeal } from 'utils/meals'

import { useAppDispatch, useAppSelector } from 'hooks'
import AddTagToMealForm from './AddTagToMealForm'
import ConfirmationModal from '../modals/ConfirmationModal'
import MealTagsTable from '../meal-tags/MealTagsTable'
import RoutineBarcodeDetails from 'components/common/BarcodeDetails'
import TabGroup, {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
} from 'components/common/TabGroup'
import UpdateImageText from './UpdateImageText'
import UpdateMeal from './UpdateMeal'
import UploadMealImage from './UploadMealImage'

type Tab = 'images' | 'info' | 'qr-codes' | 'tags'

const TAB_INDEX_ROUTES: Record<number, Tab> = {
  0: 'info',
  1: 'images',
  2: 'qr-codes',
  3: 'tags',
}

const form = reduxForm({
  form: 'selectTerm',
})

const MealDetail = (): JSX.Element => {
  const navigate = useNavigate()

  const { mealid, tab: tabParam } = useParams<{ mealid: string; tab?: Tab }>()

  const dispatch = useAppDispatch()

  const allMealTags = useAppSelector((state) => state.mealTags.allMealTags)
  const meal = useAppSelector((state) => state.meals.meal)

  const [selectedTabIndex, setSelectedTabIndex] = useState(() => {
    const tabIndex = findKey(TAB_INDEX_ROUTES, (tab) => tab === tabParam)

    return tabIndex ? Number(tabIndex) : 0
  })
  const [showRemoveFromTermModal, setShowRemoveFromTermModal] = useState(false)

  function onRemoveMealTagFromMeal(tagID: number) {
    if (!mealid) {
      return
    }

    dispatch(removeMealTagFromMeal(mealid, tagID))
  }

  const onRemoveFromTerm = () => {
    if (!mealid) {
      return
    }

    const updatedMeal: SaveMealRequestBody | null = isMeal(meal)
      ? {
          ...meal,
          surchargeCents: meal.surchargeCents.toString(),
          termid: '0',
        }
      : null

    if (!updatedMeal) {
      return
    }

    dispatch(updateMeal(mealid, updatedMeal)).then((response) => {
      if (response && response.payload) {
        dispatch(showSuccessNotification('Meal removed from term succesfully.'))
      }
    })

    toggleRemoveFromTermModal()
  }

  const toggleRemoveFromTermModal = () => {
    setShowRemoveFromTermModal((showModal) => !showModal)
  }

  const onDuplicateMeal = () => {
    if (!mealid) {
      return
    }

    dispatch(duplicateMeal(mealid)).then((duplicatedMealID) => {
      if (duplicatedMealID !== undefined) {
        navigate(`/meals/${duplicatedMealID}`)
      }
    })
  }

  function deleteImage(image: MealImage) {
    if (!mealid) {
      return
    }

    dispatch(deleteImageForMeal(mealid, image))
  }

  useEffect(() => {
    document.title = `Glaze | Update Meal ID #${mealid}`
  }, [mealid])

  useEffect(() => {
    if (mealid) {
      dispatch(getMealInfo(mealid))
    }
  }, [dispatch, mealid])

  useEffect(() => {
    dispatch(getAllMealTags())
  }, [dispatch])

  return (
    <div>
      <ConfirmationModal
        buttonText="Remove from Term"
        handleClick={onRemoveFromTerm}
        heading="Are you sure you want to remove this meal from this term?"
        isOpen={showRemoveFromTermModal}
        onCloseModal={toggleRemoveFromTermModal}
      >
        <div>
          {isMeal(meal) && (
            <>
              <p>{meal.title}</p>
              <p>Meal ID #{meal.id}</p>
            </>
          )}
        </div>
      </ConfirmationModal>

      <div>
        {isMeal(meal) && (
          <h2 className="mb-4 font-serif text-3xl font-bold">
            {getAdminScope(MEALS_WRITE) && <span>Update</span>} {meal.title}{' '}
            (Meal ID #{meal.id})
          </h2>
        )}
      </div>

      <TabGroup
        onChange={(index) => {
          navigate(`../meals/${mealid}/${TAB_INDEX_ROUTES[index]}`)

          setSelectedTabIndex(index)
        }}
        selectedIndex={selectedTabIndex}
      >
        <TabList>
          <Tab>Meal Info</Tab>
          <Tab>Images</Tab>
          <Tab>QR Code</Tab>
          <Tab>Meal Tags</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            {isMeal(meal) && mealid && meal.id && (
              <UpdateMeal
                meal={meal}
                mealid={mealid}
                onDuplicateMeal={onDuplicateMeal}
                toggleRemoveFromTermModal={toggleRemoveFromTermModal}
              />
            )}
          </TabPanel>
          <TabPanel>
            <div className="flex space-x-4">
              <div className="w-6/12">
                <p className="mb-4">
                  For &quot;key&quot; field:
                  <ul className="ml-4 list-disc pl-4">
                    <li>cell_tile - Meals page</li>
                    <li>cell_detail - Meal detail page</li>
                    <li>internal_only - Do not display this image in apps</li>
                    <li>raw - Uncooked images</li>
                    <li>cooked - Cooked images, but not plated</li>
                    <li>cooked_angle - Cooked, plated image at 3/4 angle</li>
                  </ul>
                </p>

                {isMeal(meal) &&
                  mealid &&
                  meal.images &&
                  meal.images.map((image, index) => {
                    return (
                      <div key={index} className="m-4 mb-10 space-y-2">
                        <div className="relative w-20">
                          <img
                            alt={image.alt}
                            className="rounded-full border border-grey-903 object-cover"
                            height="70"
                            src={`${image.url}`}
                            width="70"
                          />
                          {getAdminScope(MEALS_WRITE) && (
                            <button
                              className="absolute right-0 top-0 flex h-7 w-7 items-center justify-center rounded-full bg-red-902 font-bold text-white-900 hover:bg-red-901"
                              onClick={() => deleteImage(image)}
                            >
                              ×
                            </button>
                          )}
                        </div>

                        <UpdateImageText
                          alt={image.alt}
                          caption={image.caption}
                          dominantColor={image.dominantColor}
                          hash={image.hash}
                          imageTextKey={image.key}
                          mealid={mealid}
                          readOnly={!getAdminScope(MEALS_WRITE)}
                        />
                      </div>
                    )
                  })}
              </div>

              <div className="w-6/12">
                {getAdminScope(MEALS_WRITE) && mealid && (
                  <UploadMealImage mealid={mealid} />
                )}
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            {isMeal(meal) && (
              <div>
                {meal.routineMetadataObjects &&
                meal.routineMetadataObjects.length > 0 ? (
                  <div>
                    <p className="mb-8">
                      To save, right click and select &quot;Save Image As&quot;
                    </p>

                    <div className="flex space-x-8">
                      {sortBy(
                        meal.routineMetadataObjects,
                        (routine) => routine.barcodeIdentifier
                      ).map((routine) => {
                        const qrCodeValue = getMealCameraUrl({
                          barcodeIdentifier: routine.barcodeIdentifier,
                          mealID: meal.id,
                        })

                        const barcode = getMealBarcode({
                          barcodeIdentifier: routine.barcodeIdentifier,
                          mealID: meal.id,
                        })

                        return (
                          <RoutineBarcodeDetails
                            key={barcode}
                            qrCodeValue={qrCodeValue}
                            routineBarcode={barcode}
                            title={routine.marketingTitle}
                          />
                        )
                      })}
                    </div>
                  </div>
                ) : (
                  <div className="max-w-md">
                    <ErrorDisplay
                      wayOut="For any questions, please contact #proj-bangarang."
                      whatHappened="No routines found."
                      why="At least one routine is required to generate a QR Code."
                    />
                  </div>
                )}
              </div>
            )}
          </TabPanel>
          <TabPanel>
            <div className="w-8/12">
              {isMeal(meal) && meal.tags && !meal.tags.length && (
                <p>This meal has no meal tags.</p>
              )}

              {isMeal(meal) && meal.tags && meal.tags.length > 0 && (
                <MealTagsTable
                  handleRemove={onRemoveMealTagFromMeal}
                  tags={meal.tags}
                />
              )}

              {getAdminScope(MEALS_WRITE) && mealid && (
                <AddTagToMealForm allMealTags={allMealTags} mealid={mealid} />
              )}
            </div>
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </div>
  )
}

export default form(MealDetail)
