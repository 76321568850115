import { lazy, ReactNode, Suspense, useEffect, useState } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

import { analytics } from 'utils/analytics'

import { useAuth } from 'contexts/auth'
import AddCouponCode from 'components/marketing/AddCouponCode'
import AddMeal from 'components/meals/AddMeal'
import AddMealTag from 'components/meal-tags/AddMealTag'
import Alert from 'components/common/Alert'
import CacheFlusher from 'components/caches/CacheFlusher'
import CouponCodeDetail from 'components/marketing/CouponCodeDetail'
import CouponCodes from 'components/marketing/CouponCodes'
import Dashboard from 'components/dashboard/Dashboard'
import EditMenu from 'components/meals/EditMenu'
import Header from 'components/common/Header'
import HomePage from 'components/pages/HomePage'
import Imp from 'components/imp/Imp'
import ImportTermMeals from 'components/meals/ImportTermMeals'
import LeveratorAdmin from 'components/marketing/leverator/LeveratorAdmin'
import Login from 'components/auth/Login'
import Logout from 'components/auth/Logout'
import Marketing from 'components/marketing/Marketing'
import MDDLevers from 'components/marketing/MDDLevers'
import MealDetail from 'components/meals/MealDetail'
import Meals from 'components/meals/Meals'
import MealsInDevelopment from 'components/meals/MealsInDevelopment'
import MealTagDetail from 'components/meal-tags/MealTagDetail'
import MealTags from 'components/meal-tags/MealTags'
import MenuEditor from './menuEditor/MenuEditor'
import MenuLayoutPage from './menuLayout/MenuLayoutPage'
import MenuProductTermListingsPage from './menuProducts/MenuProductTermListingsPage'
import NewUser from 'components/user/NewUser'
import NotFoundPage from 'components/pages/NotFoundPage'
import OvenShippingDelay from 'components/marketing/OvenShippingDelay'
import PackslipUpload from 'components/packslips/Upload'
import Qvc from 'components/qvc/Qvc'
import RealTimeGuidance from 'components/real-time-guidance/RealTimeGuidance'
import SlotMapGroupsPage from './meal-tags/SlotMapGroupsPage'
import SoldOutCountsPage from './meals/SoldOutCountsPage'
import TermMealQRCodes from 'components/meals/TermMealQRCodes'
import TermMenus from 'components/meals/TermMenus'
import TermSideswap from 'components/meals/TermSideswap'
import UpcomingMealsEmailData from 'components/marketing/UpcomingMealsEmailData'
import User from 'components/user/User'

const ReactQueryDevtoolsProduction = lazy(() =>
  import('@tanstack/react-query-devtools/build/lib/index.prod.js').then(
    (d) => ({
      default: d.ReactQueryDevtools,
    })
  )
)

const App = (): JSX.Element => {
  const location = useLocation()
  const { isLoggedIn } = useAuth()

  const [showDevtools, setShowDevtools] = useState(false)

  useEffect(() => {
    // eslint-disable-next-line
    // @ts-ignore
    window.toggleDevtools = () => setShowDevtools((old) => !old)
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  useEffect(() => {
    analytics.page()
  }, [location.pathname])

  return (
    <div className="h-full w-full">
      <Header />

      <Alert />

      {isLoggedIn ? (
        <Routes>
          <Route
            element={
              <OldLayout>
                <HomePage />
              </OldLayout>
            }
            path="/"
          />
          <Route
            element={
              <OldLayout>
                <Logout />
              </OldLayout>
            }
            path="/logout"
          />
          <Route
            element={
              <NewLayout>
                <NewUser />
              </NewLayout>
            }
            path="/users/new"
          />
          <Route
            element={
              <OldLayout>
                <Dashboard />
              </OldLayout>
            }
            path="/users"
          />
          <Route
            element={
              <OldLayout>
                <Imp />
              </OldLayout>
            }
            path="/user/:userid/oven/:ovenid"
          />
          <Route
            element={
              <OldLayout>
                <User />
              </OldLayout>
            }
            path="/user/:userid/*"
          />
          <Route
            element={
              <OldLayout>
                <MealsInDevelopment />
              </OldLayout>
            }
            path="/meals/in-development"
          />
          <Route
            element={
              <OldLayout>
                <AddMeal />
              </OldLayout>
            }
            path="/meals/add"
          />
          <Route
            element={<Navigate replace to="info" />}
            path="/meals/:mealid"
          />
          <Route
            element={
              <OldLayout>
                <MealDetail />
              </OldLayout>
            }
            path="/meals/:mealid/:tab"
          />
          <Route
            element={
              <OldLayout>
                <Meals />
              </OldLayout>
            }
            path="/meals"
          />
          <Route
            element={
              <OldLayout>
                <AddMealTag />
              </OldLayout>
            }
            path="/meal-tags/add"
          />
          <Route
            element={
              <OldLayout>
                <MealTagDetail />
              </OldLayout>
            }
            path="/meal-tags/:tagid"
          />
          <Route
            element={
              <OldLayout>
                <MealTags />
              </OldLayout>
            }
            path="/meal-tags"
          />

          <Route
            element={
              <NewLayout>
                <SlotMapGroupsPage />
              </NewLayout>
            }
            path="/slot-map-groups/*"
          />

          <Route
            element={<Navigate replace to="meals" />}
            path="/terms/:termid/menu-display-orders"
          />
          <Route
            element={
              <NewLayout>
                <MenuLayoutPage />
              </NewLayout>
            }
            path="/terms/:termid/menu-display-orders/*"
          />
          <Route
            element={<Navigate replace to="info" />}
            path="/terms/:termid/menu-products/:productid"
          />
          <Route
            element={<Navigate replace to="info" />}
            path="/menu-products/:productid"
          />
          <Route
            element={
              <NewLayout>
                <MenuProductTermListingsPage />
              </NewLayout>
            }
            path="/terms/:termid/menu-products/:productid/:tab"
          />
          <Route
            element={
              <OldLayout>
                <TermMealQRCodes />
              </OldLayout>
            }
            path="/terms/:termid/qr-codes"
          />
          <Route
            element={
              <OldLayout>
                <TermSideswap />
              </OldLayout>
            }
            path="/terms/:termid/customize-it"
          />
          <Route
            element={
              <NewLayout>
                <SoldOutCountsPage />
              </NewLayout>
            }
            path="/terms/:termid/sold-out-counts"
          />
          <Route
            element={
              <NewLayout>
                <SoldOutCountsPage />
              </NewLayout>
            }
            path="/terms/:termid/sold-out-counts"
          />
          <Route
            element={
              <OldLayout>
                <ImportTermMeals />
              </OldLayout>
            }
            path="/terms/:termid/create-term-meals"
          />
          <Route
            element={
              <OldLayout>
                <EditMenu />
              </OldLayout>
            }
            path="/terms/:termID/menus/edit/:menuID"
          />
          <Route
            element={
              <NewLayout>
                <MenuEditor />
              </NewLayout>
            }
            path="/terms/:termID/menus/editor"
          />
          <Route
            element={
              <OldLayout>
                <TermMenus />
              </OldLayout>
            }
            path="/terms/:termID/menus"
          />
          <Route
            element={
              <OldLayout>
                <CacheFlusher />
              </OldLayout>
            }
            path="/cache-flusher"
          />
          <Route
            element={
              <OldLayout>
                <Qvc />
              </OldLayout>
            }
            path="/qvc"
          />
          <Route
            element={
              <OldLayout>
                <UpcomingMealsEmailData />
              </OldLayout>
            }
            path="/marketing/meals-data"
          />
          <Route
            element={
              <OldLayout>
                <Marketing />
              </OldLayout>
            }
            path="/marketing"
          />
          <Route
            element={
              <OldLayout>
                <AddCouponCode />
              </OldLayout>
            }
            path="/coupon-codes/add"
          />
          <Route
            element={
              <OldLayout>
                <CouponCodeDetail />
              </OldLayout>
            }
            path="/coupon-codes/:code"
          />
          <Route
            element={
              <OldLayout>
                <CouponCodes />
              </OldLayout>
            }
            path="/coupon-codes"
          />
          <Route
            element={
              <OldLayout>
                <MDDLevers />
              </OldLayout>
            }
            path="/mdd-levers"
          />
          <Route
            element={
              <OldLayout>
                <OvenShippingDelay />
              </OldLayout>
            }
            path="/oven-shipping-delay"
          />
          <Route
            element={
              <OldLayout>
                <LeveratorAdmin />
              </OldLayout>
            }
            path="/leverator"
          />
          <Route
            element={
              <OldLayout>
                <PackslipUpload />
              </OldLayout>
            }
            path="/packslip-upload"
          />
          <Route
            element={
              <OldLayout>
                <RealTimeGuidance />
              </OldLayout>
            }
            path="/real-time-guidance"
          />
          <Route element={<Navigate to="/users" />} path="/login" />
          <Route
            element={
              <OldLayout>
                <NotFoundPage />
              </OldLayout>
            }
            path="*"
          />
        </Routes>
      ) : (
        <Routes>
          <Route
            element={
              <OldLayout>
                <HomePage />
              </OldLayout>
            }
            path="/"
          />
          <Route
            element={
              <OldLayout>
                <Login />
              </OldLayout>
            }
            path="/login"
          />

          <Route
            element={
              <Navigate
                to={
                  location.pathname === '/login' ||
                  location.pathname === '/logout'
                    ? '/login'
                    : `/login?redirectURL=${location.pathname}`
                }
              />
            }
            path="*"
          />
        </Routes>
      )}

      {showDevtools && (
        <Suspense fallback={null}>
          <ReactQueryDevtoolsProduction />
        </Suspense>
      )}
    </div>
  )
}

export default App

const NewLayout = ({ children }: { children: ReactNode }) => {
  return (
    <div className="grid min-h-full w-full bg-grey-0 px-4 pt-24 font-sans-new">
      {children}
    </div>
  )
}

const OldLayout = ({ children }: { children: ReactNode }) => {
  return <div className="mt-5 px-4 pb-5 pt-24">{children}</div>
}
