import { clsx } from 'clsx'
import { ReactNode } from 'react'
import { ViewType } from './utils'

const MenuGridLayout = ({
  children,
  viewType,
}: {
  children: ReactNode
  viewType: ViewType
}) => {
  return (
    <div
      className={clsx('grid', {
        'w-[780px] grid-cols-2 gap-x-8 gap-y-12': viewType === 'desktop',
        'w-[350px] grid-cols-1 gap-12': viewType === 'mobile',
      })}
    >
      {children}
    </div>
  )
}

export default MenuGridLayout
