import { ComponentProps } from 'react'
import {
  FieldValues,
  Path,
  PathValue,
  UseControllerProps,
  useController,
} from 'react-hook-form'
import { Combobox, FormGroup } from '@tovala/component-library'

export type FormComboboxRHFProps<T extends FieldValues> = {
  label?: string
} & Omit<ComponentProps<typeof Combobox>, 'hasError' | 'onChange' | 'value'> &
  UseControllerProps<T>

export const FormComboboxRHF = <T extends FieldValues>({
  control,
  defaultValue,
  id,
  label,
  name,
  rules,
  ...rest
}: FormComboboxRHFProps<T>) => {
  const {
    field,
    fieldState: { error, isTouched },
  } = useController({
    name,
    control,
    defaultValue,
    rules,
  })

  const hasError = !!(error && !isTouched)

  return (
    <FormGroup error={error?.message} label={label} labelFor={id}>
      <Combobox
        {...field}
        {...rest}
        hasError={hasError}
        id={id}
        onChange={(option) => {
          const optionValue = option as PathValue<T, Path<T>>
          field.onChange(optionValue)
        }}
      />
    </FormGroup>
  )
}
