const BackgroundImageHeaderButton = () => {
  return (
    <button className="flex w-24 flex-col items-center space-y-1 text-center">
      <div className="flex h-10 w-10 flex-col items-center justify-center space-y-1 bg-grey-3">
        <div className="h-1 w-1/2 bg-grey-4" />
        <div className="h-1 w-3/4 bg-grey-4" />
      </div>
      <p className="text-k/13_120">Background Image Header</p>
    </button>
  )
}

export default BackgroundImageHeaderButton
