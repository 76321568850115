import { useEffect } from 'react'

import H1 from 'components/common/H1'
import MealForm from './MealForm'

const AddMeal = (): JSX.Element => {
  useEffect(() => {
    document.title = `Glaze | Add Meal`
  }, [])

  return (
    <div className="w-9/12">
      <H1>Add Meal</H1>
      <MealForm form="addMeal" />
    </div>
  )
}

export default AddMeal
