import { APIErrorDisplay, ButtonLoading } from '@tovala/component-library'
import {
  ErrorCodeMessageMapCombinedAPI,
  useSetQAMode,
  useUserV0,
  UserV1,
} from '@tovala/browser-apis-combinedapi'
import { Form, Formik, FormikErrors } from 'formik'
import { ReactNode, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { getAdminScope, USERS_WRITE } from '../../utils/getAdminScope'
import { getUserId } from 'utils/getUserId'
import { getUserInfo } from '../../actions/auth'
import { makeGenericFallbackError } from 'utils/errors'
import { successHandler } from 'actions/notifications'

import { useAppDispatch } from 'hooks'
import { useEditUser } from 'hooks/combinedAPI/users'
import AlertInline from 'components/common/AlertInline'
import FormikInput from 'components/common/FormikInput'
import FormikRadio from 'components/common/FormikRadio'
import FormikTextarea from 'components/common/FormikTextarea'

interface EditUserFormData {
  email: string
  name: string
  notes: string
}

interface QAModeFormData {
  enabled: 'false' | 'true'
}

const EDIT_USER_ERRORS: ErrorCodeMessageMapCombinedAPI<ReactNode> = {
  Fallback: makeGenericFallbackError({ action: 'edit the user' }),
}

const SET_QA_MODE_ERRORS: ErrorCodeMessageMapCombinedAPI<ReactNode> = {
  Fallback: makeGenericFallbackError({ action: 'update QA mode' }),
}

const EditUser = ({ user }: { user: UserV1 }) => {
  const glazeUserID = getUserId()

  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const userID = user.id

  const { data: userV0 } = useUserV0({ userID })

  const {
    error: editUserError,
    isError: hasEditUserError,
    isLoading: isEditingUser,
    mutate: editUser,
  } = useEditUser({
    onSuccess: () => {
      successHandler(dispatch, 'User info updated successfully.')
    },
  })

  const {
    error: setQAModeError,
    isError: hasSetQAModeError,
    isLoading: isUpdatingQAMode,
    mutate: setQAMode,
  } = useSetQAMode({
    onSuccess: (_data, { data, userID }) => {
      successHandler(dispatch, `QA Mode ${data.enabled ? 'on' : 'off'}.`)

      // The pre-react-query code did not fetch the v1 user and full customer object
      // on success. We can stop doing that once this part is in react-query as well.
      dispatch(getUserInfo(userID))
    },
  })

  useEffect(() => {
    document.title = `Glaze | User #${userID} - Edit User`

    if (!getAdminScope(USERS_WRITE)) {
      navigate(`/user/${userID}/view`)
    }
  }, [navigate, userID])

  return (
    <div className="space-y-4 font-sans-new">
      {user.info.qaModeEnabled && (
        <AlertInline alertStyle="warning">
          <strong>Note:</strong> This user is in QA Mode.
        </AlertInline>
      )}

      <div className="flex space-x-8">
        <div className="w-8/12 space-y-8">
          <h2 className="text-k/28_130">Edit User #{user.id}</h2>
          <Formik<EditUserFormData>
            enableReinitialize
            initialValues={{
              email: user.info.email,
              name: user.info.name,
              notes: userV0?.notes ?? '',
            }}
            onSubmit={(formData) => {
              const { email, name, notes } = formData

              editUser({
                data: {
                  email: email.trim(),
                  name,
                  notes,
                },
                userID,
              })
            }}
            validate={validateForm}
          >
            <Form className="space-y-8">
              <div className="space-y-4">
                <FormikInput label="Name" name="name" />
                <FormikInput
                  disabled={user.id === glazeUserID}
                  label="Email"
                  name="email"
                  type="email"
                />
                {user.id === glazeUserID && (
                  <p className="text-body-sm text-grey-9">
                    Editing email is disabled on your own account
                  </p>
                )}
                <FormikTextarea label="Notes" name="notes" rows={4} />
              </div>

              <div className="space-y-4">
                {hasEditUserError && (
                  <APIErrorDisplay
                    error={editUserError}
                    errorCodeMessageMap={EDIT_USER_ERRORS}
                  />
                )}

                <ButtonLoading isLoading={isEditingUser} size="large">
                  Save
                </ButtonLoading>
              </div>
            </Form>
          </Formik>
        </div>

        <div className="w-4/12 space-y-4">
          <div className="space-y-2">
            <h3 className="text-k/20_125">QA Mode</h3>
            <p className="text-h/14_120">
              A way for us to internally check a term before it becomes
              available to a user. Only use on Tovala users.
            </p>
          </div>

          <Formik<QAModeFormData>
            enableReinitialize
            initialValues={{
              enabled: user.info.qaModeEnabled ? 'true' : 'false',
            }}
            onSubmit={(formData) => {
              setQAMode({
                data: { enabled: formData.enabled === 'true' },
                userID,
              })
            }}
          >
            <Form className="space-y-4">
              <div className="flex space-x-4">
                <FormikRadio label="ON" name="enabled" value="true" />
                <FormikRadio label="OFF" name="enabled" value="false" />
              </div>

              {hasSetQAModeError && (
                <APIErrorDisplay
                  error={setQAModeError}
                  errorCodeMessageMap={SET_QA_MODE_ERRORS}
                />
              )}

              <ButtonLoading isLoading={isUpdatingQAMode} size="medium">
                Save
              </ButtonLoading>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default EditUser

function validateForm(values: EditUserFormData) {
  const errors: FormikErrors<EditUserFormData> = {}

  if (!values.email) {
    errors.email = 'Please enter a valid email'
  }

  return errors
}
