import { get, post } from './baseAPI'

import { MealAdmin, TermStatus } from '@tovala/browser-apis-combinedapi'

export type CreatePublicTermMenusResponse = TermStatus[]

export type GetMeal = {
  mealID: number
}

export type GetMealResponse = MealAdmin

const ENDPOINTS = {
  CREATE_PUBLIC_TERM_MENUS: () => ({
    path: `/menus/publicTerm`,
    version: 'v1' as const,
  }),
  GET_MEAL: ({ mealID }) => ({
    path: `/meals/${mealID}`,
    version: 'v1' as const,
  }),
}

export async function createPublicTermMenus(): Promise<CreatePublicTermMenusResponse> {
  return post({
    ...ENDPOINTS.CREATE_PUBLIC_TERM_MENUS(),
  })
}

export async function getMeal({ mealID }: GetMeal): Promise<GetMealResponse> {
  return get({
    ...ENDPOINTS.GET_MEAL({ mealID }),
  })
}
