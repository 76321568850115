import { User } from '@tovala/browser-apis-combinedapi'

import { get, put } from './baseAPI'

export interface DeactivateUser {
  userID: number
}

export type DeactivateUserResponse = {
  error: false
  message: 'OK'
}

export interface EditUser {
  data: Partial<{ email: string; name: string; notes: string }>
  userID: number
}

export type EditUserResponse = User

export interface GetResetPasswordLink {
  userID: number
}

export interface GetResetPasswordLinkResponse {
  link: string
}

export const ENDPOINTS = {
  DEACTIVATE_USER: ({ userID }: Pick<DeactivateUser, 'userID'>) => ({
    path: `/users/${userID}/deactivate`,
    version: 'v0' as const,
  }),
  EDIT_USER: ({ userID }: Pick<EditUser, 'userID'>) => ({
    path: `/users/${userID}`,
    version: 'v0' as const,
  }),
  GET_RESET_PASSWORD_LINK: ({
    userID,
  }: Pick<GetResetPasswordLink, 'userID'>) => ({
    path: `/users/${userID}/passwordResetLink`,
    version: 'v0' as const,
  }),
}

export async function deactivateUser({
  userID,
}: DeactivateUser): Promise<DeactivateUserResponse> {
  return put(ENDPOINTS.DEACTIVATE_USER({ userID }))
}

export async function editUser({
  data,
  userID,
}: EditUser): Promise<EditUserResponse> {
  return put({ data, ...ENDPOINTS.EDIT_USER({ userID }) })
}

export async function getResetPasswordLink({
  userID,
}: GetResetPasswordLink): Promise<GetResetPasswordLinkResponse> {
  return get(ENDPOINTS.GET_RESET_PASSWORD_LINK({ userID }))
}
