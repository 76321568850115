import { Formik, Form } from 'formik'
import { LeveratorInAppOption } from '@tovala/browser-apis-combinedapi'
import moment from 'moment'
import ReactTable, { TableProps } from 'react-table'

import {
  createLeveratorInAppOption,
  deleteLeveratorInAppOption,
} from '../../../actions/marketing'

import { useAppDispatch, useAppSelector } from 'hooks'
import Button from 'components/common/Button'
import Hr from 'components/common/Hr'
import InputField from 'components/common/InputField'

interface FormData {
  notes: string
  storyboardName: string
  title: string
  view_controller: string
}

const InAppOptions = () => {
  const dispatch = useAppDispatch()

  const allLeveratorInAppOptions = useAppSelector(
    (state) => state.marketing.allLeveratorInAppOptions
  )

  const columns: TableProps<LeveratorInAppOption>['columns'] = [
    {
      Header: 'Title',
      accessor: 'title',
      width: 200,
    },
    {
      Header: 'Storyboard Name',
      accessor: 'storyboardName',
      width: 150,
    },
    {
      Header: 'View Controller',
      accessor: 'viewController',
      width: 300,
    },
    {
      Header: 'Notes',
      accessor: 'notes',
      width: 200,
    },
    {
      Header: 'Created',
      accessor: 'created',
      Cell: (props: { value: string }) => (
        <span>{moment(props.value).format('MM/DD/YYYY h:mm a')}</span>
      ),
    },
    {
      Header: 'Updated',
      accessor: 'updated',
      Cell: (props: { value: string }) => (
        <span>{moment(props.value).format('MM/DD/YYYY h:mm a')}</span>
      ),
    },
    {
      accessor: 'id',
      width: 100,
      Cell: (props: { value: string }) => (
        <Button
          onClick={() => handleDeleteInAppOption(props.value)}
          size="large"
        >
          Delete
        </Button>
      ),
    },
  ]

  const handleInAppOptionSubmit = (formData: FormData) => {
    dispatch(createLeveratorInAppOption(formData))
  }

  const handleDeleteInAppOption = (optionID: string) => {
    dispatch(deleteLeveratorInAppOption(optionID))
  }

  return (
    <div>
      <p className="mb-4">
        <strong>Create In App Option</strong>
      </p>

      <Formik<FormData>
        enableReinitialize
        initialValues={{
          title: '',
          storyboardName: '',
          view_controller: '',
          notes: '',
        }}
        onSubmit={handleInAppOptionSubmit}
      >
        <Form className="space-y-4">
          <InputField label="Title" name="title" type="text" />

          <InputField
            label="Storyboard Name"
            name="storyboardName"
            type="text"
          />

          <InputField
            label="View Controller"
            name="viewController"
            type="text"
          />

          <InputField label="Notes" name="notes" type="text" />

          <div className="mt-4">
            <Button size="large" type="submit">
              Create In App Option
            </Button>
          </div>
        </Form>
      </Formik>

      <Hr />

      <ReactTable
        className="-striped -highlight"
        columns={columns}
        data={allLeveratorInAppOptions}
        defaultPageSize={20}
        filterable
        minRows={0}
      />
    </div>
  )
}

export default InAppOptions
