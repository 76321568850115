import { parse } from 'papaparse'
import { Term, TermMenu } from '@tovala/browser-apis-combinedapi'

import {
  getMealDocDataForListings,
  getMealDocDataForMenuProduct,
  mealDoc,
} from 'utils/mealDocDataImport'
import { MenuProductTermListingsFormData } from './helpers'

import FileDropzone from '../common/FileDropzone'

export interface Props {
  initialData: MenuProductTermListingsFormData
  handleImport(parsedCSVData: {
    formData: MenuProductTermListingsFormData
    tagsToAdd: string[]
  }): void
  menusWithListingIDs: {
    listingID: string | undefined
    menu: TermMenu
  }[]
  term: Term
}

const mealDocProperties = {
  allergens: mealDoc.allergens,
  expiration: mealDoc.expiration,
  ingredients: mealDoc.ingredients,
  garnishes: mealDoc.garnishes,
  mealTags: mealDoc.mealTags,
  nutritionalInfo: mealDoc.nutritionalInfo,
  prepSteps: mealDoc.prepSteps,
  story: mealDoc.story,
  subtitle: mealDoc.subtitle,
  surchargeCents: mealDoc.surchargeCents,
  title: mealDoc.title,
}

const mealDocPropertiesListings = {
  expiration: mealDoc.expiration,
  surchargeCents: mealDoc.surchargeCents,
}

const ImportMenuProductTermListingsData = ({
  initialData,
  handleImport,
  menusWithListingIDs,
  term,
}: Props) => {
  const handleDrop = (files: File[]) => {
    parse<string[]>(files[0], {
      skipEmptyLines: false,
      complete: (results) => {
        const { formData: formDataMenuProduct, tagsToAdd } =
          getMealDocDataForMenuProduct({
            initialData,
            mealDocProperties,
            parsedData: results.data,
          })

        const formDataListings = getMealDocDataForListings({
          initialData: {
            listings: initialData.listings,
          },
          mealDocProperties: mealDocPropertiesListings,
          menusWithListingIDs,
          parsedData: results.data,
          term,
        })

        const updatedData = { ...formDataMenuProduct, ...formDataListings }
        handleImport({ formData: updatedData, tagsToAdd })
      },
    })
  }

  return (
    <div>
      <FileDropzone
        accept={{ 'text/csv': ['.csv'] }}
        maxFiles={1}
        onDrop={handleDrop}
      />
    </div>
  )
}

export default ImportMenuProductTermListingsData
