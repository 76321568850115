import { createReducer } from '@reduxjs/toolkit'
import { MealAdmin, MealListItem } from '@tovala/browser-apis-combinedapi'

import * as types from '../actions/types'

const INITIAL_STATE: {
  allMeals: MealListItem[]
  meal: MealAdmin | Record<string, unknown>
} = {
  allMeals: [],
  meal: {},
}

export const meals = createReducer(INITIAL_STATE, {
  [types.GET_ALL_MEALS]: (state, action) => {
    state.allMeals = action.payload
  },
  [types.GET_MEAL_INFO]: (state, action) => {
    state.meal = action.payload
  },
})
