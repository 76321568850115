import { UserV1 } from '@tovala/browser-apis-combinedapi'

import { sendMealOrderReceipt } from '../../actions/user'

import { useAppDispatch } from 'hooks'
import Button from 'components/common/Button'
import Modal, { ModalBody, ModalHeader } from 'components/modals/Modal'

export interface Props {
  onCloseModal(): void
  termID: number
  user: UserV1
  userTermOrderID: string
}

const SendReceiptModal = ({
  onCloseModal,
  termID,
  user,
  userTermOrderID,
}: Props): JSX.Element => {
  const dispatch = useAppDispatch()

  return (
    <Modal onCloseModal={onCloseModal}>
      <ModalBody>
        <ModalHeader onClickClose={onCloseModal}>
          Send Meal Order Receipt
        </ModalHeader>
        <div className="w-[500px] space-y-4">
          <p>
            Send a meal order receipt to {user.info.email} for Term #{termID}.
          </p>

          <Button
            onClick={() => {
              dispatch(sendMealOrderReceipt(user.id, userTermOrderID))

              onCloseModal()
            }}
            size="large"
            type="button"
          >
            Send Receipt
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default SendReceiptModal
