export const ovenExchangeReasons = [
  {
    value: 'bakeout-emission',
    label: 'Bakeout Emission',
  },
  {
    value: 'broken-glass',
    label: 'Broken Glass',
  },
  {
    value: 'cleaning-mold',
    label: 'Cleaning/Mold',
  },
  {
    value: 'cosmetic-damaged-from-manufacturing',
    label: 'Cosmetic - Damaged from Manufacturing',
  },
  {
    value: 'cosmetic-damaged-in-transit',
    label: 'Cosmetic - Damaged in Transit',
  },
  {
    value: 'cosmetic-damaged-through-life',
    label: 'Cosmetic - Damaged Through Life',
  },
  {
    value: 'door-error',
    label: 'Door - Error',
  },
  {
    value: 'door-seal',
    label: 'Door - Seal',
  },
  {
    value: 'electrical-cut-out-during-cycle',
    label: 'Electrical - cut out during cycle',
  },
  {
    value: 'electrical-gfci',
    label: 'Electrical - GFCI',
  },
  {
    value: 'electrical-no-power',
    label: 'Electrical - No Power',
  },
  {
    value: 'electrical-other',
    label: 'Electrical - Other',
  },
  {
    value: 'electrical-unresponsive',
    label: 'Electrical - Unresponsive',
  },
  {
    value: 'fan-noise-not-working',
    label: 'Fan Noise/Not working',
  },
  {
    value: 'food-too-cooked-undercooked',
    label: 'Food - Too cooked/Undercooked',
  },
  {
    value: 'light-issue',
    label: 'Light Issue',
  },
  {
    value: 'lost',
    label: 'Lost',
  },
  {
    value: 'multiple',
    label: 'Multiple',
  },
  {
    value: 'noise-other',
    label: 'Noise - Other',
  },
  {
    value: 'scanner-issue',
    label: 'Scanner Issue',
  },
  {
    value: 'smell',
    label: 'Smell',
  },
  {
    value: 'steam-issue-boiler',
    label: 'Steam Issue - Boiler',
  },
  {
    value: 'temp-probe',
    label: 'Temp Probe',
  },
  {
    value: 'ui-issue',
    label: 'UI Issue',
  },
  {
    value: 'water-leak',
    label: 'Water - Leak',
  },
  {
    value: 'water-sensor',
    label: 'Water - Sensor',
  },
  {
    value: 'wifi-sync-issue',
    label: 'Wifi/Sync Issue',
  },
]
