import {
  CouponAmount,
  Customer,
  ProductPurchase,
  UserV1,
} from '@tovala/browser-apis-combinedapi'
import { createReducer } from '@reduxjs/toolkit'

import * as types from '../actions/types'

export interface InitialState {
  addressValidationMessage: string
  couponApplied: CouponAmount | ''
  customer: Customer | []
  isAddressValidateModalOpen: boolean
  productPurchases: ProductPurchase[]
  productTax: {
    priceWithDiscount: number
    skuPrice: number
    taxAmount: number
  } | null
  shipping: []
  unvalidatedAddress: {
    city: string
    line1: string
    line2: string
    name: string
    phone: string
    postal_code: string
    state: string
  }
  user: UserV1 | undefined
  validatedAddress: {
    address_type: string
    city: string
    country: string
    estimated_delivery_days: number | ''
    line1: string
    line2: string
    name: string
    phone: string
    postal_code: string
    ship_day_of_the_week?: string
    shipping_company?: string
    shipping_origin?: string
    shipping_service?: string
    state: string
    updated: string
    userid: number | ''
  }
  validateAddressButtonText: string
}

const INITIAL_STATE: InitialState = {
  addressValidationMessage: '',
  couponApplied: '',
  customer: [],
  isAddressValidateModalOpen: false,
  productPurchases: [],
  productTax: null,
  shipping: [],
  unvalidatedAddress: {
    city: '',
    line1: '',
    line2: '',
    name: '',
    phone: '',
    postal_code: '',
    state: '',
  },
  user: undefined,
  validatedAddress: {
    address_type: '',
    city: '',
    country: '',
    estimated_delivery_days: '',
    line1: '',
    line2: '',
    name: '',
    phone: '',
    postal_code: '',
    state: '',
    updated: '',
    userid: '',
  },
  validateAddressButtonText: '',
}

export const auth = createReducer(INITIAL_STATE, {
  [types.GET_PRODUCT_TAX_ESTIMATE]: (state, action) => {
    state.productTax = action.payload
  },
  [types.GET_COUPON_AMOUNT]: (state, action) => {
    state.couponApplied = action.payload
  },
  [types.GET_FULL_CUSTOMER_RECORD]: (state, action) => {
    state.customer = action.payload
  },
  [types.UPDATE_SHIPPING_ADDRESS]: (state, action) => {
    state.shipping = action.payload
  },
  [types.GET_PRODUCTS_PURCHASE_INFORMATION]: (state, action) => {
    state.productPurchases = action.payload
  },
  [types.TOGGLE_ADDRESS_VALIDATE_MODAL]: (state, action) => {
    state.isAddressValidateModalOpen = action.payload
  },
  [types.SET_ADDRESS_VALIDATION_MESSAGE]: (state, action) => {
    state.addressValidationMessage = action.payload
  },
  [types.SAVE_UNVALIDATED_ADDRESS]: (state, action) => {
    state.unvalidatedAddress = action.payload
  },
  [types.SAVE_VALIDATED_ADDRESS]: (state, action) => {
    state.validatedAddress = action.payload
  },
  [types.VALIDATE_ADDRESS_BUTTON_TEXT]: (state, action) => {
    state.validateAddressButtonText = action.payload
  },
  [types.GET_USER_INFO_V1]: (state, action) => {
    state.user = action.payload
  },
})
