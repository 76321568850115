import axios from 'axios'
import { MealTagSingle } from '@tovala/browser-apis-combinedapi'

import { API_URL, API_URL_V1, getHeaders } from './index'
import { AppThunk } from 'store'
import { errorHandler, successHandler } from './notifications'
import { GET_ALL_MEAL_TAGS, GET_MEAL_TAG_INFO } from './types'

// ================================
// Meal tag actions
// ================================

export function getAllMealTags(): AppThunk<void> {
  return function (dispatch) {
    axios
      .get(`${API_URL}/mealTags`, getHeaders())
      .then((response) => {
        dispatch({
          type: GET_ALL_MEAL_TAGS,
          payload: response.data,
        })
      })

      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

export function getMealTagInfo(tagId: number | string): AppThunk<void> {
  return function (dispatch) {
    axios
      .get<MealTagSingle>(`${API_URL_V1}/mealtags/${tagId}`, getHeaders())
      .then((response) => {
        dispatch({
          type: GET_MEAL_TAG_INFO,
          payload: response.data,
        })
      })

      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

export function addMealTag(tag: unknown): AppThunk<Promise<true | void>> {
  return function (dispatch) {
    return axios
      .post(`${API_URL_V1}/mealtags`, tag, getHeaders())
      .then((response) => {
        if (response.status === 200) {
          successHandler(dispatch, 'Success! Meal Tag added.')

          return true as const
        }
      })

      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

export function deleteMealTag(tagId: string): AppThunk<Promise<true | void>> {
  return function (dispatch) {
    return axios
      .delete(`${API_URL_V1}/mealtags/${tagId}`, getHeaders())
      .then((response) => {
        if (response.status === 200) {
          successHandler(dispatch, 'Success! Meal Tag deleted.')

          return true as const
        }
      })

      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

export function updateMealTag(tagid: number, tag: unknown): AppThunk<void> {
  return function (dispatch) {
    axios
      .put(`${API_URL_V1}/mealtags/${tagid}`, tag, getHeaders())
      .then((response) => {
        if (response.status === 200) {
          successHandler(dispatch, 'Success! Meal Tag updated.')
          getMealTagInfo(tagid)
          window.scrollTo(0, 0)
        }
      })

      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}
