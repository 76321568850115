import { addMealCash } from '../../actions/user'
import { getUserId } from 'utils/getUserId'
import { ReactSelectValue } from 'types/internal'
import {
  errorHandler,
  showSuccessNotification,
  successHandler,
} from '../../actions/notifications'

import { useAppDispatch } from 'hooks'
import { FormDataValidated as AddMarketingCreditFormDataValidated } from './AddMarketingCreditForm'
import {
  useInvalidateMealCash,
  useSkipWeek,
  useUnskipWeek,
} from '@tovala/browser-apis-combinedapi'

export interface RefundFormData {
  additionalCredit: 'no' | 'yes'
  affectedListingIDs: string[]
  affectedMealIDs: string[]
  amount: string
  notes: string
  reason: ReactSelectValue<string> | null
  refundShipping: boolean
  refundType: 'full' | 'partial'
  zdTicketNumber: string
}

const csAgent = getUserId()

export function useAddMarketingCreditsSubmit({
  onSuccess,
  userid,
}: {
  onSuccess(): void
  userid: number
}) {
  const dispatch = useAppDispatch()

  const { invalidateMealCashHistory } = useInvalidateMealCash()

  return {
    addMarketingCredits: (
      values: AddMarketingCreditFormDataValidated | RefundFormData
    ) => {
      if (!userid) {
        throw new Error('No user loaded')
      }

      const amount =
        typeof values.amount === 'string'
          ? Number(values.amount)
          : values.amount

      const data = {
        amount: amount * 100,
        notes: values.notes,
        reason: values.reason?.value ?? '',
        zendeskTicket: 'zdTicketNumber' in values ? values.zdTicketNumber : '',
        csAgent: csAgent ? `${csAgent}` : '',
      }

      return dispatch(addMealCash(userid, data)).then((response) => {
        if (response && response.payload) {
          onSuccess()

          dispatch(showSuccessNotification('Success! Marketing credits added.'))

          invalidateMealCashHistory(userid)

          return response
        }
      })
    },
  }
}

export const useHandleSkipChange = () => {
  const dispatch = useAppDispatch()

  const { mutate: skipWeek } = useSkipWeek({
    onError: (err) => {
      errorHandler(dispatch, err)
    },
    onSuccess: (_res, { data }) => {
      successHandler(dispatch, `Success! Term #${data.termid} skipped.`)
    },
  })

  const { mutate: unskipWeek } = useUnskipWeek({
    onError: (err) => {
      errorHandler(dispatch, err)
    },
    onSuccess: (_res, { data }) => {
      successHandler(dispatch, `Success! Term #${data.termid} unskipped.`)
    },
  })

  const handleSkipChange = ({
    isSkipped,
    termID,
    userID,
  }: {
    isSkipped: boolean
    termID: number
    userID: number
  }) => {
    const payload = { data: { termid: termID }, userID }

    if (!isSkipped) {
      skipWeek(payload)
    } else if (isSkipped) {
      unskipWeek(payload)
    }
  }

  return {
    handleSkipChange,
  }
}
