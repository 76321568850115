import { assign, createMachine } from 'xstate'

export const placeholderMachine = createMachine(
  {
    context: {
      placeholderIndex: null,
      queuedCurrentIndex: -1,
      queuedEntryIndex: -1,
    },
    id: 'placeholderMachine',
    initial: 'idle',
    schema: {
      context: {} as {
        placeholderIndex: number | null
        queuedCurrentIndex: number
        queuedEntryIndex: number
      },
      events: {} as
        | { type: 'clearPlaceholder' }
        | { index: number; type: 'setPlaceholder' },
    },
    tsTypes: {} as import('./placeholderMachine.typegen').Typegen0,

    states: {
      idle: {
        on: {
          clearPlaceholder: { actions: ['clearPlaceholder'] },
          setPlaceholder: {
            actions: ['setQueuedEntryIndex'],
            target: 'waitingToUpdatePlaceholder',
          },
        },
      },
      waitingToUpdatePlaceholder: {
        after: {
          // An arbitrary delay is added to ensure the user is hovering over the final
          // place they want to drop the item. A user could quickly be moving their mouse
          // over items and we don't want to update the layout for every single
          // mouse movement since it can become disorienting.
          200: [
            // If the indexes aren't equal after the delay, then the user dragged
            // their mouse elsewhere and we should start the delay over again.
            {
              actions: ['setPlaceholderIndex'],
              cond: 'areQueuedIndexesEqual',
              target: 'idle',
            },
            {
              actions: ['setQueuedEntryFromCurrent'],
              target: 'waitingToUpdatePlaceholder',
            },
          ],
        },
        on: {
          clearPlaceholder: { actions: ['clearPlaceholder'], target: 'idle' },
          setPlaceholder: { actions: ['setQueuedCurrentIndex'] },
        },
      },
    },
  },
  {
    actions: {
      clearPlaceholder: assign({
        placeholderIndex: null,
        queuedCurrentIndex: -1,
        queuedEntryIndex: -1,
      }),
      setQueuedCurrentIndex: assign({
        queuedCurrentIndex: (_ctx, event) => event.index,
      }),
      setQueuedEntryFromCurrent: assign({
        queuedEntryIndex: (ctx) => ctx.queuedCurrentIndex,
      }),
      setQueuedEntryIndex: assign({
        queuedCurrentIndex: (_ctx, event) => event.index,
        queuedEntryIndex: (_ctx, event) => event.index,
      }),
      setPlaceholderIndex: assign({
        placeholderIndex: (ctx) => ctx.queuedEntryIndex,
        queuedCurrentIndex: -1,
        queuedEntryIndex: -1,
      }),
    },
    guards: {
      areQueuedIndexesEqual: (ctx) =>
        ctx.queuedCurrentIndex === ctx.queuedEntryIndex,
    },
  }
)
