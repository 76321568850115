import { MenuComponentStandardizedBackgroundImageHeader } from '@tovala/browser-apis-menu-components'
import { BackgroundImageHeader as BackgroundImageHeaderComponent } from '@tovala/component-library'

const BackgroundImageHeader = ({
  properties,
}: {
  properties: MenuComponentStandardizedBackgroundImageHeader['properties']
}) => {
  return <BackgroundImageHeaderComponent {...properties} />
}

export default BackgroundImageHeader
