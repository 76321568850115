import { MealCashRecord, UserV1 } from '@tovala/browser-apis-combinedapi'
import moment from 'moment'
import ReactTable, { TableProps } from 'react-table'
import { useState } from 'react'

import { getAdminScope, USERS_WRITE } from '../../utils/getAdminScope'

import Button from 'components/common/Button'
import RemoveCreditModal from './RemoveCreditModal'

const UserCreditHistory = ({
  tovalaCashRecords,
  user,
}: {
  tovalaCashRecords: MealCashRecord[] | undefined
  user: UserV1
}): JSX.Element => {
  const [creditToRemove, setCreditToRemove] = useState<MealCashRecord | null>(
    null
  )
  const [isRemoveCreditModalOpen, setIsRemoveCreditModalOpen] = useState(false)

  const handleShowRemoveButton = (record: MealCashRecord) => {
    let showRemoveButton = false
    let mealCashCanBeRemoved = false
    let mealCashHasBeenRemoved = false

    // Meal cash added though Glaze can be removed
    // If it has already been removed, don't show the remove button
    if (
      tovalaCashRecords &&
      record.action === 'cs_mealcash' &&
      record.amount > 0
    ) {
      mealCashCanBeRemoved = true
      const matchingMealCashRemovalRecord = tovalaCashRecords.find(
        (cashRecord) =>
          cashRecord.action === 'cs_mealcash' &&
          cashRecord.notes.includes(record.id)
      )
      if (
        matchingMealCashRemovalRecord &&
        record.amount === Math.abs(matchingMealCashRemovalRecord.amount)
      ) {
        mealCashHasBeenRemoved = true
      }
    }

    if (mealCashCanBeRemoved && !mealCashHasBeenRemoved) {
      showRemoveButton = true
    }

    if (record.isVoided) {
      showRemoveButton = false
    }

    return showRemoveButton
  }

  const cashColumns: TableProps<MealCashRecord>['columns'] = [
    {
      Header: 'Created',
      accessor: 'created',
      width: 100,
      Cell: (props: { value: string }) => (
        <span>{moment(props.value).format('MM/DD/YYYY h:mm a')}</span>
      ),
      style: { whiteSpace: 'unset' },
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      width: 130,
      Cell: (props: { original: { isVoided: boolean }; value: number }) => (
        <span>
          ${(props.value / 100).toFixed(2)}{' '}
          {props.original.isVoided && <span>(VOIDED)</span>}
        </span>
      ),
    },
    {
      Header: 'Action',
      accessor: 'action',
      width: 80,
    },
    {
      Header: 'Reason',
      accessor: 'csReason',
      width: 120,
    },
    {
      Header: 'Affected Meals',
      accessor: 'csAffectedMeals',
      width: 100,
      Cell: (props: { value: string[] }) => (
        <span>{props.value.join(', ')}</span>
      ),
      style: { whiteSpace: 'unset' },
    },
    {
      Header: 'Affected Extras',
      accessor: 'csAffectedMenuProductNames',
      width: 100,
      Cell: (props: { value: string[] }) => (
        <span>{props.value.join(', ')}</span>
      ),
      style: { whiteSpace: 'unset' },
    },
    {
      Header: 'Term #',
      accessor: 'termID',
      width: 60,
    },
    {
      Header: 'ZenDesk Ticket',
      accessor: 'csZendeskTicket',
      width: 80,
    },
    {
      Header: 'Coupon Code',
      accessor: 'couponCode',
      width: 80,
    },

    {
      Header: 'CS Agent',
      accessor: 'csAgent',
      width: 60,
    },
    {
      Header: 'Notes',
      accessor: 'notes',
      width: 140,
      style: { whiteSpace: 'unset' },
    },
    {
      accessor: 'id',
      width: 120,
      show: getAdminScope(USERS_WRITE),
      Cell: (props: { original: MealCashRecord }) => {
        return handleShowRemoveButton(props.original) ? (
          <Button
            buttonStyle="grey"
            onClick={() => {
              setCreditToRemove(props.original)
              setIsRemoveCreditModalOpen(true)
            }}
            size="large"
          >
            Remove
          </Button>
        ) : null
      },
    },
  ]

  const getTrProps: TableProps<MealCashRecord>['getTrProps'] = (
    _state,
    rowInfo
  ) => {
    if (rowInfo) {
      return {
        style: {
          color: rowInfo.original.isVoided ? 'red' : 'black',
        },
      }
    }
    return {}
  }

  return (
    <div>
      {tovalaCashRecords && tovalaCashRecords.length === 0 && (
        <p>No meal credits history found.</p>
      )}

      {tovalaCashRecords && tovalaCashRecords.length > 0 && (
        <div className="mt-4">
          <ReactTable<MealCashRecord>
            className="-striped -highlight"
            columns={cashColumns}
            data={tovalaCashRecords}
            defaultPageSize={20}
            defaultSorted={[
              {
                id: 'created',
                desc: true,
              },
            ]}
            filterable
            getTrProps={getTrProps}
            minRows={0}
          />
          <i>Rows in red have been voided.</i>
        </div>
      )}

      {isRemoveCreditModalOpen && creditToRemove && (
        <RemoveCreditModal
          onCloseModal={() => {
            setCreditToRemove(null)
            setIsRemoveCreditModalOpen(false)
          }}
          records={[creditToRemove]}
          user={user}
        />
      )}
    </div>
  )
}

export default UserCreditHistory
