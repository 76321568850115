import { Button } from '@tovala/component-library'
import { FormInputRHF } from 'components/common/FormInputRHF'
import { FormTextareaRHF } from 'components/common/FormTextareaRHF'
import { Control, useFieldArray } from 'react-hook-form'
import { MenuProductTermListingsFormData } from './helpers'

const MenuProductFormFieldset = ({
  control,
  disabled,
}: {
  control: Control<MenuProductTermListingsFormData>
  disabled: boolean
}) => {
  return (
    <fieldset className="space-y-6" disabled={disabled}>
      <FormInputRHF
        control={control}
        id="title"
        label="Title"
        name="title"
        rules={{
          required: 'Please enter a title.',
        }}
      />

      <FormInputRHF
        control={control}
        id="subtitle"
        label="Subtitle"
        name="subtitle"
      />

      <FormTextareaRHF
        control={control}
        id="story"
        label="Story"
        name="story"
        rows={5}
      />

      <FormTextareaRHF
        control={control}
        id="ingredients"
        label="Ingredients"
        name="ingredients"
        rows={5}
      />

      <PrepStepsFieldArray control={control} />

      <NutritionalInfoFieldArray control={control} />

      <AllergensFieldArray control={control} />
    </fieldset>
  )
}

export default MenuProductFormFieldset

function PrepStepsFieldArray({
  control,
}: {
  control: Control<MenuProductTermListingsFormData>
}) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'prepSteps',
  })

  return (
    <div className="space-y-4">
      <table className="w-full">
        <thead>
          <tr className="border-b border-grey-900">
            <td className="w-1/4 p-2 align-bottom text-h/14_120 font-semibold">
              Prep Steps
            </td>
            <td className="w-[15%] p-2"></td>
          </tr>
        </thead>
        <tbody>
          {fields.map((field, index) => (
            <tr key={field.id}>
              <td className="p-2">
                <FormInputRHF
                  control={control}
                  name={`prepSteps.${index}.value`}
                />
              </td>

              <td className="p-2">
                <Button
                  buttonStyle="stroke"
                  onClick={() => remove(index)}
                  size="small"
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div>
        <Button
          buttonStyle="stroke"
          onClick={() => {
            append({ value: '' })
          }}
          size="small"
        >
          + Add Prep Step
        </Button>
      </div>
    </div>
  )
}

function AllergensFieldArray({
  control,
}: {
  control: Control<MenuProductTermListingsFormData>
}) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'allergens',
  })

  return (
    <div className="space-y-4">
      <table className="w-full">
        <thead>
          <tr className="border-b border-grey-900">
            <td className="w-1/4 p-2 align-bottom text-h/14_120 font-semibold">
              Allergens
            </td>
            <td className="w-[15%] p-2"></td>
          </tr>
        </thead>
        <tbody>
          {fields.map((field, index) => (
            <tr key={field.id}>
              <td className="p-2">
                <FormInputRHF
                  control={control}
                  name={`allergens.${index}.value`}
                />
              </td>

              <td className="p-2">
                <Button
                  buttonStyle="stroke"
                  onClick={() => remove(index)}
                  size="small"
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div>
        <Button
          buttonStyle="stroke"
          onClick={() => {
            append({ value: '' })
          }}
          size="small"
        >
          + Add Allergen
        </Button>
      </div>
    </div>
  )
}

function NutritionalInfoFieldArray({
  control,
}: {
  control: Control<MenuProductTermListingsFormData>
}) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'nutritionalInfo',
  })

  return (
    <div className="space-y-4">
      <div>
        <h2 className="text-k/20_110">Nutritional Info</h2>
        <p className="text-body-sm">
          <strong>Note:</strong> For key, use &quot;golden&quot; for featured
          nutritional info (i.e. calories, protein, carbs and fat)
        </p>
      </div>

      <table className="w-full">
        <thead>
          <tr className="border-b border-grey-900">
            <td className="w-[8%] p-2 align-bottom text-h/14_120 font-semibold">
              Item
            </td>
            <td className="w-[13%] p-2 align-bottom text-h/14_120 font-semibold">
              Key
            </td>
            <td className="w-1/4 p-2 align-bottom text-h/14_120 font-semibold">
              Name
            </td>
            <td className="w-[13%] p-2 align-bottom text-h/14_120 font-semibold">
              Value
            </td>
            <td className="w-[13%] p-2 align-bottom text-h/14_120 font-semibold">
              Unit
            </td>
            <td className="w-[15%] p-2"></td>
          </tr>
        </thead>
        <tbody>
          {fields.map((field, index) => (
            <tr key={field.id}>
              <td className="p-2">#{index + 1}</td>
              <td className="p-2">
                <FormInputRHF
                  control={control}
                  name={`nutritionalInfo.${index}.key`}
                />
              </td>
              <td className="p-2">
                <FormInputRHF
                  control={control}
                  name={`nutritionalInfo.${index}.name`}
                />
              </td>
              <td className="p-2">
                <FormInputRHF
                  control={control}
                  name={`nutritionalInfo.${index}.value`}
                />
              </td>
              <td className="p-2">
                <FormInputRHF
                  control={control}
                  name={`nutritionalInfo.${index}.unit`}
                />
              </td>

              <td className="p-2">
                <Button
                  buttonStyle="stroke"
                  onClick={() => remove(index)}
                  size="small"
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div>
        <Button
          buttonStyle="stroke"
          onClick={() => {
            append({
              key: '',
              name: '',
              value: '',
              unit: '',
            })
          }}
          size="small"
        >
          + Add Nutritional Info Item
        </Button>
      </div>
    </div>
  )
}
