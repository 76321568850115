import { useEffect } from 'react'

import { useAuth } from 'contexts/auth'

const Logout = (): JSX.Element => {
  const { onJWTChanged } = useAuth()

  useEffect(() => {
    onJWTChanged(null)
  }, [onJWTChanged])

  return <div>{/* 👋 */}</div>
}

export default Logout
