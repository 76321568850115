import { clsx } from 'clsx'
import { ReactNode, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import {
  MenuProduct,
  useUpdateMenuProductComponentImage,
  useUpdateMenuProductDetails,
  useUpdateMenuProductMainImage,
} from '@tovala/browser-apis-combinedapi'
import { Button, ErrorDisplay, XIcon } from '@tovala/component-library'

import { useMenuProductFull } from 'hooks/menuProducts'
import { useAppDispatch } from 'hooks'
import { successHandler } from 'actions/notifications'
import { isAxiosResponseError } from 'utils/api'

import FileDropzone from 'components/common/FileDropzone'
import { FormInputRHF } from 'components/common/FormInputRHF'

import type { FileRejection } from 'react-dropzone'
import type { MenuProductDetailsJSON } from '@tovala/browser-apis-cdn'

type FileState =
  | { message: string; status: 'error' }
  | { status: 'pending' }
  | { status: 'success' }

interface MenuProductComponentImagesFormData {
  componentImages: {
    caption: string
    url: string
  }[]
}

const MenuProductImages = ({
  productID,
}: {
  productID: string | undefined
}) => {
  const { menuProduct, menuProductDetails } = useMenuProductFull({ productID })

  if (!menuProduct) {
    return null
  }

  return (
    <div className="space-y-12">
      <MainImage
        mainImageURL={menuProduct.imageURL}
        productID={menuProduct.id}
      />
      <ComponentImages
        menuProduct={menuProduct}
        menuProductDetails={menuProductDetails}
        productID={menuProduct.id}
      />
    </div>
  )
}

export default MenuProductImages

const ComponentImages = ({
  menuProduct,
  menuProductDetails,
  productID,
}: {
  menuProduct: MenuProduct
  menuProductDetails: MenuProductDetailsJSON | undefined
  productID: string
}) => {
  const dispatch = useAppDispatch()

  const { mutate: updateMenuProductDetails } = useUpdateMenuProductDetails()

  const { mutateAsync: updateMenuProductComponentImageAsync } =
    useUpdateMenuProductComponentImage()

  const { control, handleSubmit, formState, watch } =
    useForm<MenuProductComponentImagesFormData>({
      defaultValues: {
        componentImages: menuProductDetails?.componentImages ?? [],
      },
    })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'componentImages',
  })

  const componentImagesValue = watch('componentImages')

  const [filesState, setFilesState] = useState<Map<string, FileState>>(
    new Map()
  )

  const onSubmit = (formData: MenuProductComponentImagesFormData) => {
    const updatedMenuProductDetailsJSON = menuProductDetails
      ? { ...menuProductDetails }
      : {
          allergens: [],
          componentImages: [],
          ingredients: '',
          id: menuProduct.id,
          prepSteps: [],
          nutritionalInfo: [],
          story: '',
          subtitle: '',
        }

    const data = {
      title: menuProduct.title,
      data: {
        ...updatedMenuProductDetailsJSON,
        componentImages: formData.componentImages.filter(
          (image) => !!image.url
        ),
      },
    }

    updateMenuProductDetails(
      { data, productID: menuProduct.id },
      {
        onSuccess: () => {
          successHandler(dispatch, 'Success! Component images saved.')
        },
      }
    )
  }

  const onDrop = async (
    acceptedFiles: File[],
    rejectedFiles: FileRejection[]
  ) => {
    setFilesState(new Map())

    for (const file of acceptedFiles) {
      setFilesState((filesState) => {
        const newFilesState = new Map(filesState)
        newFilesState.set(file.name, { status: 'pending' })

        return newFilesState
      })

      const imageName = getFilenameWithoutExtension(file.name)
      const fileCopy = new File([file], file.name.replace(/\s+/g, ''), {
        type: file.type,
      })

      const formData = new FormData()
      formData.set('file', fileCopy)

      try {
        const { imageURL } = await updateMenuProductComponentImageAsync({
          data: formData,
          imageName,
          productID,
        })

        append({
          caption: makeImageCaption(imageName),
          url: imageURL,
        })

        setFilesState((filesState) => {
          const newFilesState = new Map(filesState)
          newFilesState.set(
            file.name,
            imageURL
              ? { status: 'success' }
              : {
                  message:
                    'The image was uploaded but the metadata could not be set. Please find the image on the left and update accordingly.',
                  status: 'error',
                }
          )

          return newFilesState
        })
      } catch (error) {
        if (error instanceof Error && isAxiosResponseError(error)) {
          const message = error.message

          setFilesState((filesState) => {
            const newFilesState = new Map(filesState)
            newFilesState.set(file.name, {
              message,
              status: 'error',
            })

            return newFilesState
          })
        }
      }
    }

    rejectedFiles.forEach(({ errors, file }) => {
      setFilesState((filesState) => {
        const newFilesState = new Map(filesState)
        newFilesState.set(file.name, {
          message: errors[0].message,
          status: 'error',
        })

        return newFilesState
      })
    })
  }

  return (
    <div>
      <div className="space-y-6">
        <h2 className="text-k/28_110">Component Images</h2>

        {Array.from(filesState.entries()).map(([filename, fileState]) => {
          if (fileState.status === 'error') {
            return (
              <div key={filename}>
                <ErrorDisplay
                  whatHappened={`There was a problem importing ${filename}.`}
                  why={fileState?.message}
                />
              </div>
            )
          }
        })}

        <form className="space-y-8" onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-2 gap-8">
            <div className="space-y-8">
              {fields.map((field, index) => (
                <div key={field.id} className="space-y-4">
                  {field.url && (
                    <div className="flex items-start">
                      <div className="w-40 h-40 p-4 bg-grey-3 rounded-md relative">
                        <img
                          className="object-contain w-full h-full"
                          src={field.url}
                        />
                        <div className="absolute -top-4 -right-4 bg-black p-2 rounded-full">
                          <Button
                            buttonStyle="link"
                            onClick={() => remove(index)}
                            size="fluid"
                          >
                            <div className="w-6 h-6 text-white">
                              <XIcon />
                            </div>
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}

                  <FormInputRHF
                    control={control}
                    label="URL"
                    name={`componentImages.${index}.url`}
                  />

                  <FormInputRHF
                    control={control}
                    label="Caption"
                    name={`componentImages.${index}.caption`}
                  />
                </div>
              ))}
            </div>
            <div
              className={clsx({
                'col-span-2': componentImagesValue.length === 0,
              })}
            >
              <FileDropzone
                accept={{
                  'imgage/jpeg': ['.jpg', '.jpeg'],
                  'image/png': ['.png'],
                }}
                onDrop={onDrop}
                validator={imageUploadValidator}
              >
                <div className="space-y-4">
                  <div className="flex justify-center space-x-4">
                    <div className="flex justify-center space-x-4">
                      <FileTypeIcon>.JPG</FileTypeIcon>
                      <FileTypeIcon>.PNG</FileTypeIcon>
                    </div>
                  </div>
                  <p>Drop component image files here</p>
                </div>
              </FileDropzone>
            </div>
          </div>

          <div className="flex justify-between my-8">
            <Button disabled={!formState.isDirty} size="large" type="submit">
              {menuProductDetails?.componentImages &&
              menuProductDetails.componentImages.length > 0
                ? 'Update'
                : 'Save'}{' '}
              Component Images
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

const MainImage = ({
  mainImageURL,
  productID,
}: {
  mainImageURL: string
  productID: string
}) => {
  const dispatch = useAppDispatch()

  const [filePreviewURL, setFilePreviewURL] = useState<[string, string]>()
  const [fileState, setFileState] = useState<FileState>()
  const [fileCopy, setFileCopy] = useState<File>()
  const [isUpdatingMainImage, setIsUpdatingMainImage] = useState(!mainImageURL)

  const { mutate: updateMainImage } = useUpdateMenuProductMainImage()

  const onDrop = (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0]
      setFilePreviewURL(() => {
        return [file.name, URL.createObjectURL(file)]
      })

      setFileState(() => {
        return { status: 'pending' }
      })

      const fileCopy = new File([file], file.name.replace(/\s+/g, ''), {
        type: file.type,
      })

      setFileCopy(fileCopy)
    }

    rejectedFiles.forEach(({ errors }) => {
      setFileState(() => {
        return {
          message: errors[0].message,
          status: 'error',
        }
      })
    })
  }

  const clearUploadState = (isUpdatingMainImage: boolean) => {
    setFilePreviewURL(undefined)
    setFileState(undefined)
    setFileCopy(undefined)
    setIsUpdatingMainImage(isUpdatingMainImage)
  }

  return (
    <div className="space-y-6">
      <h2 className="text-k/28_110">Main Product Image</h2>

      {!isUpdatingMainImage ? (
        <div className="relative w-80">
          <img src={mainImageURL} />
          <div className="absolute -top-4 -right-4 bg-black p-2 rounded-full">
            <Button
              buttonStyle="link"
              onClick={() => {
                setIsUpdatingMainImage(true)
              }}
              size="fluid"
              title="Replace Main Image"
            >
              <div className="w-6 h-6 text-white">
                <XIcon />
              </div>
            </Button>
          </div>
        </div>
      ) : (
        <div>
          {!fileState || fileState.status === 'error' ? (
            <div className="space-y-4">
              <FileDropzone
                accept={{
                  'imgage/jpeg': ['.jpg', '.jpeg'],
                  'image/png': ['.png'],
                }}
                maxFiles={1}
                onDrop={onDrop}
                validator={imageUploadValidator}
              >
                <div className="space-y-4">
                  <div className="flex justify-center space-x-4">
                    <FileTypeIcon>.JPG</FileTypeIcon>
                    <FileTypeIcon>.PNG</FileTypeIcon>
                  </div>
                  {mainImageURL ? (
                    <p>
                      Drop main image file here to update the existing main
                      image
                    </p>
                  ) : (
                    <p>Drop main image file here</p>
                  )}
                </div>
              </FileDropzone>

              {fileState?.status === 'error' && (
                <ErrorDisplay
                  whatHappened="There was a problem importing this file."
                  why={fileState?.message}
                />
              )}
            </div>
          ) : (
            <div className="space-y-4 w-80">
              <div className="flex justify-between">
                <p>Preview</p>

                <Button
                  buttonStyle="stroke"
                  onClick={() => {
                    clearUploadState(false)
                  }}
                  size="small"
                >
                  Cancel
                </Button>
              </div>

              {fileState?.status === 'pending' && filePreviewURL && (
                <div>
                  <div>
                    <img src={filePreviewURL[1]} />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
      <div>
        <Button
          disabled={!fileCopy}
          onClick={() => {
            if (fileCopy) {
              const formData = new FormData()
              formData.set('file', fileCopy)

              updateMainImage(
                { data: formData, productID },
                {
                  onSuccess: () => {
                    clearUploadState(false)
                    successHandler(dispatch, 'Success! Main image saved.')
                  },
                }
              )
            }
          }}
          size="large"
        >
          {mainImageURL ? 'Update' : 'Save'} Main Image
        </Button>
      </div>
    </div>
  )
}

const FileTypeIcon = ({ children }: { children: ReactNode }) => {
  return (
    <div className="relative flex h-10 w-8 items-center rounded-sm border border-grey-905">
      <div className="absolute bottom-1 right-0 bg-black-901 px-1 text-xs text-white-900">
        {children}
      </div>
    </div>
  )
}

function imageUploadValidator(file: File) {
  if (file.name?.search(/[^a-zA-Z0-9\-_.]+/) !== -1) {
    return {
      code: 'filename-invalid',
      message:
        'Please rename this image without special characters and try again.',
    }
  }

  return null
}

function getFilenameWithoutExtension(name: string) {
  const extensionStartIndex = name.lastIndexOf('.')
  return name.slice(0, extensionStartIndex)
}

function makeImageCaption(name: string) {
  const nameLowerCase = name.toLowerCase()

  let caption = name.replace(/-/g, ' ')

  if (nameLowerCase.includes('tray_main')) {
    caption = caption
      .replace(/TRAY_MAIN/gi, '')
      .replace(/_/g, ' ')
      .trim()
  }
  if (nameLowerCase.includes('tray_side')) {
    caption = caption
      .replace(/TRAY_SIDE/gi, '')
      .replace(/_/g, ' ')
      .trim()
  }
  if (nameLowerCase.includes('garnish_1oz_top')) {
    caption = caption
      .replace(/GARNISH_1OZ_TOP/gi, '')
      .replace(/_/g, ' ')
      .trim()
  }
  if (nameLowerCase.includes('garnish_1oz_bottom')) {
    caption = caption
      .replace(/GARNISH_1OZ_BOTTOM/gi, '')
      .replace(/_/g, ' ')
      .trim()
  }
  if (nameLowerCase.includes('garnish_2oz_top')) {
    caption = caption
      .replace(/GARNISH_2OZ_TOP/gi, '')
      .replace(/_/g, ' ')
      .trim()
  }
  if (nameLowerCase.includes('garnish_2oz_bottom')) {
    caption = caption
      .replace(/GARNISH_2OZ_BOTTOM/gi, '')
      .replace(/_/g, ' ')
      .trim()
  }
  if (nameLowerCase.includes('tortilla')) {
    caption = 'Tortillas'
  }

  return caption
}
