import {
  useFlushMealsCache,
  useFlushUserCache,
} from '@tovala/browser-apis-combinedapi'
import { useState } from 'react'

import { errorHandler, successHandler } from 'actions/notifications'

import { useAppDispatch } from 'hooks'
import Button from 'components/common/Button'
import H1 from 'components/common/H1'
import Input from 'components/common/Input'

const CacheFlusher = (): JSX.Element => {
  const dispatch = useAppDispatch()

  const [userID, setUserID] = useState('')

  const { isLoading: isFlushingMealsCache, mutate: flushMealsCache } =
    useFlushMealsCache({
      onError: (err) => {
        errorHandler(
          dispatch,
          err,
          'Something went wrong during flush, please contact #help-backend'
        )
      },
      onSuccess: () => {
        successHandler(dispatch, 'Success! Caches flushed')
      },
    })

  const { isLoading: isFlushingUserCache, mutate: flushUserCache } =
    useFlushUserCache({
      onError: (err) => {
        errorHandler(
          dispatch,
          err,
          'Something went wrong during flush, please contact #help-backend'
        )
      },
      onSuccess: () => {
        successHandler(dispatch, 'Success! Caches flushed')
      },
      userID: Number(userID),
    })

  if (isFlushingUserCache || isFlushingMealsCache) {
    return (
      <iframe
        allowFullScreen
        height="300"
        src="https://giphy.com/embed/vQHr6bo7iQ5UY"
        width="300"
      ></iframe>
    )
  }

  return (
    <div>
      <div className="flex flex-wrap space-x-8 md:space-x-0 md:space-y-8">
        <div className="grow md:w-full">
          <H1>Flush Meals Cache</H1>
          <div className="h-10">
            <Button
              buttonStyle="stroke"
              onClick={() => {
                flushMealsCache()
              }}
              size="fluid"
            >
              Flush Meals Cache
            </Button>
          </div>
        </div>
        <div className="grow md:w-full">
          <H1>Flush User Cache</H1>

          <Input
            onChange={(e) => setUserID(e.target.value)}
            placeholder="User ID"
            type="text"
            value={userID}
          />

          <div className="mt-4 h-10">
            <Button
              buttonStyle="stroke"
              disabled={!userID}
              onClick={() => {
                flushUserCache()
              }}
              size="fluid"
            >
              Flush user Cache
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CacheFlusher
