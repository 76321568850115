import { Formik, Form, Field } from 'formik'
import { LeveratorPayload } from '@tovala/browser-apis-combinedapi'
import { useState } from 'react'

import {
  createLeveratorPayload,
  getAllLeveratorPayloads,
} from '../../../actions/marketing'

import { useAppDispatch } from 'hooks'
import Button from 'components/common/Button'
import { INPUT_CLASSES } from 'components/common/Input'
import { TEXTAREA_CLASSES } from 'components/common/Textarea'

interface FormData {
  body: string
  requiresInteraction: boolean
  title: string
}

interface LeveratorPayloadCreate {
  showPopUp: boolean
  contentAvailable: 1
  sound: 'default'
  badge: 1
  alertTitle: string
  alertBody: string
  requiresInteraction: boolean
}

const validateField = (value: string) => {
  let error: string | undefined
  if (!value) {
    error = 'Required'
  }
  return error
}

const PayloadForm = ({
  payload,
  selectPayload,
}: {
  payload: LeveratorPayload | undefined
  selectPayload: ({
    payloadID,
    title,
  }: {
    payloadID: string
    title: string
  }) => void
}) => {
  const dispatch = useAppDispatch()

  const [duplicatedPayloadContent, setDuplicatedPayloadContent] =
    useState<FormData>()

  const handleCreatePayload = (formData: FormData) => {
    const payload: LeveratorPayloadCreate = {
      showPopUp: true,
      contentAvailable: 1,
      sound: 'default',
      badge: 1,
      alertTitle: formData.title,
      alertBody: formData.body,
      requiresInteraction: formData.requiresInteraction,
    }

    dispatch(createLeveratorPayload(payload)).then((response) => {
      if (response && response.payload) {
        const payload = response.payload as LeveratorPayload
        dispatch(getAllLeveratorPayloads()).then(() => {
          selectPayload({
            payloadID: payload.id,
            title: payload.aps.alert.title,
          })

          if (duplicatedPayloadContent) {
            setDuplicatedPayloadContent(undefined)
          }
        })
      }
    })
  }

  const initialValues = {
    title: payload?.aps.alert.title || duplicatedPayloadContent?.title || '',
    body: payload?.aps.alert.body || duplicatedPayloadContent?.body || '',
    requiresInteraction: payload
      ? payload.requiresInteraction
      : duplicatedPayloadContent
      ? duplicatedPayloadContent.requiresInteraction
      : true,
  }

  const readOnly = !!payload?.id

  return (
    <Formik<FormData>
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleCreatePayload}
    >
      {({ errors }) => {
        return (
          <Form className="space-y-4">
            <div>
              <label>Title</label>
              {!readOnly && errors.title && (
                <span className="text-red-901">
                  {' '}
                  - <i>{errors.title}</i>
                </span>
              )}
              <Field
                className={INPUT_CLASSES}
                disabled={readOnly}
                name="title"
                type="text"
                validate={validateField}
              />
            </div>

            <div>
              <label>
                Body <span>- Optional</span>
              </label>
              <Field
                as="textarea"
                className={TEXTAREA_CLASSES}
                disabled={readOnly}
                name="body"
                rows={5}
                type="text"
              />
            </div>

            <label>
              <Field
                disabled={readOnly}
                name="requiresInteraction"
                type="checkbox"
              />
              &nbsp;Requires Interaction (i.e., cannot swipe to dismiss)
            </label>

            {!readOnly && (
              <div className="mt-4 space-y-4">
                <p className="font-bold">
                  You&apos;ll add actions after you finalize this content.
                </p>
                <Button size="large" type="submit">
                  Create Push Notification Content
                </Button>
              </div>
            )}

            {readOnly && (
              <div>
                <button
                  className="mt-4 text-sm hover:underline"
                  onClick={() => {
                    const title = payload.aps.alert.title
                    const body = payload.aps.alert.body
                    const requiresInteraction = payload.requiresInteraction
                    selectPayload({ payloadID: '', title: '' })
                    setDuplicatedPayloadContent({
                      title,
                      body,
                      requiresInteraction,
                    })
                  }}
                  type="button"
                >
                  Need to edit this Push Notification Content? Click here to
                  duplicate and edit this content.
                </button>
              </div>
            )}
          </Form>
        )
      }}
    </Formik>
  )
}

export default PayloadForm
