import { useEffect } from 'react'
import { UserV1, useMealCashHistory } from '@tovala/browser-apis-combinedapi'

import H2 from 'components/common/H2'
import UserCreditHistory from './UserCreditHistory'

const RefundHistoryPage = ({ user }: { user: UserV1 }) => {
  const userID = user.id

  const { data: mealCashHistory } = useMealCashHistory({ userID })

  useEffect(() => {
    document.title = `Glaze | User #${userID} - Refund History`
  }, [userID])

  return (
    <div>
      <H2>Credits History</H2>
      <UserCreditHistory
        tovalaCashRecords={
          mealCashHistory ? mealCashHistory.tovalaCashRecords : undefined
        }
        user={user}
      />
    </div>
  )
}

export default RefundHistoryPage
