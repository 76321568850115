import { useEffect } from 'react'

import H1 from 'components/common/H1'
import MealTagForm from './MealTagForm'

const AddMealTag = (): JSX.Element => {
  useEffect(() => {
    document.title = `Glaze | Add Meal Tag`
  }, [])

  return (
    <div className="w-3/4 md:w-full">
      <H1>Add Meal Tag</H1>
      <MealTagForm />
    </div>
  )
}

export default AddMealTag
