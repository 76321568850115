import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'

import { AppThunk, RootState } from 'store'
import {
  CLEAR_NOTIFICATIONS,
  SHOW_ERROR_NOTIFICATION,
  SHOW_SUCCESS_NOTIFICATION,
} from './types'
import { isAxiosResponseError } from 'utils/api'

export function showErrorNotification(
  message = 'Oops! Something went wrong...'
): AppThunk {
  return function (dispatch) {
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATIONS })
    }, 6000)

    return dispatch({
      type: SHOW_ERROR_NOTIFICATION,
      payload: message,
    })
  }
}

export function errorHandler(
  dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  error: Error | null,
  message: string | null = null
) {
  let errorMessage = ''

  if (isAxiosResponseError(error) && error.response) {
    errorMessage = error.response.data?.message
    const status = error.response.status
    if (status === 502) {
      errorMessage = 'Something went wrong. Try again in a few minutes.'
    }

    if (error.request) {
      // The request was made but no response was received
      errorMessage = 'Something went wrong. Please try again in a few minutes.'
    }
  }

  if (message) {
    errorMessage = message
  }

  dispatch({
    type: SHOW_ERROR_NOTIFICATION,
    payload: errorMessage,
  })

  setTimeout(() => {
    dispatch({ type: CLEAR_NOTIFICATIONS })
  }, 9000)
}

export function successHandler(
  dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  message = 'Hooray! Success!'
) {
  const errorMessage = message

  dispatch({
    type: SHOW_SUCCESS_NOTIFICATION,
    payload: errorMessage,
  })

  setTimeout(() => {
    dispatch({ type: CLEAR_NOTIFICATIONS })
  }, 9000)
}

export function showSuccessNotification(
  message = 'Hooray! Success!'
): AppThunk {
  return function (dispatch) {
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATIONS })
    }, 6000)

    return dispatch({
      type: SHOW_SUCCESS_NOTIFICATION,
      payload: message,
    })
  }
}
