import { createReducer } from '@reduxjs/toolkit'
import * as types from '../actions/types'

const INITIAL_STATE: {
  packslips: ''
} = {
  packslips: '',
}

export const packslips = createReducer(INITIAL_STATE, {
  [types.UPLOAD_PACKSLIPS]: (state, action) => {
    state.packslips = action.payload
  },
})
