import { getImageUploadURL } from '@tovala/browser-apis-menu-delivery'
import { FormFieldError } from '@tovala/component-library'
import { clsx } from 'clsx'
import { useToast } from 'contexts/toast'
import { useDropzone } from 'react-dropzone'
import { replaceSpaces } from 'utils/general'

export interface ImageFormData {
  contentType: string | undefined
  file: File | undefined
  filename: string | undefined
  url: string
  src: string
  presignedURL: string | undefined
}

export interface ImageData {
  contentType: string
  file: File
  filename: string
  url: string
  src: string
  presignedURL: string
}

const ImageUpload = ({
  error,
  hasError = false,
  onBlur,
  onChange,
  onImageAdded,
}: {
  error?: string
  hasError?: boolean
  onBlur(): void
  onChange(event: ImageFormData | React.ChangeEvent<Element> | undefined): void
  onImageAdded(opts: ImageData): void
}) => {
  const { openToast } = useToast()

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (files) => {
      const file = files[0]
      const reader = new FileReader()

      reader.addEventListener(
        'load',
        async () => {
          if (typeof reader.result === 'string') {
            try {
              const filename = replaceSpaces(file.name, '_')

              const { presignedURL } = await getImageUploadURL({
                data: { uploadedFileName: filename },
              })

              const fullURL = new URL(presignedURL)

              onImageAdded({
                contentType: file.type,
                file,
                filename,
                url: `https:/${fullURL.pathname}`,
                presignedURL,
                src: reader.result,
              })
            } catch (error) {
              openToast({
                heading: 'Unable to get presigned URL',
                message: 'Please check the image file and try again.',
                type: 'error',
              })
            }
          }
        },
        false
      )

      reader.readAsDataURL(files[0])
    },
  })

  return (
    <div className="space-y-2">
      <div
        className={clsx('border border-dashed p-8 text-center', {
          'text-black': isDragActive,
          'text-grey-6': !isDragActive,
          'border-current': !hasError,
          'border-red': hasError,
        })}
        {...getRootProps()}
      >
        <input {...getInputProps({ onBlur, onChange })} />
        <p>Drop an image here or click to select one</p>
      </div>

      {hasError && <FormFieldError>{error}</FormFieldError>}
    </div>
  )
}

export default ImageUpload
