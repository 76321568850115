import { useInvalidateUsers } from '@tovala/browser-apis-combinedapi'
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query'

import {
  DeactivateUser,
  deactivateUser,
  DeactivateUserResponse,
  editUser,
  EditUser,
  EditUserResponse,
  GetResetPasswordLink,
  getResetPasswordLink,
  GetResetPasswordLinkResponse,
} from 'services/combinedAPI/users'
import { getUserInfo } from 'actions/auth'

import { useAppDispatch } from 'hooks'

export function useDeactivateUser(
  opts?: Omit<
    UseMutationOptions<DeactivateUserResponse, Error, DeactivateUser>,
    'mutationFn'
  >
) {
  const dispatch = useAppDispatch()

  return useMutation({
    ...opts,
    mutationFn: (opts: DeactivateUser) => {
      return deactivateUser(opts)
    },
    onSuccess: (...args) => {
      dispatch(getUserInfo(args[1].userID))

      opts?.onSuccess?.(...args)
    },
  })
}

export function useEditUser(
  opts?: Omit<
    UseMutationOptions<EditUserResponse, Error, EditUser>,
    'mutationFn'
  >
) {
  const dispatch = useAppDispatch()
  const { invalidateUserV1 } = useInvalidateUsers()

  return useMutation({
    ...opts,
    mutationFn: (opts: EditUser) => {
      return editUser(opts)
    },
    onSuccess: (...args) => {
      invalidateUserV1(args[1].userID)
      dispatch(getUserInfo(args[1].userID))

      opts?.onSuccess?.(...args)
    },
  })
}

export function useResetPasswordLink({
  userID,
  ...rest
}: GetResetPasswordLink &
  Omit<
    UseQueryOptions<GetResetPasswordLinkResponse, Error>,
    'queryFn' | 'queryKey'
  >) {
  return useQuery<GetResetPasswordLinkResponse, Error>({
    ...rest,
    queryFn: () => {
      return getResetPasswordLink({ userID })
    },
    queryKey: ['reset-password-link', userID],
  })
}
