import { Field, FormErrors, reduxForm } from 'redux-form'
import { FormEventHandler, useEffect, useState } from 'react'
import {
  UpdatedAddress,
  useInvalidateTermStatuses,
  UserV1,
  UserV1ShipPeriodInfo,
  UserV1ShippingAddress,
} from '@tovala/browser-apis-combinedapi'
import { useParams } from 'react-router-dom'

import { CS_ADMIN, getAdminScope, USERS_WRITE } from '../../utils/getAdminScope'
import {
  getUserInfo,
  setAddressValidateModalVisiblity,
  updateShippingAddress,
  validateShippingAddress,
} from '../../actions/auth'
import { InitialState as AuthInitialState } from 'reducers/auth_reducer'

import { useAppDispatch, useAppSelector } from 'hooks'
import { useEditUser } from 'hooks/combinedAPI/users'
import Button from 'components/common/Button'
import ConfirmationModal from '../modals/ConfirmationModal'
import H2 from 'components/common/H2'
import Modal, { ModalBody, ModalHeader } from 'components/modals/Modal'
import ShippingForm, { FormData as ShippingFormData } from './ShippingForm'

interface FormData extends ShippingFormData {
  ignoreValidation: boolean
}

interface Props {
  user: UserV1
}

const AddressValidateModal = ({
  addressValidationMessage,
  handleSaveShippingIgnoreValidation,
  handleSaveShippingWithSuggestion,
  onCloseModal,
  unvalidatedAddress,
  validateAddressButtonText,
  validatedAddress,
}: {
  addressValidationMessage: string
  handleSaveShippingIgnoreValidation(): void
  handleSaveShippingWithSuggestion(): void
  onCloseModal(): void
  unvalidatedAddress: AuthInitialState['unvalidatedAddress']
  validateAddressButtonText: string
  validatedAddress: AuthInitialState['validatedAddress']
}): JSX.Element => {
  return (
    <Modal onCloseModal={onCloseModal}>
      <ModalBody>
        <ModalHeader onClickClose={onCloseModal}>
          Please verify the shipping address
        </ModalHeader>
        <div className="w-[500px]">
          <strong>{addressValidationMessage}</strong> <br />
          {validatedAddress.address_type && (
            <p>
              <span>{validatedAddress.name}</span> <br />
              <span>{validatedAddress.line1}</span> <br />
              {validatedAddress.line2 && (
                <span>
                  {validatedAddress.line2}
                  <br />
                </span>
              )}
              <span>{validatedAddress.city}</span>, {validatedAddress.state}{' '}
              {validatedAddress.postal_code}
            </p>
          )}
          <br />
          {unvalidatedAddress && (
            <div>
              <strong>Address as Entered</strong>
              <p>
                <span>{unvalidatedAddress.name}</span> <br />
                <span>{unvalidatedAddress.line1}</span> <br />
                {unvalidatedAddress.line2 && (
                  <span>
                    {unvalidatedAddress.line2}
                    <br />
                  </span>
                )}
                <span>{unvalidatedAddress.city}</span>,{' '}
                {unvalidatedAddress.state} {unvalidatedAddress.postal_code}
              </p>
            </div>
          )}
          <div className="mt-4 flex space-x-4">
            {validateAddressButtonText === 'Edit Address' && (
              <Button onClick={onCloseModal} size="large">
                {validateAddressButtonText}
              </Button>
            )}
            {validateAddressButtonText === 'Use Suggested Address' && (
              <Button onClick={handleSaveShippingWithSuggestion} size="large">
                {validateAddressButtonText}
              </Button>
            )}

            <Button
              buttonStyle="grey"
              onClick={() => handleSaveShippingIgnoreValidation()}
              size="large"
            >
              Save Address as Entered
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

const form = reduxForm<FormData, Props>({
  form: 'shippingAddress',
  validate,
})

function validate(formProps: FormData) {
  const errors: FormErrors<FormData> = {}

  if (!formProps.name) {
    errors.name = 'Please enter a name'
  }

  if (!formProps.line1) {
    errors.line1 = 'Please enter an address'
  }

  if (!formProps.city) {
    errors.city = 'Please enter a city'
  }

  if (!formProps.state) {
    errors.state = 'Please enter a state'
  }

  if (!formProps.postal_code) {
    errors.postal_code = 'Please enter a postal code'
  }

  if (!formProps.phone) {
    errors.phone = 'Please enter a phone number'
  }

  return errors
}

const UserShipment = ({
  handleSubmit,
  initialize,
  reset,
  user,
}: Props & {
  handleSubmit(onSubmit: (formData: FormData) => void): FormEventHandler
  initialize(formData: FormData): void
  reset(): void
}): JSX.Element => {
  const dispatch = useAppDispatch()

  const { userid } = useParams<{ userid: string }>()

  const addressValidationMessage = useAppSelector(
    (state) => state.auth.addressValidationMessage
  )
  const isAddressValidateModalOpen = useAppSelector(
    (state) => state.auth.isAddressValidateModalOpen
  )
  const unvalidatedAddress = useAppSelector(
    (state) => state.auth.unvalidatedAddress
  )
  const validateAddressButtonText = useAppSelector(
    (state) => state.auth.validateAddressButtonText
  )
  const validatedAddress = useAppSelector(
    (state) => state.auth.validatedAddress
  )

  const { invalidateUserTermStatuses } = useInvalidateTermStatuses()

  const [
    showFacilityNetworkChangeWarning,
    setShowFacilityNetworkChangeWarning,
  ] = useState(false)

  const { mutate: editUser } = useEditUser()

  const getFacilityNetwork = (
    shippingAddress: UpdatedAddress | UserV1ShippingAddress | '' | undefined
  ) => {
    let shipPeriodInfo: UserV1ShipPeriodInfo | '' | undefined = ''
    let currentFacilityNetwork = ''
    if (shippingAddress && shippingAddress.shipPeriodInfo) {
      shipPeriodInfo = shippingAddress.shipPeriodInfo.find(
        (shipPeriod) => shipPeriod.isDefaultShipPeriodForAddress
      )

      if (shipPeriodInfo) {
        currentFacilityNetwork = shipPeriodInfo.facilityNetwork
      }
    }

    return currentFacilityNetwork
  }

  const handlePostShippingUpdateTasks = (
    oldAddress: UserV1ShippingAddress | '' | undefined,
    updatedAddress: UpdatedAddress
  ) => {
    dispatch(getUserInfo(user.id))
    invalidateUserTermStatuses(user.id)

    // If we don't have a name for a user, fill it from the shipping name
    if (!user.info.name && updatedAddress.name) {
      const name = updatedAddress.name
      const email = user.info.email

      editUser({ data: { name, email }, userID: user.id })
    }

    const oldFacilityNetwork = getFacilityNetwork(oldAddress)
    const updatedFacilityNetwork = getFacilityNetwork(updatedAddress)
    if (oldFacilityNetwork !== updatedFacilityNetwork) {
      setShowFacilityNetworkChangeWarning(true)
    }
  }

  const handleUpdateShipping = (data: Omit<FormData, 'ignoreValidation'>) => {
    let oldAddress: UserV1ShippingAddress | '' | undefined = ''
    if (user.shippingAddresses.length) {
      oldAddress = user.shippingAddresses[0]
    }

    dispatch(
      updateShippingAddress(user.id, user.subscription.customerID, data)
    ).then((response) => {
      if (response && response.payload) {
        const updatedAddress = response.payload
        handlePostShippingUpdateTasks(oldAddress, updatedAddress)
      } else {
        // Error updating address; clear form so it's clear the address didn't update
        reset()
      }
    })
  }

  const handleSaveShippingIgnoreValidation = () => {
    const address = unvalidatedAddress
    const addressPayload = Object.assign({}, address, {
      email: user.info.email,
    })

    handleUpdateShipping(addressPayload)
  }

  const handleSaveShippingWithSuggestion = () => {
    // Save shipping with validation suggestion
    const address = unvalidatedAddress
    const addressPayload = Object.assign({}, address, {
      email: user.info.email,
    })
    dispatch(
      validateShippingAddress(
        user.id,
        user.subscription.customerID,
        addressPayload
      )
    ).then((response) => {
      if (response && response.payload) {
        handleUpdateShipping(response.payload)
      }
    })
  }

  function handleFormSubmit(formData: FormData) {
    // Scroll to top of page
    window.scrollTo(0, 0)

    if (formData.ignoreValidation) {
      handleUpdateShipping(formData)
    } else if (userid) {
      dispatch(
        validateShippingAddress(userid, user.subscription.customerID, formData)
      ).then((response) => {
        if (response && response.payload) {
          handleUpdateShipping(response.payload)
        }
      })
    }
  }

  const closeModal = () => {
    dispatch(setAddressValidateModalVisiblity(false))
  }

  useEffect(() => {
    document.title = `Glaze | User #${userid} - Shipping`
  }, [userid])

  return (
    <div className="w-9/12">
      {isAddressValidateModalOpen && (
        <AddressValidateModal
          addressValidationMessage={addressValidationMessage}
          handleSaveShippingIgnoreValidation={
            handleSaveShippingIgnoreValidation
          }
          handleSaveShippingWithSuggestion={handleSaveShippingWithSuggestion}
          onCloseModal={closeModal}
          unvalidatedAddress={unvalidatedAddress}
          validateAddressButtonText={validateAddressButtonText}
          validatedAddress={validatedAddress}
        />
      )}

      <H2>Edit User Shipping Address</H2>

      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <ShippingForm
          initialize={initialize}
          readOnly={!getAdminScope(USERS_WRITE)}
        />

        {getAdminScope(CS_ADMIN) && (
          <div className="mt-4">
            <label>
              <Field
                component="input"
                name="ignoreValidation"
                type="checkbox"
              />
              &nbsp; Ignore address validation
            </label>
            <p>
              Please only check &quot;Ignore address validation&quot; if our
              address validation service is incorrectly autocorrecting the
              address.
            </p>
          </div>
        )}

        {getAdminScope(USERS_WRITE) && (
          <div className="mt-4 h-10">
            <Button size="fluid" type="submit">
              Save
            </Button>
          </div>
        )}
      </form>

      <ConfirmationModal
        cancelText="OK"
        heading="User's meal selections removed"
        isOpen={showFacilityNetworkChangeWarning}
        onCloseModal={() => {
          setShowFacilityNetworkChangeWarning(false)
        }}
      >
        <div>
          <p>
            This address change resulted in a change to the facility network
            that the user&apos;s meals will ship from.
          </p>
          <br />
          <p>
            If the user had meal selections, their selections have been removed.
          </p>
        </div>
      </ConfirmationModal>
    </div>
  )
}

export default form(UserShipment)
