import { createReducer } from '@reduxjs/toolkit'
import * as types from '../actions/types'

const INITIAL_STATE: {
  error: ''
  message: ''
  success: ''
} = {
  error: '',
  message: '',
  success: '',
}

export const notifications = createReducer(INITIAL_STATE, {
  [types.SHOW_ERROR_NOTIFICATION]: (state, action) => {
    state.error = action.payload
    state.message = ''
    state.success = ''
  },
  [types.SHOW_SUCCESS_NOTIFICATION]: (state, action) => {
    state.error = ''
    state.message = ''
    state.success = action.payload
  },
  [types.SHOW_MESSAGE_NOTIFICATION]: (state, action) => {
    state.message = action.payload
  },
  [types.CLEAR_NOTIFICATIONS]: (state) => {
    state.error = ''
    state.message = ''
    state.success = ''
  },
})
