import { MouseEvent, ReactNode } from 'react'

import Button from 'components/common/Button'
import Modal, { ModalBody, ModalHeader } from './Modal'

const ConfirmationModal = ({
  buttonText,
  cancelText,
  children,
  handleClick,
  heading,
  isOpen,
  onCloseModal,
}: {
  buttonText?: string
  cancelText?: string
  children: ReactNode
  handleClick?: (event: MouseEvent<HTMLButtonElement>) => void
  heading: string
  isOpen: boolean
  onCloseModal(): void
}): JSX.Element | null => {
  if (!isOpen) {
    return null
  }

  return (
    <Modal onCloseModal={onCloseModal}>
      <ModalBody>
        <ModalHeader onClickClose={onCloseModal}>{heading}</ModalHeader>

        <div className="mt-5">{children}</div>

        <div className="mt-10 flex space-x-2">
          {handleClick && (
            <Button onClick={handleClick} size="large">
              {buttonText}
            </Button>
          )}
          <Button
            buttonStyle="cancel"
            id="cancel"
            onClick={onCloseModal}
            size="large"
          >
            {cancelText ? cancelText : 'Cancel'}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ConfirmationModal
