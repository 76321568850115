import { PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { AppThunk } from 'store'

import { API_URL_V1, getHeaders } from './index'
import { errorHandler, successHandler } from './notifications'
import { UPLOAD_PACKSLIPS } from './types'

export function uploadPackslips(payload: {
  formData: FormData
  orderId: string
  orderNumber: string
}): AppThunk<Promise<PayloadAction<unknown, typeof UPLOAD_PACKSLIPS> | void>> {
  return function (dispatch) {
    const formData = payload.formData

    return axios
      .post(
        `${API_URL_V1}/qvc/order/${payload.orderNumber}/item/${payload.orderId}/uploadPackslip`,
        formData,
        getHeaders(true)
      )
      .then((response) => {
        successHandler(dispatch, 'Success! Packslip uploaded')
        return dispatch({
          type: UPLOAD_PACKSLIPS,
          payload: response.data,
        })
      })

      .catch((error) => {
        errorHandler(
          dispatch,
          error.response,
          'Something went wrong during upload, please check your file or contact Bart'
        )
      })
  }
}

export function uploadTrackingFile(payload: {
  formData: unknown
}): AppThunk<Promise<boolean | void>> {
  return function (dispatch) {
    const formData = payload.formData

    return axios
      .post(
        `${API_URL_V1}/qvc/matchMealboxTrackingNumbers`,
        formData,
        getHeaders(true)
      )
      .then(() => {
        successHandler(dispatch, 'Success! Packslip uploaded')

        return true as const
      })

      .catch((error) => {
        errorHandler(
          dispatch,
          error.response,
          'Something went wrong during upload, please check your file or contact Bart'
        )
      })
  }
}

export function uploadLargeQvcPackslip(payload: {
  formData: unknown
}): AppThunk<Promise<true | void>> {
  return function (dispatch) {
    const formData = payload.formData

    return axios
      .post(
        `${API_URL_V1}/qvc/processMultiplePackslips`,
        formData,
        getHeaders(true)
      )
      .then(() => {
        successHandler(dispatch, 'Success! Packslip uploaded')

        return true as const
      })

      .catch((error) => {
        errorHandler(
          dispatch,
          error.response,
          'Something went wrong during upload, please check your file or contact Bart'
        )
      })
  }
}

export function generateMealboxCsv(): AppThunk<Promise<true | void>> {
  return function (dispatch) {
    return axios
      .get(`${API_URL_V1}/qvc/generateMealFulfillmentCSV`, getHeaders())
      .then(() => {
        successHandler(dispatch, 'Success! Meal Fulfillment CSV Generated')

        return true as const
      })

      .catch((error) => {
        errorHandler(
          dispatch,
          error.response,
          'Something went wrong during upload, please check your file or contact Bart'
        )
      })
  }
}

export function createOvenFulfillments(): AppThunk<Promise<void>> {
  return function (dispatch) {
    return axios
      .get(`${API_URL_V1}/qvc/createOvenFulfillments`, getHeaders())
      .then(() => {
        successHandler(dispatch, 'Success! Oven Fulfillments Created')
      })

      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

// GET v1/qvc/processOrderFiles/sftp
export function processOrderFilesQVC(): AppThunk<
  Promise<{ error?: unknown } | void>
> {
  return function (dispatch) {
    return axios
      .get(`${API_URL_V1}/qvc/processOrderFiles/sftp`, getHeaders())
      .then((response) => {
        return response.data
      })

      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}
