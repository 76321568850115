import { clsx } from 'clsx'
import {
  Listing,
  ListingSelection,
  MealSummary,
} from '@tovala/browser-apis-combinedapi'
import { OrderPricing, getMealPriceString } from './helpers'
import { formatCentsToDollars } from 'utils/currency'

const OrderSummary = ({
  listings,
  listingSelections,
  maxSelections,
  maxSelectionsDefaultSubscription,
  mealSummaries,
  orderPricing,
  selectedSubscriptionTypeIsDefault,
  selectionsMealIDs,
}: {
  listings: Listing[]
  listingSelections: ListingSelection[]
  maxSelections: number | undefined
  maxSelectionsDefaultSubscription: number | undefined
  mealSummaries: MealSummary[]
  orderPricing: OrderPricing
  selectedSubscriptionTypeIsDefault: boolean
  selectionsMealIDs: number[]
}) => {
  if (!maxSelections) {
    return null
  }

  const listingSelectionsTotalQuantity = listingSelections.reduce(
    (totalQuantity, listingSelection) =>
      totalQuantity + listingSelection.quantity,
    0
  )

  return (
    <div className="space-y-6">
      <div>
        <div className="flex justify-between">
          <span>
            <span
              className={clsx({
                'line-through': !selectedSubscriptionTypeIsDefault,
              })}
            >
              {maxSelectionsDefaultSubscription} Meal Plan
            </span>

            {!selectedSubscriptionTypeIsDefault && (
              <span>
                &nbsp;&nbsp;&nbsp;
                {maxSelections} Meals
              </span>
            )}
          </span>
          <span>{`${formatCentsToDollars(orderPricing.mealsPriceCents)}`}</span>
        </div>
        <div className="flex justify-between">
          <span>Surcharges</span>
          <span>
            {orderPricing.surchargePriceCents > 0
              ? `+${formatCentsToDollars(orderPricing.surchargePriceCents)}`
              : '---'}
          </span>
        </div>

        <div className="flex justify-between">
          <span>Shipping</span>
          <span>
            {orderPricing.shippingPriceCents > 0
              ? `${formatCentsToDollars(orderPricing.shippingPriceCents)}`
              : '---'}
          </span>
        </div>

        {orderPricing.discountPriceCents !== undefined && (
          <div className="flex justify-between">
            <span>Discount</span>
            <span>{formatCentsToDollars(orderPricing.discountPriceCents)}</span>
          </div>
        )}
        {orderPricing.taxPriceCents !== undefined &&
          orderPricing.taxPriceCents > 0 && (
            <div className="flex justify-between">
              <span>Tax</span>
              <span>{formatCentsToDollars(orderPricing.taxPriceCents)}</span>
            </div>
          )}
        <hr className="my-1" />
        <div className="flex justify-between font-bold">
          <span>Total</span>
          <span>{`${formatCentsToDollars(orderPricing.totalPriceCents)}`}</span>
        </div>
      </div>

      <div>
        <div className="space-y-2">
          <div className="font-bold">
            {selectionsMealIDs.length}/{maxSelections} Meals Selected
          </div>
          {mealSummaries.map((meal) => {
            const { title, subtitle, surchargeCents, totalPriceCents } = meal
            const quantity = selectionsMealIDs.filter((mealID) => {
              return mealID === meal.id
            }).length

            if (quantity > 0) {
              return (
                <SummaryItem
                  key={meal.id}
                  id={meal.id}
                  priceString={getMealPriceString({
                    surchargeCents,
                    totalPriceCents,
                  })}
                  quantity={quantity}
                  subtitle={subtitle}
                  title={title}
                />
              )
            }
          })}
        </div>
      </div>
      {listingSelectionsTotalQuantity > 0 && (
        <div className="space-y-2">
          <div className="font-bold">
            {listingSelectionsTotalQuantity} Extra
            {listingSelectionsTotalQuantity > 1 ? 's' : ''} Selected
          </div>
          <p className="text-sm text-grey-9">
            Extras cost is included in Surcharges line item above.
          </p>
          {listings.map((listing) => {
            const { title, priceCents } = listing
            const quantity = listingSelections.find(
              (selection) => selection.id === listing.id
            )?.quantity

            if (quantity && quantity > 0) {
              return (
                <SummaryItem
                  key={listing.id}
                  priceString={formatCentsToDollars(priceCents)}
                  quantity={quantity}
                  title={title}
                />
              )
            }
          })}
        </div>
      )}
    </div>
  )
}

export default OrderSummary

const SummaryItem = ({
  id,
  priceString,
  quantity,
  subtitle,
  title,
}: {
  id?: number
  priceString: string
  quantity: number
  subtitle?: string
  title: string
}) => {
  return (
    <div className="grid grid-cols-[40px_1fr] border-t border-grey-900 pt-1">
      <div>{quantity}</div>

      <div className="space-y-1">
        <p>{title}</p>
        <p className="text-sm text-grey-906">
          {subtitle} {id ? `(#${id})` : ''}
        </p>
        <p className="text-sm">{priceString}</p>
      </div>
    </div>
  )
}
