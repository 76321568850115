import { Link } from 'react-router-dom'
import { clsx } from 'clsx'
import { useState } from 'react'
import { useReadUserGuidanceEvent } from 'hooks/combinedAPI/realTimeGuidanceEvent'
import { GuidanceEvent } from 'types/domainModels'

const UserGuidanceEventCard = ({ userId }: { userId: number }) => {
  const [shouldShowAll, setShouldShowAll] = useState(false)

  const { data: guidanceEventsResponse = [] } = useReadUserGuidanceEvent({
    userId,
  })
  const guidanceEvents = guidanceEventsResponse

  let content = <></>
  let shownGuidanceEvents = guidanceEvents
  if (!shouldShowAll) {
    shownGuidanceEvents = guidanceEvents.filter((event) => !event.isExpired)
  }
  if (guidanceEvents.length > 0) {
    content = (
      <table className="table-fixed w-full">
        <colgroup>
          <col width={'110px'}></col>
          <col width={'115px'}></col>
          <col width={'20%'}></col>
          <col width={'25%'}></col>
          <col width={'40%'}></col>
          <col width={'100px'}></col>
          <col width={'10%'}></col>
        </colgroup>
        <thead>
          <tr key={'guidance-events-header-row'}>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Title</th>
            <th>User Specifics</th>
            <th>Problem Description</th>
            <th>Resolution</th>
            <th>Extra Links</th>
          </tr>
        </thead>
        <tbody>
          {shownGuidanceEvents.map(
            ({
              id,
              eventStartDate,
              eventEndDate,
              title,
              problemDescription,
              resolutionDescription,
              resolutionLink,
              extraLinks,
              usersInfo,
            }: GuidanceEvent) => {
              return (
                <tr key={id + 'tr'} className={'odd:bg-grey-901'}>
                  <td>
                    <div className="p-2 h-14 overflow-auto hover:text-grey-909">
                      {eventStartDate.split('T')[0] ?? eventStartDate}
                    </div>
                  </td>
                  <td>
                    <div className="p-2 h-14 overflow-auto hover:text-grey-909">
                      {eventEndDate.split('T')[0] ?? eventEndDate}
                    </div>
                  </td>
                  <td>
                    <div className="p-2 h-14 text-center overflow-auto hover:text-grey-909">
                      {title}
                    </div>
                  </td>
                  <td>
                    <div className="p-2 h-14 overflow-auto hover:text-grey-909">
                      {usersInfo[0]?.extraInfo ?? ''}
                    </div>
                  </td>
                  <td>
                    <div className="p-2 h-14 overflow-auto hover:text-grey-909">
                      {problemDescription}
                    </div>
                  </td>
                  <td>
                    <div className="p-2 h-14 overflow-auto">
                      <Link
                        className="truncate hover:text-grey-909"
                        to={resolutionLink}
                      >
                        {resolutionDescription}
                      </Link>
                    </div>
                  </td>
                  <td>
                    <div className="p-2 h-14 overflow-auto">
                      <Link
                        className="truncate hover:text-grey-909"
                        to={extraLinks}
                      >
                        {extraLinks !== '' ? 'link' : ''}
                      </Link>
                    </div>
                  </td>
                </tr>
              )
            }
          )}
        </tbody>
      </table>
    )
  }

  return (
    <div className="overflow-auto">
      <h1
        className={clsx(`text-left font-bold text-xl`, {
          'text-red-906': guidanceEvents.length > 0,
        })}
      >
        <button
          onClick={() => {
            setShouldShowAll(!shouldShowAll)
          }}
        >
          Real Time Guidance{' '}
          <span className="text-black text-lg">
            (click to show/hide all {guidanceEvents.length} events)
          </span>
        </button>
      </h1>
      {content}
    </div>
  )
}

export default UserGuidanceEventCard
