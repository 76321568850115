import { ReactNode } from 'react'

const Card = ({ children }: { children: ReactNode }) => {
  return (
    <div className="rounded border border-grey-900 bg-white-900">
      {children}
    </div>
  )
}

export default Card

export const CardBody = ({ children }: { children: ReactNode }) => {
  return <div className="p-5">{children}</div>
}
