import { Link, useParams } from 'react-router-dom'
import {
  MealCashRecord,
  OrderHistoryReceipt,
  RemainingOrderBalance as IRemainingOrderBalance,
  Term,
  UserV1,
  useMealCashHistory,
  useOrderHistoryReceipts,
  ErrorCodeMessageMapCombinedAPI,
  MealCashHistory,
  useCurrentTerm,
  useUserTermStatus,
  TermStatus,
  useListingsReceipt,
  ListingsReceipt,
} from '@tovala/browser-apis-combinedapi'
import { ReactNode, useEffect, useState } from 'react'

import {
  addToDate,
  convertUTCToCT,
  DATE_FORMATS,
  formatDate,
} from 'utils/dates'
import { formatCentsToDollars } from 'utils/currency'
import {
  CS_ADMIN,
  getAdminScope,
  OATS_DASHBOARD,
  ORDER_OVERRIDE,
  VENGEFUL_GOD,
} from '../../utils/getAdminScope'
import { getRemainingOrderBalance } from '../../actions/user'

import { useAppDispatch } from 'hooks'
import AddMarketingCreditsModal, {
  Props as AddMarketingCreditsModalProps,
} from './AddMarketingCreditsModal'
import CancelOrderModal, {
  Props as CancelOrderModalProps,
} from './CancelOrderModal'
import FlushUTOCacheModal, {
  Props as FlushUTOCacheModalProps,
} from './FlushUTOCacheModal'
import H2 from 'components/common/H2'
import H3 from 'components/common/H3'
import Hr from 'components/common/Hr'
import RefundOrderModal from './RefundOrderModal'
import RemainingOrderBalanceModal from './RemainingOrderBalanceModal'
import RemoveCreditModal, {
  Props as RemoveCreditModalProps,
} from './RemoveCreditModal'
import SendReceiptModal, {
  Props as SendReceiptModalProps,
} from './SendReceiptModal'
import UpdateUTOCycleModal, {
  Props as UpdateUTOCycleModalProps,
} from './UpdateUTOCycleModal'
import UpdateUTOFulfillmentStatusModal, {
  Props as UpdateUTOFulfillmentStatusModalProps,
} from './UpdateUTOFulfillmentStatusModal'
import UpdateUTOPaymentReprocessStatusModal, {
  Props as UpdateUTOPaymentReprocessStatusModalProps,
} from './UpdateUTOPaymentReprocessStatusModal'
import UpdateUTOShippingAddressModal, {
  Props as UpdateUTOShippingAddressModalProps,
} from './UpdateUTOShippingAddressModal'
import { flatten } from 'lodash-es'
import { APIErrorDisplay, ButtonLoading } from '@tovala/component-library'
import RemoveExtrasModal, {
  Props as RemoveExtrasModalProps,
} from './RemoveExtrasModal'
import {
  isCurrentTimeBeforeMSRDeadline,
  isOrderRefundAvailable,
  isRemoveExtrasAvailable,
} from './helpers'

function isJSON(str: string) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

const LOAD_ORDER_HISTORY_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please reload the page.',
    why: "We couldn't load user order history due to a technical issue on our end.",
  },
}

const ViewUserHistory = ({ user }: { user: UserV1 }): JSX.Element => {
  const { userid } = useParams<{ userid: string }>()

  const {
    data: getOrderHistoryReceiptsResponse,
    error: getOrderHistoryReceiptsError,
    fetchNextPage: loadMoreOrderHistory,
    isError: hasGetOrderHistoryReceiptsError,
    isFetchingNextPage,
    isLoading,
  } = useOrderHistoryReceipts({
    userID: user.id,
  })

  const { pages = [] } = getOrderHistoryReceiptsResponse || {}
  const orderHistoryReceipts = flatten(pages.map((page) => page.receipts ?? []))
  const totalOrderCount = pages[0]?.totalOrderCount

  const { data: mealCashHistory } = useMealCashHistory({ userID: user.id })

  const { data: currentTerm } = useCurrentTerm()

  const { data: currentUserTermStatus } = useUserTermStatus({
    termID: currentTerm?.id,
    userID: user.id,
  })

  useEffect(() => {
    document.title = `Glaze | User #${userid} - Meal Order History`
  }, [userid])

  if (isLoading) {
    return <div>Loading</div>
  }

  if (hasGetOrderHistoryReceiptsError) {
    return (
      <APIErrorDisplay
        error={getOrderHistoryReceiptsError}
        errorCodeMessageMap={LOAD_ORDER_HISTORY_ERRORS}
      />
    )
  }

  const showMoreHistory = totalOrderCount
    ? orderHistoryReceipts.length < totalOrderCount
    : false

  return (
    <div>
      <H2>Meal Order History</H2>

      <p className="mb-4">
        User&apos;s processed orders and skips. If a term is not listed, the
        user&apos;s subscription was paused/canceled for that term.
      </p>

      {orderHistoryReceipts && totalOrderCount === 0 && (
        <p>No order history found.</p>
      )}

      {orderHistoryReceipts &&
        orderHistoryReceipts.map((receipt) => {
          return (
            <Receipt
              key={receipt.userTermOrderID}
              currentTerm={currentTerm}
              currentUserTermStatus={currentUserTermStatus}
              mealCashHistory={mealCashHistory}
              receipt={receipt}
              user={user}
            />
          )
        })}

      {showMoreHistory && (
        <div className="flex justify-center">
          <div className="h-10 w-48">
            <ButtonLoading
              buttonStyle="gray"
              isLoading={isFetchingNextPage}
              onClick={() => {
                loadMoreOrderHistory()
              }}
              size="fluid"
            >
              Show more
            </ButtonLoading>
          </div>
        </div>
      )}
    </div>
  )
}

export default ViewUserHistory

const Receipt = ({
  currentTerm,
  currentUserTermStatus,
  mealCashHistory,
  receipt,
  user,
}: {
  currentTerm: Term | undefined
  currentUserTermStatus: TermStatus | undefined
  mealCashHistory: MealCashHistory | undefined
  receipt: OrderHistoryReceipt
  user: UserV1
}) => {
  const { data: listingsReceipt } = useListingsReceipt({
    orderID: receipt.userTermOrderID,
    userID: user.id,
  })
  const paymentID = receipt.paymentLineItems.length
    ? receipt.paymentLineItems[0].paymentID
    : ''
  const refundMealCashRecords = mealCashHistory
    ? mealCashHistory.tovalaCashRecords.filter(
        (r) =>
          (r.action === 'order_refund' ||
            r.action === 'order_refund_partial') &&
          r.paymentID === paymentID &&
          !r.isVoided
      )
    : []
  const csMealCashRecords = mealCashHistory
    ? mealCashHistory.tovalaCashRecords.filter(
        (r) =>
          r.action === 'cs_mealcash' &&
          r.termID === receipt.termID &&
          !r.isVoided
      )
    : []
  const partialRefundLineItems = receipt.paymentLineItems.filter(
    (item) => item.type === 'refund'
  )

  let allMealsAutoSelected = false
  let partialAutoSelected = ''
  let paymentErrorDetails: { code?: string; message?: string } | '' = ''

  if (receipt.paymentError && isJSON(receipt.paymentError)) {
    paymentErrorDetails = JSON.parse(receipt.paymentError)
  }

  if (receipt.selectedMeals && receipt.selectedMeals.length > 0) {
    const autoSelectedMeals = receipt.selectedMeals.filter(
      (meal) => meal.selectedAutomatically
    )

    // If all meals were selected automatically
    if (autoSelectedMeals.length === receipt.selectedMeals.length) {
      allMealsAutoSelected = true

      // If some meals were selected automatically
    } else if (
      autoSelectedMeals.length !== 0 &&
      autoSelectedMeals.length !== receipt.selectedMeals.length
    ) {
      partialAutoSelected = `${autoSelectedMeals.length} meals`
    }
  }

  return (
    <>
      <div>
        <H3>
          <strong>
            {formatDate(receipt.termStartDate, {
              format: DATE_FORMATS.DOW_MONTH_ABBR_DAY_YEAR,
            })}{' '}
            &mdash;{' '}
            {formatDate(
              addToDate(new Date(receipt.termStartDate), {
                quantity: 4,
                units: 'days',
              }),
              {
                format: DATE_FORMATS.DOW_MONTH_ABBR_DAY_YEAR,
              }
            )}{' '}
            /&nbsp;
          </strong>
          Term #{receipt.termID}
        </H3>

        {receipt.orderStatus === 'skipped' ? (
          <div>
            <strong>Order Status</strong>
            <p>Skipped</p>
          </div>
        ) : (
          <div className="space-y-2">
            <UserHistoryItem label="Notes">
              {partialRefundLineItems.map((item) => {
                return (
                  <p key={item.id}>
                    ($
                    {(item.invoiceAmountCents / 100).toFixed(2)} refunded on{' '}
                    {formatDate(new Date(item.created), {
                      format: DATE_FORMATS.DEFAULT_DATE_FORMAT,
                    })}{' '}
                    {item.notes}
                  </p>
                )
              })}
            </UserHistoryItem>

            <div className="flex space-x-4 pl-4">
              <div className="w-4/12 space-y-2 border-r border-grey-900 pr-4">
                {receipt.updated &&
                  receipt.updated !== '0001-01-01T00:00:00Z' && (
                    <UserHistoryItem label="Updated">
                      {formatDate(convertUTCToCT(receipt.updated), {
                        format: DATE_FORMATS.DEFAULT_DATETIME_FORMAT,
                      })}
                    </UserHistoryItem>
                  )}

                <UserHistoryItem label="Order Status">
                  {receipt.orderStatus && (
                    <p>{receipt.orderStatus.replace('_', ' ')}</p>
                  )}
                </UserHistoryItem>

                {receipt.orderStatus === 'payment_error' &&
                  paymentErrorDetails &&
                  paymentErrorDetails.code && (
                    <UserHistoryItem label="Stripe Code">
                      {paymentErrorDetails.code}
                    </UserHistoryItem>
                  )}

                {receipt.orderStatus === 'payment_error' &&
                  paymentErrorDetails &&
                  paymentErrorDetails.message && (
                    <UserHistoryItem label="Stripe Reason">
                      {paymentErrorDetails.message}
                    </UserHistoryItem>
                  )}

                {receipt.paymentLineItems && (
                  <UserHistoryItem label="Payment">
                    <ReceiptPaymentLineItems
                      csMealCashRecords={csMealCashRecords}
                      paymentLineItems={receipt.paymentLineItems}
                      paymentStatus={receipt.paymentStatus}
                      refundMealCashRecords={refundMealCashRecords}
                    />
                  </UserHistoryItem>
                )}

                {receipt.trackingURLs && receipt.trackingURLs.length > 0 && (
                  <UserHistoryItem label="Tracking">
                    {receipt.trackingURLs.map((trackingURL) => {
                      const trackingNumber = trackingURL.substring(
                        trackingURL.lastIndexOf('/') + 1
                      )

                      return (
                        <p key={trackingURL}>
                          <a
                            href={`${trackingURL}`}
                            rel="noreferrer noopener"
                            target="_blank"
                          >
                            {trackingNumber}
                          </a>
                        </p>
                      )
                    })}
                  </UserHistoryItem>
                )}

                {receipt.shippingAddress && (
                  <>
                    <UserHistoryItem label="Shipping Address">
                      <div>{receipt.shippingAddress.name}</div>
                      <div>{receipt.shippingAddress.line1}</div>
                      <div>{receipt.shippingAddress.line2}</div>
                      <div>
                        {receipt.shippingAddress.city},{' '}
                        {receipt.shippingAddress.state}{' '}
                        {receipt.shippingAddress.zipCode}
                      </div>
                      <div>{receipt.shippingAddress.phone}</div>
                    </UserHistoryItem>
                    <UserHistoryItem label="Shipping Carrier">
                      <div>{receipt.shippingAddress.shippingCompany}</div>
                    </UserHistoryItem>
                  </>
                )}

                {receipt && receipt.deliveryDate && (
                  <UserHistoryItem label="Delivery Day">
                    {formatDate(new Date(receipt.deliveryDate), {
                      format: DATE_FORMATS.DOW_MONTH_DAY,
                    })}
                  </UserHistoryItem>
                )}

                {receipt && receipt.menu && (
                  <UserHistoryItem label="Menu">
                    {receipt.menu.name ? (
                      <span>{receipt.menu.name}</span>
                    ) : (
                      <span>
                        {receipt.menu.facilityNetwork} -{' '}
                        {receipt.menu.shipPeriod}
                      </span>
                    )}
                  </UserHistoryItem>
                )}
              </div>

              <div className="w-5/12 space-y-4 border-r border-grey-900 pr-4">
                {receipt.mealCount > 0 && (
                  <UserHistoryItem label="Meal Plan">
                    {receipt.mealCount} Meals
                  </UserHistoryItem>
                )}

                {receipt.selectedMeals && receipt.selectedMeals.length > 0 && (
                  <UserHistoryItem label="Meals Ordered">
                    <p>
                      Selected automatically:
                      {allMealsAutoSelected && <strong> true</strong>}
                      {!allMealsAutoSelected && !partialAutoSelected && (
                        <strong> false</strong>
                      )}
                      {!allMealsAutoSelected && partialAutoSelected && (
                        <strong> {partialAutoSelected}</strong>
                      )}
                    </p>
                    <div className="mt-4">
                      {receipt.selectedMeals.map((mealSelection, i) => {
                        return (
                          <div
                            key={i}
                            className="flex justify-between space-x-8 border-t border-grey-900 py-2"
                          >
                            <div>
                              <Link
                                className="font-bold"
                                to={`/meals/${mealSelection.id}`}
                              >
                                {mealSelection.title}
                                {mealSelection.surchargeCents > 0 && (
                                  <span>
                                    {' '}
                                    | +
                                    {formatCentsToDollars(
                                      mealSelection.surchargeCents
                                    )}
                                  </span>
                                )}
                              </Link>

                              {partialAutoSelected &&
                                mealSelection.selectedAutomatically && (
                                  <span>*</span>
                                )}

                              <p className="text-sm">
                                {mealSelection.subtitle} (#
                                {mealSelection.id})
                              </p>
                            </div>

                            <p className="shrink-0">
                              {formatCentsToDollars(
                                mealSelection.basePriceCents +
                                  mealSelection.surchargeCents
                              )}
                            </p>
                          </div>
                        )
                      })}
                    </div>
                    {partialAutoSelected && (
                      <p className="mt-4 text-sm italic">
                        * selected automatically
                      </p>
                    )}
                  </UserHistoryItem>
                )}

                {listingsReceipt && listingsReceipt.listings.length > 0 && (
                  <UserHistoryItem label="Extras Ordered">
                    {listingsReceipt.listings.map((listing, index) => {
                      return (
                        <div
                          key={`${listing.id}-${index}`}
                          className="flex justify-between space-x-8 border-t border-grey-900 py-2"
                        >
                          <div className="font-bold">{listing.title}</div>
                          <p className="shrink-0">
                            {formatCentsToDollars(listing.priceCents)}
                          </p>
                        </div>
                      )
                    })}
                  </UserHistoryItem>
                )}
              </div>
              <div className="w-3/12">
                <ReceiptActions
                  csMealCashRecords={csMealCashRecords}
                  currentTerm={currentTerm}
                  currentUserTermStatus={currentUserTermStatus}
                  listingsReceipt={listingsReceipt}
                  receipt={receipt}
                  refundMealCashRecords={refundMealCashRecords}
                  user={user}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <Hr />
    </>
  )
}

const ReceiptActions = ({
  csMealCashRecords,
  currentTerm,
  currentUserTermStatus,
  listingsReceipt,
  receipt,
  refundMealCashRecords,
  user,
}: {
  csMealCashRecords: MealCashRecord[] | ''
  currentTerm: Term | undefined
  currentUserTermStatus: TermStatus | undefined
  listingsReceipt: ListingsReceipt | undefined
  receipt: OrderHistoryReceipt
  refundMealCashRecords: MealCashRecord[] | ''
  user: UserV1
}) => {
  if (
    !receipt.orderStatus ||
    receipt.orderStatus === 'skipped' ||
    !currentTerm ||
    !currentUserTermStatus
  ) {
    return null
  }

  const isCurrentTerm = currentTerm.id === receipt.termID
  const isEmployeeOrder = user.info.isEmployee

  return (
    <div>
      <p className="font-bold">Term #{receipt.termID} Actions</p>

      <div className="mt-2 flex flex-col space-y-3">
        {isCurrentTerm && (
          <ReceiptActionsCurrentTerm
            currentTermOrderBy={currentTerm.order_by}
            currentUserTermStatus={currentUserTermStatus}
            listingsReceipt={listingsReceipt}
            receipt={receipt}
            user={user}
          />
        )}

        {getAdminScope(ORDER_OVERRIDE) && (
          <>
            <RefundOrder
              currentTerm={currentTerm}
              listingsReceipt={listingsReceipt}
              receipt={receipt}
              user={user}
            />

            {refundMealCashRecords && refundMealCashRecords.length > 0 && (
              <RemoveCredit
                csMealCashRecords={csMealCashRecords}
                records={refundMealCashRecords}
                user={user}
              />
            )}

            {!isEmployeeOrder && <AddMarketingCredits user={user} />}

            <SendReceipt
              termID={receipt.termID}
              user={user}
              userTermOrderID={receipt.userTermOrderID}
            />

            <RemainingOrderBalance receipt={receipt} />
          </>
        )}

        {getAdminScope(VENGEFUL_GOD) && (
          <FlushUTOCache receipt={receipt} user={user} />
        )}
      </div>
    </div>
  )
}

const ReceiptActionsCurrentTerm = ({
  currentTermOrderBy,
  currentUserTermStatus,
  listingsReceipt,
  receipt,
  user,
}: {
  currentTermOrderBy: string
  currentUserTermStatus: TermStatus | undefined
  listingsReceipt: ListingsReceipt | undefined
  receipt: OrderHistoryReceipt
  user: UserV1
}) => {
  const isMSRUpdateAllowed = isCurrentTimeBeforeMSRDeadline(currentTermOrderBy)

  if (!isMSRUpdateAllowed) {
    return null
  }

  const isRemoveExtrasAllowed = isRemoveExtrasAvailable(currentTermOrderBy)
  const canRemoveExtras =
    isRemoveExtrasAllowed &&
    listingsReceipt &&
    listingsReceipt.listings.length > 0 &&
    (receipt.paymentStatus === 'paid' ||
      receipt.paymentStatus === 'authorized' ||
      receipt.paymentStatus === 'declined' ||
      receipt.paymentStatus === 'authorize_declined')

  const canCancel =
    receipt.paymentStatus === 'paid' || receipt.paymentStatus === 'authorized'
  const hasMDDAvailable =
    currentUserTermStatus &&
    currentUserTermStatus.subTerms.filter((subTerm) => subTerm.isAvailable)
      .length > 1
  const paymentWasDeclined =
    receipt.paymentStatus === 'declined' ||
    receipt.paymentStatus === 'authorize_declined'
  const fulfillmentIsRefundedOrCanceled =
    receipt.fulfillmentStatus === 'canceled' ||
    receipt.fulfillmentStatus === 'refunded'

  const canEditMeals =
    getAdminScope(OATS_DASHBOARD) &&
    (receipt.paymentStatus === 'paid' || receipt.paymentStatus === 'authorized')

  return (
    <>
      {canEditMeals && (
        <Link
          className="text-sm text-blue-901 hover:underline"
          state={{ receipt }}
          to={`/user/${user.id}/edit-past-meals/${receipt.termID}`}
        >
          Edit Meals
        </Link>
      )}

      {getAdminScope(CS_ADMIN) && canRemoveExtras && (
        <RemoveExtras
          listingsReceipt={listingsReceipt}
          receipt={receipt}
          user={user}
        />
      )}

      {getAdminScope(ORDER_OVERRIDE) && (
        <>
          {canCancel && <CancelOrder receipt={receipt} user={user} />}

          {hasMDDAvailable && (
            <UpdateUTOCycle
              receipt={receipt}
              termStatus={currentUserTermStatus}
              user={user}
            />
          )}

          {receipt.shippingAddress && (
            <UpdateUTOShippingAddress
              currentShippingAddress={receipt.shippingAddress}
              receipt={receipt}
              user={user}
            />
          )}

          {paymentWasDeclined && (
            <UpdateUTOPaymentReprocessStatus receipt={receipt} user={user} />
          )}

          {fulfillmentIsRefundedOrCanceled && (
            <UpdateUTOFulfillmentStatus receipt={receipt} user={user} />
          )}
        </>
      )}
    </>
  )
}

const ReceiptPaymentLineItems = ({
  csMealCashRecords,
  paymentLineItems,
  paymentStatus,
  refundMealCashRecords,
}: {
  csMealCashRecords: MealCashRecord[]
  paymentLineItems: OrderHistoryReceipt['paymentLineItems']
  paymentStatus: OrderHistoryReceipt['paymentStatus']
  refundMealCashRecords: MealCashRecord[]
}) => {
  const paymentCharges = paymentLineItems.filter(
    (item) => item.type === 'charge'
  )
  const totalCharge = paymentCharges.reduce((total, charge) => {
    return total + charge.transactionAmountCents
  }, 0)
  const paymentSku = paymentLineItems.find((item) => item.type === 'sku')
  const paymentSurcharge = paymentLineItems.find(
    (item) => item.type === 'surcharge'
  )
  const paymentBase = paymentLineItems.find((item) => item.type === 'baseprice')
  const paymentTax = paymentLineItems.find((item) => item.type === 'tax')
  const shipping = paymentLineItems.find((item) => item.type === 'shipping')
  const discount = paymentLineItems.find((item) => item.type === 'discount')
  const mealCash = paymentLineItems.find((item) => item.type === 'meal_cash')
  const mealCredits = paymentLineItems.find(
    (item) => item.type === 'meal_credit'
  )
  const giftCardPayment = paymentLineItems.find(
    (item) => item.type === 'gift_card'
  )
  const paymentRefunded = paymentLineItems.find(
    (item) => item.type === 'refunded'
  )
  const partialRefund = paymentLineItems.filter(
    (item) => item.type === 'refund'
  )
  let partialRefundTotal = 0

  // Multiple partial refunds are allowed, so add them up to show the total refunded amount
  if (partialRefund.length) {
    partialRefundTotal = partialRefund
      .map((item) => item.invoiceAmountCents)
      .reduce((total, item) => total + item)
  }

  return (
    <div className="space-y-1">
      <PaymentItem label="Status">{paymentStatus}</PaymentItem>

      {paymentSku && !paymentBase && (
        <PaymentItem label="Meal Plan">
          ${(paymentSku.invoiceAmountCents / 100).toFixed(2)}
        </PaymentItem>
      )}
      {paymentBase && (
        <PaymentItem label="Meal Plan">
          ${(paymentBase.invoiceAmountCents / 100).toFixed(2)}
        </PaymentItem>
      )}
      {paymentSurcharge && (
        <PaymentItem label="Surcharges">
          ${(paymentSurcharge.invoiceAmountCents / 100).toFixed(2)}
        </PaymentItem>
      )}
      {discount && (
        <PaymentItem label="Discount">
          ${(discount.invoiceAmountCents / 100).toFixed(2)}
        </PaymentItem>
      )}
      {mealCredits && (
        <PaymentItem label="Meal Credits">
          ${(mealCredits.invoiceAmountCents / 100).toFixed(2)}
        </PaymentItem>
      )}
      {mealCash && (
        <PaymentItem label="Tovala Cash">
          ${(mealCash.invoiceAmountCents / 100).toFixed(2)}
        </PaymentItem>
      )}
      {giftCardPayment && (
        <PaymentItem label="Gift Card">
          ${(giftCardPayment.invoiceAmountCents / 100).toFixed(2)}
        </PaymentItem>
      )}
      {paymentTax && (
        <PaymentItem label="Tax">
          ${(paymentTax.invoiceAmountCents / 100).toFixed(2)}
        </PaymentItem>
      )}
      {shipping && (
        <PaymentItem label="Shipping">
          ${(shipping.invoiceAmountCents / 100).toFixed(2)}
        </PaymentItem>
      )}
      <hr className="my-2" />
      {paymentCharges.length > 1 && (
        <>
          {paymentCharges.map((paymentCharge) => {
            return (
              <PaymentItem key={paymentCharge.id} label="Charge">
                ${(paymentCharge.transactionAmountCents / 100).toFixed(2)}
              </PaymentItem>
            )
          })}
        </>
      )}

      <PaymentItem label="Total Charge">
        ${(totalCharge / 100).toFixed(2)}
      </PaymentItem>

      {paymentRefunded && (
        <PaymentItem label="Refunded">
          ${(paymentRefunded.invoiceAmountCents / 100).toFixed(2)}
        </PaymentItem>
      )}
      {partialRefundTotal > 0 && (
        <PaymentItem label="Refunded">
          ${(partialRefundTotal / 100).toFixed(2)}
        </PaymentItem>
      )}
      {refundMealCashRecords.map((record) => {
        return (
          <PaymentItem key={record.id} label="Refunded to Tovala Cash">
            ${(record.amount / 100).toFixed(2)}
          </PaymentItem>
        )
      })}
      {csMealCashRecords.map((record) => (
        <PaymentItem key={record.id} label="Tovala Cash Added">
          ${(record.amount / 100).toFixed(2)}
        </PaymentItem>
      ))}
    </div>
  )
}

const AddMarketingCredits = (
  props: Omit<AddMarketingCreditsModalProps, 'onCloseModal'>
) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <button
        className="text-left text-sm text-blue-901 hover:underline"
        onClick={() => {
          setIsModalOpen(true)
        }}
      >
        Add Marketing Credits (Discount)
      </button>

      {isModalOpen && (
        <AddMarketingCreditsModal
          {...props}
          onCloseModal={() => {
            setIsModalOpen(false)
          }}
        />
      )}
    </>
  )
}

const CancelOrder = (props: Omit<CancelOrderModalProps, 'onCloseModal'>) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <button
        className="text-left text-sm text-blue-901 hover:underline"
        onClick={() => {
          setIsModalOpen(true)
        }}
      >
        Cancel Order
      </button>

      {isModalOpen && (
        <CancelOrderModal
          {...props}
          onCloseModal={() => {
            setIsModalOpen(false)
          }}
        />
      )}
    </>
  )
}

const FlushUTOCache = (
  props: Omit<FlushUTOCacheModalProps, 'onCloseModal'>
) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <button
        className="text-left text-sm text-blue-901 hover:underline"
        onClick={() => {
          setIsModalOpen(true)
        }}
      >
        Flush Order Cache
      </button>

      {isModalOpen && (
        <FlushUTOCacheModal
          {...props}
          onCloseModal={() => {
            setIsModalOpen(false)
          }}
        />
      )}
    </>
  )
}

const RefundOrder = ({
  currentTerm,
  listingsReceipt,
  receipt,
  user,
}: {
  currentTerm: Term
  listingsReceipt: ListingsReceipt | undefined
  receipt: OrderHistoryReceipt
  user: UserV1
}) => {
  const dispatch = useAppDispatch()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [paymentInvoice, setPaymentInvoice] = useState<
    IRemainingOrderBalance['paymentInvoice'] | null
  >(null)
  const [refundType, setRefundType] = useState<'credit' | 'refund'>('refund')

  const openRefundModal = () => {
    dispatch(getRemainingOrderBalance(receipt.userTermOrderID)).then(
      (response) => {
        if (response && response.payload) {
          setIsModalOpen(true)
          setPaymentInvoice(response.payload.paymentInvoice)
        }
      }
    )
  }

  const isCurrentTerm = currentTerm.id === receipt.termID

  const isEmployeeOrder = user.info.isEmployee
  const isRefundAllowed = isOrderRefundAvailable(
    currentTerm.order_by,
    isCurrentTerm
  )

  const canRefundOrder =
    isRefundAllowed &&
    (receipt.paymentStatus === 'paid' ||
      receipt.paymentStatus === 'refunded_partial')
  const canCreditOrder =
    isRefundAllowed &&
    (receipt.paymentStatus === 'paid' ||
      receipt.paymentStatus === 'refunded_mealcash') &&
    !isEmployeeOrder

  return (
    <>
      {isModalOpen && paymentInvoice && (
        <RefundOrderModal
          listingsReceipt={listingsReceipt}
          onCloseModal={() => {
            setIsModalOpen(false)
          }}
          order={receipt}
          paymentInvoice={paymentInvoice}
          type={refundType}
          user={user}
        />
      )}

      {canCreditOrder && (
        <button
          className="text-left text-sm text-blue-901 hover:underline"
          onClick={() => {
            setRefundType('credit')
            openRefundModal()
          }}
        >
          Credit Order with Tovala Cash
        </button>
      )}

      {canRefundOrder && (
        <button
          className="text-left text-sm text-blue-901 hover:underline"
          onClick={() => {
            setRefundType('refund')
            openRefundModal()
          }}
        >
          Refund Order
        </button>
      )}
    </>
  )
}

const RemainingOrderBalance = ({
  receipt,
}: {
  receipt: OrderHistoryReceipt
}) => {
  const dispatch = useAppDispatch()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [paymentInvoice, setPaymentInvoice] = useState<
    IRemainingOrderBalance['paymentInvoice'] | null
  >(null)

  const showRemainingOrderBalance = () => {
    dispatch(getRemainingOrderBalance(receipt.userTermOrderID)).then(
      (response) => {
        if (response && response.payload) {
          setPaymentInvoice(response.payload.paymentInvoice)
          setIsModalOpen(true)
        }
      }
    )
  }

  return (
    <>
      <button
        className="text-left text-sm text-blue-901 hover:underline"
        onClick={() => {
          showRemainingOrderBalance()
        }}
      >
        Show Remaining Order Balance
      </button>

      {isModalOpen && paymentInvoice && (
        <RemainingOrderBalanceModal
          onCloseModal={() => {
            setIsModalOpen(false)
          }}
          paymentInvoice={paymentInvoice}
          receipt={receipt}
        />
      )}
    </>
  )
}

const RemoveCredit = (props: Omit<RemoveCreditModalProps, 'onCloseModal'>) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <button
        className="text-left text-sm text-blue-901 hover:underline"
        onClick={() => {
          setIsModalOpen(true)
        }}
      >
        Remove Tovala Cash Refund
      </button>

      {isModalOpen && (
        <RemoveCreditModal
          {...props}
          onCloseModal={() => {
            setIsModalOpen(false)
          }}
        />
      )}
    </>
  )
}

const RemoveExtras = (props: Omit<RemoveExtrasModalProps, 'onCloseModal'>) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <button
        className="text-left text-sm text-blue-901 hover:underline"
        onClick={() => {
          setIsModalOpen(true)
        }}
      >
        Remove Extras
      </button>

      {isModalOpen && (
        <RemoveExtrasModal
          {...props}
          onCloseModal={() => {
            setIsModalOpen(false)
          }}
        />
      )}
    </>
  )
}

const SendReceipt = (props: Omit<SendReceiptModalProps, 'onCloseModal'>) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <button
        className="text-left text-sm text-blue-901 hover:underline"
        onClick={() => {
          setIsModalOpen(true)
        }}
      >
        Send Receipt to User
      </button>

      {isModalOpen && (
        <SendReceiptModal
          {...props}
          onCloseModal={() => {
            setIsModalOpen(false)
          }}
        />
      )}
    </>
  )
}

const UpdateUTOCycle = (
  props: Omit<UpdateUTOCycleModalProps, 'onCloseModal'>
) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <button
        className="text-left text-sm text-blue-901 hover:underline"
        onClick={() => {
          setIsModalOpen(true)
        }}
      >
        Update Delivery Day
      </button>

      {isModalOpen && (
        <UpdateUTOCycleModal
          {...props}
          onCloseModal={() => {
            setIsModalOpen(false)
          }}
        />
      )}
    </>
  )
}

const UpdateUTOFulfillmentStatus = (
  props: Omit<UpdateUTOFulfillmentStatusModalProps, 'onCloseModal'>
) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <button
        className="text-left text-sm text-blue-901 hover:underline"
        onClick={() => {
          setIsModalOpen(true)
        }}
      >
        Update Order Status
      </button>

      {isModalOpen && (
        <UpdateUTOFulfillmentStatusModal
          {...props}
          onCloseModal={() => {
            setIsModalOpen(false)
          }}
        />
      )}
    </>
  )
}

const UpdateUTOPaymentReprocessStatus = (
  props: Omit<UpdateUTOPaymentReprocessStatusModalProps, 'onCloseModal'>
) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <button
        className="text-left text-sm text-blue-901 hover:underline"
        onClick={() => {
          setIsModalOpen(true)
        }}
      >
        Update Payment Reprocess Status
      </button>

      {isModalOpen && (
        <UpdateUTOPaymentReprocessStatusModal
          {...props}
          onCloseModal={() => {
            setIsModalOpen(false)
          }}
        />
      )}
    </>
  )
}

const UpdateUTOShippingAddress = (
  props: Omit<UpdateUTOShippingAddressModalProps, 'onCloseModal'>
) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <button
        className="text-left text-sm text-blue-901 hover:underline"
        onClick={() => {
          setIsModalOpen(true)
        }}
      >
        Update Shipping Address
      </button>

      {isModalOpen && (
        <UpdateUTOShippingAddressModal
          {...props}
          onCloseModal={() => {
            setIsModalOpen(false)
          }}
        />
      )}
    </>
  )
}

const UserHistoryItem = ({
  children,
  label,
}: {
  children: ReactNode
  label: string
}) => {
  return (
    <div>
      <div className="font-bold">{label}</div>
      <div>{children}</div>
    </div>
  )
}

const PaymentItem = ({
  children,
  label,
}: {
  children: ReactNode
  label: string
}) => {
  return (
    <div className="grid grid-cols-[3fr_1fr] text-sm">
      <div className="font-semibold text-grey-905">{label}</div>
      <div>{children}</div>
    </div>
  )
}
