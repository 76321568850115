import { clsx } from 'clsx'
import { ReactNode } from 'react'

type BadgeStyle = 'black' | 'grey' | 'red'

const Badge = ({
  badgeStyle,
  children,
}: {
  badgeStyle: BadgeStyle
  children: ReactNode
}) => {
  return (
    <div
      className={clsx('inline-flex rounded px-2 py-0.5 text-xs', {
        'bg-black-901 font-bold text-white-900': badgeStyle === 'black',
        'bg-grey-903': badgeStyle === 'grey',
        'bg-red-901 font-bold text-white-900': badgeStyle === 'red',
      })}
    >
      {children}
    </div>
  )
}

export default Badge
