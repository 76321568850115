import {
  APIErrorDisplay,
  ButtonLoading,
  ErrorDisplay,
} from '@tovala/component-library'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { ErrorCodeMessageMapCombinedAPI } from '@tovala/browser-apis-combinedapi'
import { FormEvent, useEffect, useState } from 'react'
import { GoodErrorMessaging } from '@tovala/browser-apis-core'

import { hasFailedToLoadStripe } from '../../utils/stripe'

const ADD_PAYMENT_METHOD_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please try again.',
    why: "We couldn't add the payment method due to a technical issue on our end.",
  },
  'StripeError-card_error': {
    helpToFix: 'Please try using different payment credentials.',
    why: "We couldn't add the payment method because it was declined.",
  },
}

const CreditCardInput = ({
  addPaymentSourceError,
  isAddingPaymentSource,
  onSubmit,
}: {
  addPaymentSourceError: Error | null
  isAddingPaymentSource: boolean
  onSubmit(stripeToken: string): Promise<unknown>
}) => {
  // We need separate state for checking if Stripe failed to load since the
  // "stripe" variable from "useStripe" will be null while Stripe is loading.
  const [failedToLoadStripe, setFailedToLoadStripe] = useState(false)
  const [isProcessingSubmit, setIsProcessingSubmit] = useState(false)
  const [submissionError, setSubmissionError] =
    useState<GoodErrorMessaging | null>(null)

  const stripe = useStripe()
  const elements = useElements()

  const handleSubmit = async (ev: FormEvent<HTMLFormElement>) => {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault()

    setSubmissionError(null)

    const goodErrorMessaging = {
      helpToFix: 'Please reload the page and try again.',
      why: "We couldn't add the payment method due to a technical issue on our end.",
    }

    const cardElement = elements?.getElement('card')

    if (!stripe || !cardElement) {
      setSubmissionError(goodErrorMessaging)
      return
    }

    setIsProcessingSubmit(true)

    const { error, token } = await stripe.createToken(cardElement)

    if (error || !token) {
      setSubmissionError(goodErrorMessaging)
    } else {
      try {
        await onSubmit(token.id)

        cardElement.clear()
      } catch (err) {
        // Caller is expected to handle any error here.
      }
    }

    setIsProcessingSubmit(false)
  }

  useEffect(() => {
    if (stripe === null) {
      setFailedToLoadStripe(hasFailedToLoadStripe())
    }
  }, [stripe])

  return (
    <div>
      {/*
       * The "cc-input-container" test ID is needed so we can target the Stripe card element
       * accurately in our end-to-end tests.
       */}
      <form data-testid="cc-input-container" onSubmit={handleSubmit}>
        <div className="space-y-4">
          {failedToLoadStripe ? (
            <ErrorDisplay
              helpToFix="You may have a browser extension blocking our script, but please reload the page to try again."
              why="We can't add a new card to the account because we couldn't load our payment processor."
            />
          ) : (
            <div className="rounded-lg border border-grey-4 p-4">
              <CardElement
                options={{
                  style: {
                    base: {
                      color: '#6F6F6A',
                      fontFamily: 'Sohne, sans-serif',
                      fontSize: '14px',
                      letterSpacing: '0.02em',
                      '::placeholder': {
                        color: '#6F6F6A',
                      },
                    },
                  },
                }}
              />
            </div>
          )}

          {addPaymentSourceError ? (
            <APIErrorDisplay
              error={addPaymentSourceError}
              errorCodeMessageMap={ADD_PAYMENT_METHOD_ERRORS}
            />
          ) : submissionError ? (
            <ErrorDisplay {...submissionError} />
          ) : null}
        </div>
        <div className="mt-6">
          <ButtonLoading
            isLoading={isProcessingSubmit || isAddingPaymentSource}
            size="large"
            type="submit"
          >
            Save Payment
          </ButtonLoading>
        </div>
      </form>
    </div>
  )
}

export default CreditCardInput
