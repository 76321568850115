import { ReactNode } from 'react'

const MenuItemLayout = ({
  children,
  sidebar,
}: {
  children: ReactNode
  sidebar: ReactNode
}) => {
  return (
    <div className="flex space-x-4">
      <div className="w-9/12">{children}</div>
      <div className="w-3/12">{sidebar}</div>
    </div>
  )
}

export default MenuItemLayout
