import {
  MenuComponentAnimatedMealCarousel,
  MenuComponentMeal,
  MenuComponentMealWithExtra,
  MenuComponents,
  MenuComponentTextBanner,
  MenuComponentTextImageStack,
  MenuComponentTwoMealPicker,
} from '@tovala/browser-apis-cdn'
import {
  MenuComponentStandardized,
  MenuComponentStandardizedMeal,
  MenuComponentStandardizedMealCarousel,
  MenuComponentStandardizedMealWithExtra,
  MenuComponentStandardizedTextBanner,
  MenuComponentStandardizedTwoMealPicker,
} from '@tovala/browser-apis-menu-components'
import { v4 as uuidV4 } from 'uuid'
import { getValidImageURL } from './utils'

export function downloadJSON({
  filename,
  json,
}: {
  filename: string
  json: Record<string, unknown>
}) {
  const jsonStr = JSON.stringify(json, null, 2)
  const blob = new Blob([jsonStr], { type: 'application/json' })
  const url = URL.createObjectURL(blob)

  const link = document.createElement('a')
  link.href = url
  link.download = filename

  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)

  URL.revokeObjectURL(url)
}

export function makeCDNComponents(components: MenuComponentStandardized[]) {
  const cdnComponents: MenuComponents['components'] = []

  components.forEach((component) => {
    if (component.type === 'meal') {
      cdnComponents.push(makeMealComponent(component))
    } else if (component.type === 'mealWithExtra') {
      cdnComponents.push(makeMealWithExtraComponent(component))
    } else if (component.type === 'twoMealPicker') {
      cdnComponents.push(makeTwoMealPickerComponent(component))
    } else if (component.type === 'animatedMealCarousel') {
      cdnComponents.push(makeAnimatedMealCarouselComponent(component))
    } else if (component.type === 'backgroundImageHeader') {
      const { id, properties, type } = component

      cdnComponents.push({
        id,
        properties: {
          ...properties,
        },
        type,
      })
    } else if (component.type === 'textBanner') {
      cdnComponents.push(makeTextBannerComponent(component))
    } else if (component.type === 'textImageStack') {
      const { id, properties, type } = component

      let image: { url: string } | undefined = undefined
      if (properties.image?.url) {
        image = { url: properties.image.url }
      }

      const children: MenuComponentTextImageStack['properties']['children'] = []

      properties.children.forEach((child) => {
        if (child.type === 'meal') {
          children.push(makeMealComponent(child))
        } else if (child.type === 'mealWithExtra') {
          children.push(makeMealWithExtraComponent(child))
        } else if (child.type === 'twoMealPicker') {
          children.push(makeTwoMealPickerComponent(child))
        } else if (child.type === 'animatedMealCarousel') {
          children.push(makeAnimatedMealCarouselComponent(child))
        }
      })

      cdnComponents.push({
        id: id.startsWith('TEMP') ? uuidV4() : id,
        properties: {
          children,
          image,
          subtitle: properties.subtitle,
          title: properties.title,
        },
        type,
      })
    }
  })

  return cdnComponents
}

export function makeMealComponent(
  component: MenuComponentStandardizedMeal
): MenuComponentMeal {
  const { id, properties, type } = component

  return {
    id: id.startsWith('TEMP') ? uuidV4() : id,
    properties: {
      mealID: properties.id,
    },
    type,
  }
}

export function makeMealWithExtraComponent(
  component: MenuComponentStandardizedMealWithExtra
): MenuComponentMealWithExtra {
  const { id, properties, type } = component

  const url = getValidImageURL(properties.mealOption.meal.image.url)

  return {
    id: id.startsWith('TEMP') ? uuidV4() : id,
    properties: {
      meal: {
        mealID: properties.meal.id,
      },
      mealIDs: [properties.meal.id, properties.mealOption.meal.id],
      mealOption: {
        meal: {
          mealID: properties.mealOption.meal.id,
          image: {
            url,
          },
        },
        detailsMealID: properties.mealOption.detailsMealID,
      },
    },
    type,
  }
}

export function makeAnimatedMealCarouselComponent(
  component: MenuComponentStandardizedMealCarousel
): MenuComponentAnimatedMealCarousel {
  const { id, properties, type } = component

  return {
    id: id.startsWith('TEMP') ? uuidV4() : id,
    properties: {
      buttonTitle: properties.buttonTitle,
      mealOptions: properties.mealOptions.map((mealOption) => {
        return {
          mealID: mealOption.id,
          optionTitle: mealOption.optionTitle,
        }
      }),
    },
    type,
  }
}

export function makeTextBannerComponent(
  component: MenuComponentStandardizedTextBanner
): MenuComponentTextBanner {
  const { id, properties, type } = component

  return {
    id: id.startsWith('TEMP') ? uuidV4() : id,
    properties: {
      title: properties.title,
    },
    type,
  }
}

export function makeTwoMealPickerComponent(
  component: MenuComponentStandardizedTwoMealPicker
): MenuComponentTwoMealPicker {
  const { id, properties, type } = component

  return {
    id: id.startsWith('TEMP') ? uuidV4() : id,
    properties: {
      firstMeal: {
        buttonTitle: properties.meals[0].mealSummary.shortSubtitle,
        meal: {
          mealID: properties.meals[0].id,
        },
      },
      secondMeal: {
        buttonTitle: properties.meals[1].mealSummary.shortSubtitle,
        meal: {
          mealID: properties.meals[1].id,
        },
      },
    },
    type,
  }
}
