import { MenuComponentStandardizedMeal } from '@tovala/browser-apis-menu-components'
import { Meal as MealComponent, MealImage } from '@tovala/component-library'

const Meal = ({
  properties,
}: {
  properties: MenuComponentStandardizedMeal['properties']
}) => {
  const { image, subtitle, surcharge, tags, title } = properties
  return (
    <MealComponent
      image={<MealImage image={image} />}
      subtitle={subtitle}
      surcharge={surcharge}
      tags={tags}
      title={title}
    />
  )
}

export default Meal
