import { ButtonLoading, ErrorDisplay } from '@tovala/component-library'
import {
  ErrorCodeMessageMapCombinedAPI,
  useRedeemCoupon,
  useRedeemGiftCard,
  UserV1,
} from '@tovala/browser-apis-combinedapi'
import { Field, reduxForm } from 'redux-form'
import { FormEventHandler, ReactNode, useEffect, useState } from 'react'

import { getAdminScope, USERS_WRITE } from '../../utils/getAdminScope'
import { getUserInfo } from '../../actions/auth'
import { makeGenericFallbackError, REDEEM_GIFT_CARD_ERRORS } from 'utils/errors'
import { successHandler } from 'actions/notifications'

import { useAppDispatch } from 'hooks'
import InputRFCompLib from 'components/common/InputRFCompLib'
import SentryAPIErrorDisplay from 'components/common/SentryAPIErrorDisplay'

interface FormData {
  coupon_code: string
  gift_code: string
}

interface Props {
  user: UserV1
}

const INITIAL_VALUES = {
  coupon_code: '',
  gift_code: '',
}

const form = reduxForm<FormData, Props>({
  form: 'promotions',
  initialValues: INITIAL_VALUES,
})

const REDEEM_COUPON_ERRORS: ErrorCodeMessageMapCombinedAPI<ReactNode> = {
  couponcodesDoesNotExist: {
    helpToFix: 'Please check the entered code and try again.',
    why: "We couldn't apply that coupon code because it does not exist.",
  },
  Fallback: makeGenericFallbackError({ action: 'apply that coupon code' }),
  'InvalidCoupon-AlreadyRedeemedByUser': {
    wayOut:
      'If you need further assistance, please send a message in the #help-web Slack channel.',
    why: "We couldn't apply that coupon code because it has already been redeemed.",
  },
  'InvalidCoupon-SKUMismatch': {
    wayOut:
      'If you need further assistance, please send a message in the #help-web Slack channel.',
    why: "We couldn't apply that coupon code because it's only valid for a Tovala Oven purchase.",
  },
}

const UserPromos = ({
  handleSubmit,
  initialize,
  user,
}: Props & {
  handleSubmit(onSubmit: (formData: FormData) => void): FormEventHandler
  initialize(formData: FormData): void
}): JSX.Element => {
  const dispatch = useAppDispatch()

  const { isEmployee } = user.info

  const [submitErrorText, setSubmitErrorText] = useState('')

  const {
    error: redeemCouponError,
    isError: hasRedeemCouponError,
    isLoading: isRedeemingCoupon,
    mutate: redeemCoupon,
  } = useRedeemCoupon({
    onSuccess: () => {
      successHandler(dispatch, 'Coupon successfully applied to account')

      initialize(INITIAL_VALUES)
    },
  })

  const {
    error: redeemGiftCardError,
    isError: hasRedeemGiftCardError,
    isLoading: isRedeemingGiftCard,
    mutate: redeemGiftCard,
  } = useRedeemGiftCard({
    onSuccess: () => {
      successHandler(
        dispatch,
        `Hooray! The gift card balance has been applied to ${user.info.email}.`
      )
      dispatch(getUserInfo(user.id))

      initialize(INITIAL_VALUES)
    },
  })

  function handleFormSubmit(formData: FormData) {
    if (!formData.coupon_code && !formData.gift_code) {
      setSubmitErrorText('Please enter a coupon or a gift code.')
      return
    }

    setSubmitErrorText('')

    if (formData.gift_code) {
      redeemGiftCard({
        cardID: formData.gift_code,
        data: { cardID: formData.gift_code },
        userID: user.id,
      })
    } else if (formData.coupon_code) {
      redeemCoupon({ data: { code: formData.coupon_code }, userID: user.id })
    }
  }

  useEffect(() => {
    document.title = `Glaze | User #${user.id} - Promos`
  }, [user.id])

  return (
    <div className="w-5/12 space-y-8 font-sans-new">
      {isEmployee ? (
        <p>
          Applying a promo or gift card is not available for employee accounts.
        </p>
      ) : (
        <>
          <h2 className="text-k/28_130">Apply a promo to a customer</h2>

          {getAdminScope(USERS_WRITE) ? (
            <form
              className="space-y-8"
              onSubmit={handleSubmit(handleFormSubmit)}
            >
              <p>
                Use this form to apply meal credit or gifts to this user&apos;s
                account.
              </p>

              <div className="space-y-4">
                <Field
                  component={InputRFCompLib}
                  name="coupon_code"
                  placeholder="enter coupon code"
                />
                <p className="text-center">or</p>
                <Field
                  component={InputRFCompLib}
                  name="gift_code"
                  placeholder="enter gift code"
                />
              </div>

              <div className="space-y-4">
                {submitErrorText ? (
                  <ErrorDisplay why={submitErrorText} />
                ) : hasRedeemGiftCardError ? (
                  <SentryAPIErrorDisplay
                    error={redeemGiftCardError}
                    errorCodeMessageMap={REDEEM_GIFT_CARD_ERRORS}
                  />
                ) : hasRedeemCouponError ? (
                  <SentryAPIErrorDisplay
                    error={redeemCouponError}
                    errorCodeMessageMap={REDEEM_COUPON_ERRORS}
                  />
                ) : null}

                <ButtonLoading
                  isLoading={isRedeemingCoupon || isRedeemingGiftCard}
                  size="large"
                >
                  Submit
                </ButtonLoading>
              </div>
            </form>
          ) : (
            <p>You do not have the required permissions to apply a promo.</p>
          )}
        </>
      )}
    </div>
  )
}

export default form(UserPromos)
