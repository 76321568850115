import {
  OrderHistoryReceipt,
  useFlushUTOCache,
  UserV1,
} from '@tovala/browser-apis-combinedapi'

import { errorHandler, successHandler } from 'actions/notifications'

import { useAppDispatch } from 'hooks'
import Button from 'components/common/Button'
import Modal, { ModalBody, ModalHeader } from 'components/modals/Modal'

export interface Props {
  onCloseModal(): void
  receipt: OrderHistoryReceipt
  user: UserV1
}

const FlushUTOCacheModal = ({
  onCloseModal,
  receipt,
  user,
}: Props): JSX.Element => {
  const dispatch = useAppDispatch()

  const { userTermOrderID, termID } = receipt

  const { mutate: flushUTOCache } = useFlushUTOCache({
    onError: (err) => {
      errorHandler(
        dispatch,
        err,
        'Something went wrong during flush, please contact #help-backend'
      )
    },
    onSuccess: () => {
      successHandler(dispatch, 'Success! Order cache flushed')
    },
  })

  return (
    <Modal onCloseModal={onCloseModal}>
      <ModalBody>
        <ModalHeader onClickClose={onCloseModal}>Flush Order Cache</ModalHeader>
        <div className="w-[500px] space-y-4">
          <p>
            Flush order cache for Term #{termID} for {user.info.email}.
          </p>
          <Button
            onClick={() => {
              flushUTOCache({ data: { field: 'id', keys: [userTermOrderID] } })
              onCloseModal()
            }}
            size="large"
          >
            Flush Order Cache
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default FlushUTOCacheModal
