import { FileRejection } from 'react-dropzone'
import { MealImage } from '@tovala/browser-apis-combinedapi'
import { ReactNode, useCallback, useState } from 'react'

import { addMealImage, updateImageText } from 'actions/meals'
import { isAxiosResponseError } from 'utils/api'

import { useAppDispatch } from 'hooks'
import CheckIcon from 'components/common/icons/CheckIcon'
import CircleLoader from 'components/common/CircleLoader'
import FileDropzone from 'components/common/FileDropzone'

type FileState =
  | { message: string; status: 'error' }
  | { status: 'pending' }
  | { status: 'success' }

const UPLOAD_FILE_ERROR_MAPPING = {
  UniqueConstraint:
    'An image with that filename already exists. Please rename the file and try again.',
}

function imageUploadValidator(file: File) {
  if (file.name.search(/[^a-zA-Z0-9\-_.]+/) !== -1) {
    return {
      code: 'filename-invalid',
      message:
        'Please rename this image without special characters and try again.',
    }
  }

  return null
}

const UploadMealImage = ({ mealid }: { mealid: string }) => {
  const dispatch = useAppDispatch()

  const [filePreviewURLs, setFilePreviewURLs] = useState<Map<string, string>>(
    new Map()
  )
  const [filesState, setFilesState] = useState<Map<string, FileState>>(
    new Map()
  )

  const handleUpdateImageText = useCallback(
    ({ file, mealImage }: { file: File; mealImage: MealImage }) => {
      const fileName = file.name
        .replace('.jpg', '')
        .replace('.png', '')
        .replace(/-/g, ' ')
      const fileNameLower = fileName.toLowerCase()

      let key = ''
      let caption = ''
      if (fileNameLower.includes('cell_tile')) {
        key = 'cell_tile'
      }
      if (fileNameLower.includes('raw')) {
        key = 'raw'
      }
      if (fileNameLower.includes('tray_main')) {
        key = 'tray_main'
        caption = fileName
          .replace(/TRAY_MAIN/gi, '')
          .replace(/_/g, ' ')
          .trim()
      }
      if (fileNameLower.includes('tray_side')) {
        key = 'tray_side'
        caption = fileName
          .replace(/TRAY_SIDE/gi, '')
          .replace(/_/g, ' ')
          .trim()
      }
      if (fileNameLower.includes('garnish_1oz_top')) {
        key = 'garnish_1oz_top'
        caption = fileName
          .replace(/GARNISH_1OZ_TOP/gi, '')
          .replace(/_/g, ' ')
          .trim()
      }
      if (fileNameLower.includes('garnish_1oz_bottom')) {
        key = 'garnish_1oz_bottom'
        caption = fileName
          .replace(/GARNISH_1OZ_BOTTOM/gi, '')
          .replace(/_/g, ' ')
          .trim()
      }
      if (fileNameLower.includes('garnish_2oz_top')) {
        key = 'garnish_2oz_top'
        caption = fileName
          .replace(/GARNISH_2OZ_TOP/gi, '')
          .replace(/_/g, ' ')
          .trim()
      }
      if (fileNameLower.includes('garnish_2oz_bottom')) {
        key = 'garnish_2oz_bottom'
        caption = fileName
          .replace(/GARNISH_2OZ_BOTTOM/gi, '')
          .replace(/_/g, ' ')
          .trim()
      }
      if (fileNameLower.includes('tortilla')) {
        key = 'tortillas'
        caption = 'Tortillas'
      }

      const { alt, dominantColor, hash, mealid } = mealImage

      return dispatch(
        updateImageText(mealid, hash, {
          alt,
          caption,
          dominantColor,
          key,
        })
      )
    },
    [dispatch]
  )

  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      for (const file of acceptedFiles) {
        setFilePreviewURLs((filePreviews) => {
          const newFilePreviews = new Map(filePreviews)
          newFilePreviews.set(file.name, URL.createObjectURL(file))

          return newFilePreviews
        })

        setFilesState((filesState) => {
          const newFilesState = new Map(filesState)
          newFilesState.set(file.name, { status: 'pending' })

          return newFilesState
        })

        const fileCopy = new File([file], file.name.replace(/\s+/g, ''), {
          type: file.type,
        })

        dispatch(addMealImage({ file: fileCopy, mealid }))
          .then((mealImage) => {
            handleUpdateImageText({ file: fileCopy, mealImage }).then(
              (response) => {
                setFilesState((filesState) => {
                  const newFilesState = new Map(filesState)
                  newFilesState.set(
                    file.name,
                    response
                      ? { status: 'success' }
                      : {
                          message:
                            'The image was uploaded but the metadata could not be set. Please find the image on the left and update accordingly.',
                          status: 'error',
                        }
                  )

                  return newFilesState
                })
              }
            )
          })
          .catch((error) => {
            if (isAxiosResponseError(error)) {
              setFilesState((filesState) => {
                const errorMessage = error.response?.data.message
                const message = errorMessage
                  ? UPLOAD_FILE_ERROR_MAPPING[errorMessage]
                  : error.message

                const newFilesState = new Map(filesState)
                newFilesState.set(file.name, { message, status: 'error' })

                return newFilesState
              })
            }
          })
      }

      rejectedFiles.forEach(({ errors, file }) => {
        setFilesState((filesState) => {
          const newFilesState = new Map(filesState)
          newFilesState.set(file.name, {
            message: errors[0].message,
            status: 'error',
          })

          return newFilesState
        })
      })
    },
    [handleUpdateImageText, dispatch, mealid]
  )

  return (
    <>
      <FileDropzone onDrop={onDrop} validator={imageUploadValidator}>
        <div className="space-y-4">
          <div className="flex justify-center space-x-4">
            <FileTypeIcon>.JPG</FileTypeIcon>
            <FileTypeIcon>.PNG</FileTypeIcon>
          </div>
          <p>Drop files here to upload</p>
        </div>
      </FileDropzone>

      {filesState.size > 0 && (
        <div className="mt-4">
          <p>Files:</p>

          <div className="divide-y divide-grey-900">
            {Array.from(filesState.entries()).map(([filename, fileState]) => {
              const previewURL = filePreviewURLs.get(filename)
              const filePreview = previewURL ? (
                <img src={filePreviewURLs.get(filename)} />
              ) : (
                <div />
              )

              if (fileState.status === 'pending') {
                return (
                  <FileStatusRow key={filename} filePreview={filePreview}>
                    <div className="flex space-x-2">
                      <span className="font-bold">{filename}: </span>
                      <CircleLoader loaderStyle="colored" />
                    </div>
                  </FileStatusRow>
                )
              } else if (fileState.status === 'success') {
                return (
                  <FileStatusRow key={filename} filePreview={filePreview}>
                    <div className="flex space-x-2">
                      <span className="font-bold">{filename}: </span>
                      <div className="h-6 w-6 text-green-903">
                        <CheckIcon strokeWidth={2} />
                      </div>
                    </div>
                  </FileStatusRow>
                )
              }

              return (
                <FileStatusRow key={filename} filePreview={filePreview}>
                  <p>
                    <span className="font-bold">{filename}: </span>
                    <span className="text-red-901">{fileState.message}</span>
                  </p>
                </FileStatusRow>
              )
            })}
          </div>
        </div>
      )}
    </>
  )
}

export default UploadMealImage

const FileStatusRow = ({
  children,
  filePreview,
}: {
  children: ReactNode
  filePreview: ReactNode
}) => {
  return (
    <div className="grid grid-cols-[100px_1fr] gap-2 py-2">
      {filePreview}

      {children}
    </div>
  )
}

const FileTypeIcon = ({ children }: { children: ReactNode }) => {
  return (
    <div className="relative flex h-10 w-8 items-center rounded-sm border border-grey-905">
      <div className="absolute bottom-1 right-0 bg-black-901 px-1 text-xs text-white-900">
        {children}
      </div>
    </div>
  )
}
