import { Link } from 'react-router-dom'
import { useEffect } from 'react'

import { getAdminScope, MEALS_WRITE } from '../../utils/getAdminScope'

import Button from 'components/common/Button'
import MealTagsTable from './MealTagsTable'
import H1 from 'components/common/H1'
import { useMealTags } from '@tovala/browser-apis-combinedapi'

const MealTags = (): JSX.Element => {
  const { data: allMealTags = [] } = useMealTags()

  useEffect(() => {
    document.title = `Glaze | Meal Tags`
  }, [])

  return (
    <div className="flex space-x-8">
      <div className="w-10/12">
        <H1>Meal Tags</H1>
        {allMealTags.length > 0 && <MealTagsTable edit tags={allMealTags} />}
      </div>
      <div className="w-2/12 space-y-3">
        {getAdminScope(MEALS_WRITE) && (
          <div className="h-10">
            <Link to="/meal-tags/add">
              <Button buttonStyle="stroke" size="fluid">
                Add Meal Tag
              </Button>
            </Link>
          </div>
        )}
        <div className="h-10">
          <Link to="/slot-map-groups">
            <Button buttonStyle="stroke" size="fluid">
              Slot Map Groups
            </Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default MealTags
