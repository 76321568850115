import { createReducer } from '@reduxjs/toolkit'
import { TermMenu, TermMenuUpdated } from '@tovala/browser-apis-combinedapi'

import * as types from '../actions/types'

const INITIAL_STATE: {
  selectedMenu: TermMenu | TermMenuUpdated | ''
} = {
  selectedMenu: '',
}

export const terms = createReducer(INITIAL_STATE, {
  [types.GET_MENU]: (state, action) => {
    state.selectedMenu = action.payload
  },
})
