import { TermSubTerm } from '@tovala/browser-apis-combinedapi'
import { Modal, ModalHeader, Checkbox, Button } from '@tovala/component-library'
import { useState } from 'react'

const CopyComponentDialog = ({
  onClose,
  onSave,
  subTerms,
}: {
  onClose(): void
  onSave(subTermIDs: Set<string>): void
  subTerms: TermSubTerm[]
}) => {
  const [subTermIDs, setSubTermIDs] = useState(
    new Set<string>(subTerms.map((subTerm) => subTerm.id))
  )

  return (
    <Modal onCloseModal={onClose}>
      <ModalHeader onClickClose={onClose}>Copy to Other Menus</ModalHeader>
      <form
        onSubmit={(event) => {
          event.preventDefault()

          onSave(subTermIDs)
        }}
      >
        <div className="p-6 font-sans-new">
          <div className="w-[500px] space-y-4">
            {subTerms.map((subTerm) => {
              const label = `${subTerm.facilityNetwork} ${subTerm.shipPeriod}`

              return (
                <div key={subTerm.id} className="flex">
                  <Checkbox
                    checked={subTermIDs.has(subTerm.id)}
                    label={
                      <span className="text-k/13_120 uppercase">{label}</span>
                    }
                    name={label}
                    onChange={() => {
                      setSubTermIDs((subTermIDs) => {
                        const newSubTermIDs = new Set(subTermIDs)

                        if (newSubTermIDs.has(subTerm.id)) {
                          newSubTermIDs.delete(subTerm.id)
                        } else {
                          newSubTermIDs.add(subTerm.id)
                        }

                        return newSubTermIDs
                      })
                    }}
                  />
                </div>
              )
            })}
          </div>

          <div className="mt-8 flex flex-row-reverse gap-4">
            <Button size="large" type="submit">
              Copy
            </Button>
            <Button
              buttonStyle="stroke"
              onClick={() => {
                onClose()
              }}
              size="large"
            >
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default CopyComponentDialog
