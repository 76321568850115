import { useEffect } from 'react'

import H1 from 'components/common/H1'

const NotFoundPage = () => {
  useEffect(() => {
    document.title = `Glaze | 404`
  }, [])

  return (
    <div>
      <H1>Page Not Found ¯\_(ツ)_/¯</H1>
    </div>
  )
}

export default NotFoundPage
