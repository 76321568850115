import {
  SlotMapGroup,
  useMenuProductSlotMapGroups,
  useSlotMapGroups,
  useUpdateMenuProductSlotMapGroups,
} from '@tovala/browser-apis-combinedapi'
import { ButtonLoading } from '@tovala/component-library'
import { FormComboboxRHF } from 'components/common/FormComboboxRHF'
import SlotMapGroupsTable, {
  SlotMapGroupRow,
} from 'components/meal-tags/SlotMapGroupsTable'
import { useForm } from 'react-hook-form'

const MenuProductSlotMapGroups = ({
  productID,
}: {
  productID: string | undefined
}) => {
  const { data: slotMapGroups } = useSlotMapGroups()
  const { data: menuProductSlotMapGroups = [] } = useMenuProductSlotMapGroups({
    productID,
  })
  const {
    isLoading: isUpdatingSlotMapGroups,
    mutate: updateMenuProductSlotMapGroups,
  } = useUpdateMenuProductSlotMapGroups()

  if (!productID) {
    return null
  }

  const menuProductSlotMapGroupIDs = menuProductSlotMapGroups.map(
    (slotMapGroup) => slotMapGroup.id
  )
  return (
    <div className="space-y-8 w-8/12">
      {menuProductSlotMapGroups.length > 0 && (
        <SlotMapGroupsTable>
          {[...menuProductSlotMapGroups]
            .sort((a, b) => a.title.localeCompare(b.title))
            .map((slotMapGroup) => {
              return (
                <SlotMapGroupRow
                  key={slotMapGroup.id}
                  slotMapGroup={slotMapGroup}
                >
                  <ButtonLoading
                    buttonStyle="stroke"
                    isLoading={false}
                    onClick={() => {
                      const updatedSlotMapGroupIDs =
                        menuProductSlotMapGroupIDs.filter(
                          (id) => id !== slotMapGroup.id
                        )

                      updateMenuProductSlotMapGroups({
                        productID,
                        data: updatedSlotMapGroupIDs,
                      })
                    }}
                    size="small"
                  >
                    Remove
                  </ButtonLoading>
                </SlotMapGroupRow>
              )
            })}
        </SlotMapGroupsTable>
      )}
      {slotMapGroups && (
        <SlotMapGroupsForm
          isUpdatingSlotMapGroups={isUpdatingSlotMapGroups}
          menuProductSlotMapGroupIDs={menuProductSlotMapGroupIDs}
          onClickAdd={(slotMapGroupID: string) => {
            const updatedSlotMapGroups = [
              ...menuProductSlotMapGroupIDs,
              slotMapGroupID,
            ]

            updateMenuProductSlotMapGroups({
              productID,
              data: updatedSlotMapGroups,
            })
          }}
          slotMapGroups={slotMapGroups}
        />
      )}
    </div>
  )
}

export default MenuProductSlotMapGroups

interface FormData {
  slotMapGroup: {
    label: string
    value: string
  }
}

const SlotMapGroupsForm = ({
  isUpdatingSlotMapGroups,
  menuProductSlotMapGroupIDs,
  onClickAdd,
  slotMapGroups,
}: {
  isUpdatingSlotMapGroups: boolean
  menuProductSlotMapGroupIDs: string[]
  onClickAdd(slotGroupMapID: string): void
  slotMapGroups: SlotMapGroup[]
}) => {
  const { control, handleSubmit, reset, watch } = useForm<FormData>({
    defaultValues: {
      slotMapGroup: {
        label: '',
        value: '',
      },
    },
  })

  const slotMapGroupValue = watch('slotMapGroup')?.value

  const onSubmit = (data: FormData) => {
    onClickAdd(data.slotMapGroup.value)

    reset({
      slotMapGroup: {
        label: '',
        value: '',
      },
    })
  }

  const options = slotMapGroups
    .filter((slotMapGroup) => {
      return !menuProductSlotMapGroupIDs.includes(slotMapGroup.id)
    })
    .map((slotMapGroup) => {
      return {
        label: slotMapGroup.associatedMealTag,
        value: slotMapGroup.id,
      }
    })

  return (
    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <FormComboboxRHF
        control={control}
        id="slotMapGroup"
        isClearable={false}
        label="Tag"
        name="slotMapGroup"
        options={options}
      />

      <ButtonLoading
        disabled={!slotMapGroupValue}
        isLoading={isUpdatingSlotMapGroups}
        size="large"
        type="submit"
      >
        Add
      </ButtonLoading>
    </form>
  )
}
