import { ComponentProps } from 'react'
import { FieldValues, UseControllerProps, useController } from 'react-hook-form'
import { FormGroup, Textarea } from '@tovala/component-library'

export type FormTextareaRHFProps<T extends FieldValues> = {
  label: string
} & ComponentProps<typeof Textarea> &
  UseControllerProps<T>

export const FormTextareaRHF = <T extends FieldValues>({
  control,
  defaultValue,
  id,
  label,
  name,
  rules,
  ...rest
}: FormTextareaRHFProps<T>) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue,
    rules,
  })

  const hasError = !!error

  return (
    <FormGroup error={error?.message} label={label} labelFor={id}>
      <Textarea {...field} {...rest} hasError={hasError} id={id} />
    </FormGroup>
  )
}
