import { createReducer } from '@reduxjs/toolkit'
import { MealTagList, MealTagSingle } from '@tovala/browser-apis-combinedapi'

import * as types from '../actions/types'

const INITIAL_STATE: {
  allMealTags: MealTagList[]
  mealTag: MealTagSingle | Record<string, unknown>
} = {
  allMealTags: [],
  mealTag: {},
}

export const mealTags = createReducer(INITIAL_STATE, {
  [types.GET_ALL_MEAL_TAGS]: (state, action) => {
    state.allMealTags = action.payload
  },
  [types.GET_MEAL_TAG_INFO]: (state, action) => {
    state.mealTag = action.payload
  },
})
