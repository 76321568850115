import {
  ListingSelection,
  MenuListingsLayout,
} from '@tovala/browser-apis-combinedapi'
import {
  MealImage,
  MealImageSoldOut,
  QuantityStepper,
} from '@tovala/component-library'
import { formatCentsToDollars } from 'utils/currency'
import MenuItem from './MenuItem'

const ExtrasGrid = ({
  canDecrement,
  canIncrement,
  listingSelections,
  menuListingsLayout,
  onDecrementListing,
  onIncrementListing,
}: {
  canDecrement: boolean
  canIncrement: boolean
  listingSelections: ListingSelection[]
  menuListingsLayout: MenuListingsLayout | undefined
  onDecrementListing(listingID: string): void
  onIncrementListing(listingID: string): void
}) => {
  if (!menuListingsLayout) {
    return null
  }

  return (
    <div>
      {menuListingsLayout.sections.map((section, index) => {
        return (
          <div key={section?.title ?? `section-${index}`}>
            <div className="grid grid-cols-3 gap-x-6 gap-y-8">
              {section?.listings.map((listing) => {
                const { imageURL, isSoldOut, priceCents, title } = listing

                const quantity =
                  listingSelections.find(
                    (listingSelection) => listingSelection.id === listing.id
                  )?.quantity ?? 0
                return (
                  <div key={listing.id}>
                    <MenuItem
                      image={
                        <MealImage
                          cover={
                            isSoldOut ? (
                              <MealImageSoldOut textSize="small" />
                            ) : null
                          }
                          image={{ url: imageURL }}
                        />
                      }
                      stepper={
                        !isSoldOut || quantity > 0 ? (
                          <QuantityStepper
                            disabledDecrement={!canDecrement}
                            disabledIncrement={
                              !canIncrement || listing.isSoldOut
                            }
                            onClickDecrement={() => {
                              onDecrementListing(listing.id)
                            }}
                            onClickIncrement={() => {
                              onIncrementListing(listing.id)
                            }}
                            quantity={quantity}
                            size="medium"
                          />
                        ) : null
                      }
                      surcharge={`${formatCentsToDollars(priceCents)}`}
                      title={title}
                    />
                  </div>
                )
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default ExtrasGrid
