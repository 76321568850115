import { LeveratorAction } from '@tovala/browser-apis-combinedapi'
import ReactTable, { TableProps } from 'react-table'

import { deleteLeveratorAction } from '../../../actions/marketing'

import { useAppDispatch, useAppSelector } from 'hooks'
import Button from 'components/common/Button'

const ActionsTable = () => {
  const dispatch = useAppDispatch()

  const allLeveratorActions = useAppSelector(
    (state) => state.marketing.allLeveratorActions
  )

  const handleDeleteAction = (actionID: string) => {
    dispatch(deleteLeveratorAction(actionID))
  }

  const columns: TableProps<LeveratorAction>['columns'] = [
    {
      Header: 'Title',
      accessor: 'action.title',
      width: 200,
    },
    {
      Header: 'Type',
      accessor: 'actionIdentifier',
      width: 150,
    },
    {
      Header: 'URL',
      accessor: 'action.urlString',
      width: 300,
    },
    {
      Header: 'Headers',
      accessor: 'action.headers',
      Cell: (props: { value: Record<string, unknown> }) => (
        <span>{JSON.stringify(props.value)}</span>
      ),
    },
    {
      Header: 'Body',
      accessor: 'action.body',
    },
    {
      Header: 'Storyboard Name',
      accessor: 'action.storyboardName',
      width: 150,
    },
    {
      Header: 'View Controller',
      accessor: 'action.viewControllerIdentifier',
      width: 300,
    },
    {
      accessor: 'action.id',
      width: 100,
      Cell: (props: { value: string }) => (
        <Button onClick={() => handleDeleteAction(props.value)} size="large">
          Delete
        </Button>
      ),
    },
  ]

  return (
    <div>
      <p className="mb-4">
        <strong>Actions</strong>
      </p>

      <ReactTable
        className="-striped -highlight"
        columns={columns}
        data={allLeveratorActions}
        defaultPageSize={20}
        filterable
        minRows={0}
      />
    </div>
  )
}

export default ActionsTable
