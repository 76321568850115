import { Oven, OvenCookStatus } from '@tovala/browser-apis-combinedapi'

// Oven Model Information
// https://docs.google.com/spreadsheets/d/14a_nRC3LXSEaNgSvyIZ0zIJrHyHbSGCkpG-4X1n5Xyo/edit#gid=0
const BASKETVALA_BATCH_START = 54
const GEN_2_MODEL = 'gen2'
const GEN_3_MODEL = 'airvala'

const OVEN_MODEL_MARKETING_NAMES = {
  airvala: 'Tovala Smart Oven Air Fryer',
  basketvala: 'Tovala Smart Oven Pro',
  gen2: 'Tovala Smart Oven Gen2',
}

export function getOvenModelMarketingName(oven: Oven) {
  if (oven.model === GEN_2_MODEL) {
    // Serial Number (returned from API without dashes): TOVMN-X(Gen#)-XXX(Batch#)-XXXX(Unique#)
    const batch = oven.tovala.serial.substring(6, 9)

    if (batch && Number.parseInt(batch, 10) >= BASKETVALA_BATCH_START) {
      return OVEN_MODEL_MARKETING_NAMES.basketvala
    }
    return OVEN_MODEL_MARKETING_NAMES.gen2
  } else if (oven.model === GEN_3_MODEL) {
    return OVEN_MODEL_MARKETING_NAMES.airvala
  }
  return `${oven.type} ${oven.model}`
}

export function isOvenCookStatus(data: unknown): data is OvenCookStatus {
  const typedData = data as OvenCookStatus

  return (
    typedData.remote_control_enabled !== undefined &&
    typedData.state !== undefined
  )
}
