import jwtDecode from 'jwt-decode'
import { JWTPayload } from '@tovala/browser-apis-combinedapi'

import { getCookie } from './storage'

export function getUserId() {
  const userCookie = getCookie('JWT_TOKEN')

  if (userCookie) {
    const decodedUserToken = jwtDecode<JWTPayload>(userCookie)

    return decodedUserToken.userId
  }

  return null
}
