import { APIErrorDisplay } from '@tovala/component-library'
import {
  ErrorCodeMessageMapCombinedAPI,
  OrderHistoryReceipt,
  UserV1,
  useDoNotReprocessOrder,
  useReprocessOrder,
} from '@tovala/browser-apis-combinedapi'
import { ReactNode, useState } from 'react'

import { makeGenericFallbackError } from 'utils/errors'
import { successHandler } from 'actions/notifications'

import { useAppDispatch } from 'hooks'
import ButtonLoading from 'components/common/ButtonLoading'
import Input from 'components/common/Input'
import Modal, { ModalBody, ModalHeader } from 'components/modals/Modal'

export interface Props {
  onCloseModal(): void
  receipt: OrderHistoryReceipt
  user: UserV1
}

const REPROCESS_ERRORS: ErrorCodeMessageMapCombinedAPI<ReactNode> = {
  OrderUpdateNotAllowed: {
    wayOut:
      'If you need further assistance, please send a message in the #help-web Slack channel.',
    why: "We couldn't update the failed order because it is outside of the timeframe that order updates are allowed.",
  },
  Fallback: makeGenericFallbackError({ action: 'update the failed order' }),
}

const UpdateUTOPaymentReprocessStatusModal = ({
  onCloseModal,
  receipt,
  user,
}: Props): JSX.Element | null => {
  const dispatch = useAppDispatch()

  const [notes, setNotes] = useState('')
  const [reprocess, setReprocess] = useState<'doNotReprocess' | 'reprocess'>(
    'doNotReprocess'
  )

  const {
    error: doNotReprocessOrderError,
    isError: hasDoNotReprocessOrderError,
    isLoading: isSettingDoNoReprocessOrder,
    mutate: doNotReprocessOrder,
  } = useDoNotReprocessOrder({
    onSuccess: () => {
      successHandler(dispatch, 'Success! Payment will NOT reprocess.')
      onCloseModal()
    },
  })

  const {
    error: reprocessOrderError,
    isError: hasReprocessOrderError,
    isLoading: isReprocessingOrder,
    mutate: reprocessOrder,
  } = useReprocessOrder({
    onSuccess: () => {
      successHandler(dispatch, 'Success! Payment WILL reprocess.')
      onCloseModal()
    },
  })

  const reprocessError = hasDoNotReprocessOrderError
    ? doNotReprocessOrderError
    : hasReprocessOrderError
    ? reprocessOrderError
    : null

  return (
    <Modal onCloseModal={onCloseModal}>
      <ModalBody>
        <ModalHeader onClickClose={onCloseModal}>
          Update Term #{receipt.termID} Payment Reprocess Status
        </ModalHeader>
        <div className="w-[500px] space-y-4">
          <div>
            <p>{user.info.name}</p>
            <p>{user.info.email}</p>
          </div>

          <p>
            Payment status is declined and by default will reprocess at 12pm CT
            on Thursday.
          </p>

          <form
            className="space-y-4"
            onSubmit={(e) => {
              e.preventDefault()

              const orderID = receipt.userTermOrderID
              const userID = user.id

              if (reprocess === 'reprocess') {
                reprocessOrder({ data: { notes }, orderID, userID })
              } else {
                doNotReprocessOrder({ data: { notes }, orderID, userID })
              }
            }}
          >
            <p>
              Select <strong>DO NOT REPROCESS PAYMENT</strong> if the user does
              not want their Term #{receipt.termID} payment reprocessed.
            </p>

            <p>
              If you would like to reprocess the failed payment immediately,
              select <strong>REPROCESS PAYMENT IMMEDIATELY</strong>.
            </p>

            <p>
              NOTE: Currently, indicating that a failed order should not be
              reprocessed will not show up anywhere in Glaze, but if you see a
              success message, the change was successful.
            </p>

            <div className="flex space-x-4">
              <label>
                <input
                  checked={reprocess === 'doNotReprocess'}
                  onChange={() => {
                    setReprocess('doNotReprocess')
                  }}
                  type="radio"
                  value="doNotReprocess"
                />
                &nbsp;DO NOT REPROCESS PAYMENT
              </label>

              <label>
                <input
                  checked={reprocess === 'reprocess'}
                  onChange={() => {
                    setReprocess('reprocess')
                  }}
                  type="radio"
                  value="reprocess"
                />
                &nbsp;REPROCESS PAYMENT IMMEDIATELY
              </label>
            </div>

            <div>
              <label className="font-bold" htmlFor="notes">
                Notes*
              </label>
              <Input
                id="notes"
                onChange={(e) => {
                  setNotes(e.target.value)
                }}
                required
                type="text"
                value={notes}
              />
            </div>

            {reprocessError && (
              <APIErrorDisplay
                error={reprocessError}
                errorCodeMessageMap={REPROCESS_ERRORS}
              />
            )}

            <ButtonLoading
              isLoading={isReprocessingOrder || isSettingDoNoReprocessOrder}
              size="large"
              type="submit"
            >
              Submit
            </ButtonLoading>
          </form>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default UpdateUTOPaymentReprocessStatusModal
