import { Field, Form, Formik, FormikProps, useField } from 'formik'
import { MealCashRecord } from '@tovala/browser-apis-combinedapi'
import { MutableRefObject, useState } from 'react'
import { PayloadAction } from '@reduxjs/toolkit'
import ReactSelect from 'react-select'

import { MARKETING_REASON_OPTIONS } from 'utils/dropdowns'
import { ReactSelectValue } from 'types/internal'
import { REFUND_ORDER_SUCCESS } from 'actions/types'

import AlertInline from 'components/common/AlertInline'
import Button from 'components/common/Button'
import FormLabel from 'components/common/FormLabel'
import { INPUT_CLASSES } from 'components/common/Input'
import { TEXTAREA_CLASSES } from 'components/common/Textarea'

interface FormData {
  amount: number | ''
  csAgent: string
  notes: string
  reason: ReactSelectValue<string> | null
  zendeskTicket: string
}

export interface FormDataValidated extends FormData {
  amount: number
  reason: ReactSelectValue<string>
}

export interface Props {
  handleSubmit(
    formData: FormDataValidated
  ): Promise<PayloadAction<MealCashRecord, typeof REFUND_ORDER_SUCCESS> | void>
  innerRef?: MutableRefObject<FormikProps<FormData> | null>
}

const validateField = (value: string) => {
  let error: string | undefined
  if (!value) {
    error = 'Required'
  }
  return error
}

const Select = (props: {
  name: string
  onChange(option: ReactSelectValue<string> | null): void
  options: ReactSelectValue<string>[]
  validate(value: string): string | undefined
  value: ReactSelectValue<string> | null
}): JSX.Element => {
  const [field] = useField({ name: props.name, validate: props.validate })

  return <ReactSelect {...field} {...props} />
}

const AddMarketingCreditForm = ({
  handleSubmit,
  innerRef,
}: Props): JSX.Element => {
  const [submitDisabled, setSubmitDisabled] = useState(false)

  const onSubmit = (values: FormDataValidated) => {
    if (submitDisabled) {
      return
    }

    setSubmitDisabled(true)

    handleSubmit(values).then((response) => {
      if (response && response.payload) {
        setSubmitDisabled(false)
      }
    })
  }

  return (
    <Formik<FormData>
      enableReinitialize
      initialValues={{
        amount: '',
        csAgent: '',
        notes: '',
        reason: null,
        zendeskTicket: '',
      }}
      innerRef={innerRef}
      onSubmit={(formData) => {
        return onSubmit(formData as FormDataValidated)
      }}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ setFieldValue, values, errors }) => {
        return (
          <Form>
            <div className="space-y-4">
              <div>
                <FormLabel>Reason*</FormLabel>
                <Select
                  name="reason"
                  onChange={(option) => setFieldValue('reason', option)}
                  options={MARKETING_REASON_OPTIONS}
                  validate={validateField}
                  value={values.reason}
                />
                {errors.reason && (
                  <div className="text-red-901">{errors.reason}</div>
                )}

                {values.reason && values.reason.value === 'referrer-cash' && (
                  <div className="mt-2">
                    <AlertInline alertStyle="warning">
                      Note: Referral Credit will be added to Tovala Cash
                      balance, not to Discounts balance.
                    </AlertInline>
                  </div>
                )}
              </div>

              <div>
                <FormLabel>Amount in Dollars ($1.00 - $150.00)*</FormLabel>
                <Field
                  className={INPUT_CLASSES}
                  max="150"
                  min="0"
                  name="amount"
                  step="0.01"
                  type="number"
                  validate={validateField}
                  value={values.amount}
                />
                {errors.amount && (
                  <div className="text-red-901">{errors.amount}</div>
                )}
              </div>

              <div>
                <FormLabel>ZD Ticket #</FormLabel>
                <Field
                  className={INPUT_CLASSES}
                  name="zendeskTicket"
                  placeholder="Enter ZD Ticket # if applicable"
                  type="text"
                />
              </div>

              <div>
                <FormLabel>Notes*</FormLabel>
                <Field
                  as="textarea"
                  className={TEXTAREA_CLASSES}
                  name="notes"
                  rows={5}
                  validate={validateField}
                />
                {errors.notes && (
                  <div className="text-red-901">{errors.notes}</div>
                )}
              </div>
            </div>

            <div className="mt-4">
              <Button disabled={submitDisabled} size="large" type="submit">
                {submitDisabled ? (
                  'Adding...'
                ) : (
                  <span>
                    Add&nbsp;
                    {values.amount && <span>${values.amount} </span>}
                  </span>
                )}
              </Button>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default AddMarketingCreditForm
