import { ErrorMessage, useField } from 'formik'
import { InputHTMLAttributes } from 'react'

import Input from './Input'

const InputField = ({
  description,
  label,
  validate,
  ...rest
}: InputHTMLAttributes<HTMLInputElement> & {
  description?: string
  label: string
  name: string
  validate?: (value: string) => string | undefined
}) => {
  const [field] = useField({
    name: rest.name,
    type: rest.type,
    validate,
    value: rest.value,
  })

  return (
    <div>
      {label && <label>{label}</label>}
      <Input {...field} {...rest} />
      {description && <p className="text-sm">{description}</p>}
      <ErrorMessage name={field.name}>
        {(message) => {
          return <span className="italic text-red-901">{message}</span>
        }}
      </ErrorMessage>
    </div>
  )
}

export default InputField
