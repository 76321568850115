import {
  ErrorCodeMessageMapCombinedAPI,
  ListingAdmin,
  Term,
  TermSubTerm,
  useAddMealSoldOutCount,
  useInvalidateListingsAdmin,
  useInvalidateTerms,
  useTerm,
  useUpdateListing,
  useUpdateMealSoldOutCount,
} from '@tovala/browser-apis-combinedapi'
import { ReactNode, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { clsx } from 'clsx'

import Loader from 'components/common/Loader'
import TabGroup, {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
} from 'components/common/TabGroup'
import { APIErrorDisplay, ButtonLoading } from '@tovala/component-library'
import { intersection, map, sortBy, uniqBy } from 'lodash-es'
import { MEALS_WRITE, getAdminScope } from 'utils/getAdminScope'
import { useForm } from 'react-hook-form'
import { FormInputRHF } from 'components/common/FormInputRHF'
import { TermMenuListings, useTermMenuListings } from 'hooks/menuProducts'

const LOAD_LISTINGS_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please reload the page to try again.',
    whatHappened: 'Unable to Load Extras',
    why: "We couldn't load extras due to a technical issue on our end.",
  },
}

const LOAD_TERM_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please reload the page to try again.',
    whatHappened: 'Unable to Load Term',
    why: "We couldn't load the term due to a technical issue on our end.",
  },
}

const UPDATE_LISTINGS_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please wait a few minutes and try again.',
    whatHappened: 'Unable to Save Sold Out Counts',
    why: "We couldn't save one or both of these sold out counts due to a technical issue on our end.",
  },
}

const UPDATE_MEAL_SOLD_OUT_COUNT_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please wait a few minutes and try again.',
    whatHappened: 'Unable to Save Sold Out Counts',
    why: "We couldn't save one or both of these sold out counts due to a technical issue on our end.",
  },
}

interface SoldOutCountMeal {
  id: number
  title: string
  subtitle: string
  productionCode: string
  menuMeals: {
    soldOutCount: number | null | undefined
    isSoldOut: boolean | undefined
    selectionsCount: number | undefined
    subTermID: string
  }[]
}

interface SoldOutCountProduct {
  id: string
  productionCode: string
  title: string
  listings: (ListingAdmin & {
    subTermID: string
  })[]
}

interface FacilityNetworkInfo {
  chicago: {
    products: SoldOutCountProduct[]
    meals: SoldOutCountMeal[]
    name: 'chicago'
    subTerms: TermSubTerm[]
  }
  slc: {
    products: SoldOutCountProduct[]
    meals: SoldOutCountMeal[]
    name: 'slc'
    subTerms: TermSubTerm[]
  }
}

const SoldOutCountsPage = () => {
  const { termid: termIDParam } = useParams()

  const termID = termIDParam ? Number.parseInt(termIDParam, 10) : undefined

  const {
    data: term,
    isLoading: isLoadingTerm,
    isError: hasLoadTermError,
    error: loadTermError,
  } = useTerm({ termID })

  const {
    error: loadTermMenuListingsError,
    isError: hasLoadTermMenuListingsError,
    isLoading: isLoadingTermMenuListings,
    data: termMenuListings,
  } = useTermMenuListings({ term })

  useEffect(() => {
    document.title = `Glaze | T${termIDParam} Sold Out Counts`
  }, [termIDParam])

  if (isLoadingTerm || isLoadingTermMenuListings) {
    return <Loader />
  }

  if (hasLoadTermError || hasLoadTermMenuListingsError) {
    return (
      <APIErrorDisplay
        display="page"
        error={loadTermError || loadTermMenuListingsError}
        errorCodeMessageMap={
          hasLoadTermError ? LOAD_TERM_ERRORS : LOAD_LISTINGS_ERRORS
        }
      />
    )
  }

  return (
    <div className="font-sans-new">
      <SoldOutCounts term={term} termMenuListings={termMenuListings} />
    </div>
  )
}

export default SoldOutCountsPage

const SoldOutCounts = ({
  term,
  termMenuListings,
}: {
  term: Term
  termMenuListings: TermMenuListings
}) => {
  const readOnly = !getAdminScope(MEALS_WRITE)

  const facilityNetworks = getSoldOutCountsByFacilityNetwork({
    term,
    termMenuListings,
  })

  return (
    <div className="space-y-6">
      <h1 className="text-k/36_110">Term #{term.id} Sold Out Counts</h1>
      <TabGroup>
        <TabList>
          <Tab>Chicago</Tab>
          <Tab>SLC</Tab>
        </TabList>
        <TabPanels>
          {map(facilityNetworks, (facilityNetwork) => {
            return (
              <TabPanel key={facilityNetwork.name}>
                <div className="sticky top-[56px] left-0 right-0 w-[inherit] z-10 flex border-b border-grey-5 bg-grey-0">
                  <Row>
                    {facilityNetwork.subTerms &&
                      facilityNetwork.subTerms.map((subTerm, index) => {
                        return (
                          <div
                            key={subTerm.id}
                            className={clsx(
                              'text-sm uppercase tracking-widest col-span-1',
                              {
                                'col-start-2': index === 0,
                                'col-start-3': index === 1,
                              }
                            )}
                          >
                            {subTerm.facilityNetwork} Cycle {subTerm.shipPeriod}
                          </div>
                        )
                      })}
                  </Row>
                </div>
                <div className="divide-y">
                  {facilityNetwork.meals.map((meal) => {
                    return (
                      <Meal
                        key={meal.id}
                        meal={meal}
                        readOnly={readOnly}
                        subTerms={facilityNetwork.subTerms}
                      />
                    )
                  })}

                  {facilityNetwork.products.map((product) => {
                    return (
                      <ProductListings
                        key={product.id}
                        product={product}
                        readOnly={readOnly}
                        subTerms={facilityNetwork.subTerms}
                      />
                    )
                  })}
                </div>
              </TabPanel>
            )
          })}
        </TabPanels>
      </TabGroup>
    </div>
  )
}

interface MealFormData {
  [subTermID: string]: {
    soldOutCount: number | ''
  }
}

const Meal = ({
  meal,
  readOnly,
  subTerms,
}: {
  meal: SoldOutCountMeal
  readOnly: boolean
  subTerms: TermSubTerm[]
}) => {
  const { id, productionCode, title, subtitle, menuMeals } = meal

  const [mealSoldOutCountError, setMealSoldOutCountError] = useState<{
    hasError: boolean
    error: Error | null
  }>({
    hasError: false,
    error: null,
  })

  const {
    isLoading: isAddingMealSoldOutCount,
    mutateAsync: addMealSoldOutCountAsync,
  } = useAddMealSoldOutCount()
  const {
    isLoading: isUpdatingMealSoldOutCount,
    mutateAsync: updateMealSoldOutCountAsync,
  } = useUpdateMealSoldOutCount()

  const { invalidateTerm } = useInvalidateTerms()

  const soldOutCounts = menuMeals.map((menuMeal) => {
    return [
      menuMeal.subTermID,
      {
        soldOutCount:
          menuMeal.soldOutCount === null ? '' : menuMeal.soldOutCount,
      },
    ]
  })

  const defaultValues: MealFormData = Object.fromEntries(soldOutCounts)

  const { control, handleSubmit } = useForm<MealFormData>({ defaultValues })

  const handleSave = async ({ mealID, subTermID, soldOutCount }) => {
    const originalSoldOutCount = menuMeals.find(
      (menuMeal) => menuMeal.subTermID === subTermID
    )?.soldOutCount

    const data = {
      soldOutCount: Number(soldOutCount),
    }

    if (originalSoldOutCount === null) {
      return await addMealSoldOutCountAsync({
        data,
        mealID,
        subTermID,
      })
    }
    return await updateMealSoldOutCountAsync({
      data,
      mealID,
      subTermID,
    })
  }

  const onSubmit = (formData: MealFormData) => {
    const updatedMeals = Object.entries(formData).filter(
      ([, { soldOutCount }]) => {
        return (
          soldOutCount !== null && soldOutCount !== '' && soldOutCount !== 0
        )
      }
    )

    Promise.all(
      updatedMeals.map(([subTermID, { soldOutCount }]) => {
        return handleSave({
          mealID: meal.id,
          subTermID,
          soldOutCount,
        })
      })
    )
      .then(() => {
        invalidateTerm(subTerms[0].termID)
        if (mealSoldOutCountError.hasError) {
          setMealSoldOutCountError({
            hasError: false,
            error: null,
          })
        }
      })
      .catch((error) => {
        setMealSoldOutCountError({
          hasError: true,
          error,
        })
      })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <div>
          <p>
            <strong>
              {title} (MEAL ID #{id})
            </strong>
          </p>
          <p>{subtitle}</p>
          <p>Production #{productionCode}</p>
        </div>
        {subTerms.map((subTerm) => {
          const menuMeal = menuMeals.find((m) => m.subTermID === subTerm.id)

          return (
            <div key={`${subTerm.id}${meal.id}`}>
              {menuMeal && (
                <div>
                  <FormInputRHF
                    control={control}
                    disabled={readOnly}
                    min="1"
                    name={`[${subTerm.id}].soldOutCount`}
                    required={!!menuMeal.soldOutCount}
                    type="number"
                  />
                  <p className="text-sm">
                    Selections count: {menuMeal.selectionsCount ?? 0}
                    {menuMeal.isSoldOut && (
                      <span className="font-bold text-red-901">
                        &nbsp;SOLD OUT
                      </span>
                    )}
                  </p>
                </div>
              )}
            </div>
          )
        })}
        <div>
          <ButtonLoading
            isLoading={isAddingMealSoldOutCount || isUpdatingMealSoldOutCount}
            size="medium"
          >
            Save
          </ButtonLoading>
        </div>
      </Row>

      {mealSoldOutCountError.hasError && (
        <APIErrorDisplay
          error={mealSoldOutCountError.error}
          errorCodeMessageMap={UPDATE_MEAL_SOLD_OUT_COUNT_ERRORS}
        />
      )}
    </form>
  )
}

interface ListingsFormData {
  [listingID: string]: {
    soldOutCount: string
  }
}

const ProductListings = ({
  product,
  readOnly,
  subTerms,
}: {
  product: SoldOutCountProduct
  readOnly: boolean
  subTerms: TermSubTerm[]
}) => {
  const { invalidateListings } = useInvalidateListingsAdmin()

  const { listings, productionCode, title } = product

  const {
    error: updateListingError,
    isError: hasUpdateListingError,
    isLoading: isUpdatingListing,
    mutate: updateListing,
  } = useUpdateListing()

  const soldOutCounts = listings.map((listing) => {
    return [
      listing.id,
      {
        soldOutCount: listing.soldOutCount ?? '',
      },
    ]
  })

  const defaultValues: ListingsFormData = Object.fromEntries(soldOutCounts)

  const { control, handleSubmit } = useForm<ListingsFormData>({ defaultValues })

  const onSubmit = (formData: ListingsFormData) => {
    for (const [listingID, { soldOutCount }] of Object.entries(formData)) {
      const listing = listings.find((l) => l.id === listingID)

      if (listing && Number(soldOutCount) > 0) {
        const { expirationDate, priceCents } = { ...listing }

        const updatedListing = {
          expirationDate,
          priceCents,
          soldOutCount: Number(soldOutCount),
        }

        updateListing(
          { data: updatedListing, listingID: listing.id },
          {
            onSuccess: () => {
              invalidateListings()
            },
          }
        )
      }
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <div>
          <p>
            <strong>{title}</strong>
          </p>
          <p>Production #{productionCode}</p>
        </div>
        {subTerms.map((subTerm) => {
          const listing = listings.find(
            (listing) => listing.subTermID === subTerm.id
          )

          return (
            <div key={`${subTerm.id}${product.id}`}>
              {listing && (
                <div>
                  <FormInputRHF
                    control={control}
                    disabled={readOnly}
                    min="1"
                    name={`${listing.id}.soldOutCount`}
                    required={!!(listing.soldOutCount > 0)}
                    type="number"
                  />
                  <p className="text-sm">
                    {listing.isSoldOut && (
                      <span className="font-bold text-red-901">
                        &nbsp;SOLD OUT
                      </span>
                    )}
                  </p>
                </div>
              )}
            </div>
          )
        })}
        <div>
          <ButtonLoading isLoading={isUpdatingListing} size="medium">
            Save
          </ButtonLoading>
        </div>
      </Row>

      {hasUpdateListingError && (
        <APIErrorDisplay
          error={updateListingError}
          errorCodeMessageMap={UPDATE_LISTINGS_ERRORS}
        />
      )}
    </form>
  )
}

const Row = ({ children }: { children: ReactNode }) => {
  return (
    <div className="grid grid-cols-[2fr_repeat(3,_1fr)] gap-4 w-full py-4">
      {children}
    </div>
  )
}

const getSoldOutCountsByFacilityNetwork = ({
  term,
  termMenuListings,
}: {
  term: Term
  termMenuListings: TermMenuListings
}) => {
  const facilityNetworks: FacilityNetworkInfo = {
    chicago: {
      products: [],
      meals: [],
      name: 'chicago',
      subTerms: [],
    },
    slc: {
      products: [],
      meals: [],
      name: 'slc',
      subTerms: [],
    },
  }

  map(facilityNetworks, (facilityNetwork) => {
    const sortedFacilityNetworkSubTerms = sortBy(
      term.subTerms.filter(
        (subTerm) => subTerm.facilityNetwork === facilityNetwork.name
      ),
      'shipPeriod'
    )
    facilityNetworks[facilityNetwork.name].subTerms =
      sortedFacilityNetworkSubTerms

    const menuIDs = sortedFacilityNetworkSubTerms
      .map((subterm) => subterm.menus.map((menu) => menu.id))
      .flat()

    const facilityNetworkMeals = term.meals
      .filter((meal) => {
        const menuMealsMenuIDs = meal.menuMeals
          ? meal.menuMeals.map((menuMeal) => menuMeal.menuID)
          : []
        const matchingMenuIDs = intersection(menuIDs, menuMealsMenuIDs)

        return matchingMenuIDs.length > 0
      })
      .map((meal) => {
        const menuMeals = sortedFacilityNetworkSubTerms.map((subTerm) => {
          const fullMeal = subTerm.defaultMenu.meals.find(
            (m) => m.id === meal.id
          )

          return {
            soldOutCount: fullMeal?.soldOutCount,
            isSoldOut: fullMeal?.isSoldOut,
            selectionsCount: fullMeal?.selectionsCount,
            subTermID: subTerm.id,
          }
        })

        return {
          id: meal.id,
          title: meal.title,
          subtitle: meal.subtitle,
          productionCode: meal.menuMeals
            ? meal.menuMeals[0].productionCode
            : '0',
          menuMeals,
        }
      })

    facilityNetworks[facilityNetwork.name].meals = sortBy(
      facilityNetworkMeals,
      (meal) => {
        return Number(meal.productionCode)
      }
    )

    const facilityNetworkMenuListings = termMenuListings.filter(
      (menuListings) => {
        if (menuListings?.menuID) {
          return menuIDs.includes(menuListings?.menuID)
        }
      }
    )

    const uniqueProducts = uniqBy(
      facilityNetworkMenuListings
        .map((menuListings) => menuListings?.listings)
        .filter((listings): listings is ListingAdmin[] => !!listings)
        .flat(),
      'productID'
    )

    const facilityNetworkProducts = uniqueProducts.map((listing) => {
      const listings = sortedFacilityNetworkSubTerms
        .map((subTerm) => {
          const menuListings = facilityNetworkMenuListings.find(
            (termMenuListings) => {
              return termMenuListings?.menuID === subTerm.defaultMenu.id
            }
          )

          return menuListings
            ? menuListings.listings
                .filter((l) => l.productID === listing.productID)
                .map((listing) => {
                  return {
                    ...listing,
                    subTermID: subTerm.id,
                  }
                })
            : []
        })
        .flat()

      return {
        id: listing.productID,
        productionCode: listing.productionCode,
        title: listing.title,
        listings,
      }
    })

    facilityNetworks[facilityNetwork.name].products = sortBy(
      facilityNetworkProducts,
      (product) => {
        return Number(product.productionCode)
      }
    )
  })

  return facilityNetworks
}
