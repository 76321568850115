import { InputHTMLAttributes } from 'react'

import CheckIcon from './icons/CheckIcon'

const Radio = ({
  checked,
  label,
  name,
  onChange,
  value,
}: {
  checked?: boolean
  label: string
  name: string
  onChange: InputHTMLAttributes<HTMLInputElement>['onChange']
  value: string
}) => {
  return (
    <label className="group flex">
      <div className="relative mr-2 h-[22px] w-[22px] shrink-0 translate-y-[2px] rounded-full">
        <input
          checked={checked}
          className="peer h-full w-full appearance-none rounded-full border border-grey-900 bg-white-900 checked:border-red-906 checked:bg-red-906 group-hover:border-2 group-hover:border-red-906"
          name={name}
          onChange={onChange}
          type="radio"
          value={value}
        />
        <div className="absolute top-1/2 left-1/2 z-10 flex h-[15px] w-[15px] -translate-x-1/2 -translate-y-1/2 scale-0 text-white-900 peer-checked:scale-100">
          <CheckIcon strokeWidth={4} />
        </div>
      </div>
      <span>{label}</span>
    </label>
  )
}

export default Radio
