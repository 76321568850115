import {
  APIErrorDisplay,
  Button,
  ButtonLoading,
  FormGroup,
  Input,
  Modal,
  ModalHeader,
  Textarea,
} from '@tovala/component-library'
import {
  ErrorCodeMessageMapCombinedAPI,
  useDeactivateUser,
  useDeletePushToken,
  useOvens,
  useResetPasswordLink,
  UserV1,
  useUserV0,
} from '@tovala/browser-apis-combinedapi'
import { FormEvent, ReactNode, useEffect, useState } from 'react'
import { isEmpty } from 'lodash-es'
import { Link } from 'react-router-dom'
import moment from 'moment'

import {
  getAdminScope,
  ORDER_OVERRIDE,
  USERS_WRITE,
} from '../../utils/getAdminScope'
import { getEnvVar } from 'utils/env'
import { getUserId } from 'utils/getUserId'
import { getUserInfo } from 'actions/auth'
import { makeGenericFallbackError } from 'utils/errors'
import { successHandler } from 'actions/notifications'

import { useAppDispatch } from 'hooks'
import { useEditUserReferralCode } from 'hooks/combinedAPI/referralCodes'
import AlertInline from 'components/common/AlertInline'

const DEACTIVATE_USER_ERRORS: ErrorCodeMessageMapCombinedAPI<ReactNode> = {
  Fallback: makeGenericFallbackError({
    action: 'deactivate the user',
  }),
}

const DELETE_PUSH_TOKEN_ERRORS: ErrorCodeMessageMapCombinedAPI<ReactNode> = {
  Fallback: makeGenericFallbackError({
    action: 'delete the push token',
  }),
}

const EDIT_REFERRAL_CODE_ERRORS: ErrorCodeMessageMapCombinedAPI<ReactNode> = {
  Fallback: makeGenericFallbackError({
    action: 'update the referral code',
  }),
}

const GET_PASSWORD_RESET_ERRORS: ErrorCodeMessageMapCombinedAPI<ReactNode> = {
  Fallback: makeGenericFallbackError({
    action: 'get the password reset link',
    helpToFix: 'Please close and reopen this dialog.',
  }),
}

const PasswordResetLinkModal = ({
  onCloseModal,
  userID,
}: {
  onCloseModal(): void
  userID: number
}): JSX.Element => {
  const [copyStatus, setCopyStatus] = useState('')

  const {
    data: resetPasswordLinkResponse,
    error: getResetPasswordLinkError,
    isError: hasGetPasswordResetLinkError,
    isLoading: isLoadingPasswordResetLink,
  } = useResetPasswordLink({ userID })
  const resetPasswordLink = resetPasswordLinkResponse?.link ?? ''

  const copyLink = () => {
    const type = 'text/plain'
    const blob = new Blob([resetPasswordLink], { type })
    const data = [new ClipboardItem({ [type]: blob })]

    navigator.clipboard.write(data).then(
      () => {
        setCopyStatus('success')
      },
      () => {
        setCopyStatus('failed')
      }
    )
  }

  return (
    <Modal onCloseModal={onCloseModal}>
      <div className="font-sans-new">
        <ModalHeader onClickClose={onCloseModal}>
          Password Reset Link
        </ModalHeader>
        <div className="w-[500px] space-y-8 p-6">
          <div className="space-y-4">
            {copyStatus === 'success' && (
              <AlertInline alertStyle="success">
                <span className="font-bold">Success!</span> Link copied.
              </AlertInline>
            )}
            {copyStatus === 'failed' && (
              <AlertInline alertStyle="danger">
                <span className="font-bold">Oops!</span> Unable to copy link.
                Please copy using your keyboard/mouse instead.
              </AlertInline>
            )}

            {resetPasswordLink || isLoadingPasswordResetLink ? (
              <Textarea readOnly rows={10} value={resetPasswordLink} />
            ) : hasGetPasswordResetLinkError ? (
              <APIErrorDisplay
                error={getResetPasswordLinkError}
                errorCodeMessageMap={GET_PASSWORD_RESET_ERRORS}
              />
            ) : null}
          </div>

          <div className="flex justify-end space-x-4">
            <Button buttonStyle="stroke" onClick={onCloseModal} size="large">
              Close
            </Button>
            <Button
              disabled={!resetPasswordLink}
              onClick={copyLink}
              size="large"
            >
              Copy Link
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

const ReferralCodeModal = ({
  onCloseModal,
  user,
}: {
  onCloseModal(): void
  user: UserV1
}): JSX.Element => {
  const dispatch = useAppDispatch()

  const [referralCode, setReferralCode] = useState('')

  const {
    error: editReferralCodeError,
    isError: hasEditReferralCodeError,
    isLoading: isEditingReferralCode,
    mutate: editUserReferralCode,
  } = useEditUserReferralCode({
    onSuccess: () => {
      successHandler(dispatch, 'Success! User referral code updated.')

      onCloseModal()
    },
  })

  return (
    <Modal onCloseModal={onCloseModal}>
      <div className="font-sans-new">
        <ModalHeader onClickClose={onCloseModal}>
          Update Referral Code
        </ModalHeader>
        <form
          className="w-[500px] space-y-8 p-6"
          onSubmit={(event) => {
            event.preventDefault()

            editUserReferralCode({
              data: { code: referralCode },
              userID: user.id,
            })
          }}
        >
          <div className="space-y-4">
            <div>
              {user.info.name && <p>{user.info.name}</p>}
              <p>{user.info.email}</p>
            </div>

            <div>
              <p>Current Referral Code</p>
              <p className="font-bold">{user.referralCode.code}</p>
            </div>

            <FormGroup label="New Referral Code" labelFor="new-referral-code">
              <Input
                id="new-referral-code"
                name="new-referral-code"
                onChange={(e) => setReferralCode(e.target.value)}
                required
                type="text"
                value={referralCode}
              />
            </FormGroup>
          </div>

          <div className="space-y-4">
            {hasEditReferralCodeError && (
              <APIErrorDisplay
                error={editReferralCodeError}
                errorCodeMessageMap={EDIT_REFERRAL_CODE_ERRORS}
              />
            )}

            <div className="flex justify-end space-x-4">
              <Button buttonStyle="stroke" onClick={onCloseModal} size="large">
                Cancel
              </Button>
              <ButtonLoading isLoading={isEditingReferralCode} size="large">
                Update
              </ButtonLoading>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  )
}

const DeactivateUserModal = ({
  onCloseModal,
  user,
}: {
  onCloseModal(): void
  user: UserV1
}): JSX.Element => {
  const dispatch = useAppDispatch()

  const [deactivateValue, setDeactivateValue] = useState('')

  const {
    error: deactivateUserError,
    isError: hasDeactivateUserError,
    isLoading: isDeactivatingUser,
    mutate: deactivateUser,
  } = useDeactivateUser({
    onSuccess: () => {
      successHandler(dispatch, 'Success! User deactivated.')

      dispatch(getUserInfo(user.id))
      onCloseModal()
    },
  })

  function onSubmitDeactivateUser(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()

    if (deactivateValue === 'Yes') {
      deactivateUser({ userID: user.id })
    } else {
      alert('Not Deactivating User!')
      onCloseModal()
    }
  }

  return (
    <Modal onCloseModal={onCloseModal}>
      <div className="font-sans-new">
        <ModalHeader onClickClose={onCloseModal}>Deactivate User</ModalHeader>

        <form
          className="w-[500px] space-y-8 p-6"
          onSubmit={onSubmitDeactivateUser}
        >
          <div className="space-y-4">
            <div>
              {user.info.name && <p className="mb-0">{user.info.name}</p>}
              <p>{user.info.email}</p>
            </div>

            <p>
              This will overwrite the user&apos;s email and name, delete payment
              information, and set their meal subscription to inactive.
            </p>

            <FormGroup label="Please type 'Yes' if you wish to deactivate this user.">
              <Input
                onChange={(event) => {
                  setDeactivateValue(event.target.value)
                }}
                type="text"
                value={deactivateValue}
              />
            </FormGroup>
          </div>

          <div className="space-y-4">
            {hasDeactivateUserError && (
              <APIErrorDisplay
                error={deactivateUserError}
                errorCodeMessageMap={DEACTIVATE_USER_ERRORS}
              />
            )}

            <div className="flex justify-end space-x-4">
              <Button buttonStyle="stroke" onClick={onCloseModal} size="large">
                Cancel
              </Button>
              <ButtonLoading isLoading={isDeactivatingUser} size="large">
                Deactivate
              </ButtonLoading>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  )
}

const PushTokensModal = ({
  closeModal,
  email,
  pushTokens,
  userID,
}: {
  closeModal: () => void
  email: string
  pushTokens: Record<string, string>
  userID: number
}): JSX.Element => {
  return (
    <Modal onCloseModal={closeModal}>
      <div className="font-sans-new">
        <ModalHeader onClickClose={closeModal}>Push Tokens</ModalHeader>
        <div className="w-[1000px] p-6">
          <div className="mb-4 space-y-2">
            <p>
              The push tokens listed below have been registered for{' '}
              <span className="font-bold">{email}</span>.
            </p>
            <p>
              If the user is receiving duplicate push notifications, check if
              the same push token has been registered more than once and delete
              any duplicates.
            </p>
          </div>
          <div>
            {Object.entries(pushTokens).map((token, index) => {
              return (
                <PushTokenRow
                  key={index}
                  onDeleteSuccess={() => {
                    // Close modal if deleting last push token
                    if (Object.entries(pushTokens).length === 1) {
                      closeModal()
                    }
                  }}
                  token={token}
                  userID={userID}
                />
              )
            })}
          </div>
        </div>
      </div>
    </Modal>
  )
}

const PushTokenRow = ({
  onDeleteSuccess,
  token,
  userID,
}: {
  onDeleteSuccess(): void
  token: [string, string]
  userID: number
}) => {
  const dispatch = useAppDispatch()

  const {
    error: deletePushTokenError,
    isError: hasDeletePushTokenError,
    isLoading: isDeletingPushToken,
    mutate: deletePushToken,
  } = useDeletePushToken({
    onSuccess: () => {
      successHandler(dispatch, 'Success! Push token deleted.')

      dispatch(getUserInfo(userID))
      onDeleteSuccess()
    },
  })

  const handleDelete = ([key, value]: [string, string]) => {
    deletePushToken({
      data: {
        push_tokens: {
          [key]: value,
        },
        token_type: 'apns',
      },
      userID,
    })
  }

  return (
    <div className="grid w-full grid-cols-[175px_1fr_100px] gap-4 border-t border-grey-900 px-2 py-4 hover:bg-grey-904">
      {token.map((value) => {
        return (
          <div key={value} className="break-all">
            {value}
          </div>
        )
      })}

      {getAdminScope(USERS_WRITE) && (
        <ButtonLoading
          isLoading={isDeletingPushToken}
          onClick={() => handleDelete(token)}
          size="medium"
          type="button"
        >
          Delete
        </ButtonLoading>
      )}

      {hasDeletePushTokenError && (
        <div className="col-span-3">
          <APIErrorDisplay
            error={deletePushTokenError}
            errorCodeMessageMap={DELETE_PUSH_TOKEN_ERRORS}
          />
        </div>
      )}
    </div>
  )
}

const ViewUser = ({ user }: { user: UserV1 }): JSX.Element => {
  const glazeUserID = getUserId()

  const [isDeactivateUserModalOpen, setIsDeactivateUserModalOpen] =
    useState(false)
  const [isPasswordResetModalOpen, setIsPasswordResetModalOpen] =
    useState(false)
  const [isPushTokensModalOpen, setIsPushTokensModalOpen] = useState(false)
  const [isReferralCodeModalOpen, setIsReferralCodeModalOpen] = useState(false)

  const { data: userV0 } = useUserV0({ userID: user.id })
  const { data: ovens = [] } = useOvens({ userID: user.id })

  useEffect(() => {
    document.title = `Glaze | User #${user.id} - User Details`
  }, [user.id])

  const pushTokens = {
    ...user.info.pushTokens,
    ...user.info.fcmTokens,
  }

  return (
    <div className="flex space-x-4 font-sans-new">
      <div className="w-9/12 space-y-8">
        <h2 className="text-k/28_130">User #{user.id}</h2>

        <div className="space-y-4">
          <UserDetailLine label="Member Since">
            {moment(user.info.registered).format('MMMM DD, YYYY')}
          </UserDetailLine>

          {user.info.name && (
            <UserDetailLine label="Name">{user.info.name}</UserDetailLine>
          )}

          <UserDetailLine label="Email">{user.info.email}</UserDetailLine>

          {user.subscription.customerID !== '' && (
            <UserDetailLine label="Customer ID">
              {user.subscription.customerID}
            </UserDetailLine>
          )}

          <UserDetailLine label="Referral code">
            {user.referralCode.code}
          </UserDetailLine>

          {userV0 && userV0.referral_url !== '' && (
            <UserDetailLine label="Referral link">
              {userV0.referral_url}
            </UserDetailLine>
          )}

          {userV0?.password_exists !== undefined && (
            <UserDetailLine label="Password Set">
              {userV0.password_exists.toString().toUpperCase()}
            </UserDetailLine>
          )}

          {ovens.map((oven) => {
            return (
              <div key={oven.id} className="space-y-4">
                <div className="inline-flex rounded border border-grey-3 p-2 text-k/12_120 uppercase">
                  <Link to={`/user/${oven.userid}/oven/${oven.id}`}>
                    {oven.name}
                  </Link>
                </div>

                <UserDetailLine label="Model">
                  {oven.type} {oven.model}
                </UserDetailLine>

                {oven[oven.type].deviceid && (
                  <UserDetailLine label="Device ID">
                    {oven[oven.type].deviceid}
                  </UserDetailLine>
                )}

                <UserDetailLine label="Agent ID">
                  {oven[oven.type].agentid}
                </UserDetailLine>

                <UserDetailLine label="Created">
                  {moment(oven.created).format('MMMM DD, YYYY')}
                </UserDetailLine>

                <UserDetailLine label="Updated">
                  {moment(oven.updated).format('MMMM DD, YYYY')}
                </UserDetailLine>
              </div>
            )
          })}

          {userV0 && userV0.notes !== '' && (
            <UserDetailLine label="Notes">{userV0.notes}</UserDetailLine>
          )}

          {!isEmpty(pushTokens) && (
            <UserDetailLine label="Push Tokens">
              <Button
                buttonStyle="gray"
                onClick={() => {
                  setIsPushTokensModalOpen(true)
                }}
                size="small"
              >
                Show
              </Button>
            </UserDetailLine>
          )}

          {isPushTokensModalOpen && (
            <PushTokensModal
              closeModal={() => {
                setIsPushTokensModalOpen(false)
              }}
              email={user.info.email}
              pushTokens={pushTokens}
              userID={user.id}
            />
          )}
        </div>
      </div>
      <div className="w-3/12 space-y-4">
        {getAdminScope(USERS_WRITE) && (
          <>
            <div className="grid">
              <Button
                buttonStyle="gray"
                disabled={user.id === glazeUserID}
                onClick={() => {
                  setIsPasswordResetModalOpen(true)
                }}
                size="medium"
              >
                Password Reset Link
              </Button>
            </div>
            {user.id === glazeUserID && (
              <p className="text-center text-body-sm text-grey-9">
                Please use this{' '}
                <a
                  className="text-orange-1 underline"
                  href={
                    getEnvVar('APP_ENV') === 'production'
                      ? 'https://my.tovala.com/forgot-password'
                      : 'https://my.dev.tovala.com/forgot-password'
                  }
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  forgot password{' '}
                </a>
                flow to reset your own password.
              </p>
            )}

            {isPasswordResetModalOpen && (
              <PasswordResetLinkModal
                onCloseModal={() => {
                  setIsPasswordResetModalOpen(false)
                }}
                userID={user.id}
              />
            )}
          </>
        )}

        {getAdminScope(USERS_WRITE) && (
          <>
            <div className="grid">
              <Button
                buttonStyle="gray"
                onClick={() => {
                  setIsReferralCodeModalOpen(true)
                }}
                size="medium"
              >
                Update Referral Code
              </Button>
            </div>

            {isReferralCodeModalOpen && (
              <ReferralCodeModal
                onCloseModal={() => {
                  setIsReferralCodeModalOpen(false)
                }}
                user={user}
              />
            )}
          </>
        )}

        {getAdminScope(ORDER_OVERRIDE) && (
          <>
            <div className="grid">
              <Button
                buttonStyle="gray"
                disabled={user.id === glazeUserID}
                onClick={() => {
                  setIsDeactivateUserModalOpen(true)
                }}
                size="medium"
              >
                Deactivate User
              </Button>
            </div>

            {user.id === glazeUserID && (
              <p className="text-body-sm text-grey-9">
                Deactivate User is disabled on your own account
              </p>
            )}

            {isDeactivateUserModalOpen && (
              <DeactivateUserModal
                onCloseModal={() => {
                  setIsDeactivateUserModalOpen(false)
                }}
                user={user}
              />
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default ViewUser

const UserDetailLine = ({
  children,
  label,
}: {
  children: ReactNode
  label: string
}) => {
  return (
    <div className="grid grid-cols-[200px_1fr] items-center text-k/14_120">
      <div className="text-h/14_120 font-bold">{label}</div>
      <div>{children}</div>
    </div>
  )
}
