import { arrayMove } from '@dnd-kit/sortable'
import { TermSubTerm, useTerm } from '@tovala/browser-apis-combinedapi'
import {
  getMenuMealComponents,
  MenuComponentsStandardized,
  MenuComponentStandardized,
  MenuComponentStandardizedTextImageStack,
} from '@tovala/browser-apis-menu-components'
import { MenuComponents } from '@tovala/browser-apis-menu-delivery'
import { useTermMealSummaries } from 'hooks/combinedAPI/menus'
import {
  cloneDeep,
  findIndex,
  flatMap,
  isEmpty,
  isEqual,
  orderBy,
} from 'lodash-es'
import { useState } from 'react'
import { v4 as uuidV4 } from 'uuid'
import {
  ComponentDropPlaceholder,
  getComponentTempID,
  validateMealSwaps,
} from './utils'

export function useMenuComponentsForSubterm({
  orderedSubTerms,
  placeholderIndex,
  termResolvedMenuComponents,
}: {
  orderedSubTerms: TermSubTerm[]
  placeholderIndex: number | null
  termResolvedMenuComponents: MenuComponentsStandardized[]
}) {
  const [selectedSubTermIndex, setSelectedSubTermIndex] = useState(0)
  const selectedSubTerm = orderedSubTerms[selectedSubTermIndex]

  const [newComponents, setNewComponents] = useState<Record<
    string,
    MenuComponentStandardized[]
  > | null>(null)
  const [componentsOrdered, setComponentsOrdered] = useState<Record<
    string,
    { id: string; type: string }[]
  > | null>(null)
  const components: Record<
    string,
    (ComponentDropPlaceholder | MenuComponentStandardized)[]
  > = {}

  orderedSubTerms.forEach((subTerm, index) => {
    let componentsForSubTerm: (
      | ComponentDropPlaceholder
      | MenuComponentStandardized
    )[] = []

    const resolvedMenuComponents = termResolvedMenuComponents[index] ?? []
    const subTermNewComponents =
      newComponents && newComponents[subTerm.id]
        ? newComponents[subTerm.id]
        : []

    const allComponents = [...subTermNewComponents, ...resolvedMenuComponents]

    const orderForSubTerm =
      componentsOrdered && componentsOrdered[subTerm.id].length > 0
        ? componentsOrdered[subTerm.id]
        : resolvedMenuComponents.map((component) => ({
            id: component.id,
            type: component.type,
          }))

    componentsForSubTerm = orderForSubTerm
      .map((orderedComponent) => {
        return allComponents.find(
          (component) => component.id === orderedComponent.id
        )
      })
      .filter(
        (component): component is MenuComponentStandardized => !!component
      )

    components[subTerm.id] = componentsForSubTerm
  })

  let selectedComponents = selectedSubTerm ? components[selectedSubTerm.id] : []

  if (placeholderIndex !== null) {
    selectedComponents = [
      ...selectedComponents.slice(0, placeholderIndex),
      { id: uuidV4(), type: 'dropPlaceholder' },
      ...selectedComponents.slice(placeholderIndex),
    ]
  }

  function clonePrevComponentsOrdered(
    prevComponentsOrdered: Record<string, { id: string; type: string }[]> | null
  ) {
    const updatedComponents = {}
    if (prevComponentsOrdered === null) {
      orderedSubTerms.forEach((subTerm, index) => {
        updatedComponents[subTerm.id] = termResolvedMenuComponents[index].map(
          (component) => {
            return {
              id: component.id,
              type: component.type,
            }
          }
        )
      })
    } else {
      orderedSubTerms.forEach((subTerm, index) => {
        if (prevComponentsOrdered[subTerm.id].length === 0) {
          updatedComponents[subTerm.id] = termResolvedMenuComponents[index].map(
            (component) => {
              return {
                id: component.id,
                type: component.type,
              }
            }
          )
        } else {
          updatedComponents[subTerm.id] = cloneDeep(
            prevComponentsOrdered[subTerm.id]
          )
        }
      })
    }

    return updatedComponents
  }

  function clonePrevNewComponents(
    prevNewComponents: Record<string, MenuComponentStandardized[]> | null,
    subTermID: string
  ) {
    let updatedNewComponents: Record<string, MenuComponentStandardized[]> = {}

    if (prevNewComponents === null || isEmpty(prevNewComponents)) {
      updatedNewComponents[subTermID] = []
    } else {
      updatedNewComponents = cloneDeep(prevNewComponents)

      if (updatedNewComponents[subTermID] === undefined) {
        updatedNewComponents[subTermID] = []
      }
    }

    return updatedNewComponents
  }

  return {
    addComponent: (newComponent: {
      component: MenuComponentsStandardized[number]
      index?: number
      subTermID: string
    }) => {
      const subTermID = newComponent.subTermID
      const index =
        newComponent.index === undefined
          ? placeholderIndex === null
            ? 0
            : placeholderIndex
          : newComponent.index

      setNewComponents((prevNewComponents) => {
        const updatedNewComponents = clonePrevNewComponents(
          prevNewComponents,
          newComponent.subTermID
        )

        updatedNewComponents[newComponent.subTermID] = [
          ...updatedNewComponents[newComponent.subTermID],
          { ...newComponent.component },
        ]

        return updatedNewComponents
      })

      setComponentsOrdered((prevComponentsOrdered) => {
        const updatedComponents = clonePrevComponentsOrdered(
          prevComponentsOrdered
        )

        let updatedOrder = [...updatedComponents[subTermID]]

        updatedOrder = [
          ...updatedOrder.slice(0, index),
          { id: newComponent.component.id, type: newComponent.component.type },
          ...updatedOrder.slice(index),
        ]

        if (
          newComponent.component.type === 'textImageStack' &&
          newComponent.component.properties.children.length
        ) {
          newComponent.component.properties.children.forEach((child) => {
            const childIndex = findIndex(
              updatedOrder,
              (component) => component.id === child.id
            )
            if (childIndex !== -1) {
              updatedOrder = updatedOrder.filter(
                (_component, index) => index !== childIndex
              )
            }
          })
        }

        updatedComponents[subTermID] = updatedOrder

        return updatedComponents
      })
    },
    components,
    componentsOrdered: componentsOrdered || components,
    onChangeSelectedSubTermIndex: (index: number) => {
      setSelectedSubTermIndex(index)
    },
    removeComponent: ({
      component,
      subTermID,
    }: {
      component: MenuComponentsStandardized[number]
      subTermID: string
    }) => {
      setComponentsOrdered((prevComponentsOrdered) => {
        const updatedComponents = clonePrevComponentsOrdered(
          prevComponentsOrdered
        )

        let updatedOrder = [...updatedComponents[subTermID]]

        const componentIndex = findIndex(
          updatedOrder,
          (comp) => comp.id === component.id
        )
        if (componentIndex !== -1) {
          updatedOrder = updatedOrder.filter(
            (_component, index) => index !== componentIndex
          )

          if (
            component.type === 'textImageStack' &&
            component.properties.children.length
          ) {
            updatedOrder = [
              ...updatedOrder.slice(0, componentIndex),
              ...component.properties.children,
              ...updatedOrder.slice(componentIndex),
            ]
          }
        }

        updatedComponents[subTermID] = updatedOrder

        return updatedComponents
      })
    },
    reorderComponents: (activeIndex: number, overIndex: number) => {
      setComponentsOrdered((prevComponentsOrdered) => {
        const updatedComponents = clonePrevComponentsOrdered(
          prevComponentsOrdered
        )

        const updatedOrder = [...updatedComponents[selectedSubTerm.id]]

        updatedComponents[selectedSubTerm.id] = arrayMove(
          updatedOrder,
          activeIndex,
          overIndex
        )

        return updatedComponents
      })
    },
    selectedComponents,
    selectedSubTerm,
    selectedSubTermIndex,
    deleteAllOrderedComponents: () => {
      setComponentsOrdered(null)
    },
    deleteOrderedComponents: ({ subTermID }: { subTermID: string }) => {
      setComponentsOrdered((prevComponentsOrdered) => {
        const updatedComponents = clonePrevComponentsOrdered(
          prevComponentsOrdered
        )

        updatedComponents[subTermID] = []

        return updatedComponents
      })

      if (newComponents && newComponents[subTermID]) {
        setNewComponents((prevNewComponents) => {
          if (prevNewComponents === null) {
            return prevNewComponents
          }

          const updatedNewComponents = cloneDeep(prevNewComponents)

          const { [subTermID]: _selectedSubTermID, ...rest } =
            updatedNewComponents

          return rest
        })
      }
    },
    updateComponent: (updatedComponent: {
      component: MenuComponentStandardized
      previousChildren?: MenuComponentStandardizedTextImageStack['properties']['children']
      subTermID: string
    }) => {
      setNewComponents((prevNewComponents) => {
        const updatedNewComponents = clonePrevNewComponents(
          prevNewComponents,
          updatedComponent.subTermID
        )

        const index = findIndex(
          updatedNewComponents[updatedComponent.subTermID],
          (component) => component.id === updatedComponent.component.id
        )

        let updatedComponents = [
          ...updatedNewComponents[updatedComponent.subTermID],
        ]

        if (index > -1) {
          updatedComponents[index] = updatedComponent.component
        } else {
          updatedComponents = [...updatedComponents, updatedComponent.component]
        }
        updatedNewComponents[updatedComponent.subTermID] = updatedComponents

        return updatedNewComponents
      })

      setComponentsOrdered((prevComponentsOrdered) => {
        const updatedComponents = clonePrevComponentsOrdered(
          prevComponentsOrdered
        )

        let updatedOrder = [...updatedComponents[updatedComponent.subTermID]]

        // If the component is a textImageStack, we need to return any removed children
        // back to the main component order
        if (updatedComponent.component.type === 'textImageStack') {
          if (updatedComponent.previousChildren) {
            updatedOrder = [
              ...updatedComponent.previousChildren,
              ...updatedOrder,
            ]
          }

          if (updatedComponent.component.properties.children) {
            updatedComponent.component.properties.children.forEach((child) => {
              const childIndex = findIndex(
                updatedOrder,
                (component) => component.id === child.id
              )
              if (childIndex !== -1) {
                updatedOrder = updatedOrder.filter(
                  (_component, index) => index !== childIndex
                )
              }
            })
          }
        }

        updatedComponents[updatedComponent.subTermID] = updatedOrder

        return updatedComponents
      })
    },
  }
}

export function useOrderedSubTerms({ termID }: { termID: number | undefined }) {
  const { data: term } = useTerm({ termID })
  const subTerms = term?.subTerms ?? []

  const orderedSubTerms = orderBy(subTerms, ['facilityNetwork', 'shipPeriod'])

  return {
    orderedSubTerms,
    term,
  }
}

export function useTermResolvedMenuComponents({
  orderedSubTerms,
  termDefaultMenus,
  termSpecialEvent,
}: {
  orderedSubTerms: TermSubTerm[]
  termDefaultMenus: MenuComponents[]
  termSpecialEvent: string | undefined
}) {
  const termMealSummaries = useTermMealSummaries({
    subTermIDs: orderedSubTerms.map((subTerm) => subTerm.id),
  })

  const isLoadingTermMealSummaries = termMealSummaries.some(
    (termMealSummaries) => termMealSummaries.isLoading
  )

  const termResolvedMenuComponents = orderedSubTerms.map((subTerm, index) => {
    const mealSwaps = subTerm.defaultMenu.mealSwaps ?? []
    const meals = termMealSummaries[index]?.data ?? []
    let menuComponents = termDefaultMenus[index]?.components ?? []

    const specialEvent = subTerm.specialEvent || termSpecialEvent

    const mealSwapsMatch =
      menuComponents.length > 0
        ? validateMealSwaps({ components: menuComponents, subTerm })
        : true

    if (!mealSwapsMatch) {
      const mealSwapIDs = flatMap(
        mealSwaps.map((mealSwap) => [mealSwap.mealID, ...mealSwap.swapIDs])
      )

      menuComponents = menuComponents.filter((component) => {
        // Discard meal component if meal exists in mealSwaps
        if (
          component.type === 'meal' &&
          mealSwapIDs.includes(component.properties.mealID)
        ) {
          return false
        } else if (component.type === 'mealWithExtra') {
          const mealSwap = mealSwaps.find(
            (mealSwap) => mealSwap.mealID === component.properties.meal.mealID
          )

          // Discard mealWithExtra component if there's no matching mealSwap pair
          if (
            !mealSwap ||
            mealSwap.swapIDs[0] !== component.properties.mealOption.meal.mealID
          ) {
            return false
          }
          return true
        } else if (component.type === 'animatedMealCarousel') {
          const mealSwap = mealSwaps.find(
            (mealSwap) =>
              mealSwap.mealID === component.properties.mealOptions[0].mealID
          )
          const mealSwapIDs = mealSwap
            ? [mealSwap.mealID, ...mealSwap.swapIDs]
            : []
          // Discard animatedMealCarousel if there's no matching mealSwap group
          if (
            !mealSwap ||
            !isEqual(
              mealSwapIDs,
              component.properties.mealOptions.map((option) => option.mealID)
            )
          ) {
            return false
          }

          return true
        }
        return true
      })
    }

    const components = getMenuMealComponents({
      mealSwaps,
      meals: orderBy(meals, 'mainDisplayOrder'),
      menuComponents,
      specialEvent,
      suggestions: undefined,
    })

    return components.map((component) => {
      const menuComponent = menuComponents.find(
        (menuComponent) => menuComponent.id === component.id
      )
      return menuComponent
        ? component
        : {
            ...component,
            id: `${getComponentTempID(component)}-${subTerm.id}`,
          }
    })
  })

  return {
    termResolvedMenuComponents: !isLoadingTermMealSummaries
      ? termResolvedMenuComponents
      : [],
  }
}
