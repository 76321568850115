import { AdminScope, JWTPayload } from '@tovala/browser-apis-combinedapi'
import jwtDecode from 'jwt-decode'

import { getCookie } from './storage'

export function getAdminScope(scope: AdminScope) {
  const userCookie = getCookie('JWT_TOKEN')

  if (userCookie) {
    const decodedUserToken = jwtDecode<JWTPayload>(userCookie)
    const adminScope = decodedUserToken.adminScope

    if (adminScope && adminScope.includes(scope)) {
      return true
    }
  }

  return false
}

export const CS_ADMIN: AdminScope = 'CS_ADMIN'
export const CS_OVEN: AdminScope = 'CS_OVEN'
export const MEALS_READ: AdminScope = 'MEALS_READ'
export const MEALS_WRITE: AdminScope = 'MEALS_WRITE'
export const MSR_ADMIN: AdminScope = 'MSR_ADMIN'
export const OATS_DASHBOARD: AdminScope = 'OATS_DASHBOARD'
export const ORDER_OVERRIDE: AdminScope = 'ORDER_OVERRIDE'
export const PRODUCTS_READ: AdminScope = 'PRODUCTS_READ'
export const PRODUCTS_WRITE: AdminScope = 'PRODUCTS_WRITE'
export const USERS_ADMIN: AdminScope = 'USERS_ADMIN'
export const USERS_READ: AdminScope = 'USERS_READ'
export const USERS_WRITE: AdminScope = 'USERS_WRITE'
export const VENGEFUL_GOD: AdminScope = 'VENGEFUL_GOD'
