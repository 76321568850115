import {
  addDays,
  addMonths,
  addYears,
  format as dateFnsFormat,
  getISOWeek,
  isAfter,
  isBefore,
  isValid,
  min,
  parse,
  parseISO,
} from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

export const DATE_FORMATS = {
  // General date formats
  API_DATE_FORMAT: 'yyyy-MM-dd',
  DEFAULT_DATE_FORMAT: 'M/d/yyyy',
  DEFAULT_DATETIME_FORMAT: 'M/d/yyyy h:mm:ss aaa',
  DOW_FULL: 'EEEE',
  DOW_MONTH_ABBR_DAY: 'E, MMM d',
  DOW_MONTH_ABBR_DAY_YEAR: 'E, MMM d, yyyy',
  DOW_MONTH_DAY: 'E, M/d',
  DOW_MONTH_FULL_DAY: 'E, MMMM d',
  DOW_MONTH_FULL_DAY_SUFFIX: 'E, MMMM do',
  DOW_FULL_MONTH_DAY: 'EEEE, M/d',
  MONTH_ABBR_DAY: 'MMM d',
  MONTH_DAY: 'M/d',
  MONTH_FULL_DAY_SUFFIX: 'MMMM do',
  MONTH_FULL_DAY_YEAR_FULL: 'MMMM d, yyyy',

  // Specific use cases
  MEAL_EXPIRATION_DATE: "yyyy-MM-dd'T00:00:00Z'", // Escape the non-date part so it's ignored.
  ORDER_BY_DATE: "EEEE, M/d 'at' h aaaa 'CT'",
} as const

export type DateFormatKey = keyof typeof DATE_FORMATS
export type DateFormat = (typeof DATE_FORMATS)[DateFormatKey]
export type DateUnits = 'days' | 'months' | 'years'

export function addToDate(
  date: Date,
  { quantity, units }: { quantity: number; units: DateUnits }
): Date {
  if (units === 'days') {
    return addDays(date, quantity)
  } else if (units === 'months') {
    return addMonths(date, quantity)
  }

  return addYears(date, quantity)
}

export function convertUTCToCT(date: string) {
  return utcToZonedTime(date, 'US/Central')
}

export function formatDate(
  value: Date | string,
  {
    format,
    parseFormat,
  }: { format: DateFormat; parseFormat?: DateFormat | null }
): string {
  if (!format) {
    return typeof value === 'string'
      ? value
      : value instanceof Date
      ? value.toISOString()
      : ''
  }

  const valueAsDate =
    typeof value === 'string' ? parseToDate(value, { parseFormat }) : value

  // If we fail to parse the provided input, we can't try to format it.
  if (!isValidDate(valueAsDate)) {
    return typeof value === 'string' ? value : ''
  }

  return dateFnsFormat(valueAsDate, format)
}

export function getISOWeekOfDate(date: Date) {
  return getISOWeek(date)
}

export function getMinDate(dates: Date[]) {
  return min(dates)
}

export function isDateAfter(firstDate: Date, secondDate: Date): boolean {
  return isAfter(firstDate, secondDate)
}

export function isDateBefore(firstDate: Date, secondDate: Date): boolean {
  return isBefore(firstDate, secondDate)
}

export function isValidDate(date: Date) {
  return isValid(date)
}

/**
 * Parses the provided value to a Date. By default, this will try to parse the provided
 * value as an ISO date. If you provide a parseFormat, it will try to parse using that
 * format instead.
 */
export function parseToDate(
  value: string,
  { parseFormat = null }: { parseFormat?: DateFormat | null } = {}
): Date {
  return parseFormat ? parse(value, parseFormat, new Date()) : parseISO(value)
}
