import { clsx } from 'clsx'
import { ComponentProps, ReactNode } from 'react'
import { Tab as HeadlessUITab } from '@headlessui/react'

type TabStyle = 'cards' | 'default' | 'pills'

const TabGroup = (props: {
  children: ReactNode
  onChange?: (index: number) => void
  selectedIndex?: number
}) => {
  return <HeadlessUITab.Group {...props} />
}

export default TabGroup

export const TabList = ({
  children,
  tabStyle = 'default',
}: {
  children: ReactNode
  tabStyle?: TabStyle
}) => {
  return (
    <HeadlessUITab.List
      className={clsx('flex', {
        'border-b border-grey-903': tabStyle === 'default',
        'flex-col': tabStyle === 'cards',
      })}
    >
      {children}
    </HeadlessUITab.List>
  )
}

export const Tab = ({
  children,
  tabStyle = 'default',
}: {
  children: ReactNode
  tabStyle?: TabStyle
}) => {
  return (
    // We need both the "ui-selected" and "ui-not-selected" because otherwise for nested tabs
    // the individual tabs will all be selected (since they'll inherit from the parent tabs).
    // Specifying both variants is a workaround.
    // See https://github.com/tailwindlabs/headlessui/issues/1857
    <HeadlessUITab
      className={clsx('px-4 py-2', {
        'ui-selected:rounded ui-selected:bg-grey-3 ui-selected:text-black ui-not-selected:bg-grey-0 ui-not-selected:text-inherit w-full rounded-md text-left ui-not-selected:hover:bg-grey-1 outline-none':
          tabStyle === 'cards',
        '-mb-px rounded-t border ui-selected:border-grey-903 ui-selected:border-b-current ui-not-selected:border-transparent':
          tabStyle === 'default',
        'ui-selected:rounded ui-selected:bg-red-901 ui-selected:text-white-900 ui-not-selected:bg-white-900 ui-not-selected:text-inherit':
          tabStyle === 'pills',
      })}
    >
      {children}
    </HeadlessUITab>
  )
}

export const TabPanels = (
  props: ComponentProps<typeof HeadlessUITab.Panels>
) => {
  return <HeadlessUITab.Panels {...props} />
}

export const TabPanel = ({ children }: { children: ReactNode }) => {
  return (
    <HeadlessUITab.Panel className="mt-4 p-4">{children}</HeadlessUITab.Panel>
  )
}
