import { InputHTMLAttributes } from 'react'
import { WrappedFieldProps } from 'redux-form'

import FormLabel from './FormLabel'
import { INPUT_CLASSES } from './Input'

const InputRF = ({
  input,
  label,
  meta: { touched, error },
  ...rest
}: InputHTMLAttributes<HTMLInputElement> &
  WrappedFieldProps & {
    label: string
  }): JSX.Element => {
  return (
    <div>
      {label && rest.type !== 'checkbox' && (
        <FormLabel htmlFor={input.name}>{label}</FormLabel>
      )}

      <input
        className={rest.type === 'checkbox' ? '' : INPUT_CLASSES}
        id={input.name}
        {...input}
        {...rest}
      />

      {label && rest.type === 'checkbox' && (
        <label className="pl-2" htmlFor={input.name}>
          {label}
        </label>
      )}

      {touched && error && (
        <div className="text-sm text-red-901">
          <strong>{error}</strong>
        </div>
      )}
    </div>
  )
}

export default InputRF
