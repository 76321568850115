import { ErrorMessage, useField, useFormikContext } from 'formik'
import ReactSelect, { Props as ReactSelectProps } from 'react-select'

import { ReactSelectValue } from 'types/internal'

function SelectInput<
  OptionValueType,
  Option extends ReactSelectValue<OptionValueType>
>({
  afterChange,
  label,
  options,
  ...rest
}: Omit<
  ReactSelectProps<Option, false>,
  'isDisabled' | 'onChange' | 'name' | 'options' | 'value'
> & {
  afterChange?: (newOption: ReactSelectValue<OptionValueType> | null) => void
  label: string
  name: string
  options: Option[]
  readOnly?: boolean
  validate?: (value: string) => string | undefined
}) {
  const [field] = useField({ name: rest.name, validate: rest.validate })
  const { setFieldValue } = useFormikContext()

  return (
    <div>
      {label && <label>{label}</label>}
      <ReactSelect
        {...field}
        {...rest}
        isDisabled={rest.readOnly}
        onChange={(option) => {
          setFieldValue(field.name, option ? option.value : null)

          if (afterChange) {
            afterChange(option)
          }
        }}
        options={options}
        value={
          field.value
            ? options.find((option) => option.value === field.value)
            : null
        }
      />
      <ErrorMessage name={field.name}>
        {(message) => {
          return <span className="ml-1 italic text-red-901">{message}</span>
        }}
      </ErrorMessage>
    </div>
  )
}

export default SelectInput
