import { useMenuProductDetailsJSON } from '@tovala/browser-apis-cdn'
import {
  ListingAdmin,
  Term,
  useAllListingsForMenus,
  useMenuProductDetails,
} from '@tovala/browser-apis-combinedapi'
import { flatMap } from 'lodash-es'

export const useMenuProductFull = ({
  productID,
}: {
  productID: string | undefined
}) => {
  const {
    data: menuProduct,
    error: loadMenuProductError,
    isError: hasLoadMenuProductError,
    isLoading: isLoadingMenuProduct,
  } = useMenuProductDetails({ productID })

  const {
    data: menuProductDetails,
    isLoading: isLoadingMenuProductDetailsJSON,
  } = useMenuProductDetailsJSON({
    productDetailsURL: menuProduct?.productDetailsURL,
  })

  const details = menuProduct?.productDetailsURL
    ? menuProductDetails
    : {
        allergens: [],
        componentImages: [],
        id: menuProduct?.id ?? '',
        ingredients: '',
        nutritionalInfo: [],
        prepSteps: [],
        story: '',
        subtitle: '',
      }

  return {
    hasLoadMenuProductError,
    isLoadingMenuProduct,
    isLoadingMenuProductDetailsJSON,
    loadMenuProductError,
    menuProduct,
    menuProductDetails: details,
  }
}

export interface TermMenuListingsResponse {
  data: TermMenuListings
  error: Error | null
  isError: boolean
  isLoading: boolean
}

export type TermMenuListings = (
  | {
      listings: ListingAdmin[]
      nextPageToken: string
      menuID: string
    }
  | undefined
)[]

export const useTermMenuListings = ({
  term,
}: {
  term: Term | undefined
}): TermMenuListingsResponse => {
  const orderedMenus = term
    ? flatMap(
        term.subTerms.map((subTerm) => subTerm.menus.map((menu) => menu)) ?? []
      ).sort((a, b) => a.name.localeCompare(b.name))
    : []

  const menuIDs = orderedMenus.map((menu) => menu.id)

  const response = useAllListingsForMenus({ menuIDs })
  const data = response.map((res, index) => {
    if (res.data !== undefined) {
      return {
        menuID: menuIDs[index],
        ...res.data,
      }
    }

    return undefined
  })
  const isError = response.some((res) => res.isError)
  const error = isError
    ? response.map((res) => res.error).find((error) => error) ?? null
    : null
  const isLoading = response.some((res) => res.isLoading)

  return {
    data,
    error,
    isError,
    isLoading,
  }
}
