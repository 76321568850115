import RoutineBarcodeDetails from 'components/common/BarcodeDetails'
import { useMenuProductFull } from 'hooks/menuProducts'
import { useParams } from 'react-router-dom'
import { makeMenuProductQRCodeValue } from 'utils/meals'

const MenuProductQRCode = ({
  productID,
}: {
  productID: string | undefined
}) => {
  const { termid: termIDParam } = useParams()

  const termID = termIDParam ? Number.parseInt(termIDParam, 10) : undefined

  const { menuProduct } = useMenuProductFull({ productID })

  if (!menuProduct?.barcode || !termID) {
    return <div>No QR Code available yet.</div>
  }

  const { value: qrCodeValue } = makeMenuProductQRCodeValue(
    menuProduct.barcode,
    termID
  )

  return (
    <div>
      <p className="mb-8">
        To save, right click and select &quot;Save Image As&quot;
      </p>

      <RoutineBarcodeDetails
        qrCodeValue={qrCodeValue}
        routineBarcode={menuProduct.barcode}
        title={menuProduct.title}
      />
    </div>
  )
}

export default MenuProductQRCode
