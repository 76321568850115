import {
  MenuComponentStandardized,
  MenuComponentStandardizedBackgroundImageHeader,
  MenuComponentStandardizedMeal,
  MenuComponentStandardizedMealCarousel,
  MenuComponentStandardizedMealWithExtra,
  MenuComponentStandardizedTextBanner,
  MenuComponentStandardizedTextImageStack,
  MenuComponentStandardizedTwoMealPicker,
} from '@tovala/browser-apis-menu-components'
import {
  MenuComponentAnimatedMealCarousel,
  MenuComponentBackgroundImageHeader,
  MenuComponentMeal,
  MenuComponentMealWithExtra,
  MenuComponents,
  MenuComponentTextBanner,
  MenuComponentTextImageStack,
  MenuComponentTwoMealPicker,
} from '@tovala/browser-apis-menu-delivery'
import { v4 as uuidV4 } from 'uuid'

export function makeMenuComponentBackgroundHeaderImage(
  component: MenuComponentStandardizedBackgroundImageHeader
): MenuComponentBackgroundImageHeader {
  return {
    '@type':
      'type.googleapis.com/tovala.menudelivery.menudelivery.v1.BackgroundImageComponent',
    ...component,
  }
}

export function makeMenuComponentMeal(
  component: MenuComponentStandardizedMeal
): MenuComponentMeal {
  const { id, properties, type } = component

  return {
    '@type':
      'type.googleapis.com/tovala.menudelivery.menudelivery.v1.MealComponent',
    id: id.startsWith('TEMP') ? uuidV4() : id,
    properties: {
      mealID: properties.id,
    },
    type,
  }
}

export function makeMenuComponentMealWithExtra(
  component: MenuComponentStandardizedMealWithExtra
): MenuComponentMealWithExtra {
  const { id, properties, type } = component

  return {
    '@type':
      'type.googleapis.com/tovala.menudelivery.menudelivery.v1.MealWithExtraComponent',
    id: id.startsWith('TEMP') ? uuidV4() : id,
    properties: {
      meal: {
        mealID: properties.meal.id,
      },
      mealIDs: [properties.meal.id, properties.mealOption.meal.id],
      mealOption: {
        meal: {
          mealID: properties.mealOption.meal.id,
          image: {
            url: properties.mealOption.meal.image.url,
          },
        },
        detailsMealID: properties.mealOption.detailsMealID,
      },
    },
    type,
  }
}

export function makeMenuComponentAnimatedMealCarousel(
  component: MenuComponentStandardizedMealCarousel
): MenuComponentAnimatedMealCarousel {
  const { id, properties, type } = component

  return {
    '@type':
      'type.googleapis.com/tovala.menudelivery.menudelivery.v1.AnimatedMealCarouselComponent',
    id: id.startsWith('TEMP') ? uuidV4() : id,
    properties: {
      buttonTitle: properties.buttonTitle,
      mealOptions: properties.mealOptions.map((mealOption) => {
        return {
          mealID: mealOption.id,
          optionTitle: mealOption.optionTitle,
        }
      }),
    },
    type,
  }
}

export function makeMenuComponentTextBanner(
  component: MenuComponentStandardizedTextBanner
): MenuComponentTextBanner {
  const { id, properties, type } = component

  return {
    '@type':
      'type.googleapis.com/tovala.menudelivery.menudelivery.v1.TextBannerComponent',
    id: id.startsWith('TEMP') ? uuidV4() : id,
    properties: {
      title: properties.title,
    },
    type,
  }
}

export function makeMenuComponentTextImageStack(
  component: MenuComponentStandardizedTextImageStack
): MenuComponentTextImageStack {
  const { id, properties, type } = component

  const children: MenuComponentTextImageStack['properties']['children'] = []

  properties.children.forEach((child) => {
    if (child.type === 'meal') {
      children.push(makeMenuComponentMeal(child))
    } else if (child.type === 'mealWithExtra') {
      children.push(makeMenuComponentMealWithExtra(child))
    } else if (child.type === 'twoMealPicker') {
      children.push(makeMenuComponentTwoMealPicker(child))
    } else if (child.type === 'animatedMealCarousel') {
      children.push(makeMenuComponentAnimatedMealCarousel(child))
    }
  })

  return {
    '@type':
      'type.googleapis.com/tovala.menudelivery.menudelivery.v1.TextImageStackComponent',
    id,
    properties: {
      ...properties,
      children,
    },
    type,
  }
}

export function makeMenuComponentTwoMealPicker(
  component: MenuComponentStandardizedTwoMealPicker
): MenuComponentTwoMealPicker {
  const { id, properties, type } = component

  return {
    '@type':
      'type.googleapis.com/tovala.menudelivery.menudelivery.v1.TwoMealPickerComponent',
    id: id.startsWith('TEMP') ? uuidV4() : id,
    properties: {
      firstMeal: {
        buttonTitle: properties.meals[0].mealSummary.shortSubtitle,
        meal: {
          mealID: properties.meals[0].id,
        },
      },
      secondMeal: {
        buttonTitle: properties.meals[1].mealSummary.shortSubtitle,
        meal: {
          mealID: properties.meals[1].id,
        },
      },
    },
    type,
  }
}

export function makeMDSComponents({
  components,
}: {
  components: MenuComponentStandardized[]
}) {
  const mdsComponents: MenuComponents['components'] = []

  components.forEach((component) => {
    if (component.type === 'meal') {
      mdsComponents.push(makeMenuComponentMeal(component))
    } else if (component.type === 'mealWithExtra') {
      mdsComponents.push(makeMenuComponentMealWithExtra(component))
    } else if (component.type === 'twoMealPicker') {
      mdsComponents.push(makeMenuComponentTwoMealPicker(component))
    } else if (component.type === 'animatedMealCarousel') {
      mdsComponents.push(makeMenuComponentAnimatedMealCarousel(component))
    } else if (component.type === 'backgroundImageHeader') {
      mdsComponents.push(makeMenuComponentBackgroundHeaderImage(component))
    } else if (component.type === 'textBanner') {
      mdsComponents.push(makeMenuComponentTextBanner(component))
    } else if (component.type === 'textImageStack') {
      mdsComponents.push(makeMenuComponentTextImageStack(component))
    }
  })

  return mdsComponents
}
