import { CouponCode } from '@tovala/browser-apis-combinedapi'
import { Form, Formik } from 'formik'
import moment from 'moment'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { getAdminScope, PRODUCTS_WRITE } from '../../utils/getAdminScope'
import { getCouponCode, updateCouponCode } from 'actions/marketing'

import { useAppDispatch, useAppSelector } from 'hooks'
import CouponCodeForm, {
  CodeField,
  FormData as CouponFormData,
} from './CouponCodeForm'
import H1 from 'components/common/H1'

type FormData = CouponFormData

const CouponCodeDetail = (): JSX.Element | null => {
  const dispatch = useAppDispatch()

  const { code } = useParams<{ code: string }>()

  useEffect(() => {
    document.title = `Glaze | Update Coupon Code ${code}`
  }, [code])

  useEffect(() => {
    if (code) {
      dispatch(getCouponCode(code))
    }
  }, [code, dispatch])

  const couponCode = useAppSelector((state) => state.marketing.couponCode)

  return couponCode ? <CouponCodeDetailLoaded couponCode={couponCode} /> : null
}

export default CouponCodeDetail

const CouponCodeDetailLoaded = ({
  couponCode,
}: {
  couponCode: CouponCode
}): JSX.Element => {
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const readOnly = !getAdminScope(PRODUCTS_WRITE)
  const types = ['dollar_percent', 'dollar_amount', 'credit_amount']

  const initialValues: FormData = {
    code: couponCode.code,
    credit_amount: couponCode.credit_amount,
    description: couponCode.description,
    dollar_amount: couponCode.dollar_amount,
    dollar_percent: couponCode.dollar_percent,
    max_redemptions: `${couponCode.max_redemptions}`,
    notes: couponCode.notes,
    redeem_by: couponCode.redeem_by ?? '',
    sku: couponCode.sku,
    type: '',
    userid: couponCode.userid ? `${couponCode.userid}` : '',
  }

  if (couponCode) {
    types.forEach((type) => {
      if (couponCode[type] > 0) {
        initialValues.type = type
      }
    })

    if (couponCode.dollar_amount > 0 && couponCode.credit_amount > 0) {
      initialValues.type = 'dollar_and_credit'
    }

    initialValues.redeem_by = couponCode.redeem_by
      ? moment(couponCode.redeem_by).format('YYYY-MM-DDTHH:mm:ss').toString()
      : ''
  }

  const handleSubmit = (values: FormData) => {
    const data = {
      code: values.code,
      credit_amount: values.credit_amount || 0,
      description: values.description,
      dollar_amount: values.dollar_amount || 0,
      dollar_percent: values.dollar_percent || 0,
      max_redemptions: values.max_redemptions
        ? Number.parseInt(values.max_redemptions, 10)
        : null,
      notes: values.notes,
      redeem_by: values.redeem_by
        ? moment(values.redeem_by).toISOString()
        : null,
      sku: values.sku,
      userid: values.userid ? Number.parseInt(values.userid, 10) : null,
    }

    dispatch(updateCouponCode(couponCode.code, data)).then(
      (wasCouponCodeUpdated) => {
        if (wasCouponCodeUpdated) {
          navigate('/coupon-codes')
        }
      }
    )
  }

  return (
    <div>
      <H1>
        Update Coupon Code {couponCode.code && couponCode.code.toUpperCase()}
      </H1>

      <Formik<FormData>
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        <Form className="space-y-4">
          <CodeField readOnly={readOnly} />
          <CouponCodeForm readOnly={readOnly} />
        </Form>
      </Formik>
    </div>
  )
}
