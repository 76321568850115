import { ListingAdmin, TermMenu } from '@tovala/browser-apis-combinedapi'
import { Control, useFieldArray, useWatch } from 'react-hook-form'

import { MenuProductTermListingsFormData, PRICE_OPTIONS } from './helpers'

import { FormComboboxRHF } from 'components/common/FormComboboxRHF'
import { FormInputRHF } from 'components/common/FormInputRHF'

const ListingsFormFieldset = ({
  control,
  disabled,
  listings,
  menusWithListingIDs,
}: {
  control: Control<MenuProductTermListingsFormData>
  disabled: boolean
  listings: ListingAdmin[]
  menusWithListingIDs: {
    listingID: string | undefined
    menu: TermMenu
  }[]
}) => {
  const listingsValue = useWatch({
    control,
    name: 'listings',
  })

  const { fields } = useFieldArray({
    control,
    name: 'listings',
  })

  return (
    <fieldset className="space-y-6" disabled={disabled}>
      <table className="w-full">
        <thead>
          <tr className="border-b border-grey-900">
            <td className="w-1/4 p-2 align-bottom text-h/14_120 font-semibold">
              Menu
            </td>
            <td className="w-1/4 p-2 align-bottom text-h/14_120 font-semibold">
              Production Code
            </td>
            <td className="w-1/4 p-2 align-bottom text-h/14_120 font-semibold">
              Expiration Date
            </td>
            <td className="w-1/4 p-2 align-bottom text-h/14_120 font-semibold">
              Price
            </td>
          </tr>
        </thead>
        <tbody>
          {fields.map((field, index) => {
            const fieldValue = listingsValue[index]
            const menuWithListingID = menusWithListingIDs.find(
              ({ listingID }) => {
                return listingID === fieldValue.listingID
              }
            )
            const listing = listings.find(({ id }) => {
              return id === fieldValue.listingID
            })
            return (
              <tr key={field.id}>
                <td className="p-2">{menuWithListingID?.menu.name}</td>
                <td className="p-2">{listing?.productionCode}</td>
                <td className="p-2">
                  <label
                    className="sr-only"
                    htmlFor={`listings.${index}.expirationDate`}
                  >
                    Expiration Date
                  </label>
                  <FormInputRHF
                    control={control}
                    id={`listings.${index}.expirationDate`}
                    name={`listings.${index}.expirationDate`}
                    type="date"
                  />
                </td>
                <td className="p-2">
                  <label
                    className="sr-only"
                    htmlFor={`listings.${index}.priceCents`}
                  >
                    Price
                  </label>
                  <FormComboboxRHF
                    control={control}
                    id={`listings.${index}.priceCents`}
                    isClearable={false}
                    name={`listings.${index}.priceCents`}
                    options={PRICE_OPTIONS}
                  />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </fieldset>
  )
}

export default ListingsFormFieldset
