import { TovalaProduct } from '@tovala/browser-apis-combinedapi'

import { get } from './baseAPI'

export const ENDPOINTS = {
  GET_TOVALA_PRODUCTS: () => ({
    path: '/products',
    version: 'v1' as const,
  }),
}

export async function getTovalaProducts(): Promise<TovalaProduct[]> {
  return get(ENDPOINTS.GET_TOVALA_PRODUCTS())
}
