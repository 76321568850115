import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { deleteMealTag, getMealTagInfo } from '../../actions/meal-tags'
import { getAdminScope, MEALS_WRITE } from '../../utils/getAdminScope'
import { isMealTag } from 'utils/mealTags'

import { useAppDispatch, useAppSelector } from 'hooks'
import Button from 'components/common/Button'
import ConfirmationModal from '../modals/ConfirmationModal'
import MealTagForm from './MealTagForm'
import H2 from 'components/common/H2'

const MealTagDetail = (): JSX.Element => {
  const navigate = useNavigate()

  const { tagid } = useParams<{ tagid: string }>()

  const dispatch = useAppDispatch()

  const mealTag = useAppSelector((state) => state.mealTags.mealTag)

  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const onDeleteMealTag = () => {
    if (!tagid) {
      return
    }

    dispatch(deleteMealTag(tagid)).then((wasMealTagDeleted) => {
      if (wasMealTagDeleted) {
        navigate('/meal-tags')
      }
    })
  }

  const toggleDeleteModal = () => {
    setShowDeleteModal((show) => !show)
  }

  useEffect(() => {
    document.title = `Glaze | Update Meal Tag ID #${tagid}`
  }, [tagid])

  useEffect(() => {
    if (tagid) {
      dispatch(getMealTagInfo(tagid))
    }
  }, [dispatch, tagid])

  return (
    <div className="flex space-x-4">
      <div className="w-2/3">
        <div className="mb-5 text-red-901">
          <Link to="/meal-tags">Back to All Meal Tags</Link>
        </div>

        {/* Update meal tag form */}
        {isMealTag(mealTag) && mealTag.title && (
          <div>
            <H2>
              {getAdminScope(MEALS_WRITE) && <span>Update</span>}{' '}
              {mealTag.title}
            </H2>

            <MealTagForm mealTag={mealTag} />

            <ConfirmationModal
              buttonText="Delete Meal Tag"
              handleClick={onDeleteMealTag}
              heading="Are you sure you want to delete this meal tag?"
              isOpen={showDeleteModal}
              onCloseModal={toggleDeleteModal}
            >
              <div>
                <p>{mealTag.title}</p>
                <p>Meal Tag ID #{mealTag.id}</p>
              </div>
            </ConfirmationModal>
          </div>
        )}
      </div>

      <div className="w-1/3">
        {getAdminScope(MEALS_WRITE) && (
          <Button buttonStyle="grey" onClick={toggleDeleteModal} size="large">
            Delete Meal Tag
          </Button>
        )}
      </div>
    </div>
  )
}

export default MealTagDetail
