import { clsx } from 'clsx'
import { Link, useParams } from 'react-router-dom'
import moment from 'moment'
import { useEffect } from 'react'
import {
  useSkippedWeeks,
  UserV1,
  useUserTermStatuses,
  TermStatus,
  SkippedWeeks,
  useMealSummaries,
  ErrorCodeMessageMapCombinedAPI,
  useMenuListingsLayout,
  useListingSelections,
} from '@tovala/browser-apis-combinedapi'

import { DATE_FORMATS, formatDate } from 'utils/dates'
import { getAdminScope, USERS_WRITE } from '../../../utils/getAdminScope'

import Hr from 'components/common/Hr'
import { getUserTerm } from 'utils/terms'
import { sortBy } from 'lodash-es'
import { APIErrorDisplay, Button } from '@tovala/component-library'
import OrderSummary from './OrderSummary'
import { getOrderPricing } from './helpers'
import { useHandleSkipChange } from '../hooks'

const LOAD_USER_TERM_STATUSES_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please reload the page to try again.',
    whatHappened: 'Unable to Load User Calendar',
    why: "We couldn't load the user calendar due to a technical issue on our end.",
  },
}

const UserCalendar = ({ user }: { user: UserV1 }): JSX.Element => {
  const { userid } = useParams<{ userid: string }>()

  const {
    data: userTermStatuses = [],
    error: loadUserTermStatusesError,
    isError: hasLoadUserTermStatusesError,
    isLoading: isLoadingUserTermStatuses,
  } = useUserTermStatuses({ userID: user.id })

  const { data: skippedWeeks } = useSkippedWeeks({
    userID: user.id,
  })

  const { handleSkipChange } = useHandleSkipChange()

  useEffect(() => {
    document.title = `Glaze | User #${userid} - Calendar`
  }, [userid])

  if (isLoadingUserTermStatuses) {
    return <div>Loading</div>
  }

  if (hasLoadUserTermStatusesError) {
    return (
      <div>
        <APIErrorDisplay
          error={loadUserTermStatusesError}
          errorCodeMessageMap={LOAD_USER_TERM_STATUSES_ERRORS}
        />
      </div>
    )
  }

  let activePlanFlag = true
  if (user && user.subscription.status !== 'active') {
    activePlanFlag = false
  }

  return (
    <div className="font-sans-new">
      <h1 className="text-k/36_110">Calendar</h1>

      {activePlanFlag ? (
        <div>
          {userTermStatuses.map((termStatus) => {
            return (
              <div key={termStatus.termID}>
                <div className="w-3/5 py-8">
                  <CalendarTermDetails
                    handleSkipChange={handleSkipChange}
                    skippedWeeks={skippedWeeks}
                    term={termStatus}
                    user={user}
                  />
                </div>
                <Hr />
              </div>
            )
          })}
        </div>
      ) : (
        <p>Subscription is inactive.</p>
      )}
    </div>
  )
}

export default UserCalendar

export const CalendarTermDetails = ({
  handleSkipChange,
  skippedWeeks,
  term,
  user,
}: {
  handleSkipChange({
    isSkipped,
    termID,
    userID,
  }: {
    isSkipped: boolean
    termID: number
    userID: number
  }): void
  skippedWeeks: SkippedWeeks | undefined
  term: TermStatus
  user: UserV1
}) => {
  const selectedUserTerm = getUserTerm({ skippedWeeks, term, user })

  const {
    termID,
    startDate,
    endDate,
    mealSelections,
    orderByDate,
    selectedSubTerm,
    subscriptionType,
    selectedSubscriptionTypeIsDefault,
    isSkipped,
  } = selectedUserTerm
  const selectedMenuID = selectedSubTerm?.mainMenu.id

  const { data: mealSummaries = [] } = useMealSummaries({
    subTermID: selectedUserTerm?.selectedSubTermID,
  })
  const sortedMealSummaries = sortBy(mealSummaries, 'id')

  const { data: menuListingsLayout } = useMenuListingsLayout({
    menuID: selectedMenuID,
  })

  const { data: listingSelectionsResponse } = useListingSelections({
    menuID: selectedMenuID,
    userID: user.id,
  })

  const listingSelections = listingSelectionsResponse?.listingsSelected ?? []
  const listingSelectionsPricing =
    listingSelectionsResponse?.estimatedTotalCents ?? 0

  const utcOffset = moment().isDST() ? -5 : -6
  const skippedStatus = isSkipped ? 'skipped' : 'scheduled'

  if (selectedUserTerm && selectedSubTerm) {
    return (
      <div>
        <div className="flex justify-between items-center">
          <p className="text-k/20_110">
            Term #{termID} |{' '}
            {formatDate(new Date(startDate), {
              format: DATE_FORMATS.DOW_MONTH_DAY,
            })}{' '}
            -{' '}
            {formatDate(new Date(endDate), {
              format: DATE_FORMATS.DOW_MONTH_DAY,
            })}
          </p>

          {getAdminScope(USERS_WRITE) && !isSkipped && (
            <Link to={`/user/${user.id}/menu/${termID}`}>
              <Button buttonStyle="stroke" size="medium">
                Edit
              </Button>
            </Link>
          )}
        </div>

        <div>
          <div>
            <div className="my-4 flex space-x-8">
              <div>
                <div className="font-semibold">Order by</div>
                <span>
                  {moment(orderByDate)
                    .utcOffset(utcOffset)
                    .format('ddd, M/D h:mma')}{' '}
                  CT
                </span>
              </div>

              {selectedSubTerm.deliveryDate && (
                <div>
                  <div className="font-semibold">Delivery Day</div>
                  <span>
                    {formatDate(new Date(selectedSubTerm.deliveryDate), {
                      format: DATE_FORMATS.DOW_MONTH_DAY,
                    })}
                  </span>
                </div>
              )}
            </div>

            <div className="flex justify-between items-center">
              <span
                className={clsx('font-semibold uppercase tracking-wider', {
                  'text-green-905': skippedStatus === 'scheduled',
                  'text-red-901': skippedStatus === 'skipped',
                })}
              >
                {skippedStatus}
              </span>
              {getAdminScope(USERS_WRITE) && (
                <Button
                  onClick={() =>
                    handleSkipChange({ isSkipped, termID, userID: user.id })
                  }
                  size="medium"
                >
                  {isSkipped ? 'Unskip Week' : 'Skip Week'}
                </Button>
              )}
            </div>
          </div>
          {!isSkipped && (
            <div>
              <div className="font-semibold">Order Summary</div>

              <OrderSummary
                listingSelections={listingSelections}
                listings={
                  menuListingsLayout
                    ? menuListingsLayout.sections
                        .map((section) => section.listings)
                        .flat()
                    : []
                }
                maxSelections={subscriptionType?.maxSelections}
                maxSelectionsDefaultSubscription={
                  user.subscription.subscriptionType?.maxSelections
                }
                mealSummaries={sortedMealSummaries}
                orderPricing={getOrderPricing({
                  listingSelectionsPricing,
                  subscriptionPricing: selectedSubTerm.subscriptionPricing,
                })}
                selectedSubscriptionTypeIsDefault={
                  selectedSubscriptionTypeIsDefault
                }
                selectionsMealIDs={mealSelections.map(
                  (selection) => selection.mealID
                )}
              />
            </div>
          )}
        </div>
      </div>
    )
  }

  return null
}
