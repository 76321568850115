import { ComponentProps } from 'react'
import { FormGroup, Textarea } from '@tovala/component-library'
import { useField } from 'formik'

type Props = Pick<ComponentProps<typeof FormGroup>, 'label' | 'labelFor'> &
  ComponentProps<typeof Textarea> & { name: string }

const FormikTextarea = ({ label, labelFor, ...rest }: Props) => {
  const [field] = useField({
    name: rest.name,
    value: rest.value,
  })

  return (
    <FormGroup label={label} labelFor={labelFor}>
      <Textarea {...field} {...rest} />
    </FormGroup>
  )
}

export default FormikTextarea
