import { clsx } from 'clsx'

const StatusDot = ({ color }: { color: 'green' | 'red' }) => {
  return (
    <div
      className={clsx('h-2.5 w-2.5 rounded-full', {
        'bg-green-904': color === 'green',
        'bg-red-905': color === 'red',
      })}
    />
  )
}

export default StatusDot
