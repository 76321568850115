import { APIErrorDisplay } from '@tovala/component-library'
import { ComponentProps, ReactNode, useEffect } from 'react'
import { getAPIErrorCode } from '@tovala/browser-apis-core'
import * as Sentry from '@sentry/react'

function SentryAPIErrorDisplay<
  ErrorCodes extends string,
  PresentationType extends ReactNode = string
>({
  error,
  errorCodeMessageMap,
  ...rest
}: ComponentProps<typeof APIErrorDisplay<ErrorCodes, PresentationType>>) {
  const errorKey = getAPIErrorCode<ErrorCodes>({ error })

  // We'll only log Sentry errors for errors that we're not currently configured
  // to handle. This helps us identify places where we're not showing the best error
  // messaging to our users.
  const captureSentryError =
    errorKey === undefined || errorCodeMessageMap[errorKey] === undefined

  useEffect(() => {
    if (captureSentryError && error) {
      Sentry.captureException(error)
    }
  }, [captureSentryError, error])

  return (
    <APIErrorDisplay
      {...rest}
      error={error}
      errorCodeMessageMap={errorCodeMessageMap}
    />
  )
}

export default SentryAPIErrorDisplay
