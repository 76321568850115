import { ReactNode } from 'react'

import Button, { Props as ButtonProps } from 'components/common/Button'
import CircleLoader from 'components/common/CircleLoader'

export type Props = ButtonProps & {
  children: ReactNode
  isLoading: boolean
}

const ButtonLoading = ({
  buttonStyle,
  children,
  disabled,
  isLoading,
  ...rest
}: Props): JSX.Element => {
  return (
    <Button
      buttonStyle={buttonStyle}
      disabled={disabled || isLoading}
      type="submit"
      {...rest}
    >
      <div className="flex w-full items-center justify-center">
        {isLoading && (
          <div className="mr-2">
            <CircleLoader loaderStyle="white" />
          </div>
        )}
        <span>{children}</span>
      </div>
    </Button>
  )
}

export default ButtonLoading
