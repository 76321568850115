import { MenuComponentStandardizedMealWithExtra } from '@tovala/browser-apis-menu-components'
import { MealWithExtra as MealWithExtraComponent } from '@tovala/component-library'
import Meal from './Meal'

const MealWithExtra = ({
  properties,
}: {
  properties: MenuComponentStandardizedMealWithExtra['properties']
}) => {
  const { meal, mealOption } = properties
  const { id, image, surcharge, mealSummary } = mealOption.meal
  return (
    <MealWithExtraComponent
      meal={<Meal properties={meal} />}
      mealOption={{
        id,
        image,
        isDisabled: false,
        isHiddenByFilters: false,
        isSelected: false,
        isSoldOut: false,
        surcharge,
        title: mealSummary.shortSubtitle,
      }}
    />
  )
}

export default MealWithExtra
