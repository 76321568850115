import { MenuComponentStandardizedMealCarousel } from '@tovala/browser-apis-menu-components'
import { MealCarousel as MealCarouselComponent } from '@tovala/component-library'

const MealCarousel = ({
  properties,
}: {
  properties: MenuComponentStandardizedMealCarousel['properties']
}) => {
  const { buttonTitle, mealOptions } = properties
  return (
    <MealCarouselComponent
      buttonTitle={buttonTitle}
      mealOptions={mealOptions.map((mealOption) => {
        return {
          ...mealOption,
          quantity: 0,
        }
      })}
    />
  )
}

export default MealCarousel
