import { MealAdmin, TermSubTerm } from '@tovala/browser-apis-combinedapi'
import { MenuComponentStandardizedMealWithExtra } from '@tovala/browser-apis-menu-components'
import { Modal, ModalHeader, Checkbox, Button } from '@tovala/component-library'
import { flatMap, forEach, map } from 'lodash-es'
import { useState } from 'react'

const EditAdditionalMealWithExtraDialog = ({
  components,
  meal,
  onClose,
  onSave,
  orderedSubTerms,
}: {
  components: Record<string, MenuComponentStandardizedMealWithExtra[]>
  meal: MealAdmin
  onClose(): void
  onSave(
    components: Record<string, MenuComponentStandardizedMealWithExtra[]>,
    meal: MealAdmin
  ): void
  orderedSubTerms: TermSubTerm[]
}) => {
  const [componentIDs, setComponentIDs] = useState(
    new Set<string>(
      flatMap(components, (components) => {
        return components.map((component) => component.id)
      })
    )
  )

  return (
    <Modal onCloseModal={onClose}>
      <ModalHeader onClickClose={onClose}>
        Additional Matching Extras
      </ModalHeader>
      <form
        onSubmit={(event) => {
          event.preventDefault()

          const componentsToUpdate: Record<
            string,
            MenuComponentStandardizedMealWithExtra[]
          > = {}

          forEach(components, (componentsForSubTerm, subTermID) => {
            const updatedComponents = componentsForSubTerm.filter((component) =>
              componentIDs.has(component.id)
            )
            componentsToUpdate[subTermID] = updatedComponents
          })

          onSave(componentsToUpdate, meal)
        }}
      >
        <div className="p-6 font-sans-new">
          <div className="w-[500px] space-y-4">
            <p>Select the components you'd like to update with {meal.title}.</p>
            {map(components, (components, subTermID) => {
              const subTerm = orderedSubTerms.find(
                (subTerm) => subTerm.id === subTermID
              )

              const label = subTerm
                ? `${subTerm.facilityNetwork} ${subTerm.shipPeriod}`
                : ''

              return (
                <div key={subTermID} className="space-y-4">
                  <div className="text-k/12_120 uppercase text-black">
                    {label}
                  </div>
                  {components.map((component) => {
                    return (
                      <div key={component.id}>
                        <Checkbox
                          checked={componentIDs.has(component.id)}
                          label={
                            <div className="flex items-center space-x-4">
                              <img
                                className="h-16 rounded-md"
                                src={
                                  component.properties.mealOption.meal.image.url
                                }
                              />
                              <div>
                                <div>
                                  {component.properties.mealOption.meal.title}
                                </div>
                                <div className="text-sm">
                                  {
                                    component.properties.mealOption.meal
                                      .subtitle
                                  }
                                </div>
                              </div>
                            </div>
                          }
                          name={component.id}
                          onChange={() => {
                            setComponentIDs((componentIDs) => {
                              const newComponentIDs = new Set(componentIDs)

                              if (newComponentIDs.has(component.id)) {
                                newComponentIDs.delete(component.id)
                              } else {
                                newComponentIDs.add(component.id)
                              }

                              return newComponentIDs
                            })
                          }}
                        />
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>

          <div className="mt-8 flex flex-row-reverse gap-4">
            <Button size="large" type="submit">
              Save
            </Button>

            <Button
              buttonStyle="stroke"
              onClick={() => {
                onClose()
              }}
              size="large"
            >
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default EditAdditionalMealWithExtraDialog
