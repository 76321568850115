import axios from 'axios'
import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { MealImage, Routine } from '@tovala/browser-apis-combinedapi'

import {
  ADD_MEAL,
  CREATE_BULK_MEALS,
  GET_ALL_MEALS,
  GET_MEAL_INFO,
  UPDATE_MENU_MEAL,
  UPDATE_SOLD_OUT_COUNT,
} from './types'
import { API_URL, API_URL_V1, getHeaders } from './index'
import { AppThunk } from 'store'
import { errorHandler, successHandler } from './notifications'
import { PayloadAction } from '@reduxjs/toolkit'

interface DuplicateMealResponse {
  chef_image: string
  created_by: string
  customer_quote: string
  customer_quote_by: string
  dietary_restrictions: []
  displayOrders: {
    all: number
    chefsTable: number
    glutenFree: number
    homeClassics: number
    lowCarb: number
    vegetarian: number
  }
  expiration: string
  expirationOffset: string
  firstShipPeriodAvailability: null
  id: number
  images: []
  ingredients: string
  mealPrepSteps: string
  meal_fact: string
  nf_meal_code: string
  nutritionalInfo: []
  prep_in_seconds: number
  price: number
  routine: Routine
  secondShipPeriodAvailability: null
  shortSubtitle: string
  soldOutCount: number
  story: string
  subtitle: string
  tags: []
  termid: number
  title: string
}

interface SaveBulkMealsRequestBody {
  meals: {
    expirationDate: string
    menuIDs: string[]
    productionCode: number
    termid: number
    title: string
  }[]
}

export interface SaveMealRequestBody {
  chef_image: string
  created_by: string
  customer_quote: string
  customer_quote_by: string
  ingredients: string
  mealPrepSteps: string
  nutritionalInfo: {
    dailyValuePercentage: string
    id?: string
    key: string
    name: string
    unit: string
    value: string
  }[]
  shortSubtitle: string
  story: string
  subtitle: string
  surchargeCents: string
  termid: string
  title: string
}

interface SaveMealResponse {
  basePriceCents: number
  chef_image: string
  created_by: string
  customer_quote: string
  customer_quote_by: string
  display_order_all: number
  display_order_chefs_table: number
  display_order_gluten_free: number
  display_order_home_classics: number
  display_order_low_carb: number
  display_order_vegetarian: number
  expiration: string
  expirationOffset: string
  id: number
  ingredients: string
  mealPrepSteps: string
  meal_fact: string
  mealcode: string
  nutritionalInfo: []
  preptime: number
  price: number
  shortSubtitle: string
  story: string
  subtitle: string
  surchargeCents: number
  termid: number | null
  title: string
  updated: string
}

interface UpdateMenuMealRequestBody {
  expirationDate: string
  productionCode: string
  mainDisplayOrder: number
  mealID: number
  menuID: string
}

interface UpdateMenuMealResponse {
  created: string
  expirationDate: string
  id: string
  mainDisplayOrder: number
  mealID: number
  menuID: string
  notes: string
  productionCode: number
  updated: string
}

type UpdateMultipleMenuMealsRequestBody = UpdateMenuMealRequestBody[]

// GET https://api.tovala.com/v0/meals
export function getAllMeals(): AppThunk<void> {
  return function (dispatch) {
    dispatch(showLoading())

    axios
      .get(`${API_URL}/meals`, getHeaders())
      .then((response) => {
        dispatch(hideLoading())
        dispatch({
          type: GET_ALL_MEALS,
          payload: response.data,
        })
      })
      .catch((error) => {
        dispatch(hideLoading())
        errorHandler(dispatch, error)
      })
  }
}

// GET https://api.tovala.com/v0/meals/:mealId
export function getMealInfo(
  mealId: number | string
): AppThunk<Promise<PayloadAction<unknown, typeof GET_MEAL_INFO> | void>> {
  return function (dispatch) {
    return axios
      .get(`${API_URL_V1}/meals/${mealId}`, getHeaders())
      .then((response) => {
        return dispatch({
          type: GET_MEAL_INFO,
          payload: response.data,
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

// POST https://api.tovala.com/v0/meals
export function addMeal(
  formProps: Omit<SaveMealRequestBody, 'termid'>
): AppThunk<Promise<PayloadAction<SaveMealResponse, typeof ADD_MEAL> | void>> {
  return function (dispatch) {
    const meal = {
      ...formProps,
      surchargeCents: formProps.surchargeCents
        ? Number.parseInt(formProps.surchargeCents, 10)
        : 0,
      termid: null,
    }

    return axios
      .post(`${API_URL_V1}/mealinfo`, meal, getHeaders())
      .then((response) => {
        if (response.status === 200) {
          return dispatch({
            type: ADD_MEAL,
            payload: response.data,
          })
        }
      })
      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

// PUT https://api.tovala.com/v1/mealinfo/:mealId
export function updateMeal(
  mealid: string,
  formProps: SaveMealRequestBody
): AppThunk<
  Promise<PayloadAction<SaveMealResponse, typeof GET_MEAL_INFO> | void>
> {
  return function (dispatch) {
    const meal = {
      ...formProps,
      surchargeCents: formProps.surchargeCents
        ? Number.parseInt(formProps.surchargeCents, 10)
        : 0,
      termid:
        !formProps.termid || formProps.termid === '0' || formProps.termid === ''
          ? null
          : Number.parseInt(formProps.termid, 10),
    }

    return axios
      .put(`${API_URL_V1}/mealinfo/${mealid}`, meal, getHeaders())
      .then((response) => {
        return dispatch({
          type: GET_MEAL_INFO,
          payload: response.data,
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error.response)
      })
  }
}

export function addMealImage({
  file,
  mealid,
}: {
  mealid: string
  file: File
}): AppThunk<Promise<MealImage>> {
  return function () {
    const formData = new FormData()
    formData.set('file', file)

    return axios
      .post<MealImage>(
        `${API_URL}/meals/${mealid}/images`,
        formData,
        getHeaders()
      )
      .then((response) => {
        return response.data
      })
  }
}

// PUT http://api.tovala.com/v0/meals/:mealId/images/:image_hash
export function updateImageText(
  mealid: number | string,
  imageHash: string,
  formProps: {
    alt: string
    caption: string
    dominantColor: string
    key: string
  }
): AppThunk<Promise<boolean | void>> {
  return function (dispatch) {
    const key = formProps.key
    const alt = formProps.alt
    const caption = formProps.caption
    const dominantColor = formProps.dominantColor

    return axios
      .put(
        `${API_URL}/meals/${mealid}/images/${imageHash}`,
        { key, alt, caption, dominantColor },
        getHeaders()
      )
      .then((response) => {
        if (response.status === 200) {
          successHandler(dispatch, 'Image text updated.')

          dispatch(getMealInfo(response.data.mealid))

          return true
        }
      })
      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

// POST https://api.tovala.com/v0/meals/:mealId/duplicate
export function duplicateMeal(
  mealid: string
): AppThunk<Promise<DuplicateMealResponse['id'] | void>> {
  return function (dispatch) {
    return axios
      .post<DuplicateMealResponse>(
        `${API_URL}/meals/${mealid}/duplicate`,
        {},
        getHeaders()
      )
      .then((response) => {
        successHandler(dispatch, 'Success! Meal duplicated.')

        return response.data.id
      })
      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

// DELETE http://api.tovala.com/v0/meals/:mealId/images/:image_hash
export function deleteImageForMeal(
  mealid: string,
  image: { hash: string }
): AppThunk<void> {
  return function (dispatch) {
    // construct payload data
    const imageHash = image.hash

    axios
      .delete(`${API_URL}/meals/${mealid}/images/${imageHash}`, getHeaders())
      .then(() => {
        successHandler(dispatch, 'Success! Image deleted.')
        dispatch(getMealInfo(mealid))
      })
      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

// PUT https://api.tovala.com/v0/meals/:mealID/tags/:tagID
export function addMealTagtoMeal(
  mealid: string,
  tagid: number | string,
  metadata = {}
): AppThunk<void> {
  return function (dispatch) {
    axios
      .put(`${API_URL}/meals/${mealid}/tags/${tagid}`, metadata, getHeaders())
      .then(() => {
        successHandler(dispatch, 'Success! Meal tag added to meal.')
        dispatch(getMealInfo(mealid))
      })
      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

// DELETE https://api.tovala.com/v0/meals/:mealID/tags/:tagID
export function removeMealTagFromMeal(
  mealid: string,
  tagid: number
): AppThunk<void> {
  return function (dispatch) {
    axios
      .delete(`${API_URL}/meals/${mealid}/tags/${tagid}`, getHeaders())
      .then(() => {
        successHandler(dispatch, 'Success! Meal tag removed from meal.')
        dispatch(getMealInfo(mealid))
      })
      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

// POST v1/meals/:mealID/soldOutCount/:subTermID
export function addMealSoldOutCount({
  mealID,
  soldOutCount,
  subTermID,
}: {
  mealID: string
  soldOutCount: number
  subTermID: string
}): AppThunk<
  Promise<PayloadAction<'success', typeof UPDATE_SOLD_OUT_COUNT> | void>
> {
  const data = { soldOutCount }

  return function (dispatch) {
    return axios
      .post(
        `${API_URL_V1}/meals/${mealID}/soldOutCount/${subTermID}`,
        data,
        getHeaders()
      )
      .then(() => {
        successHandler(
          dispatch,
          `Success! Meal ID #${mealID} sold out count updated.`
        )

        return dispatch({
          type: UPDATE_SOLD_OUT_COUNT,
          payload: 'success' as const,
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

// PUT v1/meals/:mealID/soldOutCount/:subtermID
export function updateMealSoldOutCount({
  mealID,
  soldOutCount,
  subTermID,
}: {
  mealID: string
  soldOutCount: number
  subTermID: string
}): AppThunk<
  Promise<PayloadAction<'success', typeof UPDATE_SOLD_OUT_COUNT> | void>
> {
  const data = { soldOutCount }

  return function (dispatch) {
    return axios
      .put(
        `${API_URL_V1}/meals/${mealID}/soldOutCount/${subTermID}`,
        data,
        getHeaders()
      )
      .then(() => {
        successHandler(
          dispatch,
          `Success! Meal ID #${mealID} sold out count updated.`
        )

        return dispatch({
          type: UPDATE_SOLD_OUT_COUNT,
          payload: 'success' as const,
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

// POST https://api.tovala.com/v1/menus/:menuID/meals
export function addMenuMeal(
  menuID: string,
  data: unknown
): AppThunk<Promise<void>> {
  return function (dispatch) {
    return axios
      .post(`${API_URL_V1}/menus/${menuID}/meals`, data, getHeaders())
      .then(() => {
        successHandler(dispatch, `Success! Meal added to menu.`)
      })
      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

// PUT https://api.tovala.com/v1/menus/:menuID/meals/:mealID
export function updateMenuMeal(
  menuID: string,
  mealID: string,
  data: UpdateMenuMealRequestBody
): AppThunk<
  Promise<PayloadAction<UpdateMenuMealResponse, typeof UPDATE_MENU_MEAL> | void>
> {
  return function (dispatch) {
    return axios
      .put(`${API_URL_V1}/menus/${menuID}/meals/${mealID}`, data, getHeaders())
      .then((response) => {
        return dispatch({
          type: UPDATE_MENU_MEAL,
          payload: response.data,
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

// PUT https://api.tovala.com/v1/menus/:menuID/meals
// Currently only used for updating meal display orders
export function updateMultipleMenuMeals(
  menuID: string,
  data: UpdateMultipleMenuMealsRequestBody
): AppThunk<Promise<void>> {
  return function (dispatch) {
    dispatch(showLoading())

    return axios
      .put(`${API_URL_V1}/menus/${menuID}/meals`, data, getHeaders())
      .then(() => {
        dispatch(hideLoading())
        successHandler(dispatch, `Success! Meal display orders updated.`)
      })
      .catch((error) => {
        dispatch(hideLoading())
        errorHandler(dispatch, error)
      })
  }
}

// DELETE https://api.tovala.com/v1/menus/:menuID/meals/:mealID
export function deleteMenuMeal(
  menuID: string,
  mealID: number
): AppThunk<Promise<void>> {
  return function (dispatch) {
    dispatch(showLoading())

    return axios
      .delete(`${API_URL_V1}/menus/${menuID}/meals/${mealID}`, getHeaders())
      .then(() => {
        dispatch(hideLoading())
        successHandler(dispatch, `Success! Meal removed from menu.`)
      })
      .catch((error) => {
        dispatch(hideLoading())
        errorHandler(dispatch, error)
      })
  }
}

// POST https://api.tovala.com/v1/tools/createBulkMealsToTerm
export function createBulkTermMeals(
  data: SaveBulkMealsRequestBody
): AppThunk<Promise<PayloadAction<true, typeof CREATE_BULK_MEALS> | void>> {
  return function (dispatch) {
    dispatch(showLoading())

    return axios
      .post(`${API_URL_V1}/tools/createBulkMealsToTerm`, data, getHeaders())
      .then(() => {
        dispatch(hideLoading())
        successHandler(dispatch, `Success! Meals added.`)

        return dispatch({
          type: CREATE_BULK_MEALS,
          payload: true as const,
        })
      })
      .catch((error) => {
        dispatch(hideLoading())
        errorHandler(dispatch, error)
      })
  }
}
