import { clsx } from 'clsx'
import { ReactNode } from 'react'

type AlertStyle = 'danger' | 'success' | 'warning'

const AlertInline = ({
  alertStyle,
  children,
}: {
  alertStyle: AlertStyle
  children: ReactNode
}) => {
  return (
    <div
      className={clsx('rounded p-4', {
        'bg-red-903 text-red-904': alertStyle === 'danger',
        'bg-green-901 text-green-902': alertStyle === 'success',
        'bg-yellow-901 text-yellow-902': alertStyle === 'warning',
      })}
    >
      {children}
    </div>
  )
}

export default AlertInline
