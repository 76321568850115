import { Field, Form, Formik, useField } from 'formik'
import {
  MealCashRecord,
  UserV1,
  useInvalidateMealCash,
  useInvalidateReceipts,
} from '@tovala/browser-apis-combinedapi'
import ReactSelect from 'react-select'

import { errorHandler, successHandler } from 'actions/notifications'
import { getUserId } from '../../utils/getUserId'
import { isOptionGroup } from 'utils/general'
import {
  MARKETING_REASON_OPTIONS,
  MEAL_CASH_REFUND_REASON_OPTIONS,
} from 'utils/dropdowns'
import { ReactSelectOptionGroup, ReactSelectValue } from 'types/internal'

import { useAppDispatch } from 'hooks'
import { useRemoveMealCash } from 'hooks/combinedAPI/mealCash'
import ButtonLoading from 'components/common/ButtonLoading'
import FormLabel from 'components/common/FormLabel'
import { INPUT_CLASSES } from 'components/common/Input'
import Modal, { ModalBody, ModalHeader } from 'components/modals/Modal'
import { TEXTAREA_CLASSES } from 'components/common/Textarea'

interface FormData {
  csMealCashRecords: string[]
  notes: string
  records: MealCashRecord[]
}

export interface Props {
  csMealCashRecords?: MealCashRecord[] | ''
  onCloseModal(): void
  records: MealCashRecord[]
  user: UserV1
}

const Select = (props: {
  name: string
  onChange(option: ReactSelectValue<string> | null): void
  options: ReactSelectValue<string>[] | ReactSelectOptionGroup<string>[]
  value: ReactSelectValue<string> | null
}): JSX.Element => {
  const [field] = useField({ name: props.name })

  return <ReactSelect {...field} {...props} />
}

const RemoveCreditModal = ({
  csMealCashRecords,
  onCloseModal,
  records,
  user,
}: Props): JSX.Element => {
  const dispatch = useAppDispatch()

  const { invalidateMealCashHistory } = useInvalidateMealCash()
  const { invalidateOrderHistoryReceipts } = useInvalidateReceipts()

  const { isLoading: isRemovingMealCash, mutate: removeMealCash } =
    useRemoveMealCash({
      onError: (err) => {
        errorHandler(dispatch, err)
      },
      onSuccess: (_data, { userID }) => {
        successHandler(dispatch, `Success! Tovala Cash removed.`)

        invalidateMealCashHistory(userID)
        invalidateOrderHistoryReceipts(userID)
      },
    })

  const handleSubmit = (values: FormData) => {
    const csAgent = getUserId()

    const commonPayloadOpts = {
      csAgent: csAgent ? `${csAgent}` : '',
      notes: values.notes,
    }

    values.records.forEach((record) => {
      removeMealCash({
        cashID: record.id,
        data: {
          ...commonPayloadOpts,
          internalCashType: record.internalCashType,
          reason: record.csReason,
          zendeskTicket: record.csZendeskTicket,
        },
        userID: user.id,
      })
    })

    if (csMealCashRecords && values.csMealCashRecords.length) {
      values.csMealCashRecords.forEach((cashID) => {
        const record = csMealCashRecords.find(
          (cashRecord) => cashRecord.id === cashID
        )

        if (!record) {
          return
        }

        removeMealCash({
          cashID,
          data: {
            ...commonPayloadOpts,
            internalCashType: record.internalCashType,
            reason: record.csReason,
            zendeskTicket: record.csZendeskTicket,
          },
          userID: user.id,
        })
      })
    }

    onCloseModal()
  }

  const initialValues = {
    csMealCashRecords: [],
    notes: '',
    records,
  }

  return (
    <Modal onCloseModal={onCloseModal}>
      <ModalBody>
        <ModalHeader onClickClose={onCloseModal}>
          Remove Tovala Cash
        </ModalHeader>

        <div className="w-[500px]">
          <Formik<FormData>
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, values }) => {
              return (
                <Form className="space-y-4">
                  <div className="space-y-4">
                    {records &&
                      records.map((record, index) => {
                        const options =
                          values.records[index].internalCashType === 'gift_card'
                            ? MARKETING_REASON_OPTIONS
                            : MEAL_CASH_REFUND_REASON_OPTIONS
                        const allValues = isOptionGroup(options)
                          ? options.map((group) => group.options).flat()
                          : options

                        const selectedValue = allValues.find(
                          (option) =>
                            option.value === values.records[index].csReason
                        )

                        return (
                          <div key={record.id} className="space-y-4">
                            <p className="font-bold">
                              Removing ${(record.amount / 100).toFixed(2)} from
                              Tovala Cash balance.
                            </p>

                            <div>
                              <FormLabel>Reason*</FormLabel>
                              <Select
                                name={`records[${index}].csReason`}
                                onChange={(option) => {
                                  setFieldValue(
                                    `records[${index}].csReason`,
                                    option?.value ?? null
                                  )
                                }}
                                options={options}
                                value={selectedValue ?? null}
                              />
                            </div>

                            <div>
                              <FormLabel>ZD Ticket #</FormLabel>
                              <Field
                                className={INPUT_CLASSES}
                                name={`records[${index}].zendeskTicket`}
                                placeholder="Enter ZD Ticket # if applicable"
                                type="text"
                              />
                            </div>
                          </div>
                        )
                      })}
                  </div>

                  <div>
                    <FormLabel>Notes*</FormLabel>
                    <Field
                      as="textarea"
                      className={TEXTAREA_CLASSES}
                      name="notes"
                      required
                      rows={5}
                    />
                  </div>

                  {csMealCashRecords &&
                    csMealCashRecords.map((record) => (
                      <div key={record.id}>
                        <Field
                          checked={values.csMealCashRecords.includes(record.id)}
                          id={record.id}
                          name="csMealCashRecords"
                          type="checkbox"
                          value={record.id}
                        />
                        <label htmlFor={record.id}>
                          &nbsp; Also remove ${(record.amount / 100).toFixed(2)}{' '}
                          in additional Tovala Cash that was added for this
                          term?
                        </label>
                      </div>
                    ))}

                  <div className="mt-4">
                    <ButtonLoading isLoading={isRemovingMealCash} size="large">
                      Submit
                    </ButtonLoading>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default RemoveCreditModal
