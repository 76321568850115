import { SVGAttributes } from 'react'

const ChevronDownIcon = (
  props: Omit<SVGAttributes<SVGElement>, 'fill' | 'viewBox' | 'xmlns'>
): JSX.Element => {
  return (
    <svg
      {...props}
      fill="none"
      viewBox="0 0 24 25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M12 15.7672L4.39858 8.1658L2.63081 9.93357L12 19.3027L21.3691 9.93357L19.6014 8.1658L12 15.7672Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default ChevronDownIcon
