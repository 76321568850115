import { clsx } from 'clsx'

type LoaderStyle = 'colored' | 'white'

const CircleLoader = ({
  loaderStyle = 'white',
}: {
  loaderStyle?: LoaderStyle
}): JSX.Element => {
  return (
    <div
      className={clsx(
        'flex h-4 w-4 animate-spin-pulse justify-center rounded-full border-2 border-b-transparent',
        {
          'border-red-901': loaderStyle === 'colored',
          'border-white-900': loaderStyle === 'white',
        }
      )}
    />
  )
}

export default CircleLoader
