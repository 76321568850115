import { assign, createMachine } from 'xstate'
import { useMachine } from '@xstate/react'

function createThrottleMachine<ValueType>(initialValue: ValueType) {
  return createMachine(
    {
      tsTypes: {} as import('./general.typegen').Typegen0,
      schema: {
        context: {} as {
          changedSinceWaiting: boolean
          throttledValue: ValueType | undefined
          value: ValueType
        },
        events: {} as { type: 'SET_VALUE'; value: ValueType },
      },
      id: 'throttleMachine',
      predictableActionArguments: true,
      context: {
        changedSinceWaiting: false,
        throttledValue: undefined,
        value: initialValue,
      },
      initial: 'idle',
      states: {
        idle: {
          on: {
            SET_VALUE: {
              actions: ['setValue', 'setThrottled'],
              target: 'waitingToSet',
            },
          },
        },
        waitingToSet: {
          after: {
            SET_DELAY: 'checkingPostDelay',
          },
          on: {
            SET_VALUE: { actions: ['setChangedSinceWaiting', 'setValue'] },
          },
        },
        checkingPostDelay: {
          exit: ['unsetChangedSinceWaiting', 'setThrottled'],
          always: [
            {
              cond: 'hasChangedSinceWaiting',
              target: 'waitingToSet',
            },
            {
              target: 'idle',
            },
          ],
        },
      },
    },
    {
      actions: {
        setChangedSinceWaiting: assign({
          changedSinceWaiting: true,
        }),
        setThrottled: assign({
          throttledValue: (context) => context.value,
        }),
        setValue: assign({
          value: (_, event) => event.value,
        }),
        unsetChangedSinceWaiting: assign({
          changedSinceWaiting: false,
        }),
      },
      guards: {
        hasChangedSinceWaiting: (context) => context.changedSinceWaiting,
      },
    }
  )
}

export function useThrottle<ValueType>(value: ValueType, ms: number) {
  const [state, send] = useMachine(
    () => createThrottleMachine<ValueType>(value),
    {
      delays: { SET_DELAY: ms },
    }
  )

  function setValue(value: ValueType) {
    send({ type: 'SET_VALUE', value })
  }

  return {
    setValue,
    throttledValue: state.context.throttledValue,
    value: state.context.value,
  }
}
