import Login from '../auth/Login'

const HomePage = (): JSX.Element => {
  return (
    <div>
      <Login />
    </div>
  )
}

export default HomePage
