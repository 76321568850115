import { ButtonHTMLAttributes } from 'react'
import { clsx } from 'clsx'

type ButtonSize = 'fluid' | 'large'
type ButtonStyle = 'cancel' | 'grey' | 'red' | 'stroke'

export type Props = Omit<
  ButtonHTMLAttributes<HTMLButtonElement>,
  'disabled' | 'className'
> & {
  buttonStyle?: ButtonStyle
  disabled?: boolean
  size: ButtonSize
}

const COLOR_CLASS_MAP: { [key in ButtonStyle]: string } = {
  cancel: 'text-sm font-semibold uppercase tracking-widest',
  grey: 'bg-grey-903 text-black-900 hover:bg-grey-901 active:bg-grey-901 disabled:bg-grey-903 disabled:text-white-900 disabled:opacity-60 transition-colors',
  red: 'bg-red-901 text-white-900 hover:bg-red-902 active:bg-red-902 disabled:bg-grey-901 disabled:opacity-60 transition-colors',
  stroke:
    'bg-white-900 text-black-901 border border-black-900 hover:bg-black-900 hover:text-white-900 active:bg-black-900 active:text-white-900 disabled:bg-black-900 disabled:opacity-60 disabled:text-white-900 transition-colors',
}

const HEIGHT_MAP: { [key in ButtonSize]: string } = {
  fluid: 'w-full h-full',
  large: 'h-10 px-4',
}

const Button = ({
  buttonStyle = 'red',
  size,
  type = 'button',
  ...rest
}: Props): JSX.Element => {
  return (
    <button
      className={clsx(
        'flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium uppercase tracking-widest',
        COLOR_CLASS_MAP[buttonStyle],
        HEIGHT_MAP[size]
      )}
      type={type}
      {...rest}
    />
  )
}

export default Button
