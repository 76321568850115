import { MenuProductDetailsJSON } from '@tovala/browser-apis-cdn'
import { ListingAdmin, MenuProduct } from '@tovala/browser-apis-combinedapi'
import { isEqual } from 'lodash-es'
import { formatCentsToDollars } from 'utils/currency'
import { DATE_FORMATS, formatDate } from 'utils/dates'
import { isArray } from 'xstate/lib/utils'

export type MenuProductFormData = {
  allergens: { value: string }[]
  ingredients: string
  nutritionalInfo: {
    key: string
    name: string
    unit: string
    value: string
  }[]
  prepSteps: { value: string }[]
  story: string
  subtitle: string
  title: string
}

export type ListingFormData = {
  listings: {
    expirationDate: string
    listingID: string
    priceCents: { label: string; value: string } | undefined
  }[]
}

export type MenuProductTermListingsFormData = MenuProductFormData &
  ListingFormData

const PRICE_AMOUNTS = [
  ...Array.from({ length: 15 }, (_value, index) => index * 100 + 99),
  2699,
]
export const PRICE_OPTIONS = PRICE_AMOUNTS.map((amount) => ({
  label: `${formatCentsToDollars(amount)}`,
  value: `${amount}`,
}))

export function getInitialMenuProductFormData(
  menuProduct?: MenuProduct,
  menuProductDetails?: MenuProductDetailsJSON
): MenuProductFormData {
  return {
    allergens:
      menuProductDetails?.allergens.map((allergen) => ({ value: allergen })) ??
      [],
    ingredients: menuProductDetails?.ingredients ?? '',
    nutritionalInfo:
      menuProductDetails?.nutritionalInfo &&
      menuProductDetails.nutritionalInfo.length > 0
        ? menuProductDetails.nutritionalInfo.map((info) => {
            return {
              key: info.key,
              name: info.name,
              unit: info.unit,
              value: info.value,
            }
          })
        : [],
    prepSteps:
      menuProductDetails?.prepSteps.map((prepStep) => ({ value: prepStep })) ??
      [],
    story: menuProductDetails?.story ?? '',
    subtitle: menuProductDetails?.subtitle ?? '',
    title: menuProduct?.title ?? '',
  }
}

export function getInitalListingFormData(
  listings?: ListingAdmin[]
): ListingFormData {
  return {
    listings: isArray(listings)
      ? listings.map((listing) => {
          return {
            expirationDate: listing.expirationDate
              ? formatDate(listing.expirationDate, {
                  format: DATE_FORMATS.API_DATE_FORMAT,
                })
              : '',
            listingID: listing.id,
            priceCents:
              PRICE_OPTIONS.find(
                ({ value }) => value === listing.priceCents.toString()
              ) ?? undefined,
          }
        })
      : [],
  }
}

export function getIsMenuProductModified({
  formData,
  menuProduct,
  menuProductDetails,
}: {
  formData: MenuProductTermListingsFormData
  menuProduct: MenuProduct
  menuProductDetails: MenuProductDetailsJSON | undefined
}) {
  const menuProductForm = {
    allergens: formData.allergens.map((allergen) => allergen.value),
    ingredients: formData.ingredients,
    nutritionalInfo: formData.nutritionalInfo,
    prepSteps: formData.prepSteps.map((prepStep) => prepStep.value),
    story: formData.story,
    subtitle: formData.subtitle,
    title: formData.title,
  }

  const menuProductSaved = {
    allergens: menuProductDetails?.allergens,
    ingredients: menuProductDetails?.ingredients,
    nutritionalInfo: menuProductDetails?.nutritionalInfo,
    prepSteps: menuProductDetails?.prepSteps,
    story: menuProductDetails?.story,
    subtitle: menuProductDetails?.subtitle,
    title: menuProduct.title,
  }

  return !isEqual(menuProductForm, menuProductSaved)
}
