import { TovalaProduct } from '@tovala/browser-apis-combinedapi'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { getTovalaProducts } from 'services/combinedAPI/products'
import { minutesToMs } from 'utils/general'

export function useGetTovalaProducts(
  opts?: Omit<
    UseQueryOptions<TovalaProduct[], Error>,
    'queryFn' | 'queryKey' | 'staleTime'
  >
) {
  return useQuery<TovalaProduct[], Error>({
    ...opts,
    queryFn: () => {
      return getTovalaProducts()
    },
    queryKey: ['tovala-products'],
    staleTime: minutesToMs(30),
  })
}
