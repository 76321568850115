import {
  Modal,
  ModalHeader,
  FormGroup,
  Input,
  Textarea,
  Button,
} from '@tovala/component-library'
import { useForm, Controller } from 'react-hook-form'
import { hasSpecialCharacters } from 'utils/general'
import ImageUpload, { ImageFormData } from '../ImageUpload'

interface BackgroundImageHeaderFormData {
  image: ImageFormData
  subtitle: string
  subtitleColor: string
  title: string
  titleColor: string
}

const BackgroundImageHeaderDialog = ({
  initialValues,
  onClose,
  onSave,
}: {
  initialValues: BackgroundImageHeaderFormData | undefined
  onClose(): void
  onSave(data: BackgroundImageHeaderFormData): void
}) => {
  const { control, formState, handleSubmit, register, setValue, watch } =
    useForm<BackgroundImageHeaderFormData>({
      defaultValues: initialValues,
    })

  const image = watch('image')

  return (
    <Modal onCloseModal={onClose}>
      <ModalHeader onClickClose={onClose}>Background Image Header</ModalHeader>
      <form onSubmit={handleSubmit(onSave)}>
        <div className="p-6 font-sans-new">
          <div className="w-[500px] space-y-4">
            <div className="grid grid-cols-[1fr_120px] gap-4">
              <FormGroup label="Image">
                <Controller
                  control={control}
                  name="image"
                  render={({ field }) => (
                    <ImageUpload
                      error={formState.errors.image?.message}
                      hasError={!!formState.errors.image}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      onImageAdded={(data) => {
                        setValue('image', data, { shouldValidate: true })
                      }}
                    />
                  )}
                  rules={{
                    required:
                      initialValues?.image.url === undefined
                        ? 'Please provide an image.'
                        : false,
                    validate: {
                      noSpecialCharacters: (value) => {
                        if (value && typeof value.filename === 'string') {
                          return (
                            !hasSpecialCharacters(value.filename) ||
                            'Please rename this image without special characters and try again.'
                          )
                        }
                        return true
                      },
                    },
                  }}
                />
              </FormGroup>
              <FormGroup label="Preview">
                <img src={image?.src} />
              </FormGroup>

              <FormGroup error={formState.errors.title?.message} label="Title">
                <Input
                  hasError={!!formState.errors.title}
                  type="text"
                  {...register('title', { required: 'Please enter a title' })}
                />
              </FormGroup>
              <FormGroup label="Title Color">
                <Input type="color" {...register('titleColor')} />
              </FormGroup>
              <FormGroup label="Subtitle">
                <Textarea rows={4} {...register('subtitle')} />
              </FormGroup>
              <FormGroup label="Subtitle Color">
                <Input type="color" {...register('subtitleColor')} />
              </FormGroup>
            </div>
          </div>

          <div className="mt-8 flex flex-row-reverse gap-4">
            <Button size="large" type="submit">
              Save
            </Button>
            <Button
              buttonStyle="stroke"
              onClick={() => {
                onClose()
              }}
              size="large"
            >
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default BackgroundImageHeaderDialog
