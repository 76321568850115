import { MealSummary } from '@tovala/browser-apis-combinedapi'
import {
  MealImage,
  MealImageSoldOut,
  QuantityStepper,
} from '@tovala/component-library'
import MenuItem from './MenuItem'
import { getMealPriceString } from './helpers'

const BLACK_SHEET_TRAY_TAG_ID = 58

const MealsGrid = ({
  canDecrement,
  canIncrement,
  selectionsMealIDs,
  mealSummaries,
  onDecrement,
  onIncrement,
}: {
  canDecrement: boolean
  canIncrement: boolean
  selectionsMealIDs: number[]
  mealSummaries: MealSummary[]
  onDecrement(mealID: number): void
  onIncrement(mealID: number): void
}) => {
  return (
    <div className="grid grid-cols-3 gap-x-6 gap-y-8">
      {mealSummaries.map((meal) => {
        const {
          id,
          imageURL,
          isSoldOut,
          subtitle,
          surchargeCents,
          tags,
          title,
          totalPriceCents,
        } = meal

        const mealTags: string[] = []
        const isBST = tags.some((tag) => tag.id === BLACK_SHEET_TRAY_TAG_ID)
        if (isBST) {
          mealTags.push('Requires Sheet Tray')
        }

        const quantity = selectionsMealIDs.filter((mealID) => {
          return mealID === meal.id
        }).length

        return (
          <div key={id}>
            <MenuItem
              image={
                <MealImage
                  cover={
                    isSoldOut ? <MealImageSoldOut textSize="small" /> : null
                  }
                  image={{ url: imageURL }}
                />
              }
              stepper={
                !isSoldOut || quantity > 0 ? (
                  <QuantityStepper
                    disabledDecrement={!canDecrement}
                    disabledIncrement={!canIncrement}
                    onClickDecrement={() => {
                      onDecrement(meal.id)
                    }}
                    onClickIncrement={() => {
                      onIncrement(meal.id)
                    }}
                    quantity={quantity}
                    size="medium"
                  />
                ) : null
              }
              subtitle={subtitle}
              surcharge={getMealPriceString({
                surchargeCents,
                totalPriceCents,
              })}
              tags={mealTags}
              title={title}
            />
          </div>
        )
      })}
    </div>
  )
}

export default MealsGrid
