import { FormEvent, useState } from 'react'
import {
  OrderHistoryReceipt,
  TermStatus,
  UserV1,
} from '@tovala/browser-apis-combinedapi'

import { updateUTOShipCycle } from '../../actions/user'

import { DATE_FORMATS, formatDate } from 'utils/dates'
import { useAppDispatch } from 'hooks'
import Button from 'components/common/Button'
import Input from 'components/common/Input'
import Modal, { ModalBody, ModalHeader } from 'components/modals/Modal'

export interface Props {
  onCloseModal(): void
  receipt: OrderHistoryReceipt
  termStatus: TermStatus
  user: UserV1
}

const UpdateUTOCycleModal = ({
  onCloseModal,
  receipt,
  termStatus,
  user,
}: Props) => {
  const dispatch = useAppDispatch()

  const [notes, setNotes] = useState('')

  const handleSubmit = (
    e: FormEvent<HTMLFormElement>,
    userID: number,
    orderID: string,
    shipCycle: number
  ) => {
    e.preventDefault()

    const payload = {
      shipCycle,
      notes,
    }
    dispatch(updateUTOShipCycle(userID, orderID, payload))
    onCloseModal()
  }

  const deliveryDate = receipt.deliveryDate
  const otherAvailableSubterm = termStatus?.subTerms.find(
    (subTerm) => subTerm.deliveryDate !== deliveryDate
  )

  return (
    <Modal onCloseModal={onCloseModal}>
      <ModalBody>
        <ModalHeader onClickClose={onCloseModal}>
          Update Term #{receipt.termID} Delivery Day
        </ModalHeader>
        <div className="w-[500px] space-y-4">
          <div>
            <p>{user.info.name}</p>
            <p>{user.info.email}</p>
          </div>

          <div>
            <p className="font-bold">Current delivery day</p>
            <p>
              {formatDate(new Date(deliveryDate), {
                format: DATE_FORMATS.DOW_MONTH_DAY,
              })}
            </p>
          </div>

          {otherAvailableSubterm && (
            <form
              className="space-y-4"
              onSubmit={(e) =>
                handleSubmit(
                  e,
                  user.id,
                  receipt.userTermOrderID,
                  otherAvailableSubterm.shipPeriod
                )
              }
            >
              <div>
                <p className="font-bold">Alternate delivery day available</p>
                <p>
                  {formatDate(new Date(otherAvailableSubterm.deliveryDate), {
                    format: DATE_FORMATS.DOW_MONTH_DAY,
                  })}
                </p>
              </div>

              <p>
                Changing the delivery day will apply to this order only. Change
                the user&apos;s delivery day on the Meal Plan + Oven Order tab
                to change their default delivery day for future orders.
              </p>

              <div>
                <p className="font-bold">Notes*</p>
                <Input
                  onChange={(e) => {
                    setNotes(e.target.value)
                  }}
                  required
                  type="text"
                  value={notes}
                />
              </div>

              <Button size="large" type="submit">
                Change delivery day to{' '}
                {formatDate(new Date(otherAvailableSubterm.deliveryDate), {
                  format: DATE_FORMATS.DOW_MONTH_DAY,
                })}
              </Button>
            </form>
          )}
        </div>
      </ModalBody>
    </Modal>
  )
}

export default UpdateUTOCycleModal
