import {
  ChangeEvent,
  InputHTMLAttributes,
  ReactNode,
  SelectHTMLAttributes,
  TextareaHTMLAttributes,
  useRef,
  useState,
} from 'react'
import { compact, orderBy, uniqBy } from 'lodash-es'
import {
  Field,
  FieldArray,
  FieldArrayRenderProps,
  FieldInputProps,
  Form,
  Formik,
  FormikProps,
} from 'formik'
import {
  MealAdmin,
  MealTagList,
  Term,
  useInvalidateTerms,
  useNextTerm,
} from '@tovala/browser-apis-combinedapi'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

import {
  addMeal,
  addMealTagtoMeal,
  addMenuMeal,
  deleteMenuMeal,
  getMealInfo,
  updateMeal,
  updateMenuMeal,
} from '../../actions/meals'
import {
  getInitialMealFormData,
  MealFormData,
  TermMenuWithFacilityNetwork,
} from './helpers'
import { showSuccessNotification } from '../../actions/notifications'
import { DATE_FORMATS, formatDate } from 'utils/dates'
import { formatCentsToDollars } from 'utils/currency'

import { useAllTerms } from 'hooks/allTerms'
import { useAppDispatch } from 'hooks'
import AlertInline from 'components/common/AlertInline'
import Button from 'components/common/Button'
import ConfirmationModal from 'components/modals/ConfirmationModal'
import FormLabel from 'components/common/FormLabel'
import H3 from 'components/common/H3'
import ImportMealData, { Props as ImportMealDataProps } from './ImportMealData'
import Input, { INPUT_CLASSES } from 'components/common/Input'
import Select from 'components/common/Select'
import SelectInput from 'components/common/SelectInput'
import Table, { TableRow, TBody, TD, TH, THead } from 'components/common/Table'
import Textarea from 'components/common/Textarea'

const SURCHARGE_AMOUNTS = [
  ...Array.from({ length: 15 }, (_value, index) => index * 100 + 99),
  ...Array.from([16, 18, 20, 22, 24, 26, 28, 30], (value) => value * 100 + 99),
  ...Array.from(
    [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
    (value) => value * 100 + 98
  ),
]
const SURCHARGE_OPTIONS = SURCHARGE_AMOUNTS.map((amount) => ({
  label: `${formatCentsToDollars(amount)}`,
  value: `${amount}`,
}))
const CHEFS = [
  'Alexander',
  'Chrissy',
  'Emily',
  'Erik',
  'Jake',
  'Mauricio',
  'Guest Chef',
  'Christine Ha',
]
const productionCodeOptions = Array.from({ length: 99 }, (_value, index) => {
  const code = ((index + 1) * 100).toString()
  return { label: code, value: code }
})

const RenderNutritionalInfo = ({
  arrayHelpers,
  readOnly,
  values,
}: {
  arrayHelpers: FieldArrayRenderProps
  readOnly?: boolean
  values: MealFormData
}): JSX.Element => {
  return (
    <div className="space-y-4">
      <H3>Nutritional Info</H3>
      <p>
        <strong>Note:</strong> For key, use &quot;golden&quot; for featured
        nutritional info (i.e. calories, protein, carbs and fat)
      </p>
      {!readOnly && (
        <Button
          buttonStyle="grey"
          onClick={() => {
            const emptyNutritionalItem: MealFormData['nutritionalInfo'][number] =
              {
                dailyValuePercentage: '',
                key: '',
                name: '',
                unit: '',
                value: '',
              }

            arrayHelpers.push(emptyNutritionalItem)
          }}
          size="large"
        >
          + Add Item
        </Button>
      )}

      {values.nutritionalInfo && values.nutritionalInfo.length > 0 && (
        <table>
          <thead>
            <tr className="border-b border-grey-900">
              <td className="w-[8%] p-2 align-bottom text-sm uppercase tracking-widest">
                Item
              </td>
              <td className="w-[13%] p-2 align-bottom text-sm uppercase tracking-widest">
                Key
              </td>
              <td className="w-1/4 p-2 align-bottom text-sm uppercase tracking-widest">
                Name
              </td>
              <td className="w-[13%] p-2 align-bottom text-sm uppercase tracking-widest">
                Value
              </td>
              <td className="w-[13%] p-2 align-bottom text-sm uppercase tracking-widest">
                Unit
              </td>
              <td className="w-[13%] p-2 align-bottom text-sm uppercase tracking-widest">
                Daily Value Percentage
              </td>
              <td className="w-[13%] p-2"></td>
            </tr>
          </thead>
          <tbody>
            {values.nutritionalInfo.map((nutritionalInfo, index) => (
              <tr
                key={nutritionalInfo.id}
                className="border-b border-grey-900 hover:bg-grey-903"
              >
                <td className="p-2">#{index + 1}</td>
                <td className="p-2">
                  <Field
                    component={InputInternal}
                    disabled={readOnly}
                    name={`nutritionalInfo.${index}.key`}
                    type="text"
                  />
                </td>
                <td className="p-2">
                  <Field
                    component={InputInternal}
                    disabled={readOnly}
                    name={`nutritionalInfo.${index}.name`}
                    type="text"
                  />
                </td>
                <td className="p-2">
                  <Field
                    component={InputInternal}
                    disabled={readOnly}
                    name={`nutritionalInfo.${index}.value`}
                    type="text"
                  />
                </td>
                <td className="p-2">
                  <Field
                    component={InputInternal}
                    disabled={readOnly}
                    name={`nutritionalInfo.${index}.unit`}
                    type="text"
                  />
                </td>
                <td className="p-2">
                  <Field
                    component={InputInternal}
                    disabled={readOnly}
                    name={`nutritionalInfo.${index}.dailyValuePercentage`}
                    type="text"
                  />
                </td>
                <td className="p-2 px-4">
                  {!readOnly && (
                    <button
                      className="text-sm uppercase tracking-widest text-red-901 hover:underline"
                      onClick={() => arrayHelpers.remove(index)}
                      type="button"
                    >
                      Remove
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  )
}

const InputInternal = ({
  field,
  label,
  type = 'text',
  ...rest
}: InputHTMLAttributes<HTMLInputElement> & {
  field: FieldInputProps<string>
  label?: string
}): JSX.Element => {
  return (
    <div>
      {label && <FormLabel>{label}</FormLabel>}
      <Input type={type} {...field} {...rest} />
    </div>
  )
}

const TextareaInternal = ({
  field,
  label,
  ...rest
}: TextareaHTMLAttributes<HTMLTextAreaElement> & {
  field: FieldInputProps<string>
  label?: string
}): JSX.Element => {
  return (
    <div>
      {label && <FormLabel>{label}</FormLabel>}
      <Textarea rows={5} {...field} {...rest} />
    </div>
  )
}

const SelectInternal = ({
  children,
  field,
  label,
  ...props
}: SelectHTMLAttributes<HTMLSelectElement> & {
  children: ReactNode
  field: FieldInputProps<string>
  label?: string
}): JSX.Element => {
  return (
    <div>
      {label && <FormLabel>{label}</FormLabel>}
      <Select {...field} {...props}>
        {children}
      </Select>
    </div>
  )
}

const MealForm = ({
  allMealTags,
  children,
  form,
  meal,
  mealid,
  readOnly,
  selectedTerm,
}: {
  allMealTags?: MealTagList[]
  children?: ReactNode
  form: 'addMeal' | 'updateMeal'
  hasMoreTermsAvailable?: boolean
  isLoadingTerms?: boolean
  loadMoreTerms?(): void
  meal?: MealAdmin
  mealid?: string
  readOnly?: boolean
  selectedTerm?: Term
  terms?: Term[]
}): JSX.Element => {
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const [tagsToAdd, setTagsToAdd] = useState<MealTagList[] | ''>('')

  const { data: presentTerm } = useNextTerm()

  const { invalidateTerm } = useInvalidateTerms()

  const formRef = useRef<FormikProps<MealFormData> | null>(null)

  const updateMenuMeals = async (
    menus: MealFormData['menus'],
    mealID: string
  ) => {
    for (const menuMeal of menus) {
      const updatedMenuMeal = {
        menuID: menuMeal.menuID,
        mealID: Number.parseInt(mealID, 10),
        productionCode: menuMeal.productionCode ? menuMeal.productionCode : '',
        mainDisplayOrder: menuMeal.mainDisplayOrder,
        // Will address when moment is completely replaced with date-fns
        // eslint-disable-next-line import/no-named-as-default-member
        expirationDate: moment
          // eslint-disable-next-line import/no-named-as-default-member
          .utc(menuMeal.expirationDate, moment.ISO_8601)
          .toISOString(),
      }
      await dispatch(updateMenuMeal(menuMeal.menuID, mealID, updatedMenuMeal))
    }
  }

  const handleSubmit = (values: MealFormData) => {
    const { menus, termid, ...rest } = { ...values }

    if (form === 'addMeal') {
      dispatch(addMeal(rest)).then((response) => {
        if (response && response.payload) {
          dispatch(
            showSuccessNotification(`Boom! ${response.payload.title} added.`)
          )
          navigate(`/meals/${response.payload.id}`)
        }
      })
    }

    if (form === 'updateMeal' && mealid) {
      rest.nutritionalInfo.forEach((nutritionalInfo) => {
        delete nutritionalInfo.id
      })

      dispatch(updateMeal(mealid, { ...rest, termid })).then((response) => {
        if (response && response.payload) {
          dispatch(
            showSuccessNotification(
              `Success! ${response.payload.title} updated.`
            )
          )

          const termID = response.payload.termid
          if (termID !== null) {
            invalidateTerm(termID)
          }

          window.scrollTo(0, 0)

          if (mealid && tagsToAdd) {
            tagsToAdd.forEach((tag) => {
              if (tag.id) {
                dispatch(addMealTagtoMeal(mealid, tag.id))
              }
            })
            setTagsToAdd('')
          }

          if (menus && mealid && Number(termid) > 0) {
            updateMenuMeals(menus, mealid).then(() => {
              dispatch(getMealInfo(mealid))
            })
          }
        }
      })
    }
  }

  const handleChefChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const chefName = e.target.value.toLowerCase()
    const chefImage = `https://cdn.tovala.com/tovala.com/chefs/${chefName
      .split(' ')
      .join('_')}.jpg`

    formRef.current?.setFieldValue('created_by', chefName)
    formRef.current?.setFieldValue('chef_image', chefImage)
  }

  const storeTagsToAdd = (tags: string[]) => {
    const VEGETARIAN_TAG_ID = 41
    const GLUTEN_FREE_TAG_ID = 42

    const existingMealTags = meal?.tags ?? []
    const tagsToAdd = tags
      .filter((tagTitle) => !existingMealTags.some((t) => t.title === tagTitle))
      .map((tagTitle) => {
        let tagDetails: MealTagList | '' | undefined = ''

        if (allMealTags) {
          // Use the menu_category versions of the vegetarian and gluten free tags
          if (tagTitle.toLowerCase() === 'vegetarian') {
            tagDetails = allMealTags.find((t) => t.id === VEGETARIAN_TAG_ID)
          } else if (tagTitle.toLowerCase().includes('gluten')) {
            tagDetails = allMealTags.find((t) => t.id === GLUTEN_FREE_TAG_ID)
          } else {
            tagDetails = allMealTags.find(
              (t) => t.title.toLowerCase() === tagTitle.toLowerCase()
            )
          }
        }

        return tagDetails
      })

    setTagsToAdd(compact(tagsToAdd))
  }

  const handleMealDataCSVImport: ImportMealDataProps['handleImport'] = (
    mealData
  ) => {
    if (mealData.tagsToAdd) {
      storeTagsToAdd(mealData.tagsToAdd)
    }

    formRef.current?.setValues(mealData.mealFormData)
  }

  let allMenus: TermMenuWithFacilityNetwork[] | '' = ''

  if (meal && meal.termid) {
    if (selectedTerm && selectedTerm.subTerms) {
      allMenus = [...selectedTerm.subTerms]
        .sort(
          (a, b) =>
            a.facilityNetwork.localeCompare(b.facilityNetwork) ||
            a.shipPeriod - b.shipPeriod
        )
        .map((subTerm) => {
          return subTerm.menus.map((menu) =>
            Object.assign({}, menu, {
              facilityNetwork: subTerm.facilityNetwork,
              shipPeriod: subTerm.shipPeriod,
            })
          )
        })
        .flat()
        .filter((menu) => menu !== undefined)
    }
  }

  return (
    <div className="flex space-x-8">
      <div className="w-9/12">
        <Formik<MealFormData>
          enableReinitialize
          initialValues={getInitialMealFormData(meal)}
          innerRef={formRef}
          onSubmit={handleSubmit}
        >
          {({ values }) => {
            return (
              <Form className="space-y-8">
                <div className="space-y-2">
                  <Field
                    component={InputInternal}
                    disabled={readOnly}
                    label="Title"
                    name="title"
                  />

                  <Field
                    component={InputInternal}
                    disabled={readOnly}
                    label="Subtitle"
                    name="subtitle"
                  />

                  <Field
                    component={InputInternal}
                    disabled={readOnly}
                    label="Short Subtitle"
                    name="shortSubtitle"
                  />

                  <Field
                    component={TextareaInternal}
                    disabled={readOnly}
                    label="Story"
                    name="story"
                  />

                  {meal && (
                    <div>
                      <p className="mb-1 mt-3">Base Price</p>
                      <p className="mb-2">
                        ${(meal.basePriceCents / 100).toFixed(2)}
                      </p>
                    </div>
                  )}

                  <Field
                    component={SelectInternal}
                    disabled={readOnly}
                    label="Surcharge"
                    name="surchargeCents"
                  >
                    <option value="">Select...</option>
                    {SURCHARGE_OPTIONS.map((surcharge) => (
                      <option key={surcharge.value} value={surcharge.value}>
                        {surcharge.label}
                      </option>
                    ))}
                  </Field>

                  <Field
                    component={TextareaInternal}
                    disabled={readOnly}
                    label="Ingredients"
                    name="ingredients"
                  />

                  <Field
                    component={TextareaInternal}
                    disabled={readOnly}
                    label="Meal Prep Steps"
                    name="mealPrepSteps"
                  />

                  <Field
                    component={SelectInternal}
                    disabled={readOnly}
                    label="Created By Chef"
                    name="created_by"
                    onChange={handleChefChange}
                  >
                    <option>Select a chef</option>
                    {CHEFS.map((chef) => (
                      <option key={chef} value={chef.toLowerCase()}>
                        {chef}
                      </option>
                    ))}
                  </Field>

                  <Field
                    component={InputInternal}
                    disabled={true}
                    label="Chef Image"
                    name="chef_image"
                  />

                  <Field
                    component={InputInternal}
                    disabled={readOnly}
                    label="Customer Quote"
                    name="customer_quote"
                  />

                  <Field
                    component={InputInternal}
                    disabled={readOnly}
                    label="Customer Quote By (First Name Last Initial)"
                    name="customer_quote_by"
                  />
                </div>

                {form === 'updateMeal' && (
                  <div>
                    <FieldArray
                      name="nutritionalInfo"
                      render={(arrayHelpers) => (
                        <RenderNutritionalInfo
                          arrayHelpers={arrayHelpers}
                          readOnly={readOnly}
                          values={values}
                        />
                      )}
                    />
                  </div>
                )}

                {form === 'updateMeal' && meal && presentTerm && (
                  <TermSelect
                    presentTerm={presentTerm}
                    readOnly={readOnly}
                    selectedTerm={selectedTerm}
                  />
                )}

                {meal && allMenus && (
                  <Menus
                    allMenus={allMenus}
                    meal={meal}
                    readOnly={readOnly}
                    values={values}
                  />
                )}

                {tagsToAdd && tagsToAdd.length > 0 && (
                  <div className="mt-10 border border-black-901 p-5">
                    <div className="flex justify-between space-x-4">
                      <p className="font-bold">
                        The following meal tags will be added on save. If you do
                        not want to add these tags, click cancel before saving.
                      </p>
                      <Button
                        buttonStyle="grey"
                        onClick={() => {
                          setTagsToAdd('')
                        }}
                        size="large"
                      >
                        Cancel
                      </Button>
                    </div>
                    {tagsToAdd.map((tag) => (
                      <p key={tag.id}>
                        {tag.title} (Tag ID #{tag.id})
                      </p>
                    ))}
                  </div>
                )}

                {!readOnly && (
                  <div className="mt-4 grid grid-cols-1 pb-20">
                    <Button size="large" type="submit">
                      Save
                    </Button>
                  </div>
                )}
              </Form>
            )
          }}
        </Formik>
      </div>
      <div className="w-3/12">
        {form === 'updateMeal' && meal && (
          <div>
            {selectedTerm && (
              <div className="mt-4">
                <p className="font-bold">CSV Import</p>

                <ImportMealData
                  existingMealData={meal}
                  handleImport={handleMealDataCSVImport}
                  term={selectedTerm}
                />
              </div>
            )}
            <div className="mt-4">{children}</div>
          </div>
        )}
      </div>
    </div>
  )
}

export default MealForm

const Menu = ({
  arrayHelpers,
  meal,
  menu,
  menusIndex,
  readOnly,
}: {
  arrayHelpers: FieldArrayRenderProps
  meal: MealAdmin
  menu: TermMenuWithFacilityNetwork
  menusIndex: number
  readOnly?: boolean
}): JSX.Element => {
  const dispatch = useAppDispatch()

  const [showAddConfirmationModal, setShowAddConfirmationModal] =
    useState(false)
  const [showRemoveConfirmationModal, setShowRemoveConfirmationModal] =
    useState(false)

  const mealIsOnMenu = menusIndex >= 0
  const name = menu.name ?? `${menu.facilityNetwork}-${menu.shipPeriod}`

  const addToMenu = () => {
    const data: MealFormData['menus'][number] = {
      expirationDate: null,
      mainDisplayOrder: 0,

      menuID: menu.id,
      productionCode: '',
    }

    dispatch(
      addMenuMeal(menu.id, {
        ...data,
        mealID: meal.id,
      })
    )
    arrayHelpers.push(data)
    setShowAddConfirmationModal(false)
  }

  const removeFromMenu = () => {
    dispatch(deleteMenuMeal(menu.id, meal.id))
    arrayHelpers.remove(menusIndex)
    setShowRemoveConfirmationModal(false)
  }

  return (
    <TableRow key={menu.id}>
      <TD width="w-[25%]">{name}</TD>

      <TD width="w-[30%]">
        {mealIsOnMenu && (
          <SelectInput
            label=""
            name={`menus[${menusIndex}].productionCode`}
            options={productionCodeOptions}
            readOnly={readOnly}
          />
        )}
      </TD>
      <TD width="w-[30%]">
        {mealIsOnMenu && (
          <Field
            className={INPUT_CLASSES}
            disabled={readOnly}
            name={`menus[${menusIndex}].expirationDate`}
            type="date"
          />
        )}
      </TD>

      <TD width="w-[15%]">
        {mealIsOnMenu ? (
          <button
            className="text-sm uppercase tracking-widest text-red-901 hover:underline"
            onClick={() => setShowRemoveConfirmationModal(true)}
            type="button"
          >
            Remove
          </button>
        ) : (
          <button
            className="text-sm uppercase tracking-widest text-red-901 hover:underline"
            onClick={() => setShowAddConfirmationModal(true)}
            type="button"
          >
            Add
          </button>
        )}
        <ConfirmationModal
          buttonText="Yes, Add to Menu"
          handleClick={addToMenu}
          heading="Add Meal to Menu"
          isOpen={showAddConfirmationModal}
          onCloseModal={() => {
            setShowAddConfirmationModal(false)
          }}
        >
          <div>
            Are you sure you want to add this meal to menu <b>{name}</b>?
          </div>
        </ConfirmationModal>

        <ConfirmationModal
          buttonText="Yes, Remove from Menu"
          handleClick={removeFromMenu}
          heading="Remove Meal from Menu"
          isOpen={showRemoveConfirmationModal}
          onCloseModal={() => {
            setShowRemoveConfirmationModal(false)
          }}
        >
          <div>
            Are you sure you want to remove this meal from menu <b>{name}</b>?
          </div>
        </ConfirmationModal>
      </TD>
    </TableRow>
  )
}

const Menus = ({
  allMenus,
  meal,
  readOnly,
  values,
}: {
  allMenus: TermMenuWithFacilityNetwork[]
  meal: MealAdmin
  readOnly?: boolean
  values: MealFormData
}): JSX.Element => {
  return (
    <div>
      <H3>Menus</H3>
      <FieldArray
        name="menus"
        render={(arrayHelpers) => {
          if (!allMenus.length) {
            return (
              <AlertInline alertStyle="danger">
                No menus found for this term. Please contact #help-backend.
              </AlertInline>
            )
          }

          return (
            <>
              <div>
                <Table>
                  <THead>
                    <TableRow>
                      <TH>Menu</TH>
                      <TH>Production Meal Code</TH>
                      <TH>Expiration Date</TH>
                      <TH></TH>
                    </TableRow>
                  </THead>
                  <TBody>
                    {allMenus.map((menu) => {
                      const menusIndex = values.menus
                        ? values.menus.findIndex((m) => m.menuID === menu.id)
                        : -1

                      return (
                        <Menu
                          key={menu.id}
                          arrayHelpers={arrayHelpers}
                          meal={meal}
                          menu={menu}
                          menusIndex={menusIndex}
                          readOnly={readOnly}
                        />
                      )
                    })}
                  </TBody>
                </Table>
              </div>
            </>
          )
        }}
      />
    </div>
  )
}

const TermSelect = ({
  presentTerm,
  readOnly,
  selectedTerm,
}: {
  presentTerm: Term
  readOnly: boolean | undefined
  selectedTerm: Term | undefined
}) => {
  const initialTermIDs = Array.from(
    { length: 5 },
    (_i, index) => index + 1 + presentTerm.id
  ).reverse()

  const { termsResponse: futureTermsResponse } = useAllTerms({
    opts: { initialTermIDs },
  })

  const futureTerms = futureTermsResponse
    .map((res) => res.data)
    .filter((term): term is Term => !!term)

  const {
    hasMoreTermsAvailable,
    loadMoreTerms,
    termsResponse: pastTermsResponse,
  } = useAllTerms({
    opts: {
      startTermID: presentTerm.id - 1,
    },
  })
  const pastTerms = pastTermsResponse.map((res) => res.data)
  const isLoadingTerms = pastTermsResponse.some((res) => res.isLoading)

  const terms = orderBy(
    uniqBy(
      [...futureTerms, presentTerm, ...pastTerms, selectedTerm].filter(
        (term): term is Term => !!term
      ),
      'id'
    ),
    'id',
    'desc'
  )

  return (
    <div>
      <Field
        component={SelectInternal}
        disabled={readOnly}
        label="Term"
        name="termid"
      >
        <option value="">Choose term</option>
        {terms.map((term) => {
          const isLive = term.ready_for_view && term.id >= presentTerm.id
          const isPastTerm = term.id < presentTerm.id

          return (
            <option key={term.id} value={term.id}>
              Term #{term.id}{' '}
              {formatDate(term.start, {
                format: DATE_FORMATS.DOW_MONTH_ABBR_DAY_YEAR,
              })}{' '}
              &mdash;{' '}
              {formatDate(term.end, {
                format: DATE_FORMATS.DOW_MONTH_ABBR_DAY_YEAR,
              })}
              &nbsp; {isLive ? ' (Live)' : isPastTerm ? '(Past)' : null}
            </option>
          )
        })}
        <option value="0">Remove meal from term</option>
      </Field>
      {hasMoreTermsAvailable && loadMoreTerms && (
        <div className="mt-1 flex justify-end">
          {isLoadingTerms ? (
            <div className="text-sm">Loading terms...</div>
          ) : (
            <button
              className="text-sm hover:underline"
              onClick={() => {
                loadMoreTerms()
              }}
              type="button"
            >
              Not seeing the term you want? Load more terms.
            </button>
          )}
        </div>
      )}
    </div>
  )
}
