import { Cookable, RoutineIntent } from '@tovala/browser-apis-combinedapi'

import { get } from './baseAPI'

export type OvenModel = 'airvala' | 'gen2'

export interface GetIntentRoutineByOvenModel {
  ovenModel: OvenModel
  routineBarcode: string
}

export type GetIntentRoutineResponse = Cookable<RoutineIntent>

export const ENDPOINTS = {
  GET_INTENT_ROUTINE_BY_OVEN_MODEL: ({
    ovenModel,
    routineBarcode,
  }: Pick<GetIntentRoutineByOvenModel, 'ovenModel' | 'routineBarcode'>) => ({
    path: `/ovenModels/${ovenModel}/intentRoutines/${routineBarcode}`,
    version: 'v1' as const,
  }),
}

export async function getIntentRoutineByOvenModel({
  ovenModel,
  routineBarcode,
}: GetIntentRoutineByOvenModel): Promise<GetIntentRoutineResponse> {
  return get(
    ENDPOINTS.GET_INTENT_ROUTINE_BY_OVEN_MODEL({ ovenModel, routineBarcode })
  )
}
