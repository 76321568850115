import { createReducer } from '@reduxjs/toolkit'
import { User, UserV1SearchResult } from '@tovala/browser-apis-combinedapi'

import * as types from '../actions/types'
import { SearchTerm } from 'types/internal'

const INITIAL_STATE: {
  defaultSearch: User[]
  pageNumber: number
  searchParam: string
  searchTerm: SearchTerm
  userSearch: UserV1SearchResult[]
} = {
  defaultSearch: [],
  pageNumber: 1,
  searchParam: '',
  searchTerm: 'nameOrEmail',
  userSearch: [],
}

export const userSearch = createReducer(INITIAL_STATE, {
  [types.GET_DEFAULT_SEARCH]: (state, action) => {
    state.defaultSearch = action.payload
  },
  [types.GET_SEARCH_RESULTS]: (state, action) => {
    state.userSearch = action.payload
  },
  [types.SET_PAGE_NUMBER]: (state, action) => {
    state.pageNumber = action.payload
  },
  [types.SET_SEARCH_TERM]: (state, action) => {
    state.searchTerm = action.payload
  },
  [types.SET_SEARCH_PARAM]: (state, action) => {
    state.searchParam = action.payload
  },
})
