import {
  DndContext,
  MouseSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { MealAdmin, TermSubTerm } from '@tovala/browser-apis-combinedapi'
import {
  MenuComponentsStandardized,
  MenuComponentStandardized,
  MenuComponentStandardizedBackgroundImageHeader,
  MenuComponentStandardizedMealWithExtra,
  MenuComponentStandardizedTextImageStack,
  MenuComponentStandardizedTextImageStackChildren,
} from '@tovala/browser-apis-menu-components'
import { Button, ButtonLoading } from '@tovala/component-library'
import { useMachine } from '@xstate/react'
import { clsx } from 'clsx'
import {
  compact,
  findIndex,
  flatMap,
  forEach,
  isEmpty,
  isEqual,
} from 'lodash-es'
import { Fragment, useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { v4 as uuidV4 } from 'uuid'

import TabGroup, {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
} from 'components/common/TabGroup'

import {
  uploadImage,
  useDefaultMenus,
  useSetDefaultMenu,
} from '@tovala/browser-apis-menu-delivery'
import ToggleButton from 'components/common/ToggleButton'
import { useToast } from 'contexts/toast'
import { isAxiosResponseError } from 'utils/api'
import ActiveComponentDragOverlay from './ComponentDragOverlay'
import BackgroundImageHeaderDialog from './dialogs/BackgroundImageHeaderDialog'
import CopyComponentDialog from './dialogs/CopyComponentDialog'
import DeleteMenuDialog from './dialogs/DeleteMenuDialog'
import EditAdditionalMealWithExtraDialog from './dialogs/EditAdditionalMealWithExtraDialog'
import MealWithExtraDialog from './dialogs/MealWithExtraDialog'
import TextImageStackDialog from './dialogs/TextImageStackDialog'
import DraggableNewComponent, {
  DraggableNewComponentProps,
} from './DraggableNewComponent'
import {
  useMenuComponentsForSubterm,
  useOrderedSubTerms,
  useTermResolvedMenuComponents,
} from './hooks'
import BackgroundImageHeaderButton from './menuComponents/BackgroundImageHeaderButton'
import TextImageStackButton from './menuComponents/TextImageStackButton'
import MenuComponentsGrid from './MenuComponentsGrid'
import { placeholderMachine } from './placeholderMachine'
import {
  getMealImageURL,
  isMealCarouselComponent,
  isMealComponent,
  isMealWithExtraComponent,
  isTwoMealPickerComponent,
  ViewType,
} from './utils'
import { downloadJSON, makeCDNComponents } from './utils-cdn'
import { makeMDSComponents } from './utils-mds'

type DefaultMenusStatus = Record<
  string,
  'error' | 'loading' | 'not-found' | 'saved' | undefined
>

const MenuEditor = () => {
  const { termID: termIDParam } = useParams<{ termID: string }>()
  const termID = Number.isNaN(Number(termIDParam))
    ? undefined
    : Number(termIDParam)

  const { orderedSubTerms, term } = useOrderedSubTerms({ termID })

  const termDefaultMenusResponse = useDefaultMenus({
    subTermIDs: orderedSubTerms.map((subTerm) => subTerm.id),
  })

  const termDefaultMenus = termDefaultMenusResponse.map((res, index) => {
    return res.data ?? { components: [], subTermID: orderedSubTerms[index].id }
  })

  const defaultMenusStatus: DefaultMenusStatus = {}
  orderedSubTerms.forEach((subTerm, index) => {
    const { isLoading, isError, error, data } = termDefaultMenusResponse[index]
    const isNotFound =
      isAxiosResponseError(error) && error.status && error.status === 404

    defaultMenusStatus[subTerm.id] = isLoading
      ? 'loading'
      : isNotFound
      ? 'not-found'
      : isError
      ? 'error'
      : data
      ? 'saved'
      : undefined
  })

  const { termResolvedMenuComponents } = useTermResolvedMenuComponents({
    orderedSubTerms,
    termDefaultMenus,
    termSpecialEvent: term?.special_event,
  })

  return termResolvedMenuComponents ? (
    <Menus
      defaultMenusStatus={defaultMenusStatus}
      orderedSubTerms={orderedSubTerms}
      termResolvedMenuComponents={termResolvedMenuComponents}
    />
  ) : null
}

export default MenuEditor

const Menus = ({
  defaultMenusStatus,
  orderedSubTerms,
  termResolvedMenuComponents,
}: {
  defaultMenusStatus: DefaultMenusStatus
  orderedSubTerms: TermSubTerm[]
  termResolvedMenuComponents: MenuComponentsStandardized[]
}) => {
  const { openToast } = useToast()

  const [placeholderState, placeholderSend] = useMachine(placeholderMachine)
  const onClearPlaceholder = useCallback(() => {
    placeholderSend({ type: 'clearPlaceholder' })
  }, [placeholderSend])
  const onInsertPlaceholder = useCallback(
    (index: number) => {
      placeholderSend({ index, type: 'setPlaceholder' })
    },
    [placeholderSend]
  )

  const {
    addComponent,
    components,
    onChangeSelectedSubTermIndex,
    removeComponent,
    reorderComponents,
    selectedComponents,
    selectedSubTerm,
    selectedSubTermIndex,
    deleteAllOrderedComponents,
    deleteOrderedComponents,
    updateComponent,
  } = useMenuComponentsForSubterm({
    orderedSubTerms,
    placeholderIndex: placeholderState.context.placeholderIndex,
    termResolvedMenuComponents,
  })

  const { mutate: setDefaultMenu, isLoading: isSavingDefaultMenu } =
    useSetDefaultMenu()

  const [dragOverlayAddComponentType, setDragOverlayAddComponentType] =
    useState<DraggableNewComponentProps['componentType'] | null>(null)
  const [activeID, setActiveID] = useState<UniqueIdentifier | null>(null)
  const getIndex = (id: UniqueIdentifier) =>
    findIndex(selectedComponents, (component) => component.id === id)
  const activeIndex = activeID ? getIndex(activeID) : -1
  const activeComponent = selectedComponents[activeIndex]

  const sensors = useSensors(
    useSensor(MouseSensor, {
      // Require the mouse to move by 10 pixels before activating.
      // Slight distance prevents sortable logic messing with
      // interactive elements in the component.
      activationConstraint: {
        distance: 10,
      },
    })
  )

  function onClickDownload() {
    orderedSubTerms.forEach((subTerm) => {
      const componentsForSubTerm = components[subTerm.id]
      const cdnComponents = makeCDNComponents(
        componentsForSubTerm.filter(
          (component): component is MenuComponentStandardized =>
            component.type !== 'dropPlaceholder'
        )
      )

      downloadJSON({
        filename: `components_${subTerm.id}.json`,
        json: {
          termID: subTerm.termID,
          subTermID: subTerm.id,
          components: cdnComponents,
        },
      })
    })
  }

  const [dialog, setDialog] = useState<
    | {
        componentType: DraggableNewComponentProps['componentType']
        data?: {
          component?: MenuComponentsStandardized[number]
          index?: number
        }
        type: 'componentEdit'
      }
    | {
        data: { component: MenuComponentsStandardized[number]; index: number }
        type: 'componentCopy'
      }
    | {
        data: {
          components: Record<string, MenuComponentStandardizedMealWithExtra[]>
          meal: MealAdmin
        }
        type: 'additionalMealWithExtraEdit'
      }
    | {
        data: {
          menu: string
          subTermID: string
        }
        type: 'deleteMenu'
      }
    | null
  >(null)
  const [viewType, setViewType] = useState<ViewType>('desktop')

  const componentToEdit =
    dialog?.type === 'componentEdit' ? dialog.data?.component : undefined

  return (
    <DndContext
      onDragEnd={({ active, over }) => {
        setActiveID(null)
        setDragOverlayAddComponentType(null)

        if (over) {
          const overIndex = getIndex(over.id)
          if (activeIndex !== overIndex) {
            reorderComponents(activeIndex, overIndex)
          }
        }

        if (active.data.current?.type === 'newComponent') {
          // TODO update type definition
          setDialog({
            componentType: active.data.current?.componentType,
            type: 'componentEdit',
          })
        }
      }}
      onDragOver={({ active, over }) => {
        if (!over) {
          return
        }

        if (active.data.current?.type === 'newComponent') {
          const overIndex = getIndex(over.id)
          if (overIndex !== -1) {
            onInsertPlaceholder(overIndex)
          }
        }
      }}
      onDragStart={({ active }) => {
        if (!active) {
          return
        }

        setActiveID(active.id)

        if (active.data.current?.type === 'newComponent') {
          setDragOverlayAddComponentType(active.data.current?.componentType)
        }
      }}
      sensors={sensors}
    >
      {selectedSubTerm ? (
        <div>
          <TabGroup
            onChange={onChangeSelectedSubTermIndex}
            selectedIndex={selectedSubTermIndex}
          >
            <div className="grid h-full grid-cols-[350px_auto] divide-x divide-grey-3">
              <div className="pr-16 space-y-8">
                <div className="space-y-4">
                  <h1 className="text-k/28_130">
                    Term #{selectedSubTerm.termID} Menus
                  </h1>

                  <ButtonLoading
                    buttonStyle="dark"
                    isLoading={isSavingDefaultMenu}
                    onClick={() => {
                      orderedSubTerms.forEach((subTerm) => {
                        const subTermComponents = components[subTerm.id]
                        const mdsComponents = makeMDSComponents({
                          components: subTermComponents.filter(
                            (
                              component
                            ): component is MenuComponentStandardized =>
                              component.type !== 'dropPlaceholder'
                          ),
                        })
                        setDefaultMenu(
                          {
                            subTermID: subTerm.id,
                            data: mdsComponents,
                          },
                          {
                            onSuccess: () => {
                              openToast({
                                heading: 'Menus Saved',
                                message: 'Success! All menus saved.',
                                type: 'success',
                              })

                              deleteAllOrderedComponents()
                            },
                          }
                        )
                      })
                    }}
                    size="medium"
                  >
                    Save All Menus
                  </ButtonLoading>

                  <TabList tabStyle="cards">
                    {orderedSubTerms.map(
                      (
                        { facilityNetwork, shipPeriod, id: subTermID },
                        index
                      ) => {
                        const content = `${facilityNetwork} ${shipPeriod}`

                        const isModified = !isEqual(
                          termResolvedMenuComponents[index],
                          components[subTermID]
                        )

                        const hasDefaultMenu =
                          defaultMenusStatus[subTermID] === 'saved'

                        return (
                          <div key={content}>
                            <Tab tabStyle="cards">
                              <div className="flex justify-between">
                                <div>
                                  <div
                                    className={clsx('text-k/20_110', {
                                      uppercase: facilityNetwork === 'slc',
                                      capitalize: facilityNetwork === 'chicago',
                                    })}
                                  >
                                    {content}
                                  </div>
                                  <div className="text-body-sm text-grey-9">
                                    {isModified
                                      ? 'Unsaved changes'
                                      : !hasDefaultMenu
                                      ? 'No menu saved'
                                      : 'Saved'}
                                  </div>
                                </div>
                              </div>
                            </Tab>
                          </div>
                        )
                      }
                    )}
                  </TabList>
                </div>

                <div className="space-y-2">
                  <h2 className="text-k/28_130">Components</h2>
                  <p className="text-k/14_120 text-grey-9">
                    Drag a component into the grid to add it to a menu.
                  </p>
                </div>

                <div className="align-center flex flex-wrap">
                  <DraggableNewComponent componentType="backgroundImageHeader">
                    <BackgroundImageHeaderButton />
                  </DraggableNewComponent>

                  <DraggableNewComponent componentType="textImageStack">
                    <TextImageStackButton />
                  </DraggableNewComponent>
                </div>

                <div className="space-y-2">
                  <h2 className="text-k/28_130">Preview</h2>
                  <div className="flex">
                    <ToggleButton
                      isFirst
                      isSelected={viewType === 'desktop'}
                      onClick={() => {
                        setViewType('desktop')
                      }}
                    >
                      Desktop
                    </ToggleButton>
                    <ToggleButton
                      isSelected={viewType === 'mobile'}
                      onClick={() => {
                        setViewType('mobile')
                      }}
                    >
                      Mobile
                    </ToggleButton>
                  </div>
                </div>

                <div className="space-y-2">
                  <h2 className="text-k/28_130">Download Files</h2>
                  <Button
                    onClick={() => {
                      onClickDownload()
                    }}
                    size="medium"
                  >
                    Download Menu Components
                  </Button>
                </div>
              </div>

              <div className="flex flex-col space-y-8 pl-16">
                <TabPanels as={Fragment}>
                  <div className="h-px grow overflow-auto pb-24">
                    {orderedSubTerms.map(
                      (
                        { facilityNetwork, shipPeriod, id: subTermID },
                        index
                      ) => {
                        const isModified = !isEqual(
                          termResolvedMenuComponents[index],
                          components[subTermID]
                        )

                        const hasDefaultMenu =
                          defaultMenusStatus[subTermID] === 'saved'

                        return (
                          <TabPanel key={subTermID}>
                            <div>
                              <div className="flex items-center justify-between sticky top-0 z-10 bg-grey-0 pb-4">
                                <div>
                                  <h2
                                    className={clsx('text-k/28_130', {
                                      uppercase: facilityNetwork === 'slc',
                                      capitalize: facilityNetwork === 'chicago',
                                    })}
                                  >
                                    {facilityNetwork} {shipPeriod}
                                  </h2>
                                </div>
                                <div className="flex space-x-4">
                                  <Button
                                    buttonStyle="gray"
                                    disabled={
                                      defaultMenusStatus[subTermID] ===
                                      'not-found'
                                    }
                                    onClick={() => {
                                      setDialog({
                                        data: {
                                          menu: `${facilityNetwork} ${shipPeriod}`,
                                          subTermID: selectedSubTerm.id,
                                        },
                                        type: 'deleteMenu',
                                      })
                                    }}
                                    size="large"
                                  >
                                    Delete Menu
                                  </Button>

                                  <ButtonLoading
                                    buttonStyle="dark"
                                    disabled={hasDefaultMenu && !isModified}
                                    isLoading={isSavingDefaultMenu}
                                    onClick={() => {
                                      const subTermID = selectedSubTerm.id
                                      const mdsComponents = makeMDSComponents({
                                        components: selectedComponents.filter(
                                          (
                                            component
                                          ): component is MenuComponentStandardized =>
                                            component.type !== 'dropPlaceholder'
                                        ),
                                      })
                                      setDefaultMenu(
                                        {
                                          subTermID,
                                          data: mdsComponents,
                                        },
                                        {
                                          onSuccess: () => {
                                            openToast({
                                              heading: 'Menu Saved',
                                              message: `Success! The menu for ${facilityNetwork} ${shipPeriod} has been saved.`,
                                              type: 'success',
                                            })

                                            deleteOrderedComponents({
                                              subTermID,
                                            })
                                          },
                                        }
                                      )
                                    }}
                                    size="large"
                                  >
                                    Save Menu
                                  </ButtonLoading>
                                </div>
                              </div>
                              <MenuComponentsGrid
                                activeID={activeID}
                                components={selectedComponents}
                                onClickCopy={(data) => {
                                  setDialog({ data, type: 'componentCopy' })
                                }}
                                onClickDelete={(
                                  component: MenuComponentStandardized
                                ) => {
                                  removeComponent({
                                    component,
                                    subTermID: selectedSubTerm.id,
                                  })
                                }}
                                onClickEdit={(opts) => {
                                  setDialog({
                                    ...opts,
                                    type: 'componentEdit',
                                  })
                                }}
                                viewType={viewType}
                              />
                            </div>
                          </TabPanel>
                        )
                      }
                    )}
                  </div>
                </TabPanels>
              </div>
            </div>
          </TabGroup>

          {dialog?.type === 'componentEdit' ? (
            <>
              {dialog.componentType === 'backgroundImageHeader' ? (
                <BackgroundImageHeaderDialog
                  initialValues={
                    componentToEdit?.type === 'backgroundImageHeader'
                      ? {
                          image: {
                            contentType: undefined,
                            file: undefined,
                            filename: undefined,
                            presignedURL: undefined,
                            src: componentToEdit.properties.image.url,
                            url: componentToEdit.properties.image.url,
                          },
                          subtitle: componentToEdit.properties.subtitle,
                          subtitleColor:
                            componentToEdit.properties.subtitleColor ?? '',
                          title: componentToEdit.properties.title,
                          titleColor:
                            componentToEdit.properties.titleColor ?? '',
                        }
                      : undefined
                  }
                  onClose={() => {
                    onClearPlaceholder()
                    setDialog(null)
                  }}
                  onSave={async (data) => {
                    let hasUploadedImage = !!(
                      componentToEdit?.type === 'backgroundImageHeader' &&
                      componentToEdit.properties.image.url
                    )

                    if (
                      data.image.presignedURL &&
                      data.image.file &&
                      data.image.contentType
                    ) {
                      try {
                        const res = await uploadImage({
                          contentType: data.image.contentType,
                          data: data.image.file,
                          presignedURL: data.image.presignedURL,
                        })

                        if (res) {
                          hasUploadedImage = true
                        }
                      } catch (error) {
                        openToast({
                          heading: 'Unable to upload image',
                          message: 'Please check the image file and try again.',
                          type: 'error',
                        })
                      }
                    }

                    if (hasUploadedImage && data.image.url) {
                      const component: MenuComponentStandardizedBackgroundImageHeader =
                        {
                          id: uuidV4(),
                          properties: {
                            image: {
                              url: data.image.url,
                            },
                            subtitle: data.subtitle,
                            subtitleColor: data.subtitleColor,
                            title: data.title,
                            titleColor: data.titleColor,
                          },
                          type: 'backgroundImageHeader',
                        }

                      if (dialog.data?.component) {
                        updateComponent({
                          component: {
                            ...component,
                            id: dialog.data.component.id,
                          },
                          subTermID: selectedSubTerm.id,
                        })
                      } else {
                        addComponent({
                          component,
                          index: dialog.data?.index,
                          subTermID: selectedSubTerm.id,
                        })
                      }

                      onClearPlaceholder()
                      setDialog(null)
                    } else {
                      openToast({
                        heading: 'Missing image data',
                        message: 'Please check the image file and try again.',
                        type: 'error',
                      })
                    }
                  }}
                />
              ) : dialog.componentType === 'textImageStack' ? (
                <TextImageStackDialog
                  components={selectedComponents}
                  initialValues={
                    componentToEdit?.type === 'textImageStack'
                      ? {
                          associatedComponentIDs: new Set(
                            componentToEdit.properties.children.map(
                              ({ id }) => id
                            )
                          ),
                          image: componentToEdit.properties.image
                            ? {
                                contentType: undefined,
                                file: undefined,
                                filename: undefined,
                                presignedURL: undefined,
                                src: componentToEdit.properties.image.url,
                                url: componentToEdit.properties.image.url,
                              }
                            : undefined,
                          subtitle: componentToEdit.properties.subtitle,
                          title: componentToEdit.properties.title,
                        }
                      : { associatedComponentIDs: new Set(), title: '' }
                  }
                  onClose={() => {
                    onClearPlaceholder()
                    setDialog(null)
                  }}
                  onSave={async (data) => {
                    let isImageUploadRequired = false
                    if (data.image && data.image.presignedURL) {
                      isImageUploadRequired = true

                      if (!data.image.contentType || !data.image.file) {
                        openToast({
                          heading: 'Unable to upload image',
                          message: 'Please check the image file and try again.',
                          type: 'error',
                        })

                        return
                      }

                      try {
                        const res = await uploadImage({
                          contentType: data.image.contentType,
                          data: data.image.file,
                          presignedURL: data.image.presignedURL,
                        })

                        if (res) {
                          isImageUploadRequired = false
                        }
                      } catch (error) {
                        isImageUploadRequired = true

                        openToast({
                          heading: 'Unable to upload image',
                          message: 'Please check the image file and try again.',
                          type: 'error',
                        })
                      }
                    }

                    if (!isImageUploadRequired) {
                      let previousChildren: MenuComponentStandardizedTextImageStackChildren =
                        []

                      if (
                        dialog.data?.component &&
                        dialog.data.component.type === 'textImageStack'
                      ) {
                        previousChildren = [
                          ...dialog.data.component.properties.children,
                        ]
                      }

                      const component: MenuComponentStandardizedTextImageStack =
                        {
                          id: uuidV4(),
                          properties: {
                            children: compact(
                              Array.from(data.associatedComponentIDs).map(
                                (componentID) => {
                                  const component = [
                                    ...previousChildren,
                                    ...selectedComponents,
                                  ].find(
                                    (component) => component.id === componentID
                                  )

                                  if (
                                    isMealComponent(component) ||
                                    isTwoMealPickerComponent(component) ||
                                    isMealCarouselComponent(component) ||
                                    isMealWithExtraComponent(component)
                                  ) {
                                    return component
                                  }
                                }
                              )
                            ),
                            image: data.image
                              ? {
                                  url: data.image.src,
                                }
                              : undefined,
                            subtitle: data.subtitle,
                            title: data.title,
                          },
                          type: 'textImageStack',
                        }

                      if (
                        dialog.data?.component &&
                        dialog.data.component.type === 'textImageStack'
                      ) {
                        updateComponent({
                          component: {
                            ...component,
                            id: dialog.data.component.id,
                          },
                          previousChildren:
                            dialog.data.component.properties.children,
                          subTermID: selectedSubTerm.id,
                        })
                      } else {
                        addComponent({
                          component,
                          index: dialog.data?.index,
                          subTermID: selectedSubTerm.id,
                        })
                      }

                      onClearPlaceholder()
                      setDialog(null)
                    } else {
                      openToast({
                        heading: 'Missing image data',
                        message: 'Please check the image file and try again.',
                        type: 'error',
                      })
                    }
                  }}
                />
              ) : dialog.componentType === 'mealWithExtra' ? (
                <MealWithExtraDialog
                  initialValues={
                    componentToEdit?.type === 'mealWithExtra'
                      ? {
                          detailsMealID:
                            componentToEdit.properties.mealOption.detailsMealID,
                        }
                      : undefined
                  }
                  onClose={() => {
                    setDialog(null)
                  }}
                  onSave={(data, meal) => {
                    if (
                      dialog.data?.component &&
                      dialog.data.component.type === 'mealWithExtra' &&
                      meal
                    ) {
                      const componentID = dialog.data.component.id

                      const url = getMealImageURL(meal)

                      const component: MenuComponentStandardizedMealWithExtra =
                        {
                          ...dialog.data.component,
                          properties: {
                            ...dialog.data.component.properties,
                            mealOption: {
                              ...dialog.data.component.properties.mealOption,
                              detailsMealID: data.detailsMealID,
                              meal: {
                                ...dialog.data.component.properties.mealOption
                                  .meal,
                                image: {
                                  url,
                                },
                              },
                            },
                          },
                        }

                      updateComponent({
                        component,
                        subTermID: selectedSubTerm.id,
                      })

                      // Find other mealWithExtra components that have the same extra and
                      // haven't already been updated with the termless meal ID that was selected
                      // so we can prompt the user to update the other matching extras
                      const componentsWithMatchingExtra: Record<
                        string,
                        MenuComponentStandardizedMealWithExtra[]
                      > = {}

                      if (meal) {
                        orderedSubTerms.forEach((subTerm) => {
                          componentsWithMatchingExtra[subTerm.id] = components[
                            subTerm.id
                          ]
                            .filter((component) => {
                              if (
                                component.type === 'mealWithExtra' &&
                                component.id !== componentID &&
                                component.properties.mealOption
                                  .detailsMealID !== meal.id &&
                                component.properties.mealOption.meal.mealSummary.shortSubtitle.includes(
                                  meal.title
                                )
                              ) {
                                return component
                              }
                            })
                            .filter(
                              (
                                component
                              ): component is MenuComponentStandardizedMealWithExtra =>
                                !!component
                            )
                        })
                      }

                      if (
                        !isEmpty(componentsWithMatchingExtra) &&
                        flatMap(
                          componentsWithMatchingExtra,
                          (components) => components
                        ).length > 0
                      ) {
                        setDialog({
                          data: {
                            components: componentsWithMatchingExtra,
                            meal,
                          },
                          type: 'additionalMealWithExtraEdit',
                        })
                      } else {
                        setDialog(null)
                      }
                    }
                  }}
                />
              ) : null}
            </>
          ) : dialog?.type === 'componentCopy' ? (
            <CopyComponentDialog
              onClose={() => {
                setDialog(null)
              }}
              onSave={(subTermIDs) => {
                subTermIDs.forEach((subTermID) => {
                  addComponent({
                    component: { ...dialog.data.component, id: uuidV4() },
                    index: dialog.data.index,
                    subTermID,
                  })
                })
                setDialog(null)
              }}
              subTerms={orderedSubTerms.filter(
                (subTerm) => subTerm.id !== selectedSubTerm.id
              )}
            />
          ) : dialog?.type === 'additionalMealWithExtraEdit' ? (
            <EditAdditionalMealWithExtraDialog
              components={dialog.data.components}
              meal={dialog.data.meal}
              onClose={() => {
                setDialog(null)
              }}
              onSave={(components, meal) => {
                const url = getMealImageURL(meal)

                forEach(components, (componentsForSubTerm, subTermID) => {
                  forEach(componentsForSubTerm, (component) => {
                    const updatedComponent: MenuComponentStandardizedMealWithExtra =
                      {
                        ...component,
                        properties: {
                          ...component.properties,
                          mealOption: {
                            ...component.properties.mealOption,
                            detailsMealID: meal.id,
                            meal: {
                              ...component.properties.mealOption.meal,
                              image: {
                                url,
                              },
                            },
                          },
                        },
                      }

                    console.log({ updatedComponent })

                    updateComponent({
                      component: updatedComponent,
                      subTermID,
                    })
                  })
                })

                setDialog(null)
              }}
              orderedSubTerms={orderedSubTerms}
            />
          ) : dialog?.type === 'deleteMenu' ? (
            <DeleteMenuDialog
              data={dialog.data}
              onClose={() => {
                setDialog(null)
              }}
              onDelete={() => {
                openToast({
                  heading: 'Menu Deleted',
                  message: `The menu for ${dialog.data.menu} has been deleted.`,
                  type: 'success',
                })

                deleteOrderedComponents({ subTermID: selectedSubTerm.id })

                setDialog(null)
              }}
            />
          ) : null}
        </div>
      ) : null}

      <ActiveComponentDragOverlay
        activeComponent={activeComponent}
        activeID={activeID}
        dragOverlayAddComponentType={dragOverlayAddComponentType}
        viewType={viewType}
      />
    </DndContext>
  )
}
