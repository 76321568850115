import { MealAdmin } from '@tovala/browser-apis-combinedapi'

export const TAG_IDS = {
  ADD_ON_MEAL: 81,
  APPETIZER_AND_ENTREE: 79,
  BEST_SELLER: 39,
  BLACK_SHEET_TRAY: 58,
  BREAKFAST: 61,
  CALORIE_SMART: 40,
  CARB_CONSCIOUS: 45,
  CHEFS_PICK: 38,
  CONTAINS_DESSERT_ADD_ON: 88,
  DUAL_SERVING: 66,
  GLUTEN_FRIENDLY: 42,
  NEW_BANNER: 86,
  NEW: 90,
  OIL_WARNING: 62,
  PREMIUM: 64,
  PROTEIN_CHOICE: 94,
  SPICY: 73,
  TWO_SERVINGS: 66,
  VEGETARIAN: 41,
}

export function getMealBarcode({
  barcodeIdentifier,
  mealID,
}: {
  barcodeIdentifier: string
  mealID: number
}) {
  // Note: The 133A254 string and the trailing pipe are needed for the back-end.
  // The 5E34BF80 string is an unused expiration date.
  // See comments on https://tovala.atlassian.net/browse/WAT-395 for more context.
  // https://tovala.atlassian.net/wiki/spaces/SOF/pages/301563905/Barcode+Schema for
  // Confluence documentation.
  return `133A254|${mealID}|5E34BF80|${barcodeIdentifier}`
}

export function getMealCameraUrl({
  barcodeIdentifier,
  mealID,
}: {
  barcodeIdentifier: string
  mealID: number
}) {
  // Note: The https://m.tvla.co/m/<meal_id>?s=<subroutineId> is needed for QR codes
  // to be picked up by the camera apps on iOS and Android.
  // See comments on https://tovala.atlassian.net/browse/WAT-746 for more context.
  // https://tovala.atlassian.net/wiki/spaces/MOBILE/pages/3091628057/Replace+QR+Payload for
  // Confluence documentation.
  return `https://m.tvla.co/m/${mealID}?s=${barcodeIdentifier}`
}

export function hasMealTag(tags: { id: number }[], tagID: number) {
  return tags.some((tag) => tag.id === tagID)
}

export function isMeal(
  meal: MealAdmin | Record<string, unknown>
): meal is MealAdmin {
  return (meal as MealAdmin).id !== undefined
}

export function makeMenuProductQRCodeValue(barcode: string, termID: number) {
  const splitBarcode = barcode.split('|')
  const shortProductID = splitBarcode[1]
  const barcodeIdentifier = splitBarcode[3]

  return {
    shortProductID,
    value: `https://m.tvla.co/p/${shortProductID}?s=${barcodeIdentifier}&t=${termID} `,
  }
}
