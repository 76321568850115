import { Button, Input } from '@tovala/component-library'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { createNewUser } from '../../actions/auth'

import { useAppDispatch } from 'hooks'

const NewUser = (): JSX.Element => {
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const [email, setEmail] = useState('')

  const createUser = () => {
    dispatch(createNewUser(email)).then((newUserID) => {
      if (newUserID) {
        navigate(`/user/${newUserID}/buy-oven`)
      }
    })
  }

  useEffect(() => {
    document.title = `Glaze | Create New User`
  }, [])

  return (
    <div className="space-y-8">
      <h1 className="text-k/36_110">Create new user</h1>
      <form
        className="w-1/4 space-y-4"
        onSubmit={(event) => {
          event.preventDefault()

          createUser()
        }}
      >
        <Input
          onChange={(e) => {
            setEmail(e.target.value)
          }}
          placeholder="Enter User's Email"
          type="email"
          value={email}
        />
        <Button size="large" type="submit">
          Create User
        </Button>
      </form>
    </div>
  )
}

export default NewUser
