import { FormEventHandler, useEffect } from 'react'
import { Field, reduxForm } from 'redux-form'
import {
  login as apiLogin,
  LoginResponse,
} from '@tovala/browser-apis-combinedapi'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

import { canAccessGlaze } from 'utils/auth'
import { errorHandler } from 'actions/notifications'

import { useAppDispatch } from 'hooks'
import { useAuth } from 'contexts/auth'
import ButtonLoading from 'components/common/ButtonLoading'
import H1 from 'components/common/H1'
import InputRF from '../common/InputRF'

interface FormData {
  email: string
  password: string
}

const form = reduxForm<FormData>({
  form: 'login',
})

const Login = ({
  handleSubmit,
}: {
  handleSubmit(onSubmit: (formData: FormData) => void): FormEventHandler
}): JSX.Element => {
  const navigate = useNavigate()
  const location = useLocation()

  const dispatch = useAppDispatch()

  const { isLoggedIn, onJWTChanged } = useAuth()

  const { isLoading: isLoggingIn, mutate: login } = useMutation<
    LoginResponse,
    Error,
    FormData
  >({
    mutationFn: (formData) => {
      return apiLogin({
        data: {
          email: formData.email,
          password: formData.password,
        },
      })
    },
    onError: (err) => {
      errorHandler(dispatch, err)
    },
    onSuccess: (data) => {
      const jwt = data.token

      // Non-employee users will be able to call the login endpoint successfully
      // but the JWT will indicate they can't log in as an admin.
      if (canAccessGlaze(jwt)) {
        onJWTChanged(jwt)

        const queryParams = new URLSearchParams(location.search)

        navigate(queryParams.get('redirectURL') || '/users')
      } else {
        errorHandler(dispatch, null, 'You are not authorized to login.')
      }
    },
  })

  useEffect(() => {
    document.title = `Glaze | Login`
  }, [])

  if (isLoggedIn) {
    return <Navigate to="/users" />
  }

  return (
    <div className="mx-auto w-1/2">
      <H1>Login</H1>

      <form onSubmit={handleSubmit(login)}>
        <div className="space-y-2">
          <Field
            component={InputRF}
            name="email"
            placeholder="Email"
            type="text"
          />
          <Field
            component={InputRF}
            name="password"
            placeholder="Password"
            type="password"
          />
        </div>

        <p className="mt-2 flex items-center justify-between">
          <a href="https://my.tovala.com/forgot-password" target="_self">
            Forgot Password?
          </a>

          <ButtonLoading isLoading={isLoggingIn} size="large" type="submit">
            Login
          </ButtonLoading>
        </p>
      </form>
    </div>
  )
}

export default form(Login)
