import { useInvalidateShipments } from '@tovala/browser-apis-combinedapi'
import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import {
  createExchangeOvenShipment,
  CreateExchangeOvenShipment,
  CreateExchangeOvenShipmentResponse,
} from 'services/combinedAPI/ovens'
import { getProductsPurchaseInformation } from 'actions/auth'

import { useAppDispatch } from 'hooks'

export function useCreateExchangeOvenShipment(
  opts?: Omit<
    UseMutationOptions<
      CreateExchangeOvenShipmentResponse,
      Error,
      CreateExchangeOvenShipment
    >,
    'mutationFn'
  >
) {
  const dispatch = useAppDispatch()

  const { invalidateShipments } = useInvalidateShipments()

  return useMutation({
    ...opts,
    mutationFn: (opts: CreateExchangeOvenShipment) => {
      return createExchangeOvenShipment(opts)
    },
    onSuccess: (...args) => {
      const { userID } = args[1]
      invalidateShipments({ userID })
      dispatch(getProductsPurchaseInformation(userID))

      opts?.onSuccess?.(...args)
    },
  })
}
