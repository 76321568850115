import { useDraggable } from '@dnd-kit/core'
import {
  MenuComponentStandardizedBackgroundImageHeader,
  MenuComponentStandardizedMealWithExtra,
  MenuComponentStandardizedTextImageStack,
} from '@tovala/browser-apis-menu-components'
import { ReactNode } from 'react'

export type DraggableNewComponentProps = {
  children: ReactNode
  componentType:
    | MenuComponentStandardizedBackgroundImageHeader['type']
    | MenuComponentStandardizedMealWithExtra['type']
    | MenuComponentStandardizedTextImageStack['type']
}

const DraggableNewComponent = ({
  children,
  componentType,
}: DraggableNewComponentProps) => {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: componentType,
    data: {
      componentType,
      type: 'newComponent',
    },
  })
  return (
    <div ref={setNodeRef} {...attributes} {...listeners}>
      {children}
    </div>
  )
}
export default DraggableNewComponent
