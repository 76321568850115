import { FormEvent, useState } from 'react'
import jwtDecode from 'jwt-decode'
import {
  JWTPayload,
  OrderHistoryReceipt,
  UserV1,
  useInvalidateReceipts,
} from '@tovala/browser-apis-combinedapi'
import ReactSelect from 'react-select'

import { getCookie } from 'utils/storage'
import { MEAL_CASH_REFUND_REASON_OPTIONS } from 'utils/dropdowns'
import { ReactSelectValue } from 'types/internal'
import { refundOrder } from '../../actions/auth'

import { useAppDispatch } from 'hooks'
import Button from 'components/common/Button'
import Input from 'components/common/Input'
import Modal, { ModalBody, ModalHeader } from 'components/modals/Modal'
import Textarea from 'components/common/Textarea'

export interface Props {
  onCloseModal(): void
  receipt: OrderHistoryReceipt
  user: UserV1
}

// Cancel Order Calls the same API endpoint as Refund: we are still refunding the charge, just adding 'canceled' as orderStatus in DB instead of 'refunded'
const CancelOrderModal = ({
  onCloseModal,
  receipt,
  user,
}: Props): JSX.Element => {
  const dispatch = useAppDispatch()

  const { invalidateOrderHistoryReceipts } = useInvalidateReceipts()

  const [notes, setNotes] = useState('')
  const [reason, setReason] = useState<ReactSelectValue<string> | null>(null)
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [zdTicketNumber, setZdTicketNumber] = useState('')

  function handleClick(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()

    if (!reason) {
      alert('Please select a reason')
    } else {
      if (submitDisabled) {
        return
      }

      setSubmitDisabled(true)

      const data: {
        csAgent?: string
        notes: string
        action: 'customer_service' | 'marketing'
        reason: string
        affectedTerm: string
        numberAffectedMeals: number
        zdTicketNumber: string
      } = {
        notes,
        action: 'customer_service',
        reason: reason.value,
        affectedTerm: receipt.termID.toString(),
        numberAffectedMeals: receipt.mealCount,
        zdTicketNumber,
      }

      if (data.reason && data.reason === 'marketing') {
        data.action = 'marketing'
      } else {
        data.action = 'customer_service'
      }

      const userCookie = getCookie('JWT_TOKEN')

      if (userCookie) {
        const decodedUserToken = jwtDecode<JWTPayload>(userCookie)
        data.csAgent = decodedUserToken.userId.toString()
      }

      e.preventDefault()
      dispatch(
        refundOrder(user.id, receipt.userTermOrderID, 'canceled', data)
      ).then(() => {
        invalidateOrderHistoryReceipts(user.id)
      })
      onCloseModal()
    }
  }

  return (
    <Modal onCloseModal={onCloseModal}>
      <ModalBody>
        <ModalHeader onClickClose={onCloseModal}>
          Cancel Order - Term #{receipt.termID}
        </ModalHeader>
        <div className="w-[500px] space-y-4">
          <div>
            <p className="font-bold">{user.info.name}</p>
            <p>{user.info.email}</p>
          </div>

          <form className="space-y-4" onSubmit={(e) => handleClick(e)}>
            <div>
              <p>
                <strong>Cancellation reason*</strong>
              </p>
              <ReactSelect
                onChange={(value) => {
                  setReason(value)
                }}
                options={MEAL_CASH_REFUND_REASON_OPTIONS}
                placeholder="Select reason"
                value={reason}
              />
            </div>

            <div>
              <p>
                <strong>ZD Ticket #</strong>
              </p>
              <Input
                onChange={(e) => {
                  setZdTicketNumber(e.target.value)
                }}
                placeholder="Enter ZD Ticket # if applicable"
                type="text"
                value={zdTicketNumber}
              />
            </div>

            <div>
              <p>
                <strong>Notes*</strong>
              </p>
              <Textarea
                onChange={(e) => {
                  setNotes(e.target.value)
                }}
                placeholder="notes here"
                required
                rows={5}
                value={notes}
              />
            </div>

            <Button disabled={submitDisabled} size="large" type="submit">
              {submitDisabled ? 'Submitting...' : 'Cancel Order'}
            </Button>
          </form>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default CancelOrderModal
