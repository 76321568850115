import {
  CouponCode,
  LeveratorAction,
  LeveratorInAppOption,
  LeveratorPayload,
} from '@tovala/browser-apis-combinedapi'
import { createReducer } from '@reduxjs/toolkit'

import * as types from '../actions/types'

const INITIAL_STATE: {
  allCouponCodes: CouponCode[]
  allLeveratorActions: LeveratorAction[]
  allLeveratorInAppOptions: LeveratorInAppOption[]
  allLeveratorPayloads: LeveratorPayload[]
  couponCode: CouponCode | ''
  sendingLeveratorPush: boolean
} = {
  allCouponCodes: [],
  couponCode: '',
  allLeveratorPayloads: [],
  allLeveratorActions: [],
  allLeveratorInAppOptions: [],
  sendingLeveratorPush: false,
}

export const marketing = createReducer(INITIAL_STATE, {
  [types.GET_ALL_COUPON_CODES]: (state, action) => {
    state.allCouponCodes = action.payload
  },
  [types.GET_COUPON_CODE]: (state, action) => {
    state.couponCode = action.payload
  },
  [types.GET_ALL_LEVERATOR_PAYLOADS]: (state, action) => {
    state.allLeveratorPayloads = action.payload
  },
  [types.GET_ALL_LEVERATOR_ACTIONS]: (state, action) => {
    state.allLeveratorActions = action.payload
  },
  [types.GET_ALL_LEVERATOR_INAPP_OPTIONS]: (state, action) => {
    state.allLeveratorInAppOptions = action.payload
  },
  [types.SENDING_LEVERATOR_PUSH]: (state, action) => {
    state.sendingLeveratorPush = action.payload
  },
})
