import { Link } from 'react-router-dom'
import { ReactNode } from 'react'

const SmallLink = (
  props:
    | { children: ReactNode; to: string }
    | { children: ReactNode; onClick(): void }
) => {
  const { children } = props
  const linkClasses =
    'text-sm font-semibold uppercase tracking-widest text-red-901'

  if ('onClick' in props) {
    return (
      <span className={`${linkClasses} cursor-pointer`} onClick={props.onClick}>
        {children}
      </span>
    )
  }

  return (
    <Link className={linkClasses} to={props.to}>
      {children}
    </Link>
  )
}

export default SmallLink
