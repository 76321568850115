import { parse, ParseResult } from 'papaparse'
import ReactSelect from 'react-select'
import { useEffect, useRef, useState } from 'react'

import {
  getAllLeveratorActions,
  getAllLeveratorInAppOptions,
  getAllLeveratorPayloads,
  pushLeveratorPayloadToUsers,
} from '../../../actions/marketing'
import { ReactSelectValue } from 'types/internal'

import { useAppDispatch, useAppSelector } from 'hooks'
import Actions from './ActionForm'
import ActionsTable from './ActionsTable'
import AlertInline from 'components/common/AlertInline'
import Button from 'components/common/Button'
import CircleLoader from 'components/common/CircleLoader'
import FileDropzone from '../../common/FileDropzone'
import H3 from 'components/common/H3'
import Hr from 'components/common/Hr'
import InAppOptions from './InAppOptions'
import PayloadForm from './PayloadForm'
import TabGroup, {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
} from 'components/common/TabGroup'
import { clsx } from 'clsx'

const LeveratorAdmin = (): JSX.Element => {
  const dispatch = useAppDispatch()

  const code = useRef('')

  const allLeveratorPayloads = useAppSelector(
    (state) => state.marketing.allLeveratorPayloads
  )

  const sendingLeveratorPush = useAppSelector(
    (state) => state.marketing.sendingLeveratorPush
  )

  const [addAction, setAddAction] = useState(false)
  const [isLeveratorAdmin, setIsLeveratorAdmin] = useState(false)
  const [success, setSuccess] = useState(false)
  const [userIDs, setUserIDs] = useState<number[] | undefined>()
  const [selectedPayloadID, setSelectedPayloadID] =
    useState<ReactSelectValue<string> | null>()

  const payload = allLeveratorPayloads.find(
    (payload) => payload.id === selectedPayloadID?.value
  )

  const payloadOptions = allLeveratorPayloads.map((payload) => ({
    value: payload.id,
    label: payload.aps.alert.title,
  }))

  useEffect(() => {
    document.title = `Glaze | Leverator Admin`
  }, [])

  useEffect(() => {
    function showAdminPermissions(e: KeyboardEvent) {
      code.current += e.key

      if (
        code.current ===
        'ArrowUpArrowUpArrowDownArrowDownArrowLeftArrowRightArrowLeftArrowRight'
      ) {
        window.removeEventListener('keydown', showAdminPermissions)
        setIsLeveratorAdmin(true)
      }
    }

    window.addEventListener('keydown', showAdminPermissions)

    return () => {
      window.removeEventListener('keydown', showAdminPermissions)
    }
  }, [])

  useEffect(() => {
    dispatch(getAllLeveratorPayloads())
    dispatch(getAllLeveratorActions())
    dispatch(getAllLeveratorInAppOptions())
  }, [dispatch])

  const handleSendPush = ({
    payloadID,
    userIDs,
  }: {
    payloadID: string
    userIDs: number[]
  }) => {
    const pushData = {
      payloadID,
      userIDs,
    }

    dispatch(pushLeveratorPayloadToUsers(pushData)).then((response) => {
      if (response && response.payload) {
        window.scrollTo(0, 0)
        setSuccess(true)
      }
    })
  }

  const selectPayload = ({
    payloadID,
    title,
  }: {
    payloadID: string
    title: string
  }) => {
    if (!payloadID && !title) {
      setSelectedPayloadID(null)
    } else {
      setSelectedPayloadID({
        value: payloadID,
        label: title,
      })
    }
  }

  return (
    <div>
      {sendingLeveratorPush && (
        <div className="fixed inset-0 z-10 flex items-center justify-center bg-white-900 text-center">
          <div className="flex flex-col items-center">
            <H3>Sending!</H3>
            <p className="mb-4">Please wait, this may take several minutes.</p>
            <CircleLoader loaderStyle="colored" />
          </div>
        </div>
      )}

      <TabGroup>
        <TabList>
          <Tab>Create Push</Tab>
          <Tab>Manage Actions</Tab>
          {isLeveratorAdmin && <Tab>Manage In App Options</Tab>}
        </TabList>

        <TabPanels>
          <TabPanel>
            {success && (
              <div className="space-y-4">
                <AlertInline alertStyle="success">
                  <p className="font-bold">Success! Push notification sent.</p>
                </AlertInline>

                <div className="flex items-center space-x-4">
                  <p>
                    Want to send the same push notification again to a new list
                    of users?
                  </p>
                  <Button
                    buttonStyle="grey"
                    onClick={() => {
                      setUserIDs(undefined)
                      setSuccess(false)
                    }}
                    size="large"
                  >
                    Clear Users
                  </Button>
                </div>
                <div className="flex space-x-4">
                  <p>Want to start over?</p>
                  <Button
                    onClick={() => {
                      setSuccess(false)
                      setUserIDs(undefined)
                      setSelectedPayloadID(null)
                    }}
                    size="large"
                  >
                    Clear Form
                  </Button>
                </div>
                <Hr />
              </div>
            )}

            <div className="flex space-x-4">
              <div className="w-2/12 shrink-0">
                <div>Step 1 / 3</div>
                <p>
                  <strong>Customer User ID List</strong>
                </p>
              </div>
              <div className="w-8/12">
                <UserList
                  handleClearUsers={() => {
                    setUserIDs(undefined)
                  }}
                  setUserIDs={setUserIDs}
                  success={success}
                  userIDs={userIDs}
                />
              </div>
            </div>

            <Hr />

            <div className="flex space-x-4">
              <div className="w-2/12 shrink-0">
                <div>Step 2 / 3</div>
                <p>
                  <strong>Push Notification Content</strong>
                </p>
              </div>
              <div className="w-7/12">
                {payloadOptions && (
                  <div>
                    <p className="mb-4">
                      <strong>
                        Select from previous push notification content
                      </strong>
                    </p>
                    <ReactSelect
                      name="payload"
                      onChange={(option) => setSelectedPayloadID(option)}
                      options={payloadOptions}
                      value={selectedPayloadID}
                    />
                  </div>
                )}

                <div
                  className={clsx('mt-5 p-5', {
                    'bg-white-900': payload?.id,
                    'bg-grey-904': !payload?.id,
                  })}
                >
                  {!payload?.id && (
                    <p>
                      <strong>Or create new push notification content</strong>
                    </p>
                  )}

                  <PayloadForm
                    payload={payload}
                    selectPayload={selectPayload}
                  />
                </div>
              </div>
              <div className="w-3/12 border-l border-grey-900 pl-4">
                <p className="mb-4">
                  <strong>Example message</strong>
                </p>
                <img
                  alt=""
                  src="https://cdn.tovala.com/glaze/leverator_push_screenshot.png"
                  width="100%"
                />
              </div>
            </div>

            <Hr />

            {payload?.id && (
              <div className="flex space-x-4">
                <div className="w-2/12 shrink-0">
                  <div>Step 3 / 3</div>
                  <p>
                    <strong>Actions</strong>
                  </p>
                  <p className="text-sm">
                    Add actions in the order they should be displayed in the
                    apps.
                  </p>
                </div>

                <div className="w-10/12">
                  <Actions
                    addAction={addAction}
                    isLeveratorAdmin={isLeveratorAdmin}
                    payload={payload}
                    setAddAction={setAddAction}
                  />
                </div>
              </div>
            )}

            <div className="mt-4 flex justify-center">
              <div className="h-10 w-96">
                <Button
                  disabled={
                    !userIDs ||
                    !payload ||
                    payload.id === '' ||
                    payload.actions.length === 0 ||
                    success
                  }
                  onClick={() => {
                    if (!userIDs || !payload || !payload.id) {
                      throw new Error(
                        'Cannot send a Leverator push notification without content or user IDs'
                      )
                    }

                    handleSendPush({ payloadID: payload.id, userIDs })
                  }}
                  size="fluid"
                >
                  Send Push
                </Button>
              </div>
            </div>
          </TabPanel>

          <TabPanel>
            <ActionsTable />
          </TabPanel>

          <TabPanel>
            <InAppOptions />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </div>
  )
}

export default LeveratorAdmin

const UserList = ({
  handleClearUsers,
  setUserIDs,
  success,
  userIDs,
}: {
  handleClearUsers: () => void
  setUserIDs: (userIDs: number[]) => void
  success: boolean
  userIDs: number[] | undefined
}): JSX.Element => {
  const handleDrop = (files: File[]) => {
    parse(files[0], {
      complete: (results: ParseResult<string[]>) => {
        const userIDs = results.data
          .flat()
          .map((userID: string) => Number.parseInt(userID, 10))

        setUserIDs(userIDs)
      },
    })
  }

  if (!userIDs) {
    return (
      <FileDropzone
        accept={{ 'text/csv': ['.csv'] }}
        maxFiles={1}
        onDrop={handleDrop}
      />
    )
  }

  return (
    <div className="flex space-x-4">
      <div className="h-36 w-1/2 overflow-auto border border-grey-900 p-3">
        <p className="mb-4">
          <strong>Send to users:</strong>
        </p>
        {userIDs.map((userID) => (
          <p key={userID}>{userID}</p>
        ))}
      </div>
      <div>
        {!success && (
          <Button buttonStyle="grey" onClick={handleClearUsers} size="large">
            Clear Users
          </Button>
        )}
      </div>
    </div>
  )
}
