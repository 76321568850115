import { cloneDeep, findIndex, flatMap } from 'lodash-es'
import {
  DndContext,
  DragOverlay,
  MouseSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { ReactNode, useState } from 'react'
import {
  ErrorCodeMessageMapCombinedAPI,
  ListingAdmin,
  MenuListingsLayout,
  Term,
  TermMenu,
  useAllListingsForMenus,
  useAllMenuListingsLayouts,
  useUpdateMenuListingsLayout,
} from '@tovala/browser-apis-combinedapi'
import {
  APIErrorDisplay,
  Button,
  ButtonLoading,
  Input,
  Modal,
  ModalHeader,
} from '@tovala/component-library'

import { formatCentsToDollars } from 'utils/currency'
import { successHandler } from 'actions/notifications'
import { useAppDispatch } from 'hooks'

import { ModalBody } from 'components/modals/Modal'
import TabGroup, {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
} from 'components/common/TabGroup'

const LOAD_MENU_LISTINGS_LAYOUTS_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please reload the page to try again.',
    whatHappened: 'Unable to Load Menu Listings',
    why: "We couldn't load menu listings due to a technical issue on our end.",
  },
}

const ListingsLayoutEditor = ({ term }: { term: Term }) => {
  const orderedMenus = flatMap(
    term.subTerms.map((subTerm) => subTerm.menus.map((menu) => menu)) ?? []
  ).sort((a, b) => a.name.localeCompare(b.name))

  const menuIDs = orderedMenus.map((menu) => menu.id)

  const {
    allListings,
    hasErrorMenuListingsLayouts,
    isLoadingMenuListingsLayouts,
    loadMenuListingsLayoutsError,
    menuListingsLayouts,
  } = useMenuLayoutsData({ menuIDs })

  if (hasErrorMenuListingsLayouts) {
    return (
      <APIErrorDisplay
        display="page"
        error={loadMenuListingsLayoutsError}
        errorCodeMessageMap={LOAD_MENU_LISTINGS_LAYOUTS_ERRORS}
      />
    )
  }

  if (isLoadingMenuListingsLayouts) {
    return null
  }

  const termHasListings = menuListingsLayouts.some((menuLayout) => {
    return (
      menuLayout.sections &&
      menuLayout.sections.some((section) => section?.listings.length > 0)
    )
  })

  if (!termHasListings) {
    return <div className="font-bold">No Extras on this term.</div>
  }

  return (
    <ExtrasPreview
      allListings={allListings}
      menuListingsLayouts={menuListingsLayouts}
      orderedMenus={orderedMenus}
    />
  )
}

export default ListingsLayoutEditor

const ExtrasPreview = ({
  allListings,
  menuListingsLayouts,
  orderedMenus,
}: {
  allListings: ListingAdmin[]
  menuListingsLayouts: MenuListingsLayout[]
  orderedMenus: TermMenu[]
}) => {
  const dispatch = useAppDispatch()

  const [activeID, setActiveID] = useState<UniqueIdentifier | null>(null)
  const [showCopiedBanner, setShowCopiedBanner] = useState(false)
  const [showCopyConfirmationDialog, setShowCopyConfirmationDialog] =
    useState(false)

  const { isLoading: isUpdatingMenuLayout, mutate: updateMenuLayout } =
    useUpdateMenuListingsLayout()

  const {
    onChangeSectionTitle,
    onChangeSelectedMenuIndex,
    onCopyChicago1,
    reorderListings,
    selectedLayout,
    orderedMenuLayouts,
  } = useOrderedLayoutForMenu({
    allListings,
    menuListingsLayouts,
    orderedMenus,
  })

  const sensors = useSensors(
    useSensor(MouseSensor, {
      // Require the mouse to move by 10 pixels before activating.
      // Slight distance prevents sortable logic messing with
      // interactive elements in the component.
      activationConstraint: {
        distance: 10,
      },
    })
  )

  const selectedLayoutHasListings =
    selectedLayout &&
    selectedLayout.sections.some((section) => section?.listings.length > 0)

  const selectedListings = selectedLayoutHasListings
    ? selectedLayout.sections[0].listings
    : []

  const getIndex = (id: UniqueIdentifier) =>
    findIndex(selectedListings, (listing) => listing.id === id)
  const activeIndex = activeID ? getIndex(activeID) : -1
  const activeListing = allListings.find((listing) => listing.id === activeID)

  const chicago1MenuID = orderedMenus[0].id
  const canCopyChicago1 =
    orderedMenuLayouts[chicago1MenuID].sections &&
    orderedMenuLayouts[chicago1MenuID].sections.some(
      (section) => section?.listings.length > 0
    )

  // Will add in section titles when adding in functionality to create sections
  const enableSectionTitle = false

  const saveMenuLayouts = () => {
    for (const [menuID, menuListingsLayout] of Object.entries(
      orderedMenuLayouts
    )) {
      if (menuListingsLayout.sections.length > 0) {
        const updatedMenuListingLayout: {
          sections: {
            title: string
            listingIDs: string[]
          }[]
        } = {
          sections: [...menuListingsLayout.sections].map(
            ({ title, listings }) => {
              return {
                title,
                listingIDs: listings.map((listing) => listing.id),
              }
            }
          ),
        }

        updateMenuLayout(
          { menuID, data: updatedMenuListingLayout },
          {
            onSuccess: () => {
              successHandler(dispatch, 'Success! Extras Layouts saved.')
            },
          }
        )
      }
    }
  }

  return (
    <div className="space-y-6 mb-20">
      <div className="flex justify-between items-center">
        <h1 className="text-k/28_110">Extras Preview</h1>
        <div className="flex space-x-4">
          {canCopyChicago1 && (
            <Button
              buttonStyle="stroke"
              onClick={() => {
                setShowCopyConfirmationDialog(true)
                setShowCopiedBanner(false)
              }}
              size="medium"
            >
              Copy chicago 1
            </Button>
          )}
          <ButtonLoading
            isLoading={isUpdatingMenuLayout}
            onClick={() => {
              setShowCopiedBanner(false)
              saveMenuLayouts()
            }}
            size="medium"
          >
            Save Extras Layouts
          </ButtonLoading>
        </div>
      </div>

      {showCopiedBanner && <MenuLayoutsCopiedBanner />}
      {showCopyConfirmationDialog && (
        <MenuLayoutsCopyConfirmationDialog
          onClickClose={() => {
            setShowCopyConfirmationDialog(false)
          }}
          onClickConfirm={() => {
            onCopyChicago1()
            setShowCopiedBanner(true)
            setShowCopyConfirmationDialog(false)
          }}
        />
      )}

      <DndContext
        onDragEnd={({ over }) => {
          setActiveID(null)
          if (over) {
            const overIndex = getIndex(over.id)
            if (activeIndex !== overIndex) {
              reorderListings(activeIndex, overIndex)
            }
          }
        }}
        onDragOver={({ over }) => {
          if (!over) {
            return
          }
        }}
        onDragStart={({ active }) => {
          if (!active) {
            return
          }

          setActiveID(active.id)
        }}
        sensors={sensors}
      >
        <TabGroup onChange={(index) => onChangeSelectedMenuIndex(index)}>
          <TabList>
            {orderedMenus.map((menu) => {
              return <Tab key={menu.id}>{menu.name}</Tab>
            })}
          </TabList>

          <TabPanels>
            {orderedMenus.map((menu) => {
              return (
                <TabPanel key={menu.id}>
                  {orderedMenuLayouts[menu.id].sections.length > 0 ? (
                    <div className="space-y-8 max-w-[342px]">
                      {enableSectionTitle && (
                        <SectionTitle
                          onChangeTitle={onChangeSectionTitle}
                          sectionIndex={0}
                          title={orderedMenuLayouts[menu.id].sections[0].title}
                        />
                      )}

                      <ListingsGrid
                        activeID={activeID}
                        allListings={allListings}
                        listingIDs={orderedMenuLayouts[
                          menu.id
                        ].sections[0].listings.map((listing) => listing.id)}
                      />
                    </div>
                  ) : (
                    <p className="font-bold">No Extras on this menu.</p>
                  )}
                </TabPanel>
              )
            })}
          </TabPanels>
        </TabGroup>

        <DragOverlay>
          {activeID && activeListing ? (
            <ListingItem listing={activeListing} />
          ) : null}
        </DragOverlay>
      </DndContext>
    </div>
  )
}

const ListingsGrid = ({
  activeID,
  allListings,
  listingIDs,
}: {
  activeID: UniqueIdentifier | null
  allListings: ListingAdmin[]
  listingIDs: string[]
}) => {
  return (
    <SortableContext items={listingIDs} strategy={rectSortingStrategy}>
      <div className="grid grid-cols-2 gap-3">
        {listingIDs.map((listingID) => {
          const listing = allListings.find(
            (listing) => listing.id === listingID
          )
          return (
            <SortableComponent
              key={listingID}
              isDragging={activeID === listingID}
              listingID={listingID}
            >
              <ListingItem listing={listing} />
            </SortableComponent>
          )
        })}
      </div>
    </SortableContext>
  )
}

const ListingItem = ({ listing }: { listing: ListingAdmin | undefined }) => {
  if (!listing) {
    return null
  }

  return (
    <div>
      <div className="w-[165px] h-[165px] bg-grey-5 rounded-md">
        <img
          alt=""
          className="w-full h-full object-cover rounded-md"
          src={listing.imageURL}
        />
      </div>
      <span className="text-grey text-k/13_120">
        {formatCentsToDollars(listing.priceCents)}
      </span>
      <h3 className="text-body-sm">{listing.title}</h3>
    </div>
  )
}

const MenuLayoutsCopiedBanner = () => {
  return (
    <div className="rounded-2xl bg-green-905 bg-opacity-30 space-y-1 p-4">
      <div>
        <p className="font-bold">Copied!</p>
        <p>
          Make sure to check the other menus are ordered as expected and save
          the Extras layouts before leaving the page.
        </p>
      </div>
    </div>
  )
}

const MenuLayoutsCopyConfirmationDialog = ({
  onClickClose,
  onClickConfirm,
}: {
  onClickClose(): void
  onClickConfirm(): void
}) => {
  return (
    <Modal onCloseModal={onClickClose}>
      <ModalBody>
        <ModalHeader onClickClose={onClickClose}>
          Copy Chicago 1 Display Order to Menus
        </ModalHeader>
        <div className="max-w-[645px] w-full">
          <div className="space-y-4 p-4 pb-12">
            <p>Note:</p>
            <ul className="list-disc pl-4">
              <li>
                Any Chicago 1 products that are not available on another menu
                will be excluded from that menu's display orders.
              </li>
              <li>
                Any additional products on another menu that are not on Chicago
                1 will be added to the beginning of that menu's display order.
              </li>
            </ul>

            <p>
              Are you sure you want to copy Chicago 1 display order to the other
              available menus on this term?
            </p>
          </div>
          <div className="flex items-center justify-between">
            <Button buttonStyle="stroke" onClick={onClickClose} size="large">
              Cancel
            </Button>
            <Button onClick={onClickConfirm} size="large">
              Yes, Copy Chicago 1
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

const SectionTitle = ({
  onChangeTitle,
  sectionIndex,
  title: prevTitle,
}: {
  onChangeTitle(title: string, sectionIndex: number): void
  sectionIndex: number
  title: string | null
}) => {
  const [isEditingTitle, setIsEditingTitle] = useState(false)
  const [title, setTitle] = useState(prevTitle ?? '')

  if (isEditingTitle) {
    return (
      <div className="flex items-center space-x-4">
        <Input
          onChange={(event) => {
            setTitle(event.target.value)
          }}
          placeholder="Section Title"
          value={title}
        />
        <Button
          buttonStyle="stroke"
          onClick={() => {
            onChangeTitle(title, sectionIndex)
            setIsEditingTitle(false)
          }}
          size="small"
        >
          Save
        </Button>
      </div>
    )
  }

  if (!title) {
    return (
      <div>
        <Button
          buttonStyle="stroke"
          onClick={() => {
            setIsEditingTitle(true)
          }}
          size="small"
        >
          Add section title
        </Button>
      </div>
    )
  }

  return (
    <div className="flex items-center space-x-4">
      <h2 className="text-k/28_110">{title}</h2>
      <div>
        <Button
          buttonStyle="stroke"
          onClick={() => {
            setIsEditingTitle(true)
          }}
          size="small"
        >
          Edit
        </Button>
      </div>
    </div>
  )
}

const SortableComponent = ({
  children,
  listingID,
  isDragging,
}: {
  children: ReactNode
  listingID: string
  isDragging: boolean
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: listingID,
    })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <div
      ref={setNodeRef}
      className="h-full w-full cursor-grab"
      style={style}
      {...attributes}
      {...listeners}
    >
      {!isDragging && children}
    </div>
  )
}

function useOrderedLayoutForMenu({
  allListings,
  menuListingsLayouts,
  orderedMenus,
}: {
  allListings: ListingAdmin[]
  menuListingsLayouts: MenuListingsLayout[]
  orderedMenus: TermMenu[]
}) {
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(0)
  const selectedMenu = orderedMenus[selectedMenuIndex]

  const [orderedLayouts, setOrderedLayouts] = useState<Record<
    string,
    MenuListingsLayout
  > | null>(null)
  const orderedMenuLayouts: Record<string, MenuListingsLayout> = {}

  orderedMenus.forEach((menu, index) => {
    orderedMenuLayouts[menu.id] = orderedLayouts
      ? orderedLayouts[menu.id]
      : menuListingsLayouts[index]
  })

  const selectedLayout = selectedMenu
    ? orderedMenuLayouts[selectedMenu.id]
    : null

  return {
    orderedMenuLayouts,
    onChangeSectionTitle: (title: string, sectionIndex: number) => {
      setOrderedLayouts((prevState) => {
        let updatedLayouts = {}
        if (prevState === null) {
          orderedMenus.forEach((menu, index) => {
            updatedLayouts[menu.id] = cloneDeep(menuListingsLayouts[index])
          })
        } else {
          updatedLayouts = cloneDeep(prevState)
        }

        updatedLayouts[selectedMenu.id].sections[sectionIndex].displayTitle =
          title

        return updatedLayouts
      })
    },
    onChangeSelectedMenuIndex: (index: number) => {
      setSelectedMenuIndex(index)
    },
    onCopyChicago1: () => {
      const chicago1Index = 0
      setOrderedLayouts((prevState) => {
        let updatedLayouts = {}
        if (prevState === null) {
          orderedMenus.forEach((menu, index) => {
            if (index !== chicago1Index) {
              updatedLayouts[menu.id] = duplicateMenuLayout({
                allListings,
                originalMenuListingsLayout: menuListingsLayouts[index],
                sourceMenuListingsLayout: menuListingsLayouts[chicago1Index],
              })
            } else {
              updatedLayouts[menu.id] = cloneDeep(menuListingsLayouts[index])
            }
          })
        } else {
          updatedLayouts = cloneDeep(prevState)

          const chicago1MenuID = orderedMenus[chicago1Index].id

          orderedMenus.forEach((menu, index) => {
            if (index !== chicago1Index) {
              updatedLayouts[menu.id] = duplicateMenuLayout({
                allListings,
                originalMenuListingsLayout: updatedLayouts[menu.id],
                sourceMenuListingsLayout: updatedLayouts[chicago1MenuID],
              })
            }
          })
        }

        return updatedLayouts
      })
    },
    reorderListings: (activeIndex: number, overIndex: number) => {
      setOrderedLayouts((prevState) => {
        let updatedLayouts = {}
        if (prevState === null) {
          orderedMenus.forEach((menu, index) => {
            updatedLayouts[menu.id] = cloneDeep(menuListingsLayouts[index])
          })
        } else {
          updatedLayouts = cloneDeep(prevState)
        }

        const order = [...updatedLayouts[selectedMenu.id].sections[0].listings]

        updatedLayouts[selectedMenu.id].sections[0].listings = arrayMove(
          order,
          activeIndex,
          overIndex
        )

        return updatedLayouts
      })
    },
    selectedLayout,
    selectedMenu,
    selectedMenuIndex,
  }
}

function getProductIDForListing(
  listingID: string,
  allListings: ListingAdmin[]
) {
  return allListings.find((listing) => listing.id === listingID)?.productID
}

function duplicateMenuLayout({
  originalMenuListingsLayout,
  sourceMenuListingsLayout,
  allListings,
}: {
  originalMenuListingsLayout: MenuListingsLayout
  sourceMenuListingsLayout: MenuListingsLayout
  allListings: ListingAdmin[]
}) {
  const sourceCopy = cloneDeep(sourceMenuListingsLayout)

  const sourceProducts = sourceCopy.sections[0].listings.map(({ id }) => {
    return {
      listingID: id,
      productID: getProductIDForListing(id, allListings),
    }
  })
  const sourceProductIDs = sourceProducts
    .map((product) => product.productID)
    .filter((productID): productID is string => !!productID)

  const originalCopy = cloneDeep(originalMenuListingsLayout)

  const originalProducts = originalCopy.sections[0].listings.map(({ id }) => {
    return {
      listingID: id,
      productID: getProductIDForListing(id, allListings),
    }
  })
  const originalProductIDs = originalProducts.map(
    (product) => product.productID
  )

  const filteredSourceProducts = sourceProducts.filter((product) => {
    return originalProductIDs.includes(product.productID)
  })

  const listingsFromSourceProducts = filteredSourceProducts
    .map((product) => {
      return originalProducts.find((p) => p.productID === product.productID)
        ?.listingID
    })
    .filter((listingID): listingID is string => !!listingID)

  const remainingListings = originalProducts
    .filter((product) => {
      if (product.productID) {
        return !sourceProductIDs.includes(product.productID)
      }
    })
    .map((product) => product.listingID)
    .filter((listingID): listingID is string => !!listingID)

  const listingIDs = [...remainingListings, ...listingsFromSourceProducts]

  sourceCopy.sections[0].listings = listingIDs
    .map((listingID) => {
      return allListings.find((listing) => listing.id === listingID)
    })
    .filter((listing): listing is ListingAdmin => !!listing)

  return sourceCopy
}

const useMenuLayoutsData = ({ menuIDs }: { menuIDs: string[] }) => {
  const allMenuListingsLayoutsResponse = useAllMenuListingsLayouts({ menuIDs })
  const hasErrorMenuListingsLayouts = allMenuListingsLayoutsResponse.some(
    (res) => res.isError
  )
  const loadMenuListingsLayoutsError = hasErrorMenuListingsLayouts
    ? allMenuListingsLayoutsResponse
        .map((res) => res.error)
        .find((error) => error) ?? null
    : null
  const isLoadingMenuListingsLayouts = allMenuListingsLayoutsResponse.some(
    (res) => res.isLoading
  )
  const menuListingsLayouts = allMenuListingsLayoutsResponse
    .map((res) => res.data)
    .filter(
      (menuListingsLayout): menuListingsLayout is MenuListingsLayout =>
        !!menuListingsLayout
    )

  // Admin listings data to get product ID
  const allListingsForMenusResponse = useAllListingsForMenus({ menuIDs })
  const hasErrorListingsForMenus = allListingsForMenusResponse.some(
    (res) => res.isError
  )
  const loadListingsForMenusError = hasErrorListingsForMenus
    ? allListingsForMenusResponse
        .map((res) => res.error)
        .find((error) => error) ?? null
    : null
  const isLoadingListingsForMenus = allListingsForMenusResponse.some(
    (res) => res.isLoading
  )
  const allListings = allListingsForMenusResponse
    .map((res) => {
      return res.data?.listings
    })
    .filter((listings): listings is ListingAdmin[] => !!listings)
    .flat()

  return {
    allListings,
    hasErrorMenuListingsLayouts:
      hasErrorMenuListingsLayouts || hasErrorListingsForMenus || false,
    isLoadingMenuListingsLayouts:
      isLoadingMenuListingsLayouts || isLoadingListingsForMenus || false,
    loadMenuListingsLayoutsError:
      loadMenuListingsLayoutsError || loadListingsForMenusError || null,
    menuListingsLayouts,
  }
}
