import { Form, Formik } from 'formik'
import { OrderHistoryReceipt, UserV1 } from '@tovala/browser-apis-combinedapi'
import { useState } from 'react'

import { updateUTOShippingAddress } from '../../actions/user'

import { useAppDispatch } from 'hooks'
import Button from 'components/common/Button'
import Modal, { ModalBody, ModalHeader } from 'components/modals/Modal'
import ShippingForm, {
  FormData as ShippingFormData,
} from './ShippingFormFields'

export interface Props {
  currentShippingAddress: NonNullable<OrderHistoryReceipt['shippingAddress']>
  onCloseModal(): void
  receipt: OrderHistoryReceipt
  user: UserV1
}

const UpdateUTOShippingAddressModal = ({
  currentShippingAddress,
  onCloseModal,
  receipt,
  user,
}: Props): JSX.Element | null => {
  const dispatch = useAppDispatch()

  const [showReminder, setShowReminder] = useState(false)

  const handleSubmit = (values: ShippingFormData) => {
    dispatch(updateUTOShippingAddress(user.id, receipt.userTermOrderID, values))
    onCloseModal()
  }

  return (
    <Modal onCloseModal={onCloseModal}>
      <ModalBody>
        <ModalHeader onClickClose={onCloseModal}>
          Update Term #{receipt.termID} Shipping Address
        </ModalHeader>
        <div className="w-[800px] space-y-4">
          <div>
            <p className="font-bold">{user.info.name}</p>
            <p>{user.info.email}</p>
          </div>

          <div>
            <p className="font-bold">Current Shipping Address</p>
            <div>{currentShippingAddress.name}</div>
            <div>{currentShippingAddress.line1}</div>
            <div>{currentShippingAddress.line2}</div>
            <div>
              {currentShippingAddress.city}, {currentShippingAddress.state}{' '}
              {currentShippingAddress.zipCode}
            </div>
            <div>{currentShippingAddress.phone}</div>
          </div>

          <Formik<ShippingFormData>
            enableReinitialize
            initialValues={{
              name: currentShippingAddress.name,
              line1: '',
              line2: '',
              city: '',
              state: '',
              zipCode: '',
              phone: currentShippingAddress.phone,
              notes: '',
            }}
            onSubmit={handleSubmit}
          >
            {({ values }) => {
              return (
                <Form>
                  {!showReminder ? (
                    <div className="space-y-4">
                      <div>
                        <p className="font-bold">New Shipping Address</p>
                        <p>
                          The new shipping address will be applied to this order
                          only. Change the user&apos;s address on the Shipping
                          tab to change their address for future orders.
                        </p>
                      </div>

                      <ShippingForm showNotes />

                      <Button
                        onClick={() => {
                          setShowReminder(true)
                        }}
                        size="large"
                        type="button"
                      >
                        Update Shipping Address
                      </Button>
                    </div>
                  ) : (
                    <div>
                      <p className="font-bold">New Shipping Address</p>
                      <div>{values.name}</div>
                      <div>{values.line1}</div>
                      <div>{values.line2}</div>
                      <div>
                        {values.city}, {values.state} {values.zipCode}
                      </div>
                      <div>{values.phone}</div>

                      <h5 className="font-weight-bold mt-5">
                        Did you confirm that the new address is in the same
                        facility network as the original address?
                      </h5>

                      <div className="mt-4 flex space-x-4">
                        <Button
                          buttonStyle="grey"
                          onClick={() => {
                            setShowReminder(false)
                          }}
                          size="large"
                          type="button"
                        >
                          No, I&apos;ll do that now
                        </Button>
                        <Button size="large" type="submit">
                          Yes, Save New Shipping Address
                        </Button>
                      </div>
                    </div>
                  )}
                </Form>
              )
            }}
          </Formik>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default UpdateUTOShippingAddressModal
