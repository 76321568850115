import {
  APIErrorDisplay,
  Button,
  ButtonLoading,
  Modal,
  ModalHeader,
} from '@tovala/component-library'
import {
  type ListingAdmin,
  type MenuProduct,
  useUpdateMenuProductDetails,
  TermMenu,
  Term,
  useUpdateListing,
  ErrorCodeMessageMapCombinedAPI,
  useInvalidateListingsAdmin,
  useSlotMapGroups,
  useMenuProductSlotMapGroups,
  useUpdateMenuProductSlotMapGroups,
  SlotMapGroup,
} from '@tovala/browser-apis-combinedapi'
import { MenuProductDetailsJSON } from '@tovala/browser-apis-cdn'
import { useForm } from 'react-hook-form'

import ImportMenuProductTermListingsData from './ImportMenuProductTermListingsData'
import ListingsFormFieldset from './ListingsFormFieldset'
import MenuItemLayout from './MenuItemLayout'
import MenuProductDetails from './MenuProductDetails'
import MenuProductFormFieldset from './MenuProductFormFieldset'

import { MEALS_WRITE, getAdminScope } from 'utils/getAdminScope'

import {
  ListingFormData,
  MenuProductTermListingsFormData,
  getInitalListingFormData,
  getInitialMenuProductFormData,
  getIsMenuProductModified,
} from './helpers'
import { ReactNode, useEffect, useState } from 'react'
import { successHandler } from 'actions/notifications'
import { useAppDispatch } from 'hooks'
import SlotMapGroupsTable, {
  SlotMapGroupRow,
} from 'components/meal-tags/SlotMapGroupsTable'

const UPDATE_MENU_PRODUCT_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please wait a few minutes and try again.',
    whatHappened: 'Unable to save Product Details changes',
    why: "We couldn't save these changes due to a technical issue on our end.",
  },
}

const UPDATE_LISTING_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please wait a few minutes and try again.',
    whatHappened: 'Unable to save Menu Listings changes',
    why: "We couldn't save these changes due to a technical issue on our end.",
  },
}

const MenuProductTermListings = ({
  isLoadingMenuProductDetailsJSON,
  listings,
  menuProduct,
  menuProductDetails,
  menusWithListingIDs,
  term,
}: {
  isLoadingMenuProductDetailsJSON: boolean
  listings: ListingAdmin[]
  menuProduct: MenuProduct
  menuProductDetails: MenuProductDetailsJSON | undefined
  menusWithListingIDs: {
    listingID: string | undefined
    menu: TermMenu
  }[]
  term: Term
}) => {
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const [slotMapGroupsToAdd, setSlotMapGroupsToAdd] = useState<SlotMapGroup[]>(
    []
  )

  const dispatch = useAppDispatch()

  const {
    error: updateMenuProductError,
    isError: hasUpdateMenuProductError,
    isLoading: isUpdatingMenuProduct,
    mutate: updateMenuProduct,
  } = useUpdateMenuProductDetails()
  const {
    error: updateListingError,
    isError: hasUpdateListingError,
    isLoading: isUpdatingListing,
    mutate: updateListing,
  } = useUpdateListing()

  const { data: slotMapGroups = [] } = useSlotMapGroups()

  const { data: menuProductSlotMapGroups = [] } = useMenuProductSlotMapGroups({
    productID: menuProduct.id,
  })
  const menuProductSlotMapGroupIDs = menuProductSlotMapGroups.map(
    (slotMapGroup) => slotMapGroup.id
  )

  const { mutate: updateMenuProductSlotMapGroups } =
    useUpdateMenuProductSlotMapGroups()

  const { invalidateListings } = useInvalidateListingsAdmin()

  const initialData = {
    ...getInitialMenuProductFormData(menuProduct, menuProductDetails),
    ...getInitalListingFormData(listings),
  }

  const { control, getValues, handleSubmit, reset } =
    useForm<MenuProductTermListingsFormData>({
      defaultValues: initialData,
    })

  const handleUpdateListings = (data: ListingFormData) => {
    const { listings: listingsData } = { ...data }

    listingsData.forEach(
      ({ expirationDate, listingID, priceCents, ...rest }) => {
        const listing = listings.find((listing) => listing.id === listingID)

        updateListing(
          {
            listingID,
            data: {
              expirationDate: expirationDate || null,
              priceCents: priceCents?.value ? Number(priceCents.value) : 0,
              soldOutCount: listing?.soldOutCount ?? 0,
              ...rest,
            },
          },
          {
            onSuccess: () => {
              successHandler(dispatch, 'Success! Menu Listings saved.')
              invalidateListings()
            },
          }
        )
      }
    )
  }

  const onSubmit = (formData: MenuProductTermListingsFormData) => {
    const { allergens, listings, prepSteps, title, ...rest } = { ...formData }

    const isMenuProductModified = getIsMenuProductModified({
      formData,
      menuProduct,
      menuProductDetails,
    })

    if (isMenuProductModified) {
      updateMenuProduct(
        {
          productID: menuProduct.id,
          data: {
            data: {
              allergens: allergens.map((allergen) => allergen.value),
              componentImages: menuProductDetails?.componentImages ?? [],
              id: menuProduct.id,
              prepSteps: prepSteps.map((prepStep) => prepStep.value),
              ...rest,
            },
            title,
          },
        },
        {
          onSuccess: () => {
            successHandler(dispatch, 'Success! Product Details saved.')
            handleUpdateListings({ listings })
          },
        }
      )
    } else {
      handleUpdateListings({ listings })
    }

    if (slotMapGroupsToAdd.length > 0) {
      const slotMapGroupIDsToAdd = slotMapGroupsToAdd.map(
        (slotMapGroup) => slotMapGroup.id
      )
      const updatedSlotMapGroups = [
        ...menuProductSlotMapGroupIDs,
        ...slotMapGroupIDsToAdd,
      ]

      updateMenuProductSlotMapGroups({
        productID: menuProduct.id,
        data: updatedSlotMapGroups,
      })
    }
  }

  const onSubmitConfirmation = (formData: MenuProductTermListingsFormData) => {
    const isMenuProductModified = getIsMenuProductModified({
      formData,
      menuProduct,
      menuProductDetails,
    })

    if (isMenuProductModified) {
      setShowConfirmationDialog(true)
    } else {
      handleSubmit(onSubmit)()
    }
  }

  const disabled = !getAdminScope(MEALS_WRITE)

  useEffect(() => {
    if (!isLoadingMenuProductDetailsJSON && menuProductDetails) {
      const initialData = {
        ...getInitialMenuProductFormData(menuProduct, menuProductDetails),
        ...getInitalListingFormData(listings),
      }
      reset(initialData)
    }
  }, [
    isLoadingMenuProductDetailsJSON,
    listings,
    menuProduct,
    menuProductDetails,
    reset,
  ])

  return (
    <>
      <div className="space-y-6 mb-20">
        <h1 className="text-k/36_110">{menuProduct.title}</h1>

        <MenuProductDetails>
          <form
            className="space-y-8"
            onSubmit={handleSubmit(onSubmitConfirmation)}
          >
            <MenuItemLayout
              sidebar={
                <div className="space-y-4">
                  <p>CSV Import</p>
                  <ImportMenuProductTermListingsData
                    handleImport={({ formData, tagsToAdd }) => {
                      reset(formData, { keepDefaultValues: true })

                      setSlotMapGroupsToAdd(() => {
                        return tagsToAdd
                          .map((tag) => {
                            return slotMapGroups.find((slotMapGroup) => {
                              return (
                                slotMapGroup.associatedMealTag.toLowerCase() ===
                                tag.toLowerCase()
                              )
                            })
                          })
                          .filter(
                            (slotMapGroup): slotMapGroup is SlotMapGroup =>
                              !!slotMapGroup
                          )
                          .filter((slotMapGroup) => {
                            return !menuProductSlotMapGroupIDs.includes(
                              slotMapGroup.id
                            )
                          })
                      })
                    }}
                    initialData={initialData}
                    menusWithListingIDs={menusWithListingIDs}
                    term={term}
                  />
                </div>
              }
            >
              <div className="space-y-8">
                <div className="space-y-4">
                  <h2 className="text-k/28_110">
                    Term #{term.id} Menu Listings
                  </h2>

                  <ListingsFormFieldset
                    control={control}
                    disabled={disabled}
                    listings={listings}
                    menusWithListingIDs={menusWithListingIDs}
                  />
                </div>

                <div className="space-y-4">
                  <h2 className="text-k/28_110">Product Details</h2>

                  <MenuProductFormFieldset
                    control={control}
                    disabled={disabled}
                  />
                </div>

                {slotMapGroupsToAdd.length > 0 && (
                  <div className="border border-grey-900 p-4 space-y-6">
                    <h2 className="text-k/20_110">Tags to add on Save</h2>
                    <SlotMapGroupsTable>
                      {slotMapGroupsToAdd.map((slotMapGroup) => {
                        return (
                          <SlotMapGroupRow
                            key={slotMapGroup.id}
                            slotMapGroup={slotMapGroup}
                          >
                            <Button
                              buttonStyle="stroke"
                              onClick={() => {
                                const updatedSlotMapGroupsToAdd =
                                  slotMapGroupsToAdd.filter(
                                    (s) => s.id !== slotMapGroup.id
                                  )
                                setSlotMapGroupsToAdd(updatedSlotMapGroupsToAdd)
                              }}
                              size="small"
                            >
                              Remove
                            </Button>
                          </SlotMapGroupRow>
                        )
                      })}
                    </SlotMapGroupsTable>
                  </div>
                )}
              </div>
            </MenuItemLayout>

            {(hasUpdateMenuProductError || hasUpdateListingError) && (
              <APIErrorDisplay
                error={updateMenuProductError || updateListingError}
                errorCodeMessageMap={
                  hasUpdateMenuProductError
                    ? UPDATE_MENU_PRODUCT_ERRORS
                    : UPDATE_LISTING_ERRORS
                }
              />
            )}

            <div>
              <ButtonLoading
                isLoading={isUpdatingMenuProduct || isUpdatingListing}
                size="large"
                type="submit"
              >
                Save
              </ButtonLoading>
            </div>
          </form>
        </MenuProductDetails>
      </div>

      {showConfirmationDialog && (
        <UpdateMenuProductConfirmationDialog
          onClickClose={() => {
            setShowConfirmationDialog(false)
          }}
          title={menuProduct.title}
        >
          <div className="flex flex-col space-y-6">
            <ButtonLoading
              isLoading={isUpdatingMenuProduct || isUpdatingListing}
              onClick={() => {
                handleSubmit(onSubmit)()
                setShowConfirmationDialog(false)
              }}
              size="large"
            >
              Yes, Save Product Details and Menu Listings
            </ButtonLoading>

            <ButtonLoading
              buttonStyle="stroke"
              isLoading={isUpdatingListing}
              onClick={async () => {
                const listingsValues = getValues('listings')
                handleUpdateListings({ listings: listingsValues })
                setShowConfirmationDialog(false)
              }}
              size="large"
            >
              No, Save Menu Listings Only
            </ButtonLoading>
            <Button
              buttonStyle="link"
              onClick={() => setShowConfirmationDialog(false)}
              size="fluid"
            >
              Cancel
            </Button>
          </div>
        </UpdateMenuProductConfirmationDialog>
      )}
    </>
  )
}

export default MenuProductTermListings

const UpdateMenuProductConfirmationDialog = ({
  children,
  onClickClose,
  title,
}: {
  children: ReactNode
  onClickClose(): void
  title: string
}) => {
  return (
    <Modal onCloseModal={onClickClose}>
      <div>
        <ModalHeader onClickClose={onClickClose}>
          Save Product Details Changes?
        </ModalHeader>
        <div className="max-w-[645px] w-full p-6">
          <div className="space-y-4">
            <p>
              You have modified the Product Details for <b>{title}</b>. Saving
              these changes will update this version of <b>{title}</b> on any
              past and future terms it is listed on.
            </p>
            <p className="font-bold">
              Do you want to save your Product Details changes for {title}?
            </p>
            <div className="max-w-[80%] mx-auto pt-6">{children}</div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
