import type { Term } from '@tovala/browser-apis-combinedapi'
import { clsx } from 'clsx'
import { ReactNode } from 'react'
import { NavLink, Route, Routes } from 'react-router-dom'
import ListingsLayoutEditor from './ListingsLayoutEditor'
import MealsLayoutEditor from './MealsLayoutEditor'

const MenuLayout = ({ term }: { term: Term }) => {
  return (
    <div className="space-y-8 font-sans-new">
      <h1 className="text-k/36_110">Term #{term.id} - Menu Display Orders</h1>
      <div className="flex space-x-8">
        <div className="w-48 shrink-0">
          <ul>
            <li>
              <MenuNavLink to="meals">Meals</MenuNavLink>
            </li>
            <li>
              <MenuNavLink to="extras">Extras</MenuNavLink>
            </li>
          </ul>
        </div>
        <div className="w-3/4 pr-4">
          <Routes>
            <Route element={<MealsLayoutEditor term={term} />} path="meals" />
            <Route
              element={<ListingsLayoutEditor term={term} />}
              path="extras"
            />
          </Routes>
        </div>
      </div>
    </div>
  )
}

export default MenuLayout

const MenuNavLink = ({ children, to }: { children: ReactNode; to: string }) => {
  return (
    <NavLink
      className={({ isActive }) => {
        return clsx('p-4 w-full block rounded-xl', {
          'bg-black text-white-900': isActive,
          'bg-grey-0 text-black-900 hover:bg-grey-2': !isActive,
        })
      }}
      to={to}
    >
      {children}
    </NavLink>
  )
}
