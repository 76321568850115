import { Form, Formik } from 'formik'

import { updateImageText } from '../../actions/meals'

import { useAppDispatch } from 'hooks'
import Button from 'components/common/Button'
import InputField from 'components/common/InputField'
import SelectInput from 'components/common/SelectInput'

interface FormData {
  alt: string
  caption: string
  dominantColor: string
  key: string
}

interface Props {
  alt: string
  caption: string
  dominantColor: string
  hash: string
  imageTextKey: string
  mealid: string
  readOnly: boolean
}

const UpdateImageText = ({
  alt,
  caption,
  dominantColor,
  hash,
  imageTextKey,
  mealid,
  readOnly,
}: Props): JSX.Element => {
  const dispatch = useAppDispatch()

  function handleFormSubmit(formData: FormData) {
    dispatch(updateImageText(mealid, hash, formData))
  }

  const keys = [
    'cell_tile',
    'cell_tile_animated',
    'cell_detail',
    'cell_background',
    'internal_only',
    'raw',
    'cooked',
    'cooked_angle',
    'tray_main',
    'tray_side',
    'garnish_2oz_top',
    'garnish_2oz_bottom',
    'garnish_1oz_top',
    'garnish_1oz_bottom',
    'tortillas',
  ]

  return (
    <Formik<FormData>
      enableReinitialize
      initialValues={{
        alt,
        caption,
        dominantColor,
        key: imageTextKey,
      }}
      onSubmit={handleFormSubmit}
    >
      <Form className="space-y-4">
        <SelectInput
          label="Key"
          name="key"
          options={keys.map((key) => ({
            label: key,
            value: key,
          }))}
          readOnly={readOnly}
        />
        <InputField
          disabled={readOnly}
          label="Alt text"
          name="alt"
          type="text"
        />
        <InputField
          disabled={readOnly}
          label="Caption"
          name="caption"
          type="text"
        />
        <InputField
          disabled
          label="Dominant Color"
          name="dominantColor"
          type="text"
        />

        {!readOnly && (
          <div className="mt-4 h-10">
            <Button size="fluid" type="submit">
              Update Image Text
            </Button>
          </div>
        )}
      </Form>
    </Formik>
  )
}

export default UpdateImageText
