import axios from 'axios'
import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { PayloadAction } from '@reduxjs/toolkit'
import { TermMenu, TermMenuUpdated } from '@tovala/browser-apis-combinedapi'

import { API_URL_V1, getHeaders } from './index'
import { AppThunk } from 'store'
import { errorHandler, successHandler } from './notifications'
import { GET_MENU, UPDATE_SIDE_SWAP } from './types'

// ================================
// Authentication actions
// ================================

// GET https://api.tovala.com/v1/menus/:menuID
export function getMenu(
  menuID: string
): AppThunk<Promise<PayloadAction<TermMenu, typeof GET_MENU> | void>> {
  return function (dispatch) {
    dispatch(showLoading())

    return axios
      .get<TermMenu>(`${API_URL_V1}/menus/${menuID}`, getHeaders())
      .then((response) => {
        dispatch(hideLoading())

        return dispatch({
          type: GET_MENU,
          payload: response.data,
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

// PUT https://api.tovala.com/v1/menus/:menuID/sideSwapCombos
export function addSideSwapsForMenu(
  menuID: string,
  payload: unknown
): AppThunk<Promise<PayloadAction<true, typeof UPDATE_SIDE_SWAP> | void>> {
  return function (dispatch) {
    dispatch(showLoading())

    return axios
      .put(
        `${API_URL_V1}/menus/${menuID}/sideSwapCombos`,
        payload,
        getHeaders()
      )
      .then(() => {
        dispatch(hideLoading())

        successHandler(dispatch, `Success! Customize It meals saved.`)
        return dispatch({
          type: UPDATE_SIDE_SWAP,
          payload: true as const,
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

// DELETE https://api.tovala.com/v1/menus/:menuID/sideSwapCombos
export function deleteSideSwapsForMenu(
  menuID: string
): AppThunk<Promise<PayloadAction<true, typeof UPDATE_SIDE_SWAP> | void>> {
  return function (dispatch) {
    dispatch(showLoading())

    return axios
      .delete(`${API_URL_V1}/menus/${menuID}/sideSwapCombos`, getHeaders())
      .then(() => {
        dispatch(hideLoading())

        successHandler(dispatch, `Success! Customize It pairs deleted.`)

        return dispatch({
          type: UPDATE_SIDE_SWAP,
          payload: true as const,
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

// PUT https://api.tovala.com/v1/menus/:menuID
export function updateMenuInfo(
  menuID: string,
  payload: unknown
): AppThunk<Promise<PayloadAction<TermMenuUpdated, typeof GET_MENU> | void>> {
  return function (dispatch) {
    dispatch(showLoading())

    return axios
      .put<TermMenuUpdated>(
        `${API_URL_V1}/menus/${menuID}`,
        payload,
        getHeaders()
      )
      .then((response) => {
        dispatch(hideLoading())

        successHandler(dispatch, `Success! menu info updated.`)

        return dispatch({
          type: GET_MENU,
          payload: response.data,
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}
