import Analytics from 'analytics'
import { JWTPayload } from '@tovala/browser-apis-combinedapi'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore Can't find types for this plugin right now.
import segmentPlugin from '@analytics/segment'
import * as Sentry from '@sentry/react'

import { getEnvVar } from './env'

const CODE_VERSION = getEnvVar('VERCEL_GIT_COMMIT_SHA')

export const analytics = Analytics({
  app: 'glaze',
  plugins:
    getEnvVar('APP_ENV') === 'test'
      ? []
      : [
          segmentPlugin({
            writeKey: getEnvVar('SEGMENT_WRITE_KEY'),
          }),
        ],
})

export function identifyUser({ decodedJWT }: { decodedJWT: JWTPayload }): void {
  const { userId } = decodedJWT

  analytics.identify(`${userId}`, {
    version: CODE_VERSION,
  })

  Sentry.configureScope((scope) => {
    scope.setUser({
      id: `${userId}`,
      version: CODE_VERSION,
    })
  })
}
