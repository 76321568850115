import { clsx } from 'clsx'
import { Link, NavLink } from 'react-router-dom'
import { ReactNode } from 'react'
import {
  useOrderHistoryReceipts,
  useOvens,
} from '@tovala/browser-apis-combinedapi'

import {
  getAdminScope,
  ORDER_OVERRIDE,
  USERS_ADMIN,
  USERS_WRITE,
} from '../../utils/getAdminScope'

import { useAppSelector } from 'hooks'
import Badge from 'components/common/Badge'

const UserNav = (): JSX.Element => {
  const user = useAppSelector((state) => state.auth.user)

  const { data: getOrderHistoryReceiptsResponse } = useOrderHistoryReceipts({
    userID: user?.id,
  })
  const { pages = [] } = getOrderHistoryReceiptsResponse || {}
  const totalOrderCount = pages[0]?.totalOrderCount

  const { data: ovens = [] } = useOvens({ userID: user?.id })

  let newUserTag = ''
  if (totalOrderCount !== null) {
    if (totalOrderCount < 5) {
      newUserTag = 'Brand New User'
    } else if (totalOrderCount < 9) {
      newUserTag = 'New User'
    }
  }

  const nokTryoutTag = user?.productPurchases?.some(
    (purchase) =>
      purchase.couponCode === 'nokfreemeals' ||
      purchase.couponCode === 'tryouts'
  )
    ? 'Nok try-outs'
    : ''

  return (
    <div className="space-y-4">
      <div className="flex">
        <div className="w-10/12">
          {user && (
            <>
              <h2 className="font-serif text-3xl font-bold">
                {user.info.name}
              </h2>
              <p className="mb-1">{user.info.email}</p>
            </>
          )}

          <div className="space-x-2">
            {newUserTag && <Badge badgeStyle="red">{newUserTag}</Badge>}
            {nokTryoutTag && <Badge badgeStyle="red">{nokTryoutTag}</Badge>}
          </div>
        </div>

        <div className="w-2/12">
          {user && user.info.isEmployee && (
            <img
              alt="Tovala"
              className="w-full"
              src="https://cdn.tovala.com/tovala.com/logos/hotpad-red.png"
            />
          )}
        </div>
      </div>

      {ovens.length > 0 && (
        <div className="flex space-x-2">
          {ovens.map((oven) => {
            return (
              <div
                key={oven.id}
                className="rounded border border-grey-901 p-1 text-sm uppercase"
              >
                <Link to={`/user/${oven.userid}/oven/${oven.id}`}>
                  {oven.name ? oven.name : 'View Oven'}
                </Link>
              </div>
            )
          })}
        </div>
      )}

      <div>
        <UserNavLink to="account-overview">Account Overview</UserNavLink>
        <UserNavLink to="refund-history">Refund History</UserNavLink>
        <UserNavLink to="order-history">Meal Order History</UserNavLink>
        <UserNavLink to="meal-plan">Meal Plan + Oven Order</UserNavLink>
        <UserNavLink to="menu">Meals</UserNavLink>
        <UserNavLink to="calendar">Calendar</UserNavLink>
        <UserNavLink to="payment">Payment</UserNavLink>
        <UserNavLink to="shipping">Shipping</UserNavLink>
        {getAdminScope(ORDER_OVERRIDE) && (
          <UserNavLink to="buy-oven">Buy Oven for Customer</UserNavLink>
        )}
        <UserNavLink to="account-balance/balance">Account Balance</UserNavLink>
        {getAdminScope(ORDER_OVERRIDE) && (
          <UserNavLink to="promos">Add Promotions / Gifts</UserNavLink>
        )}
        {user && user.subscription.customerID !== '' && (
          <UserNavLink
            isExternal
            to={`https://dashboard.stripe.com/customers/${user.subscription.customerID}`}
          >
            View User in Stripe
          </UserNavLink>
        )}
        <UserNavLink to="view">User Details</UserNavLink>
        {getAdminScope(USERS_WRITE) && (
          <UserNavLink to="edit">Edit User</UserNavLink>
        )}
        {getAdminScope(USERS_ADMIN) && (
          <UserNavLink to="admin-permissions">Admin Permissions</UserNavLink>
        )}
      </div>
    </div>
  )
}

export default UserNav

const UserNavLink = ({
  children,
  isExternal = false,
  to,
}: {
  children: ReactNode
  isExternal?: boolean
  to: string
}) => {
  const getClasses = (isActive: boolean) => {
    return clsx(
      'mt-0 block border border-b-0 border-grey-900 py-3 px-5 first:rounded-t last:rounded-b last:border-b',
      {
        'bg-red-901 text-white-900': isActive,
        'bg-grey-907 text-black-900 hover:bg-grey-903': !isActive,
      }
    )
  }

  if (isExternal) {
    return (
      <a
        className={getClasses(false)}
        href={to}
        rel="noreferrer noopener"
        target="_blank"
      >
        {children}
      </a>
    )
  }

  return (
    <NavLink
      className={({ isActive }) => {
        return getClasses(isActive)
      }}
      to={to}
    >
      {children}
    </NavLink>
  )
}
