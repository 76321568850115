import { combineReducers } from 'redux'
import { loadingBarReducer } from 'react-redux-loading-bar'
import { reducer as form } from 'redux-form'

import { auth } from './auth_reducer'
import { marketing } from './marketing_reducer'
import { mealTags } from './meal_tags_reducer'
import { meals } from './meals_reducer'
import { notifications } from './notifications_reducer'
import { packslips } from './packslips_reducer'
import { terms } from './terms_reducer'
import { user } from './user_reducer'
import { userSearch } from './user_search_reducer'

const reducer = combineReducers({
  auth,
  form,
  loadingBar: loadingBarReducer,
  marketing,
  mealTags,
  meals,
  notifications,
  packslips,
  terms,
  user,
  userSearch,
})

export default reducer
