import {
  SkippedWeeks,
  Term,
  TermMenu,
  TermMenuUpdated,
  TermStatus,
  UserV1,
} from '@tovala/browser-apis-combinedapi'

import { TermStatus as TermStatusInternal } from 'types/domainModels'
import { TermStatusExtended, UserTerm } from 'types/internal'

export function getUserTerm({
  skippedWeeks,
  term,
  user,
}: {
  skippedWeeks: SkippedWeeks | undefined
  term: TermStatus
  user: UserV1
}) {
  const selectedSubTerm = term.subTerms.find(
    (subTerm) => subTerm.subTermID === term.selectedSubTermID
  )

  const userTerm: UserTerm = {
    ...term,
    hasSelectedAllMeals:
      term.subscriptionType?.maxSelections === term.mealSelections.length ??
      false,
    isSkipped: skippedWeeks
      ? skippedWeeks.termids.includes(term.termID)
      : term.isSkipped,
    isStaticSkipped: skippedWeeks
      ? skippedWeeks.staticTermIDs.includes(term.termID)
      : term.isStaticSkipped,
    mealSwaps: selectedSubTerm?.mainMenu?.mealSwaps
      ? [...selectedSubTerm.mainMenu.mealSwaps]
      : [],
    selectedSubscriptionTypeIsDefault:
      term.subscriptionType && user?.subscription.subscriptionType
        ? term.subscriptionType.maxSelections ===
          user.subscription.subscriptionType.maxSelections
        : true,
    selectedSubTerm,
    subTerms: [...term.subTerms]
      .filter((subTerm) => subTerm.isAvailable)
      .sort((a, b) => a.shipPeriod - b.shipPeriod),
  }

  return userTerm
}

export function getUserTerms({
  skippedWeeks,
  userTermStatuses,
  user,
}: {
  skippedWeeks: SkippedWeeks | undefined
  userTermStatuses: Record<number, TermStatusInternal> | ''
  user: UserV1 | undefined
}) {
  const terms = userTermStatuses ? Object.values(userTermStatuses) : ''
  const userTerms = terms
    ? terms
        .map((term, index) => {
          const sortedSubTerms = [...term.subTerms]
            .filter((subTerm) => subTerm.isAvailable)
            .sort((a, b) => a.shipPeriod - b.shipPeriod)
          const selectedSubTerm = term.subTerms.find(
            (subTerm) => subTerm.subTermID === term.selectedSubTermID
          )

          const userTerm: TermStatusExtended = {
            ...term,
            selectedSubTerm,
            subTerms: sortedSubTerms,
            meals: selectedSubTerm?.mainMenu
              ? [...selectedSubTerm.mainMenu.meals].sort((a, b) => a.id - b.id)
              : [],
            mealSwaps: selectedSubTerm?.mainMenu
              ? [...selectedSubTerm.mainMenu.mealSwaps]
              : [],
            filters: selectedSubTerm?.mainMenu
              ? selectedSubTerm.mainMenu.filters
              : [],
            isSkipped: skippedWeeks
              ? skippedWeeks.termids.includes(term.termID)
              : term.isSkipped,
            selectedSubscriptionTypeIsDefault:
              user?.subscription?.subscriptionType && term.subscriptionType
                ? term.subscriptionType.maxSelections ===
                  user.subscription.subscriptionType.maxSelections
                : true,
            isPresentTerm: index === 0,
          }

          return userTerm
        })
        .sort((a, b) => a.termID - b.termID)
    : ''

  return userTerms
}

export function isTerm(term: Term | Record<string, unknown>): term is Term {
  return (term as Term).id !== undefined
}

export function isTermMenu(menu: TermMenu | TermMenuUpdated): menu is TermMenu {
  return (menu as TermMenu).mealSwaps !== undefined
}
