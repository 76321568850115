import { UniqueIdentifier } from '@dnd-kit/core'
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable'
import { MenuComponentStandardized } from '@tovala/browser-apis-menu-components'
import ComponentToolbar from './ComponentToolbar'
import { DraggableNewComponentProps } from './DraggableNewComponent'
import BackgroundImageHeader from './menuComponents/BackgroundImageHeader'
import Meal from './menuComponents/Meal'
import MealCarousel from './menuComponents/MealCarousel'
import MealWithExtra from './menuComponents/MealWithExtra'
import TextBanner from './menuComponents/TextBanner'
import TextImageStack from './menuComponents/TextImageStack'
import TwoMealPicker from './menuComponents/TwoMealPicker'
import MenuGridLayout from './MenuGridLayout'
import SortableComponent from './SortableComponent'
import { ComponentDropPlaceholder, ViewType } from './utils'

const MenuComponentsGrid = ({
  activeID,
  components,
  onClickCopy,
  onClickDelete,
  onClickEdit,
  viewType,
}: {
  activeID: UniqueIdentifier | null
  components: (ComponentDropPlaceholder | MenuComponentStandardized)[]
  onClickCopy(opts: {
    component: MenuComponentStandardized
    index: number
  }): void
  onClickDelete(component: MenuComponentStandardized): void
  onClickEdit(opts: {
    componentType: DraggableNewComponentProps['componentType']
    data: { component: MenuComponentStandardized; index: number }
  }): void
  viewType: ViewType
}) => {
  return (
    <SortableContext items={components} strategy={rectSortingStrategy}>
      <MenuGridLayout viewType={viewType}>
        {components.map((component, index) => {
          if (component.type === 'dropPlaceholder') {
            return (
              <div
                key={index}
                className="flex h-full min-h-[350px] w-full items-center justify-center rounded-2xl border border-dashed border-grey-6"
              >
                Drop Component Here
              </div>
            )
          }

          return (
            <SortableComponent
              key={component.id}
              cols={
                // We only have two columns on desktop. On mobile it's a single column
                // so everything stretches just one column.
                (component.type === 'textImageStack' ||
                  component.type === 'textBanner') &&
                viewType === 'desktop'
                  ? 'col-span-2'
                  : 'col-span-1'
              }
              componentID={component.id}
              isDragging={activeID === component.id}
            >
              {component.type === 'backgroundImageHeader' ? (
                <div className="group relative h-full min-h-[24rem] w-full">
                  <BackgroundImageHeader properties={component.properties} />

                  <div className="absolute bottom-0 left-0 right-0 z-10 hidden justify-center space-x-4 rounded-b-2xl bg-grey-3/60 py-4 group-hover:flex">
                    <ComponentToolbar
                      onClickCopy={() => {
                        onClickCopy({ component, index })
                      }}
                      onClickDelete={() => {
                        onClickDelete(component)
                      }}
                      onClickEdit={() => {
                        onClickEdit({
                          componentType: 'backgroundImageHeader',
                          data: { component, index },
                        })
                      }}
                    />
                  </div>
                </div>
              ) : component.type === 'meal' ? (
                <Meal properties={component.properties} />
              ) : component.type === 'mealWithExtra' ? (
                <div className="group relative">
                  <MealWithExtra properties={component.properties} />

                  <div className="absolute left-0 right-0 top-0 z-10 hidden justify-center space-x-4 rounded-b-2xl bg-grey-3/60 py-4 group-hover:flex">
                    <ComponentToolbar
                      onClickEdit={() => {
                        onClickEdit({
                          componentType: 'mealWithExtra',
                          data: { component, index },
                        })
                      }}
                    />
                  </div>
                </div>
              ) : component.type === 'twoMealPicker' ? (
                <div className="md:px-4">
                  <TwoMealPicker properties={component.properties} />
                </div>
              ) : component.type === 'animatedMealCarousel' ? (
                <MealCarousel properties={component.properties} />
              ) : component.type === 'textBanner' ? (
                <TextBanner properties={component.properties} />
              ) : component.type === 'textImageStack' ? (
                <div className="group relative col-span-2 md:col-span-1">
                  <div className="absolute left-0 right-0 top-0 z-10 hidden justify-center space-x-4 bg-grey-3/60 py-4 group-hover:flex">
                    <ComponentToolbar
                      onClickDelete={() => {
                        onClickDelete(component)
                      }}
                      onClickEdit={() => {
                        onClickEdit({
                          componentType: 'textImageStack',
                          data: { component, index },
                        })
                      }}
                    />
                  </div>

                  <TextImageStack
                    properties={component.properties}
                    viewType={viewType}
                  />
                </div>
              ) : null}
            </SortableComponent>
          )
        })}
      </MenuGridLayout>
    </SortableContext>
  )
}

export default MenuComponentsGrid
