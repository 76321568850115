import { ComponentProps } from 'react'
import { FieldValues, UseControllerProps, useController } from 'react-hook-form'
import { FormGroup, Radio } from '@tovala/component-library'

export type FormRadioRHFProps<T extends FieldValues> = {
  checked: boolean
  label?: string
} & ComponentProps<typeof Radio> &
  UseControllerProps<T>

export const FormRadioRHF = <T extends FieldValues>({
  checked,
  control,
  defaultValue,
  label,
  name,
  rules,
  ...rest
}: FormRadioRHFProps<T>) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue,
    rules,
  })

  return (
    <FormGroup error={error?.message}>
      <Radio {...field} {...rest} checked={checked} label={label} />
    </FormGroup>
  )
}
