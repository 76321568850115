import { ComponentProps } from 'react'
import { Radio } from '@tovala/component-library'
import { useField } from 'formik'

const FormikRadio = (props: ComponentProps<typeof Radio>) => {
  const [field] = useField({
    name: props.name,
    type: 'radio',
    value: props.value,
  })

  return <Radio {...field} {...props} />
}

export default FormikRadio
