import { useEffect } from 'react'
import { Link } from 'react-router-dom'

import Button from 'components/common/Button'
import H1 from 'components/common/H1'

const Marketing = (): JSX.Element => {
  useEffect(() => {
    document.title = `Glaze | Marketing`
  }, [])

  return (
    <div>
      <H1>Marketing</H1>

      <div className="mt-5 w-1/3">
        <Link to="/coupon-codes">
          <Button size="large">Coupon Codes</Button>
        </Link>
      </div>
    </div>
  )
}

export default Marketing
