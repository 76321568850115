import axios from 'axios'
import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { UserV1SearchResult } from '@tovala/browser-apis-combinedapi'

import { API_URL, API_URL_V1, getHeaders } from './index'
import { AppThunk } from 'store'
import { errorHandler } from './notifications'
import {
  GET_DEFAULT_SEARCH,
  GET_SEARCH_RESULTS,
  SET_SEARCH_PARAM,
  SET_SEARCH_TERM,
} from './types'

// Used for inital page load on Dashboard
// GET https://api.tovala.com/v0/search/users/:searchTerm
// Get all users (SYSADMIN)
export function getDefaultSearchResults(): AppThunk<void> {
  return function (dispatch) {
    dispatch(showLoading())

    axios
      .get(`${API_URL}/search/users?pageNumber=1`, getHeaders())
      .then((response) => {
        dispatch(hideLoading())
        dispatch({
          type: GET_DEFAULT_SEARCH,
          payload: response.data.data,
        })
      })
      .catch((error) => {
        dispatch(hideLoading())
        errorHandler(dispatch, error)
      })
  }
}

// GET  v1/users/search/:searchTerm/:searchParam
export function getUserSearchResults(formProps: {
  searchParam: string
  searchTerm: string
}): AppThunk<Promise<UserV1SearchResult[] | void>> {
  const { searchTerm, searchParam } = formProps
  return function (dispatch) {
    dispatch(showLoading())

    return axios
      .get<UserV1SearchResult[]>(
        `${API_URL_V1}/users/search/${searchTerm}/${searchParam}`,
        getHeaders()
      )
      .then((response) => {
        dispatch(hideLoading())

        // If there's only one search result, go directly to that user
        if (response.data.length === 1) {
          return response.data
        }

        dispatch({
          type: GET_SEARCH_RESULTS,
          payload: response.data,
        })

        dispatch({
          type: SET_SEARCH_TERM,
          payload: searchTerm,
        })

        dispatch({
          type: SET_SEARCH_PARAM,
          payload: searchParam,
        })
      })
      .catch((error) => {
        dispatch(hideLoading())
        errorHandler(dispatch, error)
      })
  }
}

export function clearUserSearchResults(): AppThunk<void> {
  return function (dispatch) {
    dispatch({
      type: GET_SEARCH_RESULTS,
      payload: [],
    })

    dispatch({
      type: SET_SEARCH_TERM,
      payload: 'nameOrEmail',
    })

    dispatch({
      type: SET_SEARCH_PARAM,
      payload: '',
    })
  }
}
