import { APIErrorResponse } from '@tovala/browser-apis-combinedapi'
import { AxiosError } from 'axios'
import { QueryClient } from '@tanstack/react-query'

type AxiosErrorWithExtra = AxiosError<
  APIErrorResponse & {
    extra: {
      message: string
    }
  }
>

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        const queryError = error as Error

        // We don't want to retry an API request if we got an Unauthorized response code - the user has to
        // log back in and get a new, valid token.
        return (
          failureCount < 3 &&
          (!isAxiosResponseError(queryError) ||
            queryError.response?.status !== 401)
        )
      },
    },
  },
})

export function isAxiosErrorWithExtra(
  error: Error | null | undefined
): error is AxiosErrorWithExtra {
  return (error as AxiosErrorWithExtra)?.response?.data.extra !== undefined
}

export function isAxiosResponseError(
  error: Error | null | undefined
): error is AxiosError<APIErrorResponse> {
  return (error as AxiosError)?.response !== undefined
}
