import { MealAdmin, Term, TermMenu } from '@tovala/browser-apis-combinedapi'
import moment from 'moment'

export type CalendarTerm = {
  end: string
  id: number
  ready_for_view: boolean
  start: string
  subTerms?: Term['subTerms']
}

export interface MealFormData {
  chef_image: string
  created_by: string
  customer_quote: string
  customer_quote_by: string
  ingredients: string
  mealPrepSteps: string
  menus: {
    expirationDate: string | null
    mainDisplayOrder: number
    menuID: string
    productionCode: string
  }[]
  nutritionalInfo: {
    dailyValuePercentage: string
    id?: string
    key: string
    name: string
    unit: string
    value: string
  }[]
  shortSubtitle: string
  story: string
  subtitle: string
  surchargeCents: string
  termid: string
  title: string
}

export interface TermMenuWithFacilityNetwork extends TermMenu {
  facilityNetwork: string
  shipPeriod: number
}

export function getInitialMealFormData(meal?: MealAdmin): MealFormData {
  return {
    chef_image: meal?.chef_image ?? '',
    created_by: meal?.created_by ?? '',
    customer_quote: meal?.customer_quote ?? '',
    customer_quote_by: meal?.customer_quote_by ?? '',
    ingredients: meal?.ingredients ?? '',
    mealPrepSteps: meal?.mealPrepSteps ?? '',
    menus: meal?.menuMeals
      ? meal.menuMeals.map((menuMeal) => {
          return {
            // Will address when moment is completely replaced with date-fns
            // eslint-disable-next-line import/no-named-as-default-member
            expirationDate: moment
              .utc(menuMeal.expirationDate)
              .format('YYYY-MM-DD'),
            id: menuMeal.id,
            mainDisplayOrder: menuMeal.mainDisplayOrder,
            menuID: menuMeal.menuID,
            productionCode: menuMeal.productionCode,
          }
        })
      : [],
    nutritionalInfo: meal?.nutritionalInfo
      ? meal.nutritionalInfo.map((info, index) => {
          return {
            dailyValuePercentage: info.dailyValuePercentage,
            // Add a unique identifier to nutritional info to use as a key in the FieldArray, using
            // index when mapping in RenderNutritionalInfo causes incorrect values to be displayed when
            // items are removed from the FieldArray and using the name causes rerenders on change
            id: `${index}`,
            key: info.key,
            name: info.name,
            unit: info.unit,
            value: info.value,
          }
        })
      : [],
    shortSubtitle: meal?.shortSubtitle ?? '',
    story: meal?.story ?? '',
    subtitle: meal?.subtitle ?? '',
    surchargeCents: meal?.surchargeCents ? `${meal.surchargeCents}` : '',
    termid: !meal || meal.termid === null ? '' : `${meal.termid}`,
    title: meal?.title ?? '',
  }
}
