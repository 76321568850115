import { UserV1 } from '@tovala/browser-apis-combinedapi'

import { useAddMarketingCreditsSubmit } from './hooks'
import AddMarketingCreditForm from './AddMarketingCreditForm'
import Modal, { ModalBody, ModalHeader } from 'components/modals/Modal'

export interface Props {
  onCloseModal(): void
  user: UserV1
}

const AddMarketingCreditsModal = ({ onCloseModal, user }: Props) => {
  const { addMarketingCredits } = useAddMarketingCreditsSubmit({
    onSuccess: onCloseModal,
    userid: user.id,
  })

  return (
    <Modal onCloseModal={onCloseModal}>
      <ModalBody>
        <ModalHeader onClickClose={onCloseModal}>
          Add Marketing Credits (Discount)
        </ModalHeader>
        <div className="w-[500px]">
          <p>
            Add Marketing Credits to the user&apos;s Discounts account balance
            to be used on a future order.
          </p>
          <AddMarketingCreditForm handleSubmit={addMarketingCredits} />
        </div>
      </ModalBody>
    </Modal>
  )
}

export default AddMarketingCreditsModal
