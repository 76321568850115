import { ReactNode } from 'react'

const OverviewLine = ({
  children,
  label,
}: {
  children: ReactNode
  label: ReactNode
}) => {
  return (
    <div className="flex space-x-2">
      <div className="w-44 shrink-0 font-semibold">{label}</div>
      <div>{children}</div>
    </div>
  )
}

export default OverviewLine
