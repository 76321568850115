import { MealAdmin } from '@tovala/browser-apis-combinedapi'
import {
  Modal,
  ModalHeader,
  FormGroup,
  Input,
  ButtonLoading,
  APIErrorDisplay,
  Button,
} from '@tovala/component-library'
import { useGetMeal } from 'hooks/combinedAPI/menus'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { GET_MEAL_ERRORS } from 'utils/errors'

interface MealWithExtraFormData {
  detailsMealID: number
}

const MealWithExtraDialog = ({
  initialValues,
  onClose,
  onSave,
}: {
  initialValues: MealWithExtraFormData | undefined
  onClose(): void
  onSave(data: MealWithExtraFormData, meal: MealAdmin | undefined): void
}) => {
  const [mealID, setMealID] = useState<number | undefined>(() => {
    return initialValues?.detailsMealID
  })

  const { formState, getValues, handleSubmit, register } =
    useForm<MealWithExtraFormData>({
      defaultValues: initialValues,
    })

  const {
    data: meal,
    error: getMealError,
    isError: hasGetMealError,
    isLoading: isLoadingMeal,
  } = useGetMeal({ mealID })

  return (
    <Modal onCloseModal={onClose}>
      <ModalHeader onClickClose={onClose}>Meal With Extra</ModalHeader>
      <form onSubmit={handleSubmit((data) => onSave(data, meal))}>
        <div className="p-6 font-sans-new">
          <div className="w-[500px] space-y-4">
            <div className="grid grid-cols-[1fr_120px] gap-4">
              <div className="col-span-2">
                <div className="flex items-end justify-between space-x-4">
                  <div className="grow">
                    <FormGroup
                      error={formState.errors.detailsMealID?.message}
                      label="Extra Details Meal ID"
                    >
                      <Input
                        hasError={!!formState.errors.detailsMealID}
                        type="number"
                        {...register('detailsMealID', {
                          required: 'Please enter a meal ID',
                          valueAsNumber: true,
                        })}
                      />
                    </FormGroup>
                  </div>

                  <ButtonLoading
                    buttonStyle="gray"
                    isLoading={isLoadingMeal}
                    onClick={() => {
                      const values = getValues()
                      setMealID(values.detailsMealID)
                    }}
                    size="large"
                    type="button"
                  >
                    Search
                  </ButtonLoading>
                </div>
              </div>
            </div>

            {hasGetMealError && (
              <APIErrorDisplay
                error={getMealError}
                errorCodeMessageMap={GET_MEAL_ERRORS}
              />
            )}

            {meal && (
              <div className="flex items-center space-x-4">
                <img
                  className="h-16 rounded-md"
                  src={
                    meal.images.find((image) => image.key === 'cell_tile')?.url
                  }
                />
                <div>
                  <div>{meal.title}</div>
                  <div className="text-sm">{meal.subtitle}</div>
                </div>
              </div>
            )}
          </div>

          <div className="mt-8 flex flex-row-reverse gap-4">
            <Button disabled={!meal} size="large" type="submit">
              Save
            </Button>

            <Button
              buttonStyle="stroke"
              onClick={() => {
                onClose()
              }}
              size="large"
            >
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default MealWithExtraDialog
