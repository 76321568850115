import OverviewLine from './OverviewLine'
import { Oven, useOvenChannels } from '@tovala/browser-apis-combinedapi'

const OvenChannel = ({ ovens, oven }: { ovens: Oven[]; oven: Oven }) => {
  const ovenChannelMap: Map<string, string> = new Map()
  const ovenSerials = ovens.map((oven: Oven) => oven.tovala.serial)

  const ovenChannels = useOvenChannels({
    serialNumbers: ovenSerials,
    retry: false,
  }).map((ovenChannel) => ovenChannel.data)

  ovenChannels.map((ovenChannel) => {
    if (ovenChannel && ovenChannel) {
      ovenChannelMap.set(ovenChannel.serialNumber, ovenChannel.channel)
    }
  })

  if (ovenChannelMap.has(oven.tovala.serial)) {
    return (
      <OverviewLine label={'Retail Channel'}>
        {ovenChannelMap.get(oven.tovala.serial)}
      </OverviewLine>
    )
  }
  return null
}

export default OvenChannel
