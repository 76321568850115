import {
  useAddMealSelection,
  useCurrentTerm,
  useDeleteMealSelection,
  useListingsReceipt,
  useMealSummaries,
  useOrderHistoryReceipts,
  UserV1,
  useUserTermStatus,
} from '@tovala/browser-apis-combinedapi'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { getAdminScope, OATS_DASHBOARD } from 'utils/getAdminScope'

import { useAppDispatch } from 'hooks'
import H4 from 'components/common/H4'
import { debounce, flatten, sortBy, uniqBy } from 'lodash-es'
import MealsGrid from './MealsGrid'
import { isAxiosResponseError } from '@tovala/browser-apis-core'
import { errorHandler, successHandler } from 'actions/notifications'
import OrderSummary from './OrderSummary'
import { isCurrentTimeBeforeMSRDeadline } from '../helpers'

const HIDDEN_MEAL_TAG_ID = 53
const MSR_DEADLINE_MESSAGE =
  'Editing meal selections is no longer available for this term.'

const CurrentTermEditMeals = ({ user }: { user: UserV1 }) => {
  const dispatch = useAppDispatch()

  const { termid: termIDParam, userid } = useParams()
  const termID = termIDParam ? Number.parseInt(termIDParam, 10) : undefined

  useEffect(() => {
    document.title = `Glaze | User #${userid} - Meals`
  }, [userid])

  const { data: getOrderHistoryReceiptsResponse } = useOrderHistoryReceipts({
    userID: user.id,
  })
  const { pages = [] } = getOrderHistoryReceiptsResponse || {}
  const orderHistoryReceipts = flatten(pages.map((page) => page.receipts ?? []))

  const receipt = orderHistoryReceipts.find(
    (receipt) => receipt.termID === termID
  )

  const { data: listingsReceipt } = useListingsReceipt({
    orderID: receipt?.userTermOrderID,
    userID: user.id,
  })
  const listings = uniqBy(listingsReceipt?.listings, 'id')
  const listingSelections = listingsReceipt?.listings
    ? listings.map((listing) => {
        const quantity = listingsReceipt.listings.filter(
          (l) => l.id === listing.id
        ).length

        return {
          id: listing.id,
          priceCents: listing.priceCents,
          quantity,
        }
      })
    : []

  const { data: currentTerm } = useCurrentTerm()

  const { data: selectedUserTerm } = useUserTermStatus({
    termID,
    userID: user.id,
  })

  const { data: mealSummaries = [] } = useMealSummaries({
    subTermID: selectedUserTerm?.selectedSubTermID,
  })

  // Don't allow CS to add meals with the hidden tag, which are added to frozen meals added after the order by date
  const sortedMealSummaries = sortBy(mealSummaries, 'id').filter(
    (mealSummary) =>
      !mealSummary.tags.some((tag) => tag.id === HIDDEN_MEAL_TAG_ID)
  )

  const { isLoading: isAddingMealSelection, mutate: addSelection } =
    useAddMealSelection({
      onError: (err) => {
        if (isAxiosResponseError(err) && err.response?.data.message) {
          const message = err.response.data.message

          if (message === 'BlackSheetTrayRequired') {
            errorHandler(
              dispatch,
              null,
              `Unable to add this meal. Please check the user's sheet tray preference.`
            )
          } else {
            errorHandler(
              dispatch,
              null,
              'Unable to add this meal. Please try again.'
            )
          }
        }
      },
      onSuccess: (...args) => {
        const { termStatus } = args[0]

        const mealSelectionsCount = termStatus.mealSelections.length
        const maxSelections = termStatus.subscriptionType?.maxSelections

        if (mealSelectionsCount === maxSelections) {
          successHandler(
            dispatch,
            `${mealSelectionsCount}/${maxSelections} meals selected. Edit this week's delivery if the user would like more meals.`
          )
        }
      },
    })
  const { isLoading: isDeletingMealSelection, mutate: deleteSelection } =
    useDeleteMealSelection({
      onError: (err) => {
        if (isAxiosResponseError(err) && err.response?.data.message) {
          errorHandler(
            dispatch,
            null,
            'Unable to remove this meal. Please try again.'
          )
        }
      },
    })

  const addMealSelection = debounce(addSelection, 250)
  const deleteMealSelection = debounce(deleteSelection, 250)

  const isUpdatingMealSelections =
    isAddingMealSelection || isDeletingMealSelection

  const hasSelectedAllMeals =
    selectedUserTerm?.subscriptionType?.maxSelections ===
    selectedUserTerm?.mealSelections.length

  const selectedSubTerm = selectedUserTerm
    ? selectedUserTerm.subTerms.find(
        (subTerm) => subTerm.subTermID === selectedUserTerm.selectedSubTermID
      )
    : ''

  const orderPricing = {
    mealsPriceCents:
      receipt?.paymentLineItems.find((pli) => pli.type === 'sku')
        ?.invoiceAmountCents ?? 0,
    shippingPriceCents:
      receipt?.paymentLineItems.find((pli) => pli.type === 'shipping')
        ?.invoiceAmountCents ?? 0,
    surchargePriceCents:
      receipt?.paymentLineItems.find((pli) => pli.type === 'surcharge')
        ?.invoiceAmountCents ?? 0,
    totalPriceCents:
      receipt?.paymentLineItems.find((pli) => pli.type === 'charge')
        ?.transactionAmountCents ?? 0,
  }

  const hasRequiredScope = getAdminScope(OATS_DASHBOARD)

  if (!hasRequiredScope) {
    return <p>You do not have access to edit past term meal selections.</p>
  }

  const isMSRUpdateAllowed =
    currentTerm && termID === currentTerm.id
      ? isCurrentTimeBeforeMSRDeadline(currentTerm.order_by)
      : false

  if (!isMSRUpdateAllowed) {
    return <p>{MSR_DEADLINE_MESSAGE}</p>
  }

  const canModifySelection = () => {
    return !!(
      currentTerm && isCurrentTimeBeforeMSRDeadline(currentTerm.order_by)
    )
  }

  if (selectedUserTerm && selectedSubTerm && receipt) {
    return (
      <div>
        <H4>Edit Meals for Term #{termID}</H4>

        <div className="flex">
          <div className="w-8/12 space-y-6">
            <MealsGrid
              canDecrement={!isUpdatingMealSelections}
              canIncrement={!hasSelectedAllMeals && !isUpdatingMealSelections}
              mealSummaries={sortedMealSummaries}
              onDecrement={(mealID) => {
                if (canModifySelection()) {
                  const selections = [...selectedUserTerm.mealSelections].sort(
                    (a, b) => {
                      return +new Date(b.created) - +new Date(a.created)
                    }
                  )
                  const mealSelectionToDelete = selections.find(
                    (selection) => selection.mealID === mealID
                  )

                  if (mealSelectionToDelete) {
                    deleteMealSelection({
                      selectionID: mealSelectionToDelete.id,
                      userID: user.id,
                    })
                  }
                } else {
                  errorHandler(dispatch, null, MSR_DEADLINE_MESSAGE)
                }
              }}
              onIncrement={(mealID) => {
                if (canModifySelection()) {
                  addMealSelection({
                    userID: user.id,
                    data: {
                      mealid: mealID,
                      termid: selectedUserTerm.termID,
                      userid: user.id,
                    },
                  })
                } else {
                  errorHandler(dispatch, null, MSR_DEADLINE_MESSAGE)
                }
              }}
              selectionsMealIDs={selectedUserTerm.mealSelections.map(
                (selection) => selection.mealID
              )}
            />
          </div>
          <div className="w-4/12 pl-6">
            <div className="text-xl font-bold">Order Summary</div>

            <OrderSummary
              listingSelections={listingSelections}
              listings={listings}
              maxSelections={selectedUserTerm.subscriptionType?.maxSelections}
              maxSelectionsDefaultSubscription={
                user.subscription.subscriptionType?.maxSelections
              }
              mealSummaries={sortedMealSummaries}
              orderPricing={orderPricing}
              selectedSubscriptionTypeIsDefault={true}
              selectionsMealIDs={selectedUserTerm.mealSelections.map(
                (selection) => selection.mealID
              )}
            />
          </div>
        </div>
      </div>
    )
  }

  return null
}

export default CurrentTermEditMeals
