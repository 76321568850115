import { MealAdmin, MealTagList } from '@tovala/browser-apis-combinedapi'

import { getAdminScope, MEALS_WRITE } from '../../utils/getAdminScope'

import SmallLink from 'components/common/SmallLink'
import Table, { TableRow, TBody, TD, TH, THead } from 'components/common/Table'

const MealTagsTable = ({
  edit = false,
  handleRemove = undefined,
  tags,
}: {
  edit?: boolean
  handleRemove?: (tagID: number) => void
  tags: MealAdmin['tags'] | MealTagList[]
}): JSX.Element => {
  return (
    <Table>
      <THead>
        <TableRow>
          <TH>Title</TH>
          <TH>Tag ID</TH>
          <TH>Description</TH>
          <TH>Display Mode</TH>
          <TH>Internal Description</TH>
          <TH></TH>
        </TableRow>
      </THead>
      <TBody>
        {[...tags]
          .sort((a, b) => a.title.localeCompare(b.title))
          .map((tag) => {
            return (
              <TableRow key={tag.id}>
                <TD>
                  <h3 className="font-bold">{tag.title}</h3>
                </TD>
                <TD>#{tag.id}</TD>
                <TD>{tag.description}</TD>
                <TD>{tag.display_mode}</TD>
                <TD>{tag.description_internal}</TD>
                <TD>
                  {edit && (
                    <SmallLink to={`/meal-tags/${tag.id}`}>
                      {getAdminScope(MEALS_WRITE) ? 'Edit' : 'View'}
                    </SmallLink>
                  )}

                  {getAdminScope(MEALS_WRITE) && handleRemove && (
                    <SmallLink onClick={() => handleRemove(tag.id)}>
                      Remove
                    </SmallLink>
                  )}
                </TD>
              </TableRow>
            )
          })}
      </TBody>
    </Table>
  )
}

export default MealTagsTable
