import {
  CustomerCreditCard,
  TovalaProduct,
  UserV1,
  UserSubscriptionType,
  useBuyProduct,
  useTovalaProducts,
  useUserSubscriptionTypes,
  useUserV0,
} from '@tovala/browser-apis-combinedapi'
import { Elements } from '@stripe/react-stripe-js'
import { FormEventHandler, useEffect, useState } from 'react'
import { reduxForm } from 'redux-form'
import { useNavigate, useParams } from 'react-router-dom'

import {
  clearCoupon,
  getCouponAmount,
  getProductsPurchaseInformation,
  getTaxByAddress,
  getUserInfo,
  updateShippingAddress,
} from '../../actions/auth'
import { getAdminScope, ORDER_OVERRIDE } from '../../utils/getAdminScope'
import { formatCentsToDollars } from 'utils/currency'
import { getAvailableProductsToBuy } from 'utils/products'
import { getElementsConfiguration, stripePromise } from 'utils/stripe'
import { isAxiosErrorWithExtra, isAxiosResponseError } from 'utils/api'
import { isCustomer } from 'utils/customers'
import { successHandler } from 'actions/notifications'

import { useAppDispatch, useAppSelector } from 'hooks'
import AlertInline from 'components/common/AlertInline'
import Button from 'components/common/Button'
import ButtonLoading from 'components/common/ButtonLoading'
import CcForm from './CcForm'
import FormLabel from 'components/common/FormLabel'
import H2 from 'components/common/H2'
import H3 from 'components/common/H3'
import H4 from 'components/common/H4'
import Input from 'components/common/Input'
import Radio from 'components/common/Radio'
import ShippingForm, {
  FormData as ShippingFormData,
  Props as ShippingFormProps,
} from './ShippingForm'
import Select from 'components/common/Select'

interface Props {
  user: UserV1
}

const form = reduxForm<ShippingFormData, Props>({
  form: 'shippingAddress',
})

const UserBuyOven = ({
  handleSubmit,
  initialize,
  user,
}: Props & {
  handleSubmit(onSubmit: (formData: ShippingFormData) => void): FormEventHandler
  initialize: ShippingFormProps['initialize']
}): JSX.Element => {
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const { userid } = useParams<{ userid: string }>()

  const couponApplied = useAppSelector((state) => state.auth.couponApplied)
  const customer = useAppSelector((state) => state.auth.customer)
  const tax = useAppSelector((state) => state.auth.productTax)

  const { data: getUserSubscriptionTypesResponse } = useUserSubscriptionTypes({
    userID: user.id,
  })
  const userSubscriptionTypes =
    getUserSubscriptionTypesResponse?.subscription_types ?? []

  const [couponCode, setCouponCode] = useState('')
  const [plan, setPlan] = useState('')
  const [product, setProduct] = useState<TovalaProduct | ''>('')

  const pageUnlocked = !!getAdminScope(ORDER_OVERRIDE)

  const { data: tovalaProducts = [] } = useTovalaProducts()

  const { data: userV0 } = useUserV0({ userID: user.id })

  const {
    error: buyProductError,
    isError: hasBuyProductError,
    isLoading: isBuyingProduct,
    mutate: buyProduct,
  } = useBuyProduct({
    onSuccess: (_, { data }) => {
      const userID = data.userid

      successHandler(dispatch, 'Boom! Order successfully placed for customer.')
      dispatch(getUserInfo(userID))
      dispatch(getProductsPurchaseInformation(userID))
      navigate(`/user/${userID}/meal-plan`)
    },
  })

  const placeOrder = () => {
    let selectedPlan: UserSubscriptionType | '' = ''
    if (plan) {
      selectedPlan = JSON.parse(plan) as UserSubscriptionType
    }

    if (!product) {
      return alert('select a product first!')
    }

    const payload = {
      userid: user.id,
      couponCode: '',
      referralCode: '',
      affirmOrder: false,
      subscriptionTypeID: selectedPlan ? selectedPlan.id : '',
    }

    if (couponApplied) {
      payload.couponCode =
        couponApplied.type === 'couponCode' ? couponApplied.couponCode.code : ''
      payload.referralCode =
        couponApplied.type === 'referralCode'
          ? couponApplied.couponCode.code
          : ''
    }

    buyProduct({ data: payload, productID: product.id })
  }

  const changeCoupon = (couponCode: string) => {
    setCouponCode(couponCode)
  }

  const saveCoupon = () => {
    if (!product) {
      return alert('Please pick a product before applying a coupon.')
    }

    dispatch(
      getCouponAmount({
        couponCode,
        userid: user.id,
        productid: product.id,
      })
    ).then((response) => {
      if (response && response.payload && response.payload.valid) {
        const couponCode = response.payload.couponCode.code
        getTax(product, couponCode)
      }
    })
  }

  const getTax = (product: TovalaProduct, couponCode: string | null = null) => {
    if (user.id && userV0) {
      const taxPayload = {
        userid: user.id,
        id: product.id,
        type: 'product',
        coupon_code: couponCode,
        shipping_address: userV0.shipping_address,
      }

      dispatch(getTaxByAddress(taxPayload))
    }
  }

  const selectProduct = (value: string) => {
    const product = value ? (JSON.parse(value) as TovalaProduct) : ''

    if (product) {
      setProduct(product)
      getTax(product)
    }

    dispatch(clearCoupon())
  }

  const handleFormSubmit = (formData: ShippingFormData) => {
    if (!userid) {
      return
    }

    // Scroll to top of page
    window.scrollTo(0, 0)
    dispatch(
      updateShippingAddress(userid, user.subscription.customerID, formData)
    )
  }

  useEffect(() => {
    document.title = `Glaze | User #${userid} - Buy Oven`
  }, [userid])

  useEffect(() => {
    setCouponCode('')
    setPlan('')
    setProduct('')
  }, [userid])

  useEffect(() => {
    return () => {
      dispatch(clearCoupon())
    }
  }, [dispatch])

  const tovalaProductOptions = getAvailableProductsToBuy(tovalaProducts).map(
    (product) => {
      return (
        <option key={product.id} value={JSON.stringify(product)}>
          {product.description}
        </option>
      )
    }
  )

  let defaultCard: CustomerCreditCard | '' | undefined = ''
  if (isCustomer(customer) && customer.default_source) {
    defaultCard = customer.sources.data.find(
      (source) => source.id === customer.default_source
    )
  }

  let errorMessage = ''
  if (
    hasBuyProductError &&
    isAxiosResponseError(buyProductError) &&
    buyProductError.response?.data.message
  ) {
    errorMessage = buyProductError.response.data.message

    if (errorMessage === 'SubscriptionTypeIDRequiredForSelectedProduct') {
      errorMessage = 'Please select a meal plan.'
    }

    if (errorMessage === 'ProductPurchasePaymentError') {
      errorMessage = "There was a problem with the user's payment method."
    }
  }

  return (
    <div className="relative mb-20">
      {!pageUnlocked && (
        <div className="absolute z-[2018] flex h-full w-full items-center justify-center bg-white-901">
          <div className="border border-grey-900 bg-white-900 px-4 py-8">
            <H4>This page is locked and requires permission to access.</H4>
          </div>
        </div>
      )}

      <H2>Buy Oven for Customer</H2>

      <p className="mb-4">
        This can be used to purchase an oven on behalf of a customer. Be sure to
        have clear permission from the customer before running. Make sure
        payment and shipping address are correct before placing order. The
        button is protected by an administrator password.
      </p>

      <div className="space-y-8">
        {user.shippingAddresses.length === 0 ? (
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <H3>1. Add a shipping address</H3>

            <ShippingForm twoColumn {...user} initialize={initialize} />

            <div className="mt-4">
              <Button size="large" type="submit">
                Save Shipping Address
              </Button>
            </div>
          </form>
        ) : (
          <div>
            <div className="text-green-903">
              <H3>1. Shipping Address &#10004;</H3>
            </div>
            <p className="text-xs">Edit on the Shipping tab</p>
            <p>
              {user.shippingAddresses[0].line1},{' '}
              {user.shippingAddresses[0].city}
            </p>
          </div>
        )}

        {isCustomer(customer) &&
        (!customer.sources || customer.sources.total_count < 1) ? (
          <div>
            <H3>2. Add a card</H3>
            <Elements
              options={getElementsConfiguration()}
              stripe={stripePromise}
            >
              <CcForm user={user} />
            </Elements>
          </div>
        ) : (
          <div>
            <div className="text-green-903">
              <H3>2. Add a card &#10004;</H3>
            </div>
            <p className="text-xs">Edit on the Payment tab</p>
            {defaultCard && (
              <p>
                {defaultCard.brand} x{defaultCard.last4}
              </p>
            )}
          </div>
        )}

        {user.shippingAddresses.length > 0 &&
          isCustomer(customer) &&
          customer.sources &&
          customer.sources.total_count > 0 && (
            <div>
              <H3>3. Select a product</H3>
              <div className="flex space-x-4">
                <div className="w-8/12 space-y-4">
                  <div>
                    <FormLabel>Product</FormLabel>
                    <Select onChange={(e) => selectProduct(e.target.value)}>
                      <option value="">Please select a product</option>
                      {tovalaProductOptions}
                    </Select>
                  </div>

                  <div>
                    <FormLabel>Meal Plan - required for commitment</FormLabel>
                    <div className="mt-2 space-y-2">
                      {userSubscriptionTypes.map((subscription) => {
                        return (
                          <Radio
                            key={subscription.id}
                            label={`${subscription.name} ($${
                              subscription.price_cents / 100
                            } + $${subscription.shippingCents / 100} shipping)`}
                            name="radio"
                            onChange={(e) => {
                              setPlan(e.target.value)
                            }}
                            value={JSON.stringify(subscription)}
                          />
                        )
                      })}
                    </div>
                  </div>

                  <div>
                    <FormLabel>Coupon Code</FormLabel>
                    <div className="flex space-x-4">
                      <Input
                        onChange={(e) => changeCoupon(e.target.value)}
                        placeholder="Enter coupon"
                        type="text"
                        value={couponCode}
                      />
                      <div className="h-10 w-1/4">
                        <Button
                          onClick={() => {
                            if (couponApplied && !couponApplied.valid) {
                              dispatch(clearCoupon())
                              changeCoupon('')
                            } else {
                              saveCoupon()
                            }
                          }}
                          size="fluid"
                        >
                          {couponApplied && !couponApplied.valid
                            ? 'Clear'
                            : 'Apply'}
                        </Button>
                      </div>
                    </div>
                    {couponApplied && !couponApplied.valid && (
                      <p className="text-red-901">Coupon Invalid!</p>
                    )}
                  </div>

                  {hasBuyProductError && (
                    <AlertInline alertStyle="danger">
                      <div className="space-y-2">
                        <p className="font-bold">
                          Something went wrong placing the order for this user.
                        </p>

                        {errorMessage && <p>{errorMessage}</p>}

                        {isAxiosErrorWithExtra(buyProductError) && (
                          <p>
                            Stripe error message: &quot;
                            {buyProductError.response?.data.extra.message}&quot;
                          </p>
                        )}
                      </div>
                    </AlertInline>
                  )}

                  <ButtonLoading
                    disabled={!!(couponApplied && !couponApplied.valid)}
                    isLoading={isBuyingProduct}
                    onClick={() => placeOrder()}
                    size="large"
                  >
                    Place Order for User
                  </ButtonLoading>
                </div>
                <div className="w-4/12">
                  {tax && (
                    <div className="space-y-4 bg-grey-902 p-4">
                      <p className="flex justify-between">
                        <span>
                          <strong>Oven</strong>
                        </span>
                        <span>{formatCentsToDollars(tax.skuPrice)}</span>
                      </p>

                      {couponApplied && couponApplied.valid && (
                        <p className="text-green-903">
                          {couponApplied.couponCode.code.toUpperCase()} applied
                          -
                          {couponApplied.couponCode.dollarAmount > 0 && (
                            <span>
                              {' '}
                              ${couponApplied.couponCode.dollarAmount}
                            </span>
                          )}
                          {couponApplied.couponCode.dollarPercent > 0 && (
                            <span>
                              {' '}
                              {couponApplied.couponCode.dollarPercent}%
                            </span>
                          )}{' '}
                          off
                        </p>
                      )}

                      <p className="flex justify-between">
                        <span>
                          <strong>Tax</strong>
                        </span>
                        <span>{formatCentsToDollars(tax.taxAmount)}</span>
                      </p>

                      <p className="flex justify-between border-t">
                        <span>
                          <strong>Total</strong>
                        </span>
                        <span>
                          {formatCentsToDollars(
                            tax.priceWithDiscount + tax.taxAmount
                          )}
                        </span>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
      </div>
    </div>
  )
}

export default form(UserBuyOven)
