import { DATE_FORMATS, formatDate } from 'utils/dates'
import {
  SubscriptionPreferences,
  UserV1,
  UserV1Membership,
  UserV1ShipPeriodInfo,
  UserV1ShippingAddress,
  useUserV0,
} from '@tovala/browser-apis-combinedapi'

import OverviewLine from './OverviewLine'

const MealPlan = ({
  memberships,
  planPreferences,
  user,
}: {
  memberships: UserV1Membership[]
  planPreferences: SubscriptionPreferences | ''
  user: UserV1
}): JSX.Element => {
  const { data: userV0 } = useUserV0({ userID: user.id })

  const getShippingText = () => {
    const subscriptionType = user.subscription.subscriptionType
    let shippingFeeApplies = true
    let shippingText = ''
    let alwaysFreeShipping = false
    let fourMealBoxFreeShipping = false

    if (memberships) {
      alwaysFreeShipping = memberships.some(
        (membership) => membership.membershipTypeID === 'grandfathered_delivery'
      )
      fourMealBoxFreeShipping = memberships.some(
        (membership) =>
          membership.membershipTypeID === 'grandfathered_four_mealbox'
      )
    }

    if (alwaysFreeShipping) {
      shippingFeeApplies = false
      shippingText = 'Free 3 & 4 (OG)'
    } else if (
      subscriptionType &&
      subscriptionType.maxSelections >= 4 &&
      fourMealBoxFreeShipping
    ) {
      shippingFeeApplies = false
      shippingText = 'Free 4 (OG)'
    }

    if (shippingFeeApplies) {
      if (subscriptionType && subscriptionType.shippingCents > 0) {
        shippingText = `$${(subscriptionType.shippingCents / 100).toFixed(2)}`
      } else {
        shippingText = 'Free'
      }
    }

    return shippingText
  }

  let shippingAddress: UserV1ShippingAddress | '' = ''
  let availableShipPeriods: UserV1ShipPeriodInfo[] | '' = ''
  let defaultShipPeriod: UserV1ShipPeriodInfo | '' | undefined = ''
  let otherShipPeriod: UserV1ShipPeriodInfo[] | '' = ''

  if (user.shippingAddresses.length) {
    shippingAddress = user.shippingAddresses[0]
    defaultShipPeriod = shippingAddress.shipPeriodInfo.find(
      (s) => s.shipPeriod === user.subscription.defaultShipPeriod
    )
    availableShipPeriods = shippingAddress.shipPeriodInfo
      .filter((s) => s.isAvailable)
      .sort((a, b) => a.shipPeriod - b.shipPeriod)

    if (availableShipPeriods.length > 1) {
      otherShipPeriod = availableShipPeriods.filter(
        (s) => s.shipPeriod !== user.subscription.defaultShipPeriod
      )
    }
  }

  let autoselectPreferencesStatus = ''
  if (planPreferences) {
    autoselectPreferencesStatus = `
      ${planPreferences.single === true ? 'Assorted Meals' : 'Paired Meals'},
      ${
        planPreferences.autofillSurchargeOK === true
          ? 'Surcharged OK'
          : 'No Surcharge'
      },
      ${
        planPreferences.autofillBreakfastOK === true
          ? 'Breakfast OK'
          : 'No Breakfast'
      }

    `
  }

  return (
    <div className="space-y-2">
      {user.subscription.status !== 'active' && (
        <OverviewLine label="Subscription">
          {user.subscription.subscriptionType ? 'Paused' : 'Canceled'}
        </OverviewLine>
      )}

      {user.subscription.status === 'active' && (
        <>
          <OverviewLine label="Subscription">Active</OverviewLine>

          <OverviewLine label="Plan">
            {user.subscription.subscriptionType && (
              <span className="m-0">
                {user.subscription.subscriptionType.typeName}
              </span>
            )}
          </OverviewLine>

          <OverviewLine label="Autoselect">
            {autoselectPreferencesStatus}
          </OverviewLine>

          <OverviewLine label="Substitution">
            {planPreferences && planPreferences.doNotReplace
              ? "Don't substitute"
              : 'Allow substitutions'}
          </OverviewLine>

          <OverviewLine label="Surcharged Meals">
            {planPreferences && planPreferences.premiumMealsOk
              ? "Don't ask before adding"
              : 'Ask before adding'}
          </OverviewLine>

          {userV0?.subscription.commitment_details && (
            <>
              {userV0.subscription.commitment_details.commitment_meals > 0 && (
                <OverviewLine label="Commitment Meals">
                  {userV0.subscription.commitment_details.commitment_meals -
                    userV0.subscription.commitment_details.meals_remaining}{' '}
                  of {userV0.subscription.commitment_details.commitment_meals}{' '}
                  ordered
                </OverviewLine>
              )}
              {userV0.subscription.commitment_details.commitment_weeks > 0 && (
                <OverviewLine label="Commitment Deliveries">
                  {userV0.subscription.commitment_details.commitment_weeks -
                    userV0.subscription.commitment_details.weeks_remaining}{' '}
                  of {userV0.subscription.commitment_details.commitment_weeks}{' '}
                  ordered
                </OverviewLine>
              )}

              <OverviewLine label="Commitment Expiration">
                {formatDate(
                  userV0.subscription.commitment_details
                    .commitment_duration_date,
                  {
                    format: DATE_FORMATS.DEFAULT_DATE_FORMAT,
                  }
                )}
              </OverviewLine>
            </>
          )}

          {user.subscription && (
            <OverviewLine label="Shipping">{getShippingText()}</OverviewLine>
          )}
        </>
      )}

      {defaultShipPeriod ? (
        <>
          <OverviewLine label="Delivery Day">
            {defaultShipPeriod.deliveryDayOfTheWeek}s
          </OverviewLine>

          {otherShipPeriod && (
            <OverviewLine label="Optional Delivery Day">
              {otherShipPeriod.map((s) => (
                <span key={s.deliveryDayOfTheWeek}>
                  {s.deliveryDayOfTheWeek}s
                </span>
              ))}
            </OverviewLine>
          )}
        </>
      ) : (
        <OverviewLine label="Available Delivery Day(s)">
          {availableShipPeriods &&
            availableShipPeriods.map((s) => (
              <div key={s.deliveryDayOfTheWeek} className="mr-2">
                {s.deliveryDayOfTheWeek}s
              </div>
            ))}
        </OverviewLine>
      )}
    </div>
  )
}

export default MealPlan
