import {
  ErrorCodeMessageMapCombinedAPI,
  MealTagList,
  SlotMapGroup,
  useCreateSlotMapGroup,
  useMealTags,
  useSlotMapGroups,
  useUpdateSlotMapGroup,
} from '@tovala/browser-apis-combinedapi'
import {
  APIErrorDisplay,
  Button,
  ButtonLoading,
} from '@tovala/component-library'
import { successHandler } from 'actions/notifications'
import { FormComboboxRHF } from 'components/common/FormComboboxRHF'
import { FormInputRHF } from 'components/common/FormInputRHF'
import Loader from 'components/common/Loader'
import SmallLink from 'components/common/SmallLink'
import { useAppDispatch } from 'hooks'
import { useEffect } from 'react'
import { Control, useForm } from 'react-hook-form'
import { Route, Routes, useNavigate, useParams } from 'react-router-dom'
import { MEALS_WRITE, getAdminScope } from 'utils/getAdminScope'
import SlotMapGroupsTable, { SlotMapGroupRow } from './SlotMapGroupsTable'

interface FormData {
  associatedMealTag: {
    label: string
    value: string
  } | null
  slotMapLetter: string
  title: string
}

const CREATE_SLOT_MAP_GROUP_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please wait a few minutes and try again.',
    whatHappened: 'Unable to Add Slot Map Group',
    why: "We couldn't add this slot map group due to a technical issue on our end.",
  },
}

const LOAD_SLOT_MAP_GROUPS_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please reload the page to try again.',
    whatHappened: 'Unable to Load Slot Map Groups',
    why: "We couldn't load slot map groups due to a technical issue on our end.",
  },
}

const UPDATE_SLOT_MAP_GROUP_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please wait a few minutes and try again.',
    whatHappened: 'Unable to Update Slot Map Group',
    why: "We couldn't update this slot map group due to a technical issue on our end.",
  },
}

const SlotMapGroupsPage = () => {
  const { data: mealTags = [] } = useMealTags()

  const {
    data: slotMapGroups,
    error: loadSlotMapGroupsError,
    isError: hasLoadSlotMapGroupsError,
    isLoading: isLoadingSlotMapGroups,
  } = useSlotMapGroups()

  if (isLoadingSlotMapGroups) {
    return <Loader />
  }

  if (hasLoadSlotMapGroupsError) {
    return (
      <APIErrorDisplay
        display="page"
        error={loadSlotMapGroupsError}
        errorCodeMessageMap={LOAD_SLOT_MAP_GROUPS_ERRORS}
      />
    )
  }

  return (
    <div className="mb-20">
      <Routes>
        <Route
          element={<ViewSlotMapGroups slotMapGroups={slotMapGroups} />}
          index
        />
        <Route
          element={
            <AddSlotMapGroup
              mealTags={mealTags}
              slotMapGroups={slotMapGroups}
            />
          }
          path="/add"
        />
        <Route
          element={
            <EditSlotMapGroup
              mealTags={mealTags}
              slotMapGroups={slotMapGroups}
            />
          }
          path="/:id"
        />
      </Routes>
    </div>
  )
}

export default SlotMapGroupsPage

const AddSlotMapGroup = ({
  mealTags,
  slotMapGroups,
}: {
  mealTags: MealTagList[]
  slotMapGroups: SlotMapGroup[]
}) => {
  useEffect(() => {
    document.title = `Glaze | Add Slot Map Group`
  }, [])

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const {
    error: createSlotMapGroupError,
    isError: hasCreateSlotMapGroupError,
    isLoading: isCreatingSlotMapGroup,
    mutate: createSlotMapGroup,
  } = useCreateSlotMapGroup()

  const { control, handleSubmit, setValue, watch } = useForm<FormData>({
    defaultValues: {
      associatedMealTag: null,
      title: '',
      slotMapLetter: '',
    },
  })

  const associatedMealTagValue = watch('associatedMealTag')

  const onSubmit = (formData: FormData) => {
    createSlotMapGroup(
      {
        data: {
          associatedMealTag: formData.associatedMealTag?.value ?? '',
          slotMapLetter: formData.slotMapLetter,
          title: formData.title,
        },
      },
      {
        onSuccess: () => {
          successHandler(
            dispatch,
            `Success! Slot map group ${formData.title} added.`
          )
          navigate('/slot-map-groups')
        },
      }
    )
  }

  // Get meal tags with "boxextra" in the title and that are not already associated with a slot map group
  const mealTagOptions = mealTags
    .filter((mealTag) => mealTag.title.toLowerCase().includes('boxextra'))
    .filter(
      (mealTag) =>
        !slotMapGroups.some(
          (slotMapGroup) => slotMapGroup.associatedMealTag === mealTag.title
        )
    )
    .map((mealTag) => {
      return {
        descriptionInternal: mealTag.description_internal,
        label: mealTag.title,
        value: mealTag.title,
      }
    })

  useEffect(() => {
    if (associatedMealTagValue?.value) {
      const title = { ...associatedMealTagValue }.value
        .toLowerCase()
        .replace('boxextra-', '')
      setValue('title', title)
    }
  }, [associatedMealTagValue, setValue])

  return (
    <div className="space-y-6">
      <h1 className="text-k/36_110">Add Slot Map Group</h1>

      <form className="space-y-10" onSubmit={handleSubmit(onSubmit)}>
        <SlotMapGroupFieldset
          control={control}
          mealTagOptions={mealTagOptions}
        />

        {hasCreateSlotMapGroupError && (
          <APIErrorDisplay
            error={createSlotMapGroupError}
            errorCodeMessageMap={CREATE_SLOT_MAP_GROUP_ERRORS}
          />
        )}

        <div>
          <ButtonLoading
            isLoading={isCreatingSlotMapGroup}
            size="large"
            type="submit"
          >
            Add
          </ButtonLoading>
        </div>
      </form>
    </div>
  )
}

const EditSlotMapGroup = ({
  mealTags,
  slotMapGroups,
}: {
  mealTags: MealTagList[]
  slotMapGroups: SlotMapGroup[]
}) => {
  const { id } = useParams()
  const dispatch = useAppDispatch()

  const selectedSlotMapGroup = slotMapGroups.find(
    (slotMapGroup) => slotMapGroup.id === id
  )

  const {
    error: updateSlotMapGroupError,
    isError: hasUpdateSlotMapGroupError,
    isLoading: isUpdatingSlotMapGroup,
    mutate: updateSlotMapGroup,
  } = useUpdateSlotMapGroup()

  const { control, handleSubmit, setValue, watch } = useForm<FormData>({
    defaultValues: {
      associatedMealTag: selectedSlotMapGroup
        ? {
            label: selectedSlotMapGroup.associatedMealTag,
            value: selectedSlotMapGroup.associatedMealTag,
          }
        : null,
      title: selectedSlotMapGroup?.title ?? '',
      slotMapLetter: selectedSlotMapGroup?.slotMapLetter ?? '',
    },
  })
  const associatedMealTagValue = watch('associatedMealTag')

  const onSubmit = (formData: FormData) => {
    if (!selectedSlotMapGroup) {
      return
    }

    updateSlotMapGroup(
      {
        data: {
          associatedMealTag: formData.associatedMealTag?.value ?? '',
          slotMapLetter: formData.slotMapLetter,
          title: formData.title,
        },
        slotMapGroupID: selectedSlotMapGroup.id,
      },
      {
        onSuccess: () => {
          successHandler(
            dispatch,
            `Success! Slot map group ${formData.title} updated.`
          )
        },
      }
    )
  }

  // Get meal tags with "boxextra" in the title and that are not already associated with a slot map group
  const mealTagOptions = mealTags
    .filter((mealTag) => mealTag.title.toLowerCase().includes('boxextra'))
    .filter(
      (mealTag) =>
        !slotMapGroups.some(
          (slotMapGroup) =>
            slotMapGroup.id !== selectedSlotMapGroup?.id &&
            slotMapGroup.associatedMealTag === mealTag.title
        )
    )
    .map((mealTag) => {
      return {
        descriptionInternal: mealTag.description_internal,
        label: mealTag.title,
        value: mealTag.title,
      }
    })

  useEffect(() => {
    if (associatedMealTagValue?.value) {
      const title = { ...associatedMealTagValue }.value
        .toLowerCase()
        .replace('boxextra-', '')
      setValue('title', title)
    }
  }, [associatedMealTagValue, setValue])

  return (
    <div className="space-y-6">
      <h1 className="text-k/36_110">Update {selectedSlotMapGroup?.title}</h1>

      <form className="space-y-10" onSubmit={handleSubmit(onSubmit)}>
        <SlotMapGroupFieldset
          control={control}
          disabled={!getAdminScope(MEALS_WRITE)}
          mealTagOptions={mealTagOptions}
        />

        {hasUpdateSlotMapGroupError && (
          <APIErrorDisplay
            error={updateSlotMapGroupError}
            errorCodeMessageMap={UPDATE_SLOT_MAP_GROUP_ERRORS}
          />
        )}

        <div>
          <ButtonLoading
            isLoading={isUpdatingSlotMapGroup}
            size="large"
            type="submit"
          >
            Save
          </ButtonLoading>
        </div>
      </form>
    </div>
  )
}

const ViewSlotMapGroups = ({
  slotMapGroups,
}: {
  slotMapGroups: SlotMapGroup[]
}) => {
  const navigate = useNavigate()

  useEffect(() => {
    document.title = `Glaze | Slot Map Groups`
  }, [])

  return (
    <div className="flex space-x-8">
      <div className="w-10/12 space-y-6">
        <h1 className="text-k/36_110">Slot Map Groups</h1>

        <SlotMapGroupsTable>
          {[...slotMapGroups]
            .sort((a, b) => a.title.localeCompare(b.title))
            .map((slotMapGroup) => {
              return (
                <SlotMapGroupRow
                  key={slotMapGroup.id}
                  slotMapGroup={slotMapGroup}
                >
                  <SmallLink to={`/slot-map-groups/${slotMapGroup.id}`}>
                    Edit
                  </SmallLink>
                </SlotMapGroupRow>
              )
            })}
        </SlotMapGroupsTable>
      </div>

      <div className="w-2/12 space-y-3">
        <Button
          buttonStyle="stroke"
          onClick={() => {
            navigate('/slot-map-groups/add')
          }}
          size="large"
        >
          Add Slot Map Group
        </Button>
      </div>
    </div>
  )
}

const SlotMapGroupFieldset = ({
  control,
  disabled = false,
  mealTagOptions,
}: {
  control: Control<FormData>
  disabled?: boolean
  mealTagOptions: {
    label: string
    value: string
  }[]
}) => {
  return (
    <fieldset className="space-y-6" disabled={disabled}>
      <div>
        <FormComboboxRHF
          control={control}
          id="associatedMealTag"
          isClearable
          label="Associated Meal Tag"
          name="associatedMealTag"
          options={mealTagOptions}
        />
        <p className="text-body-sm">
          BOXEXTRA meal tags that are available to be associated with a slot map
          group.
        </p>
      </div>

      <FormInputRHF
        control={control}
        id="title"
        label="Title"
        name="title"
        rules={{
          required: 'Please enter a title.',
        }}
      />

      <div>
        <FormInputRHF
          control={control}
          id="slotMapLetter"
          label="Slot Map Letter"
          name="slotMapLetter"
        />
        <p className="text-body-sm">
          Leave blank to use associated meal tag's letter. If entering a slot
          map letter, it must match the associated meal tag's letter if one
          exists.
        </p>
      </div>
    </fieldset>
  )
}
