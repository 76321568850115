import { FormEvent, useState } from 'react'
import { OrderHistoryReceipt, UserV1 } from '@tovala/browser-apis-combinedapi'

import { updateUTOFulfillmentStatus } from '../../actions/user'

import { useAppDispatch } from 'hooks'
import Button from 'components/common/Button'
import Input from 'components/common/Input'
import Modal, { ModalBody, ModalHeader } from 'components/modals/Modal'

export interface Props {
  onCloseModal(): void
  receipt: OrderHistoryReceipt
  user: UserV1
}

const UpdateUTOFulfillmentStatusModal = ({
  onCloseModal,
  receipt,
  user,
}: Props): JSX.Element | null => {
  const dispatch = useAppDispatch()

  const [notes, setNotes] = useState('')

  const handleSubmit = (
    e: FormEvent<HTMLFormElement>,
    userID: number,
    orderID: string,
    orderStatus: 'canceled' | 'refunded'
  ) => {
    e.preventDefault()
    const payload = {
      orderStatus,
      notes,
    }

    dispatch(updateUTOFulfillmentStatus(userID, orderID, payload))
    onCloseModal()
  }

  const isRefunded = receipt.orderStatus === 'refunded'
  const newStatus = isRefunded ? 'canceled' : 'refunded'

  return (
    <Modal onCloseModal={onCloseModal}>
      <ModalBody>
        <ModalHeader onClickClose={onCloseModal}>
          Update Term #{receipt.termID} Order Status
        </ModalHeader>
        <div className="w-[500px] space-y-4">
          <div>
            <p>{user.info.name}</p>
            <p>{user.info.email}</p>
          </div>

          <div>
            <p className="font-bold">Current Order Status</p>
            <p>{receipt.orderStatus}</p>
          </div>

          <form
            className="space-y-4"
            onSubmit={(e) => {
              return handleSubmit(
                e,
                user.id,
                receipt.userTermOrderID,
                newStatus
              )
            }}
          >
            <div>
              <p className="font-bold">New Order Status</p>
              <p>{newStatus}</p>
            </div>

            <div>
              <p className="font-bold">Notes*</p>
              <Input
                onChange={(e) => {
                  setNotes(e.target.value)
                }}
                required
                type="text"
                value={notes}
              />
            </div>

            <Button size="large" type="submit">
              Change Order Status to {newStatus}
            </Button>
          </form>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default UpdateUTOFulfillmentStatusModal
