import { useState } from 'react'

import { getAdminScope, ORDER_OVERRIDE } from '../../utils/getAdminScope'
import { uploadPackslips } from '../../actions/packslips'

import { useAppDispatch } from 'hooks'
import Button from 'components/common/Button'
import FileDropzone from '../common/FileDropzone'
import FormLabel from 'components/common/FormLabel'
import H3 from 'components/common/H3'
import Input from 'components/common/Input'

interface UploadFormData {
  orderId: string
  orderNumber: string
  formData: FormData
}

const Upload = (): JSX.Element => {
  const dispatch = useAppDispatch()

  const [file, setFile] = useState<File>()
  const [orderId, setOrderId] = useState('')
  const [orderNumber, setOrderNumber] = useState('')

  const onSetFile = (files: File[]) => {
    const filename = files[0].name

    let nameArray: string[] = []
    if (filename.includes('_')) {
      nameArray = filename.split('_')
    } else if (filename.includes('-')) {
      nameArray = filename.split('-')
    }

    const orderIDParam = nameArray[0]
    const itemIDSplit = nameArray[1].split('.')
    const itemIDParam = itemIDSplit[0]

    setOrderId(itemIDParam)
    setOrderNumber(orderIDParam)
    setFile(files[0])
  }

  const submitUpload = () => {
    if (!file) {
      throw new Error('Cannot upload packslips without a file')
    }

    const formData = new FormData()

    formData.append('file', file)
    formData.append('name', 'Tovala Admin')

    const payload: UploadFormData = {
      orderId,
      orderNumber,
      formData,
    }

    dispatch(uploadPackslips(payload)).then((response) => {
      if (response?.payload) {
        setOrderNumber('')
        setOrderId('')
        setFile(undefined)
      }
    })
  }

  if (getAdminScope(ORDER_OVERRIDE)) {
    return (
      <div>
        <div>
          <H3>Upload Packslips</H3>
        </div>

        <div className="w-80 space-y-2">
          <Input
            onChange={(event) => setOrderNumber(event.target.value)}
            placeholder="Order Number"
            type="text"
            value={orderNumber}
          />
          <Input
            onChange={(event) => setOrderId(event.target.value)}
            placeholder="Item ID"
            type="text"
            value={orderId}
          />
        </div>

        <div>
          <FormLabel>Pack Slip PDF</FormLabel>
          {file ? (
            <p>{file.name}</p>
          ) : (
            <FileDropzone
              accept={{ 'application/pdf': ['.pdf'] }}
              maxFiles={1}
              onDrop={onSetFile}
            />
          )}
        </div>

        <div className="mt-4">
          <Button
            disabled={!file || !orderId || !orderNumber}
            onClick={submitUpload}
            size="large"
          >
            Submit
          </Button>
        </div>
      </div>
    )
  }

  return <div>Sorry Charlie, you don&apos;t have permission to do this.</div>
}

export default Upload
