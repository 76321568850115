import { CouponCode, useTovalaProducts } from '@tovala/browser-apis-combinedapi'
import { filter } from 'lodash-es'
import { Link } from 'react-router-dom'
import moment from 'moment'
import ReactTable, { TableProps } from 'react-table'
import { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'

import { getAdminScope, PRODUCTS_WRITE } from '../../utils/getAdminScope'
import { getAllCouponCodes } from '../../actions/marketing'
import { validateCoupon } from '../../actions/auth'

import { useAppDispatch, useAppSelector } from 'hooks'
import Button from 'components/common/Button'
import H1 from 'components/common/H1'
import Input from 'components/common/Input'
import Modal, { ModalBody, ModalHeader } from 'components/modals/Modal'
import Select from 'components/common/Select'

const CouponCodes = (): JSX.Element => {
  const [filteredCodes, setFilteredCodes] = useState<string[][]>([])

  const dispatch = useAppDispatch()

  const allCouponCodes = useAppSelector(
    (state) => state.marketing.allCouponCodes
  )

  const [couponCode, setCouponCode] = useState('')
  const [isCouponValidateModalOpen, setIsCouponValidateModalOpen] =
    useState(false)
  const [selectedProduct, setSelectedProduct] = useState('')
  const [showExpired, setShowExpired] = useState(false)
  const [showIncompleteText, setShowIncompleteText] = useState(false)
  const [userid, setUserid] = useState('')

  const { data: tovalaProducts = [] } = useTovalaProducts()

  const openModal = () => {
    setIsCouponValidateModalOpen(true)
  }

  const onCloseModal = () => {
    setIsCouponValidateModalOpen(false)
  }

  const onValidateCoupon = () => {
    if (!selectedProduct || !couponCode) {
      setShowIncompleteText(true)
      return
    }

    const id = userid ? Number.parseInt(userid, 10) : 0
    dispatch(validateCoupon(selectedProduct, couponCode, id))
    setShowIncompleteText(false)
  }

  const validationModal = () => {
    if (!isCouponValidateModalOpen) {
      return null
    }

    const validProducts = filter(tovalaProducts, (p) => p.priceCents > 0)

    return (
      <Modal onCloseModal={onCloseModal}>
        <ModalBody>
          <ModalHeader onClickClose={onCloseModal}>
            Validate coupon code
          </ModalHeader>
          <div className="flex w-[300px] flex-col space-y-4">
            {showIncompleteText && (
              <span>
                Please make sure both fields are filled out before validating.
              </span>
            )}

            <Select
              onChange={(e) => {
                setSelectedProduct(e.target.value)
              }}
              value={selectedProduct}
            >
              <option value="">Select Product</option>
              {validProducts.map((product) => (
                <option key={product.id} value={product.id}>
                  {product.description}
                </option>
              ))}
            </Select>
            <Input
              onChange={(e) => {
                setCouponCode(e.target.value)
              }}
              placeholder="coupon code"
              type="text"
              value={couponCode}
            />
            <Input
              onChange={(e) => {
                setUserid(e.target.value)
              }}
              placeholder="user ID (optional)"
              type="text"
              value={userid}
            />
            <div className="h-10">
              <Button onClick={onValidateCoupon} size="fluid">
                Validate Coupon
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    )
  }

  useEffect(() => {
    document.title = `Glaze | Coupon Codes`
  }, [])

  useEffect(() => {
    dispatch(getAllCouponCodes())
  }, [dispatch])

  const currentCoupons: CouponCode[] = []
  const today = moment()

  if (allCouponCodes) {
    allCouponCodes.forEach((coupon) => {
      if (
        coupon.redeem_by === null ||
        (coupon.redeem_by && moment(coupon.redeem_by).isSameOrAfter(today))
      ) {
        currentCoupons.push(coupon)
      }
    })
  }

  const columns: TableProps<CouponCode>['columns'] = [
    {
      Header: 'Coupon Code',
      accessor: 'code',
      width: 150,
      filterMethod: (filter, row) =>
        row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
    },
    {
      Header: 'Description',
      accessor: 'description',
      width: 300,
      filterMethod: (filter, row) =>
        row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
    },
    {
      Header: 'Dollar Amount',
      accessor: 'dollar_amount',
      Cell: (props: { value: number }) => (
        <span>{props.value !== 0 && <span>${props.value}</span>}</span>
      ),
    },
    {
      Header: 'Percentage',
      accessor: 'dollar_percent',
      Cell: (props: { value: number }) => (
        <span>{props.value !== 0 && <span>{props.value}%</span>}</span>
      ),
    },
    {
      Header: 'Credit Amount',
      accessor: 'credit_amount',
      Cell: (props: { value: number }) => (
        <span>{props.value !== 0 && <span>${props.value / 100}</span>}</span>
      ),
    },
    {
      Header: 'Meal Credits',
      accessor: 'meals_amount',
      Cell: (props: { value: number }) => (
        <span>{props.value !== 0 && <span>{props.value}</span>}</span>
      ),
    },
    {
      Header: 'Max Redemptions',
      accessor: 'max_redemptions',
      Cell: (props: { value: number }) => (
        <span>{props.value !== 0 && <span>{props.value}</span>}</span>
      ),
    },
    {
      Header: 'Redeem By',
      accessor: 'redeem_by',
      Cell: (props: { value: string }) => (
        <span>
          {props.value && moment(props.value).format('MM/DD/YYYY h:mm a')}
        </span>
      ),
    },
    {
      Header: 'User ID',
      accessor: 'userid',
      Cell: (props: { value: number }) => (
        <span>{props.value !== 0 && <span>{props.value}</span>}</span>
      ),
    },
    {
      Header: 'Notes',
      accessor: 'notes',
      Cell: (props: { value: string }) => <span>{props.value}</span>,
      filterMethod: (filter, row) =>
        row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
    },
    {
      Header: 'Created',
      accessor: 'created',
      Cell: (props: { value: string }) => (
        <span>{moment(props.value).format('MM/DD/YYYY h:mm a')}</span>
      ),
    },
    {
      accessor: 'code',
      width: 70,
      show: getAdminScope(PRODUCTS_WRITE),
      Cell: (props: { value: number }) => (
        <Link
          className="ml-2 text-sm font-semibold uppercase tracking-widest text-red-901"
          to={`/coupon-codes/${props.value}`}
        >
          Edit
        </Link>
      ),
    },
  ]

  type filterDataType = {
    id: string
    value: string
  }

  type tableDataType = {
    code: string
    created: string
    credit_amount: number
    description: string
    dollar_amount: number
    dollar_percent: number
    max_food_orders: number
    max_redemptions: number
    meals_amount: number
    notes: string
    promotionDuration: number
    promotionExpiration: number
    promotionInterval: string
    promotionType: string
    redeem_by: string | null
    sku: string
    userid: number
  }

  const handleFilteredChange = (filtered: filterDataType[]) => {
    let filteredData: tableDataType[]
    if (filtered[0]) {
      filteredData = tableData.filter((row) => {
        return filtered.every((filter) => {
          const { id, value }: filterDataType = filter
          return String(row[id]).toLowerCase().includes(value.toLowerCase())
        })
      })
      const codesArray: string[][] = filteredData.map((item) => [
        item.code.toUpperCase(),
      ])
      setFilteredCodes(codesArray)
    } else {
      setFilteredCodes([])
    }
  }

  const tableData = showExpired ? allCouponCodes : currentCoupons

  return (
    <div>
      {validationModal()}
      <div>
        <div className="flex items-end justify-between">
          <H1>Coupon Codes</H1>

          <div className="flex space-x-2">
            <Button buttonStyle="stroke" onClick={openModal} size="large">
              Validate Coupon
            </Button>

            {getAdminScope(PRODUCTS_WRITE) && (
              <Link to="add">
                <Button buttonStyle="stroke" size="large">
                  Add Coupon Code
                </Button>
              </Link>
            )}

            <CSVLink data={filteredCodes} filename={'coupon-codes.csv'}>
              <Button
                buttonStyle="stroke"
                disabled={filteredCodes.length === 0}
                size="large"
              >
                Download Coupon Codes
              </Button>
            </CSVLink>
          </div>
        </div>

        <div>
          <label className="mb-4 flex space-x-3 text-sm uppercase tracking-widest">
            <input
              onChange={() => {
                setShowExpired((showExpired) => !showExpired)
              }}
              type="checkbox"
            />
            <span>Include Expired Coupons</span>
          </label>

          <div className="my-8">
            <p>
              <em>
                Note: Sort by clicking on column headings. Filter by typing into
                the input boxes. (Filter data to enable download.)
              </em>
            </p>
          </div>
          {currentCoupons && (
            <ReactTable
              className="-striped -highlight"
              columns={columns}
              data={tableData}
              defaultSorted={[
                {
                  id: 'created',
                  desc: true,
                },
              ]}
              filterable
              onFilteredChange={handleFilteredChange}
              pageSize={20}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default CouponCodes
