import {
  OrderHistoryReceipt,
  RemainingOrderBalance,
} from '@tovala/browser-apis-combinedapi'

import Hr from 'components/common/Hr'
import Modal, { ModalBody, ModalHeader } from 'components/modals/Modal'

export interface Props {
  onCloseModal(): void
  paymentInvoice: RemainingOrderBalance['paymentInvoice']
  receipt: OrderHistoryReceipt
}

const RemainingOrderBalanceModal = ({
  onCloseModal,
  paymentInvoice,
  receipt,
}: Props): JSX.Element => {
  const refundPLIs = [
    {
      label: 'Refunded to Credit Card',
      type: 'refundedCharge',
    },
    {
      label: 'Refunded to Tovala Cash',
      type: 'refundedCash',
    },
    {
      label: 'Refunded to Gift Card Balance',
      type: 'refundedGiftCard',
    },
  ]

  const invoicePLIs = [
    {
      label: 'Meals',
      type: 'sku',
    },
    {
      label: 'Shipping',
      type: 'shipping',
    },
    {
      label: 'Tax',
      type: 'tax',
    },
    {
      label: 'Discount',
      type: 'discount',
    },
    {
      label: 'Marketing Credits',
      type: 'marketingCredits',
    },
  ]

  const paidPLIs = [
    {
      label: 'Paid by Credit Card',
      type: 'paidCharge',
    },
    {
      label: 'Paid by Tovala Cash',
      type: 'paidCash',
    },
    {
      label: 'Paid by Gift Card',
      type: 'paidGift',
    },
  ]

  return (
    <Modal onCloseModal={onCloseModal}>
      <ModalBody>
        <ModalHeader onClickClose={onCloseModal}>
          Term #{receipt.termID}
        </ModalHeader>
        <div className="w-[500px]">
          <div className="flex space-x-4 text-lg">
            <span>Available Balance to Refund</span>
            <span>
              ${(paymentInvoice.availableBalanceToRefund / 100).toFixed(2)}
            </span>
          </div>

          {refundPLIs.map((pli) => {
            const { label, type } = pli

            if (paymentInvoice[type] > 0) {
              return (
                <div key={type} className="flex space-x-4">
                  <span>{label}</span>
                  <span>${(paymentInvoice[type] / 100).toFixed(2)}</span>
                </div>
              )
            }
          })}

          <Hr />

          <p className="font-bold">Invoice</p>
          <div className="flex space-x-4 text-lg">
            <span>Order Total</span>
            <span>${(paymentInvoice.totalInvoice / 100).toFixed(2)}</span>
          </div>

          <div className="pl-4">
            {invoicePLIs.map((pli) => {
              const { label, type } = pli
              if (paymentInvoice[type] > 0) {
                return (
                  <div key={type} className="flex space-x-4">
                    <span>{label}</span>
                    <span>${(paymentInvoice[type] / 100).toFixed(2)}</span>
                  </div>
                )
              }
            })}
          </div>

          <div className="mt-4">
            {paidPLIs.map((pli) => {
              const { label, type } = pli
              if (paymentInvoice[type] > 0) {
                return (
                  <div key={type} className="flex space-x-4 text-lg">
                    <span>{label}</span>
                    <span>${(paymentInvoice[type] / 100).toFixed(2)}</span>
                  </div>
                )
              }
            })}
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default RemainingOrderBalanceModal
