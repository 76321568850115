import { Link } from 'react-router-dom'
import { useEffect } from 'react'

import { getAdminScope, MEALS_WRITE } from '../../utils/getAdminScope'
import { getAllMeals } from '../../actions/meals'

import { useAppDispatch, useAppSelector } from 'hooks'
import Button from 'components/common/Button'
import H2 from 'components/common/H2'

const MealsInDevelopment = (): JSX.Element => {
  const dispatch = useAppDispatch()

  const allMeals = useAppSelector((state) => state.meals.allMeals)

  useEffect(() => {
    document.title = `Meals in Development`
  }, [])

  useEffect(() => {
    dispatch(getAllMeals())
  }, [dispatch])

  return (
    <div className="flex space-x-8">
      <div className="w-7/12">
        <H2>Meals in Development</H2>
        <p className="mb-4">Meals not assigned to a term.</p>

        {allMeals.map((meal, i) => {
          if (meal.termid !== 0) {
            return null
          }

          return (
            <div key={i} className="border-b border-grey-900 py-4">
              <h3 className="font-serif text-lg font-bold">
                <div data-meal-id={meal.id} data-meal-title={meal.title}>
                  <Link to={`/meals/${meal.id}`}>
                    {meal.title}{' '}
                    <span className="text-sm font-normal uppercase">
                      (Meal ID #{meal.id})
                    </span>
                  </Link>
                  <Link
                    className="ml-4 text-sm uppercase tracking-widest text-red-901"
                    to={`/meals/${meal.id}`}
                  >
                    {getAdminScope(MEALS_WRITE) ? 'Edit' : 'View'}
                  </Link>
                </div>
              </h3>
            </div>
          )
        })}
      </div>

      <div className="w-1/4 space-y-3">
        {getAdminScope(MEALS_WRITE) && (
          <Link className="block h-10" to={`/meals/add`}>
            <Button buttonStyle="stroke" size="fluid">
              Add Meal
            </Button>
          </Link>
        )}

        <Link className="block h-10" to={`/meals`}>
          <Button buttonStyle="grey" size="fluid">
            Tovala Meal Schedule
          </Button>
        </Link>
      </div>
    </div>
  )
}

export default MealsInDevelopment
