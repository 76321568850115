// From this doc: https://tovala.atlassian.net/wiki/spaces/MAR/pages/535789676/The+Meal+Copy+Stylebook+a.k.a.+how+to+make+meal+copy+accurate+look+pretty

export const ingredientAllergens: {
  allergens: string[]
  ingredient: string
  note?: string
}[] = [
  {
    ingredient: 'basil pesto',
    allergens: ['milk'],
  },
  {
    ingredient: 'candied walnuts',
    allergens: ['tree nut (walnut)', 'peanuts', 'soy'],
    note: '*Vegetable oil used in Candied Walnuts may contain peanut, cottonseed, soybean, and/or sunflower oil.',
  },
  {
    ingredient: 'chicken stock',
    allergens: ['soy'],
  },
  {
    ingredient: 'chow mein noodles',
    allergens: ['wheat'],
  },
  {
    ingredient: 'coconut',
    allergens: ['tree nuts (coconut)'],
  },
  {
    ingredient: 'corn nuts',
    allergens: [],
    note: 'The corn nuts in this meal were produced in a facility that also produces nuts.',
  },
  {
    ingredient: 'couscous',
    allergens: ['wheat'],
  },
  {
    ingredient: 'crispy onions',
    allergens: ['wheat'],
  },
  {
    ingredient: 'dark chocolate',
    allergens: ['soy', 'milk'],
    note: 'The dark chocolate in this meal may contain traces of nuts, milk and egg proteins, gluten, and peanut.',
  },
  {
    ingredient: 'edamame',
    allergens: ['soy'],
  },
  {
    ingredient: 'salmon',
    allergens: ['fish (salmon)'],
    note: 'Fish may contain bones.',
  },
  {
    ingredient: 'trout',
    allergens: ['fish (trout)'],
    note: 'Fish may contain bones.',
  },
  {
    ingredient: 'cod',
    allergens: ['fish (cod)'],
    note: 'Fish may contain bones.',
  },
  {
    ingredient: 'fish sauce',
    allergens: ['fish (anchovy)'],
  },
  {
    ingredient: 'gnocchi',
    allergens: ['wheat'],
    note: 'Gnocchi were produced in a facility that processes egg and milk. May contain soy.',
  },
  {
    ingredient: 'hoisin',
    allergens: ['soy', 'wheat'],
  },
  {
    ingredient: 'kimchi',
    allergens: ['fish (anchovy)', 'shellfish (shrimp)'],
  },
  {
    ingredient: 'mayonnaise',
    allergens: ['eggs'],
  },
  {
    ingredient: 'mild giardiniera',
    allergens: ['soy'],
  },
  {
    ingredient: 'miso paste',
    allergens: ['soy'],
  },
  {
    ingredient: 'elbow macaroni',
    allergens: ['wheat'],
  },
  {
    ingredient: 'farfalle',
    allergens: ['wheat'],
  },
  {
    ingredient: 'orecchiette',
    allergens: ['wheat'],
  },
  {
    ingredient: 'orzo',
    allergens: ['wheat'],
  },
  {
    ingredient: 'penne',
    allergens: ['wheat'],
  },
  {
    ingredient: 'rotini',
    allergens: ['wheat'],
  },
  {
    ingredient: 'spaghetti',
    allergens: ['wheat'],
  },
  {
    ingredient: 'panko',
    allergens: ['wheat'],
  },
  {
    ingredient: 'peanuts',
    allergens: ['peanuts'],
  },
  {
    ingredient: 'pita chips',
    allergens: ['wheat'],
  },
  {
    ingredient: 'plantain chips',
    allergens: [],
    note: 'Plantain chips contain canola (corn) oil and sunflower oil.',
  },
  {
    ingredient: 'sesame sticks',
    allergens: ['wheat'],
  },
  {
    ingredient: 'soy sauce',
    allergens: ['soy', 'wheat'],
  },
  {
    ingredient: 'tamari',
    allergens: ['soy'],
  },
  {
    ingredient: 'tofu',
    allergens: ['soy'],
  },
  {
    ingredient: 'wheat berries',
    allergens: ['wheat'],
  },
  {
    ingredient: 'wheat bread',
    allergens: ['soy', 'wheat'],
  },
  {
    ingredient: 'Worcestershire sauce',
    allergens: ['fish (anchovy)'],
  },
]

export const boxExtraTags: {
  keyword: string
  search: ('caption' | 'subtitle' | 'title')[]
  tag: string
  variation?: {
    tag: string
  }
}[] = [
  {
    keyword: 'flatbread',
    search: ['title'],
    tag: 'BOXEXTRA-flatbread',
  },
  {
    keyword: 'quesadilla',
    search: ['title'],
    tag: 'BOXEXTRA-tortilla10',
  },
  {
    keyword: 'tacos',
    search: ['title'],
    tag: 'BOXEXTRA-tacos',
    variation: {
      tag: 'BOXEXTRA-tortilla4-5X2',
    },
  },
  {
    keyword: 'flautas',
    search: ['title'],
    tag: 'BOXEXTRA-tacos',
  },
  {
    keyword: 'naan',
    search: ['title', 'subtitle'],
    tag: 'BOXEXTRA-naan',
  },
  {
    keyword: 'wrap',
    search: ['title'],
    tag: 'BOXEXTRA-burrito',
    variation: {
      tag: 'BOXEXTRA-tortilla10',
    },
  },
  {
    keyword: 'burrito',
    search: ['title'],
    tag: 'BOXEXTRA-burrito',
    variation: {
      tag: 'BOXEXTRA-tortilla10',
    },
  },
  {
    keyword: 'pita',
    search: ['title'],
    tag: 'BOXEXTRA-pita',
  },
  {
    keyword: 'chicken breast',
    search: ['title', 'caption'],
    tag: 'BOXEXTRA-vacpack-chicken',
  },
  {
    keyword: 'pork chop',
    search: ['title'],
    tag: 'BOXEXTRA-vacpack-pork',
  },
  {
    keyword: 'salmon',
    search: ['title'],
    tag: 'BOXEXTRA-vacpack-salmon',
  },
  {
    keyword: 'trout',
    search: ['title'],
    tag: 'BOXEXTRA-vacpack-trout',
  },
  {
    keyword: 'shrimp',
    search: ['title'],
    tag: 'BOXEXTRA-vacpack-shrimp',
  },
  {
    keyword: 'steak',
    search: ['title'],
    tag: 'BOXEXTRA-vacpack-steak',
  },
  {
    keyword: 'andouille sausage',
    search: ['title'],
    tag: 'BOXEXTRA-andouille-sausage',
  },
]

export const cookTimes: {
  keyword: string
  range: [string, string]
  search: ('routineMetadataObjects' | 'subtitle' | 'tags' | 'title')[]
  variation?: {
    keyword: string
    range: [string, string]
    search: 'title'
  }
}[] = [
  {
    keyword: 'BOXEXTRA-vacpack-chicken',
    search: ['tags'],
    range: ['20:00', ''],
  },
  {
    keyword: 'BOXEXTRA-vacpack-pork',
    search: ['tags'],
    range: ['17:30', ''],
  },

  {
    keyword: 'BOXEXTRA-vacpack-salmon',
    search: ['tags'],
    range: ['13:15', ''],
    variation: {
      keyword: 'crusted',
      search: 'title',
      range: ['17:00', '17:30'],
    },
  },
  {
    keyword: 'BOXEXTRA-vacpack-trout',
    search: ['tags'],
    range: ['14:00', '20:00'],
    variation: {
      keyword: 'crusted',
      search: 'title',
      range: ['15:00', '17:00'],
    },
  },
  {
    keyword: 'less done',
    search: ['routineMetadataObjects'],
    range: ['15:00', ''],
  },
  {
    keyword: 'more done',
    search: ['routineMetadataObjects'],
    range: ['18:00', ''],
    variation: {
      keyword: 'crusted',
      search: 'title',
      range: ['15:00', '17:00'],
    },
  },
  {
    keyword: 'lasagna',
    search: ['title'],
    range: ['20:00', ''],
  },
  {
    keyword: 'meatloaf',
    search: ['title'],
    range: ['20:00', ''],
  },
  {
    keyword: 'meatball',
    search: ['title', 'subtitle'],
    range: ['18:00', '20:00'],
  },
  {
    keyword: 'pasta',
    search: ['title'],
    range: ['17:00', '20:00'],
  },
  {
    keyword: 'stuffed shells',
    search: ['title'],
    range: ['17:00', '20:00'],
  },
]

export const nutrition: {
  keyword: string
  search: ('ingredients' | 'tags')[]
  values: [
    { name: 'calories'; value: number },
    { name: 'protein'; value: number }
  ]
}[] = [
  {
    keyword: 'BOXEXTRA-vacpack-chicken',
    search: ['tags'],
    values: [
      {
        name: 'calories',
        value: 182,
      },
      {
        name: 'protein',
        value: 33,
      },
    ],
  },
  {
    keyword: 'salmon',
    search: ['ingredients'],
    values: [
      {
        name: 'calories',
        value: 304,
      },
      {
        name: 'protein',
        value: 30,
      },
    ],
  },
  {
    keyword: 'boneless pork chop',
    search: ['ingredients'],
    values: [
      {
        name: 'calories',
        value: 263,
      },
      {
        name: 'protein',
        value: 37,
      },
    ],
  },
  {
    keyword: 'top sirloin steak',
    search: ['ingredients'],
    values: [
      {
        name: 'calories',
        value: 216,
      },
      {
        name: 'protein',
        value: 38,
      },
    ],
  },
  {
    keyword: 'steelhead trout',
    search: ['ingredients'],
    values: [
      {
        name: 'calories',
        value: 206,
      },
      {
        name: 'protein',
        value: 29,
      },
    ],
  },
  {
    keyword: 'shrimp',
    search: ['ingredients'],
    values: [
      {
        name: 'calories',
        value: 105,
      },
      {
        name: 'protein',
        value: 27,
      },
    ],
  },
]
