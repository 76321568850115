import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import {
  GetAllGuidanceEventsResponse,
  getAllGuidanceEvents,
  GetUserGuidanceEventResponse,
  GetUserGuidanceEvent,
  getUserGuidanceEvent,
} from 'services/combinedAPI/realTimeGuidanceEvents'

export function useReadUserGuidanceEvent({
  userId,
  ...rest
}: GetUserGuidanceEvent &
  Omit<
    UseQueryOptions<GetUserGuidanceEventResponse, Error>,
    'queryFn' | 'queryKey'
  >) {
  return useQuery<GetUserGuidanceEventResponse, Error>({
    ...rest,
    queryFn: () => {
      return getUserGuidanceEvent({ userId })
    },
    queryKey: ['user-guidance-event', userId],
  })
}

export function useReadAllGuidanceEvent(
  opts?: Omit<
    UseQueryOptions<GetAllGuidanceEventsResponse, Error>,
    'queryFn' | 'queryKey'
  >
) {
  return useQuery<GetAllGuidanceEventsResponse, Error>({
    ...opts,
    queryFn: () => {
      return getAllGuidanceEvents()
    },
    queryKey: ['all-guidance-events'],
  })
}
