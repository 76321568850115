import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import jwtDecode from 'jwt-decode'
import { JWTPayload } from '@tovala/browser-apis-combinedapi'

import { identifyUser } from 'utils/analytics'
import { isDateBefore } from 'utils/dates'
import { getCookie, removeCookie, setCookie } from 'utils/storage'

interface Auth {
  isLoggedIn: boolean
  onJWTChanged(newJWT: string | null): void
  userInfo: JWTPayload | null
}

const AuthContext = createContext<Auth | undefined>(undefined)

const UNAUTHORIZED_EVENT_NAME = 'glaze:unauthorized'

export function dispatchUnauthorizedEvent() {
  const unauthorizedEvent = new Event(UNAUTHORIZED_EVENT_NAME)
  document.dispatchEvent(unauthorizedEvent)
}

function getJWTExpirationDate(decodedJWT: JWTPayload) {
  return new Date(decodedJWT.exp * 1000)
}

const AuthProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [jwt, setJwt] = useState<string | null>(() => {
    const jwt = getCookie('JWT_TOKEN') ?? null
    if (!jwt) {
      return null
    }

    const decodedJWT = jwtDecode<JWTPayload>(jwt)
    const isExpired = isDateBefore(getJWTExpirationDate(decodedJWT), new Date())

    return isExpired ? null : jwt
  })
  const [userInfo, setUserInfo] = useState<JWTPayload | null>(() => {
    return jwt ? jwtDecode<JWTPayload>(jwt) : null
  })

  useEffect(() => {
    manageCookieForJWT(jwt)

    if (jwt) {
      const decodedJWT = jwtDecode<JWTPayload>(jwt)

      setUserInfo(decodedJWT)

      identifyUser({ decodedJWT })
    } else {
      setUserInfo(null)
    }
  }, [jwt])

  useEffect(() => {
    function handleUnauthorizedEvent() {
      setJwt(null)
    }

    document.addEventListener(UNAUTHORIZED_EVENT_NAME, handleUnauthorizedEvent)

    return () => {
      document.removeEventListener(
        UNAUTHORIZED_EVENT_NAME,
        handleUnauthorizedEvent
      )
    }
  }, [])

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!jwt,
        onJWTChanged: (newJWT: string | null) => {
          setJwt(newJWT)
          manageCookieForJWT(newJWT)
        },
        userInfo,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

function manageCookieForJWT(jwt: string | null) {
  if (jwt) {
    const decodedJWT = jwtDecode<JWTPayload>(jwt)

    setCookie({
      cookieName: 'JWT_TOKEN',
      content: jwt,
      options: {
        expires: getJWTExpirationDate(decodedJWT),
      },
    })
  } else {
    removeCookie('JWT_TOKEN')
  }
}

function useAuth() {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used in an AuthProvider')
  }

  return context
}

export { AuthProvider, useAuth }
