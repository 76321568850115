import { TextImageStack as TextImageStackComponent } from '@tovala/component-library'
import MenuGridLayout from '../MenuGridLayout'
import TwoMealPicker from './TwoMealPicker'
import MealWithExtra from './MealWithExtra'
import MealCarousel from './MealCarousel'
import { MenuComponentStandardizedTextImageStack } from '@tovala/browser-apis-menu-components'
import Meal from './Meal'
import { ViewType } from '../utils'

const TextImageStack = ({
  properties,
  viewType,
}: {
  properties: MenuComponentStandardizedTextImageStack['properties']
  viewType: ViewType
}) => {
  const { children, image, subtitle, title } = properties
  return (
    <TextImageStackComponent image={image} subtitle={subtitle} title={title}>
      <MenuGridLayout viewType={viewType}>
        {children?.map((child) => {
          if (child.type === 'meal') {
            return (
              <div key={`meal-${child.properties.id}`} className="md:px-4">
                <Meal properties={child.properties} />
              </div>
            )
          } else if (child.type === 'mealWithExtra') {
            return (
              <div key={`mealWithExtra-${child.properties.meal.id}`}>
                <MealWithExtra properties={child.properties} />
              </div>
            )
          } else if (child.type === 'twoMealPicker') {
            return (
              <div
                key={`twoMealPicker-${child.properties.meals[0].id}`}
                className="md:px-4"
              >
                <TwoMealPicker properties={child.properties} />
              </div>
            )
          } else if (child.type === 'animatedMealCarousel') {
            return (
              <div key={`mealCarousel-${child.properties.mealOptions[0].id}`}>
                <MealCarousel properties={child.properties} />
              </div>
            )
          }
        })}
      </MenuGridLayout>
    </TextImageStackComponent>
  )
}

export default TextImageStack
