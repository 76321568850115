import { ErrorMessage, Field, Form, Formik, FormikErrors } from 'formik'
import { orderBy } from 'lodash-es'
import { UserV1 } from '@tovala/browser-apis-combinedapi'

import { errorHandler, successHandler } from 'actions/notifications'
import { ovenExchangeReasons as reasonOptions } from './reasons'

import { getEnvVar } from 'utils/env'
import { useAppDispatch, useAppSelector } from 'hooks'
import { useCreateExchangeOvenShipment } from 'hooks/combinedAPI/ovens'
import { useGetTovalaProducts } from 'hooks/combinedAPI/products'
import ButtonLoading from 'components/common/ButtonLoading'
import Modal, { ModalBody, ModalHeader } from 'components/modals/Modal'
import SelectInput from '../common/SelectInput'
import ShippingFormFields, {
  FormData as ShippingFormData,
} from './ShippingFormFields'
import { TEXTAREA_CLASSES } from 'components/common/Textarea'

const ovenProStandaloneID = getEnvVar('TOVALA_OVEN_STANDALONE_ID_349_MSRP')
const ovenSteamReboxID = getEnvVar('TOVALA_OVEN_STEAM_REBOX_ID')
const ovenSteamBlackReboxID = getEnvVar('TOVALA_OVEN_STEAM_BLACK_REBOX_ID')
const ovenAirfryGrayReboxID = getEnvVar('TOVALA_OVEN_AIRFRY_GRAY_REBOX_ID')

interface FormData extends ShippingFormData {
  productID: string | null
  reason: string | null
}

interface FormDataValidated extends FormData {
  productID: string
  reason: string
}

function validate(values: FormData) {
  const errors: FormikErrors<FormData> = {}

  if (!values.line1) {
    errors.line1 = 'Please enter address line 1'
  }

  if (!values.name) {
    errors.name = 'Please enter a name'
  }

  if (!values.phone) {
    errors.phone = 'Please enter a phone'
  }

  if (!values.city) {
    errors.city = 'Please enter a city'
  }

  if (!values.state) {
    errors.state = 'Please enter a state'
  }

  if (!values.zipCode) {
    errors.zipCode = 'Please enter a postal code'
  }

  if (!values.productID) {
    errors.productID = 'Please select a product to ship'
  }

  if (!values.reason) {
    errors.reason = 'Please select a reason'
  }

  if (!values.notes) {
    errors.notes = 'Please enter notes.'
  }

  return errors
}

const SendExchangeOvenModal = ({
  closeModal,
  orderIndex,
  user,
}: {
  closeModal(): void
  orderIndex: number | ''
  user: UserV1
}): JSX.Element => {
  const dispatch = useAppDispatch()

  const productPurchases = useAppSelector(
    (state) => state.auth.productPurchases
  )
  const order = orderIndex === '' ? null : productPurchases[orderIndex]

  const shippingAddress = user.shippingAddresses[0]

  const { data: tovalaProductsForExchange = [] } = useGetTovalaProducts({
    select: (data) => {
      // Allow CS agents to select from the current active standalone products.
      // See https://tovala.atlassian.net/browse/WAT-529 for more information.
      return data.filter((product) => {
        return (
          product.id === ovenProStandaloneID ||
          product.id === ovenSteamReboxID ||
          product.id === ovenSteamBlackReboxID ||
          product.id === ovenAirfryGrayReboxID
        )
      })
    },
  })

  const orderedTovalaProductsForExchange = orderBy(
    tovalaProductsForExchange,
    'description',
    'asc'
  )

  const {
    isLoading: isCreatingExchangeOvenShipment,
    mutate: createExchangeOvenShipment,
  } = useCreateExchangeOvenShipment({
    onError: (err) => {
      errorHandler(dispatch, err)
    },
    onSuccess: () => {
      successHandler(dispatch, `Success! Exchange oven fulfillment created`)

      closeModal()
    },
  })

  const handleExchangeOvenSubmit = (values: FormDataValidated) => {
    const data = {
      address: {
        userName: values.name,
        phone: values.phone,
        line1: values.line1,
        line2: values.line2,
        city: values.city,
        state: values.state,
        zipCode: values.zipCode,
      },
      notes: values.notes,
      purchaseID: order?.id ?? null,
      reason: values.reason,
    }

    createExchangeOvenShipment({
      data,
      productID: values.productID,
      userID: user.id,
    })
  }

  // All airvala purchases should be exchanged with airvala rebox product, otherwise try to match product ID to original order
  const productID = order
    ? order.product.shipment_sku.includes('airfry')
      ? ovenAirfryGrayReboxID
      : order.product.id
    : null

  return (
    <Modal onCloseModal={closeModal}>
      <ModalBody>
        <ModalHeader onClickClose={closeModal}>
          Send Exchange Oven{' '}
          {orderIndex === null && 'for a Third-Party Purchase'}
        </ModalHeader>
        <div className="w-[800px] space-y-4">
          {orderIndex === null ? (
            <p>
              Submitting this form will create a product shipment and place it
              in the oven fulfillments queue.
            </p>
          ) : (
            <p>
              Submitting this form will create a product shipment, tie it the
              user&apos;s original purchase, and place it in the oven
              fulfillments queue.
            </p>
          )}

          <div>
            <div className="font-bold">{user.info.name}</div>
            <div>{user.info.email}</div>
          </div>

          <Formik<FormData>
            initialValues={{
              city: shippingAddress?.city ?? '',
              line1: shippingAddress?.line1 ?? '',
              line2: shippingAddress?.line2 ?? '',
              name: shippingAddress?.userName ?? '',
              notes: '',
              phone: shippingAddress?.phone ?? '',
              productID,
              reason: null,
              state: shippingAddress?.state ?? '',
              zipCode: shippingAddress?.zipCode ?? '',
            }}
            onSubmit={(formData) => {
              return handleExchangeOvenSubmit(formData as FormDataValidated)
            }}
            validate={validate}
          >
            <Form className="space-y-4">
              <div>
                <p className="font-bold">Shipping Address</p>
                <p>
                  Edit the shipping address if the exchange oven should be sent
                  to a different address than the one we have on file. If you
                  edit the address on this form, it will only be used for the
                  exchange oven shipment. It will not update the address on the
                  user&apos;s account.
                </p>
              </div>

              <ShippingFormFields />

              <div>
                <SelectInput
                  label="New Product"
                  name="productID"
                  options={orderedTovalaProductsForExchange.map((product) => ({
                    label: product.description,
                    value: product.id,
                  }))}
                  placeholder="Select product"
                />
              </div>

              <div>
                <SelectInput
                  label="Reason for Exchange"
                  name="reason"
                  options={reasonOptions}
                  placeholder="Select reason"
                />
              </div>

              <div>
                <label>Notes</label>
                <span className="ml-1 italic text-red-901">
                  <ErrorMessage name="notes" />
                </span>
              </div>

              <Field
                as="textarea"
                className={TEXTAREA_CLASSES}
                name="notes"
                rows={5}
              />

              <div className="mt-4 flex space-x-2">
                <ButtonLoading
                  isLoading={isCreatingExchangeOvenShipment}
                  size="large"
                  type="submit"
                >
                  Send Exchange Oven
                </ButtonLoading>
                <button
                  className="w-24 text-sm uppercase tracking-widest"
                  onClick={closeModal}
                >
                  Cancel
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default SendExchangeOvenModal
