import { clsx } from 'clsx'
import { ReactNode } from 'react'

const Table = ({ children }: { children: ReactNode }) => {
  return <table className="w-full table-auto">{children}</table>
}

export default Table

export const THead = ({ children }: { children: ReactNode }) => {
  return <thead className="border-y border-grey-900">{children}</thead>
}

export const TBody = ({ children }: { children: ReactNode }) => {
  return <tbody className="divide-y divide-grey-900">{children}</tbody>
}

export const TableRow = ({
  children,
  highlight = false,
}: {
  children: ReactNode
  highlight?: boolean
}) => {
  return (
    <tr
      className={clsx({
        'hover:bg-black-902': highlight,
      })}
    >
      {children}
    </tr>
  )
}

export const TH = ({ children }: { children?: ReactNode }) => {
  return <th className="p-3 text-left">{children}</th>
}

export const TD = ({
  children,
  width = '',
}: {
  children?: ReactNode
  width?: string
}) => {
  return <td className={`py-4 px-3 ${width}`}>{children}</td>
}
