const TextImageStackButton = () => {
  return (
    <button className="flex w-24 flex-col items-center space-y-1 text-center">
      <div className="flex h-10 w-10 flex-col items-center justify-center space-y-1 bg-grey-3">
        <div className="flex w-3/4 justify-between space-x-1">
          <div className="h-2 grow bg-grey-4" />
          <div className="h-2 w-2 bg-grey-4" />
        </div>
        <div className="h-1 w-3/4 bg-grey-4" />
        <div className="grid grid-cols-2 gap-1">
          <div className="h-2 w-2 bg-grey-4" />
          <div className="h-2 w-2 bg-grey-4" />
        </div>
      </div>
      <p className="text-k/13_120">Text Image Stack</p>
    </button>
  )
}

export default TextImageStackButton
