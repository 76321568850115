import { ChangeEvent, useState } from 'react'
import { clsx } from 'clsx'
import {
  Formik,
  Form,
  Field,
  FieldArray,
  useField,
  useFormikContext,
} from 'formik'
import {
  LeveratorAction,
  LeveratorPayload,
} from '@tovala/browser-apis-combinedapi'
import ReactSelect from 'react-select'

import {
  addActionToLeveratorPayload,
  getAllLeveratorActions,
  createLeveratorWebAction,
  createLeveratorDirectAction,
  createLeveratorInAppAction,
  removeActionFromLeveratorPayload,
  getAllLeveratorPayloads,
} from '../../../actions/marketing'
import { ReactSelectValue } from 'types/internal'

import { useAppDispatch, useAppSelector } from 'hooks'
import Button from 'components/common/Button'
import Hr from 'components/common/Hr'
import { INPUT_CLASSES } from 'components/common/Input'
import { SELECT_CLASSES } from 'components/common/Select'

interface FormData {
  body: string
  headers: { key: string; value: string }[]
  isContained: boolean
  title: string
  urlString: string
  actionIdentifier: 'direct' | 'inApp' | 'web' | ''
  httpMethod: 'get' | 'post' | 'put' | ''
  inAppOptionsID: ReactSelectValue<string> | null
}

const Actions = ({
  addAction,
  isLeveratorAdmin,
  payload,
  setAddAction,
}: {
  addAction: boolean
  isLeveratorAdmin: boolean
  payload: LeveratorPayload
  setAddAction: (addAction: boolean) => void
}) => {
  return (
    <div>
      {payload.actions.map((action, index) => {
        return (
          <div key={action.action.id}>
            <p>
              <strong>Action #{index + 1}</strong>
            </p>

            <Action
              action={action}
              isLeveratorAdmin={isLeveratorAdmin}
              payload={payload}
            />

            <Hr />
          </div>
        )
      })}

      <AddAction
        addAction={addAction}
        isLeveratorAdmin={isLeveratorAdmin}
        payload={payload}
        setAddAction={setAddAction}
      />
    </div>
  )
}

export default Actions

const Action = ({
  action,
  isLeveratorAdmin,
  payload,
}: {
  action: LeveratorAction
  isLeveratorAdmin: boolean
  payload: LeveratorPayload
}) => {
  const dispatch = useAppDispatch()

  const handleRemoveActionFromPayload = ({
    payloadID,
    actionID,
  }: {
    payloadID: string
    actionID: string
  }) => {
    return dispatch(removeActionFromLeveratorPayload(payloadID, actionID))
  }

  return (
    <div>
      <div>{action.action.title}</div>

      <ActionForm action={action} isLeveratorAdmin={isLeveratorAdmin} />

      <Button
        onClick={() => {
          handleRemoveActionFromPayload({
            payloadID: payload.id,
            actionID: action.action.id,
          })
        }}
        size="large"
      >
        Remove Action from Payload
      </Button>
    </div>
  )
}

const AddAction = ({
  addAction,
  isLeveratorAdmin,
  payload,
  setAddAction,
}: {
  addAction: boolean
  isLeveratorAdmin: boolean
  payload: LeveratorPayload
  setAddAction: (addAction: boolean) => void
}) => {
  const dispatch = useAppDispatch()

  const [selectedActionID, setSelectedActionID] =
    useState<ReactSelectValue<string> | null>()

  const allLeveratorActions = useAppSelector(
    (state) => state.marketing.allLeveratorActions
  )

  const selectedAction = allLeveratorActions.find(
    (action) => action.action.id === selectedActionID?.value
  )

  const actionOptions = allLeveratorActions.map((action) => ({
    value: action.action.id,
    label: action.action.title,
  }))

  const selectAction = ({
    actionID,
    title,
  }: {
    actionID: string
    title: string
  }) => {
    setSelectedActionID({ value: actionID, label: title })
  }

  const handleAddActionToPayload = ({
    action,
    payload,
  }: {
    action: LeveratorAction
    payload: LeveratorPayload
  }) => {
    const payloadID = payload.id
    const actionID = action.action.id

    dispatch(addActionToLeveratorPayload(payloadID, actionID)).then(
      (response) => {
        if (response && response.payload) {
          dispatch(getAllLeveratorPayloads())
          setAddAction(false)
          setSelectedActionID(undefined)
        }
      }
    )
  }

  return (
    <div>
      {addAction && (
        <div>
          <p className="mb-4">
            <strong>Select an existing action</strong>
          </p>
          <ReactSelect
            isClearable
            onChange={(option) => setSelectedActionID(option)}
            options={actionOptions}
            value={selectedActionID}
          />
          <div
            className={clsx({
              'bg-white-900': selectedAction,
              'mt-5 bg-grey-904 p-5': !selectedAction,
            })}
          >
            {!selectedActionID && (
              <p className="mb-4">
                <strong>Or create a new action</strong>
              </p>
            )}
            <ActionForm
              action={selectedAction}
              isLeveratorAdmin={isLeveratorAdmin}
              selectAction={selectAction}
            />

            {selectedAction && (
              <Button
                onClick={() =>
                  handleAddActionToPayload({ action: selectedAction, payload })
                }
                size="large"
              >
                Add Action to Payload
              </Button>
            )}
          </div>
        </div>
      )}

      {!addAction && (
        <Button
          buttonStyle="grey"
          onClick={() => setAddAction(true)}
          size="large"
        >
          {payload.actions.length > 0 ? 'Add another action' : 'Add action'}
        </Button>
      )}
    </div>
  )
}

const ActionForm = ({
  action,
  isLeveratorAdmin,
  selectAction,
}: {
  action?: LeveratorAction
  isLeveratorAdmin: boolean
  selectAction?: ({
    actionID,
    title,
  }: {
    actionID: string
    title: string
  }) => void
}) => {
  const dispatch = useAppDispatch()

  const [showDetails, setShowDetails] = useState(false)

  const allLeveratorInAppOptions = useAppSelector(
    (state) => state.marketing.allLeveratorInAppOptions
  )

  const inAppOptions = allLeveratorInAppOptions.map(({ id, title }) => ({
    value: id,
    label: title,
  }))

  const handleSelectAction = (action: LeveratorAction) => {
    dispatch(getAllLeveratorActions()).then(() => {
      if (selectAction) {
        selectAction({ actionID: action.action.id, title: action.action.title })
      }
    })
  }

  const handleCreateDirectAction = (formData: FormData) => {
    const payload = {
      title: formData.title,
      httpMethod: formData.httpMethod,
      url: formData.urlString,
      headers: {},
      body: formData.body,
    }

    if (formData.headers.length) {
      formData.headers.forEach((header) => {
        if (header.key && header.value) {
          if (payload.headers) {
            payload.headers[header.key] = header.value
          }
        }
      })
    }

    dispatch(createLeveratorDirectAction(payload)).then((response) => {
      if (response && response.payload) {
        const action = response.payload as LeveratorAction
        handleSelectAction(action)
      }
    })
  }

  const handleCreateWebAction = (formData: FormData) => {
    const payload = {
      title: formData.title,
      isContained: formData.isContained,
      url: formData.urlString,
      headers: {},
      body: formData.body,
    }

    if (formData.headers) {
      formData.headers.forEach((header) => {
        if (header.key && header.value) {
          if (payload.headers) {
            payload.headers[header.key] = header.value
          }
        }
      })
    }

    dispatch(createLeveratorWebAction(payload)).then((response) => {
      if (response && response.payload) {
        const action = response.payload as LeveratorAction
        handleSelectAction(action)
      }
    })
  }

  const handleCreateInAppAction = (formData: FormData) => {
    if (!formData.inAppOptionsID) {
      throw new Error('In App Option required')
    }
    const payload = {
      inAppOptionsID: formData.inAppOptionsID.value,
      title: formData.title,
    }

    dispatch(createLeveratorInAppAction(payload)).then((response) => {
      if (response && response.payload) {
        const action = response.payload as LeveratorAction
        handleSelectAction(action)
      }
    })
  }

  const handleCreateAction = (formData: FormData) => {
    const { actionIdentifier } = formData

    switch (actionIdentifier) {
      case 'direct':
        handleCreateDirectAction(formData)
        break

      case 'web':
        handleCreateWebAction(formData)
        break

      case 'inApp':
        handleCreateInAppAction(formData)
        break

      default:
        console.log('Missing action identifier')
    }
  }

  const initialValues: FormData = {
    body: action?.action.body || '',
    headers: action?.action.headers
      ? Object.entries(action.action.headers).map((header) => ({
          key: header[0],
          value: header[1],
        }))
      : [],
    isContained: action ? action.action.isContained : false,
    title: action?.action.title || '',
    urlString: action?.action.urlString || '',
    actionIdentifier: action?.actionIdentifier || '',
    httpMethod: action?.action.httpMethod || '',
    inAppOptionsID: null,
  }

  const readOnly = !!action?.action.id

  return (
    <Formik<FormData>
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleCreateAction}
    >
      {({ errors, values, handleChange, setFieldValue }) => {
        return (
          <Form className="space-y-4">
            {readOnly && (
              <button
                className="text-sm tracking-widest text-blue-901 hover:text-blue-902 hover:underline"
                onClick={() =>
                  setShowDetails((prevShowDetails) => !prevShowDetails)
                }
                type="button"
              >
                {showDetails ? 'hide details' : 'show details'}
              </button>
            )}

            {(!action || showDetails) && (
              <div className="space-y-4">
                <div>
                  <label>Type</label>
                  {errors.actionIdentifier && (
                    <span className="text-red-901">
                      {' '}
                      - <i>{errors.actionIdentifier}</i>
                    </span>
                  )}
                  <Field
                    as="select"
                    className={SELECT_CLASSES}
                    disabled={readOnly}
                    name="actionIdentifier"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      handleChange(e)
                      if (e.target.value === 'web') {
                        setFieldValue('isContained', true)
                      }
                    }}
                    validate={validateField}
                  >
                    <option>Select...</option>
                    <option value="inApp">Open a screen within the app</option>
                    <option value="web">Open a web page</option>
                    <option value="direct">
                      {isLeveratorAdmin ? 'Direct' : 'Dismiss the message'}
                    </option>
                  </Field>
                </div>

                {values.actionIdentifier === 'inApp' && !readOnly && (
                  <div>
                    <label>In App Option</label>
                    {errors.inAppOptionsID && (
                      <span className="text-red-901">
                        {' '}
                        - <i>{errors.inAppOptionsID}</i>
                      </span>
                    )}
                    <Select
                      name="inAppOptionsID"
                      onChange={(option: ReactSelectValue<string>) => {
                        setFieldValue('inAppOptionsID', option)
                      }}
                      options={inAppOptions}
                      validate={validateField}
                      value={values.inAppOptionsID}
                    />
                  </div>
                )}

                <div>
                  <label>Title</label>
                  {values.actionIdentifier !== 'inApp' && errors.title && (
                    <span className="text-red-901">
                      {' '}
                      - <i>{errors.title}</i>
                    </span>
                  )}
                  <Field
                    className={INPUT_CLASSES}
                    disabled={readOnly}
                    name="title"
                    validate={
                      values.actionIdentifier === 'inApp' ? null : validateField
                    }
                  />
                  {values.actionIdentifier === 'inApp' && !readOnly && (
                    <p className="mt-1 text-sm">
                      NOTE: Only enter a title if you wish to override the In
                      App Option title above.
                    </p>
                  )}
                </div>

                {isLeveratorAdmin && values.actionIdentifier === 'direct' && (
                  <>
                    <div>
                      <label>URL</label>
                      <Field
                        className={INPUT_CLASSES}
                        disabled={readOnly}
                        name="urlString"
                      />
                    </div>

                    <HeadersFieldArray readOnly={readOnly} />

                    <div>
                      <label>Body</label>
                      <Field
                        className={INPUT_CLASSES}
                        disabled={readOnly}
                        name="body"
                      />
                    </div>

                    <div>
                      <label>HTTP Method</label>
                      <Field
                        as="select"
                        className={SELECT_CLASSES}
                        disabled={readOnly}
                        name="httpMethod"
                      >
                        <option>Select...</option>
                        <option value="get">get</option>
                        <option value="post">post</option>
                        <option value="put">put</option>
                      </Field>
                    </div>
                  </>
                )}

                {values.actionIdentifier === 'web' && (
                  <div className="space-y-4">
                    <label>
                      <Field
                        disabled={readOnly}
                        name="isContained"
                        type="checkbox"
                      />{' '}
                      Open link in-app?
                    </label>

                    <div>
                      <label>URL</label>
                      {errors.urlString && (
                        <span className="text-red-901">
                          {' '}
                          - <i>{errors.urlString}</i>
                        </span>
                      )}
                      <Field
                        className={INPUT_CLASSES}
                        disabled={readOnly}
                        name="urlString"
                        validate={validateField}
                      />
                      <p className="mt-1 text-xs">
                        Note: you must include http:// or https://
                      </p>
                    </div>

                    <HeadersFieldArray readOnly={readOnly} />

                    <div>
                      <label>
                        Body <span>- Optional</span>
                      </label>
                      <Field
                        className={INPUT_CLASSES}
                        disabled={readOnly}
                        name="body"
                      />
                    </div>
                  </div>
                )}
              </div>
            )}

            <div className="mt-4">
              {!action?.action.id && (
                <Button size="large" type="submit">
                  Create Action
                </Button>
              )}
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

const validateField = (value: string) => {
  let error: string | undefined
  if (!value) {
    error = 'Required'
  }
  return error
}

const Select = ({ ...rest }): JSX.Element => {
  const [field] = useField({ name: rest.name })
  return (
    <ReactSelect {...field} {...rest} isClearable isDisabled={rest.readOnly} />
  )
}

const HeadersFieldArray = ({
  readOnly,
}: {
  readOnly: boolean
}): JSX.Element => {
  const { values }: { values: FormData } = useFormikContext()
  return (
    <div>
      <label>
        Headers <span>- Optional</span>
      </label>
      <FieldArray
        name="headers"
        render={(arrayHelpers) => (
          <div className="space-y-4">
            {values.headers && values.headers.length > 0 && (
              <div className="space-y-2">
                {values.headers.map((_unused, index) => {
                  return (
                    <div key={index} className="flex space-x-4">
                      <div className="w-32">
                        <label>Key</label>
                        <Field
                          className={INPUT_CLASSES}
                          disabled={readOnly}
                          name={`headers.${index}.key`}
                        />
                      </div>
                      <div className="w-80">
                        <label>Value</label>
                        <Field
                          className={INPUT_CLASSES}
                          disabled={readOnly}
                          name={`headers.${index}.value`}
                        />
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
            {!readOnly && (
              <Button
                buttonStyle="grey"
                onClick={() => arrayHelpers.push({ key: '', value: '' })}
                size="large"
              >
                Add header key-value pair
              </Button>
            )}
          </div>
        )}
      />
    </div>
  )
}
