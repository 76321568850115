import { DragOverlay, UniqueIdentifier } from '@dnd-kit/core'
import { MenuComponentStandardized } from '@tovala/browser-apis-menu-components'
import { DraggableNewComponentProps } from './DraggableNewComponent'
import BackgroundImageHeader from './menuComponents/BackgroundImageHeader'
import BackgroundImageHeaderButton from './menuComponents/BackgroundImageHeaderButton'
import Meal from './menuComponents/Meal'
import MealCarousel from './menuComponents/MealCarousel'
import MealWithExtra from './menuComponents/MealWithExtra'
import TextBanner from './menuComponents/TextBanner'
import TextImageStack from './menuComponents/TextImageStack'
import TextImageStackButton from './menuComponents/TextImageStackButton'
import TwoMealPicker from './menuComponents/TwoMealPicker'
import { ComponentDropPlaceholder, ViewType } from './utils'

const ActiveComponentDragOverlay = ({
  activeComponent,
  activeID,
  dragOverlayAddComponentType,
  viewType,
}: {
  activeComponent: ComponentDropPlaceholder | MenuComponentStandardized
  activeID: UniqueIdentifier | null
  dragOverlayAddComponentType:
    | DraggableNewComponentProps['componentType']
    | null
  viewType: ViewType
}) => {
  return (
    <DragOverlay>
      {activeID ? (
        <div className="max-w-fit">
          {activeComponent && activeComponent.type !== 'dropPlaceholder' ? (
            <div className="rounded-xl bg-grey-0">
              {activeComponent.type === 'backgroundImageHeader' ? (
                <div className="h-96 w-[350px]">
                  <BackgroundImageHeader
                    properties={activeComponent.properties}
                  />
                </div>
              ) : activeComponent.type === 'meal' ? (
                <Meal properties={activeComponent.properties} />
              ) : activeComponent.type === 'mealWithExtra' ? (
                <MealWithExtra properties={activeComponent.properties} />
              ) : activeComponent.type === 'twoMealPicker' ? (
                <TwoMealPicker properties={activeComponent.properties} />
              ) : activeComponent.type === 'animatedMealCarousel' ? (
                <MealCarousel properties={activeComponent.properties} />
              ) : activeComponent.type === 'textBanner' ? (
                <TextBanner properties={activeComponent.properties} />
              ) : activeComponent.type === 'textImageStack' ? (
                <TextImageStack
                  properties={activeComponent.properties}
                  viewType={viewType}
                />
              ) : null}
            </div>
          ) : (
            <div>
              {dragOverlayAddComponentType ? (
                <div>
                  {dragOverlayAddComponentType === 'backgroundImageHeader' ? (
                    <BackgroundImageHeaderButton />
                  ) : dragOverlayAddComponentType === 'textImageStack' ? (
                    <TextImageStackButton />
                  ) : null}
                </div>
              ) : null}
            </div>
          )}
        </div>
      ) : null}
    </DragOverlay>
  )
}

export default ActiveComponentDragOverlay
