import {
  ListingsReceipt,
  MealSummary,
  OrderHistoryReceipt,
  UpdateOrderMealSelectionsResponse,
  useCurrentTerm,
  useListingsReceipt,
  useMealSummaries,
  useOrderHistoryReceipts,
  UserSubscriptionType,
  UserV1,
  useUpdateOrderMealSelections,
  useUserSubscriptionTypes,
  useUserTermStatus,
} from '@tovala/browser-apis-combinedapi'
import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import {
  getAdminScope,
  OATS_DASHBOARD,
  VENGEFUL_GOD,
} from 'utils/getAdminScope'

import { findLastIndex, flatten, isEqual, sortBy, uniqBy } from 'lodash-es'
import MealsGrid from './MealsGrid'
import OrderSummary from './OrderSummary'
import { isCurrentTimeBeforeMSRDeadline } from '../helpers'
import { assign, createMachine } from 'xstate'
import { useMachine } from '@xstate/react'
import {
  Button,
  ButtonLoading,
  Modal,
  ModalHeader,
  Textarea,
} from '@tovala/component-library'
import { ModalBody } from 'components/modals/Modal'
import { formatCentsToDollars } from 'utils/currency'
import { getMealPriceString } from './helpers'
import { isAxiosResponseError } from 'utils/api'
import { useAppDispatch } from 'hooks'
import { errorHandler } from 'actions/notifications'

type DialogData =
  | {
      data: {
        orderDetails: {
          mealCount: number
          mealSelections: {
            meal: MealSummary
            quantity: number
          }[]
          totalCents: number
          paymentStatus: OrderHistoryReceipt['paymentStatus']
          originalTotalCents: number
        }
        saveMealSelections(notes: string): void
      }
      type: 'confirmSubmit'
    }
  | {
      data: {
        mealCount: number
        updateMealCount(mealCount: number): void
        userSubscriptionTypes: UserSubscriptionType[]
      }
      type: 'changeMealCount'
    }
  | null

const HIDDEN_MEAL_TAG_ID = 53
const MSR_DEADLINE_MESSAGE =
  'Editing meal selections is no longer available for this term.'

const CurrentTermEditMealsV2 = ({ user }: { user: UserV1 }) => {
  const { termid: termIDParam, userid } = useParams()
  const termID = termIDParam ? Number.parseInt(termIDParam, 10) : undefined

  useEffect(() => {
    document.title = `Glaze | User #${userid} - Meals`
  }, [userid])

  const { data: getOrderHistoryReceiptsResponse } = useOrderHistoryReceipts({
    userID: user.id,
  })
  const { pages = [] } = getOrderHistoryReceiptsResponse || {}
  const orderHistoryReceipts = flatten(pages.map((page) => page.receipts ?? []))

  const receipt = orderHistoryReceipts.find(
    (receipt) => receipt.termID === termID
  )

  const { data: currentTerm } = useCurrentTerm()

  const hasRequiredScope =
    getAdminScope(OATS_DASHBOARD) || getAdminScope(VENGEFUL_GOD)

  if (!hasRequiredScope) {
    return <p>You do not have access to edit past term meal selections.</p>
  }

  const isMSRUpdateAllowed =
    currentTerm && termID === currentTerm.id
      ? isCurrentTimeBeforeMSRDeadline(currentTerm.order_by)
      : false

  if (!isMSRUpdateAllowed) {
    return <p>{MSR_DEADLINE_MESSAGE}</p>
  }

  const canModifySelections = () => {
    return !!(
      currentTerm && isCurrentTimeBeforeMSRDeadline(currentTerm.order_by)
    )
  }

  if (receipt) {
    return (
      <div>
        <EditMeals
          canModifySelections={canModifySelections}
          receipt={receipt}
          userID={user.id}
        />
      </div>
    )
  }

  return null
}

export default CurrentTermEditMealsV2

const EditMeals = ({
  canModifySelections,
  receipt,
  userID,
}: {
  canModifySelections(): boolean
  receipt: OrderHistoryReceipt
  userID: number
}) => {
  const dispatch = useAppDispatch()

  const [dialog, setDialog] = useState<DialogData>(null)

  const { mutateAsync: updateOrderMealSelectionsAsync } =
    useUpdateOrderMealSelections({
      onSuccess: () => {
        setDialog(null)
      },
    })

  const [state, send] = useMachine(
    () => {
      return createMealSelectionsMachine({
        mealCount: receipt.mealCount,
        mealIDs: receipt.selectedMeals.map((selection) => selection.id),
      })
    },
    {
      actions: {
        showSaveMealSelectionsError: (_ctx, event) => {
          const err = event.data

          if (err instanceof Error && isAxiosResponseError(err)) {
            const message =
              err.response?.data.message ?? 'Unable to save meal selections'

            if (
              message === 'OrderUpdateNotAllowed -> outside the allowed window'
            ) {
              setDialog(null)
            }

            errorHandler(dispatch, null, message)
          }
        },
      },
      guards: {
        hasMaxAndModifiedSelections: (ctx) => {
          const hasMaxSelections = ctx.mealCount === ctx.mealIDs.length
          const receiptMealIDs = receipt.selectedMeals
            .map((selection) => selection.id)
            .sort((a, b) => a - b)

          const hasModifiedSelections = !isEqual(
            receiptMealIDs,
            ctx.mealIDs.sort((a, b) => a - b)
          )

          return (
            hasMaxSelections &&
            (hasModifiedSelections || receipt.mealCount !== ctx.mealCount)
          )
        },
        hasModifiedSelections: (ctx) => {
          const receiptMealIDs = receipt.selectedMeals
            .map((selection) => selection.id)
            .sort((a, b) => a - b)

          const hasModifiedSelections = !isEqual(
            receiptMealIDs,
            ctx.mealIDs.sort((a, b) => a - b)
          )

          return hasModifiedSelections || receipt.mealCount !== ctx.mealCount
        },
      },
      services: {
        saveMealSelections: (ctx) => {
          const { mealCount, mealIDs } = ctx

          return updateOrderMealSelectionsAsync({
            data: {
              mealCount,
              mealIDs,
              notes: 'Notes',
            },
            orderID: receipt.userTermOrderID,
            userID,
          })
        },
      },
    }
  )
  const { mealCount, mealIDs } = state.context
  const hasSelectedAllMeals = mealCount === mealIDs.length
  const isReadyToSave = state.matches('readyToSave')
  const isSaved = state.matches('saved')
  const isIdle = state.matches('idle')

  const { data: selectedUserTerm } = useUserTermStatus({
    termID: receipt.termID,
    userID,
  })

  const { data: userSubscriptionTypes } = useUserSubscriptionTypes({
    userID,
  })
  const selectedSubscription = userSubscriptionTypes?.subscription_types.find(
    (subcription) => subcription.max_selections === mealCount
  )

  const { data: mealSummaries = [] } = useMealSummaries({
    subTermID: selectedUserTerm?.selectedSubTermID,
  })

  // Don't allow CS to add meals with the hidden tag, which are added to frozen meals added after the order by date
  const sortedMealSummaries = sortBy(mealSummaries, 'id').filter(
    (mealSummary) =>
      !mealSummary.tags.some((tag) => tag.id === HIDDEN_MEAL_TAG_ID)
  )

  const { data: listingsReceipt } = useListingsReceipt({
    orderID: receipt.userTermOrderID,
    userID,
  })

  const listings = uniqBy(listingsReceipt?.listings, 'id')
  const listingSelections = listingsReceipt?.listings
    ? listings.map((listing) => {
        const quantity = listingsReceipt.listings.filter(
          (l) => l.id === listing.id
        ).length

        return {
          id: listing.id,
          priceCents: listing.priceCents,
          quantity,
        }
      })
    : []

  const orderPricing = isIdle
    ? {
        mealsPriceCents:
          receipt?.paymentLineItems.find((pli) => pli.type === 'sku')
            ?.invoiceAmountCents ?? 0,
        shippingPriceCents:
          receipt?.paymentLineItems.find((pli) => pli.type === 'shipping')
            ?.invoiceAmountCents ?? 0,
        surchargePriceCents:
          receipt?.paymentLineItems.find((pli) => pli.type === 'surcharge')
            ?.invoiceAmountCents ?? 0,
        taxPriceCents:
          receipt?.paymentLineItems.find((pli) => pli.type === 'tax')
            ?.invoiceAmountCents ?? 0,
        totalPriceCents:
          receipt?.paymentLineItems.find((pli) => pli.type === 'charge')
            ?.transactionAmountCents ?? 0,
      }
    : getOrderPricing({
        listingsReceipt,
        mealIDs,
        mealSummaries,
        selectedSubscription,
      })

  return (
    <div className="space-y-8">
      <div className="sticky top-[56px] z-20 bg-white py-4 space-y-4">
        <div className="flex justify-between space-x-4">
          <h1 className="text-k/36_110">
            {selectedUserTerm && <span>Term #{selectedUserTerm.termID}</span>} -
            Edit Meal Selections
          </h1>

          <div className="flex space-x-4">
            <Button
              buttonStyle="stroke"
              disabled={!canModifySelections() || isSaved}
              onClick={() => {
                setDialog({
                  data: {
                    mealCount,
                    updateMealCount: (mealCount: number) => {
                      send({
                        mealCount,
                        type: 'UPDATE_MEAL_COUNT',
                      })
                    },
                    userSubscriptionTypes:
                      userSubscriptionTypes?.subscription_types || [],
                  },
                  type: 'changeMealCount',
                })
              }}
              size="large"
            >
              Change Meal Count
            </Button>
            <ButtonLoading
              buttonStyle="dark"
              disabled={!isReadyToSave || !canModifySelections()}
              isLoading={false}
              onClick={() => {
                setDialog({
                  data: {
                    orderDetails: {
                      mealCount,
                      mealSelections: mealSummaries
                        .map((meal) => {
                          const quantity = mealIDs.filter((mealID) => {
                            return mealID === meal.id
                          }).length

                          if (quantity > 0) {
                            return {
                              meal,
                              quantity,
                            }
                          }
                        })
                        .filter(
                          (
                            selection
                          ): selection is {
                            meal: MealSummary
                            quantity: number
                          } => !!selection
                        ),
                      originalTotalCents:
                        receipt.paymentLineItems.find(
                          (pli) => pli.type === 'charge'
                        )?.transactionAmountCents ?? 0,
                      paymentStatus: receipt.paymentStatus,
                      totalCents: orderPricing.totalPriceCents,
                    },
                    saveMealSelections: (notes: string) => {
                      send({
                        notes,
                        type: 'SAVE_MEAL_SELECTIONS',
                      })
                    },
                  },
                  type: 'confirmSubmit',
                })
              }}
              size="large"
            >
              Save Meal Selections
            </ButtonLoading>
          </div>
        </div>
        <div>
          {isSaved ? (
            <div className="bg-green-faded p-4 rounded-lg">
              <span className="font-bold">
                Success! Meal selections have been updated.
              </span>{' '}
              <Link className="underline" to={`/user/${userID}/order-history`}>
                Return to Meal Order History
              </Link>
            </div>
          ) : !canModifySelections() ? (
            <div>
              <span className="font-bold">{MSR_DEADLINE_MESSAGE}</span>{' '}
              <Link className="underline" to={`/user/${userID}/order-history`}>
                Return to Meal Order History
              </Link>
            </div>
          ) : mealCount !== mealIDs.length ? (
            <div>
              <p className="font-bold">
                {mealIDs.length}/{mealCount} Meals Selected
              </p>
              {mealCount - mealIDs.length > 0 ? (
                <p>
                  Select {mealCount - mealIDs.length} more, then click "Save
                  Meal Selections" to complete the change to the order.
                </p>
              ) : (
                <p>
                  Remove {Math.abs(mealCount - mealIDs.length)}, then click
                  "Save Meal Selections" to complete the change to the order.
                </p>
              )}
            </div>
          ) : mealCount === mealIDs.length && isReadyToSave ? (
            <div>
              <p className="font-bold">
                {mealIDs.length}/{mealCount} Meals Selected
              </p>
              <p>
                Click "Save Meal Selections" to complete the change to the
                order.
              </p>
            </div>
          ) : (
            <div className="space-y-2">
              <p>
                Edit the customer's meal selections, then click "Save Meal
                Selections" to complete the change to the order.
              </p>
              <p>
                If the customer would like a different number of meals, click
                "Change Meal Count" to select the new quantity.
              </p>
            </div>
          )}
        </div>
      </div>

      <div className="flex">
        <div className="w-8/12 space-y-6">
          <MealsGrid
            canDecrement={canModifySelections()}
            canIncrement={!hasSelectedAllMeals && canModifySelections()}
            mealSummaries={sortedMealSummaries}
            onDecrement={(mealID) => {
              send({
                mealID,
                type: 'REMOVE_MEAL',
              })
            }}
            onIncrement={(mealID) => {
              send({
                mealID,
                type: 'ADD_MEAL',
              })
            }}
            selectionsMealIDs={mealIDs}
          />
        </div>
        <div className="w-4/12 pl-6">
          <div className="text-xl font-bold">Order Summary</div>

          <OrderSummary
            listingSelections={listingSelections}
            listings={listings}
            maxSelections={mealCount}
            maxSelectionsDefaultSubscription={receipt.mealCount}
            mealSummaries={sortedMealSummaries}
            orderPricing={orderPricing}
            selectedSubscriptionTypeIsDefault={receipt.mealCount === mealCount}
            selectionsMealIDs={mealIDs}
          />
        </div>
      </div>

      <Dialog
        closeDialog={() => {
          setDialog(null)
        }}
        dialog={dialog}
      />
    </div>
  )
}

const Dialog = ({
  closeDialog,
  dialog,
}: {
  closeDialog(): void
  dialog: DialogData
}) => {
  if (dialog?.type === 'changeMealCount') {
    const { mealCount, updateMealCount, userSubscriptionTypes } = dialog.data
    return (
      <ChangeMealCountDialog
        mealCount={mealCount}
        onClickClose={closeDialog}
        onClickConfirm={(mealCount) => {
          updateMealCount(mealCount)

          closeDialog()
        }}
        userSubscriptionTypes={userSubscriptionTypes}
      />
    )
  } else if (dialog?.type === 'confirmSubmit') {
    const { orderDetails, saveMealSelections } = dialog.data
    return (
      <ConfirmSubmitDialog
        onClickClose={closeDialog}
        onClickConfirm={(notes) => {
          saveMealSelections(notes)
        }}
        orderDetails={orderDetails}
      />
    )
  }

  return null
}

const ChangeMealCountDialog = ({
  mealCount,
  onClickClose,
  onClickConfirm,
  userSubscriptionTypes,
}: {
  mealCount: number
  onClickClose(): void
  onClickConfirm(mealCount: number): void
  userSubscriptionTypes: UserSubscriptionType[]
}) => {
  const [selectedMealCount, setSelectedMealCount] = useState(mealCount)

  return (
    <Modal onCloseModal={onClickClose}>
      <ModalBody>
        <ModalHeader onClickClose={onClickClose}>Change Meal Count</ModalHeader>
        <div className="max-w-[645px] w-full">
          <div className="space-y-4 p-4 pb-12">
            <p>
              Please select the number of meals the customer would like for this
              order.
            </p>

            <div className="flex flex-col space-y-2">
              {userSubscriptionTypes.map((subscription) => {
                return (
                  <label key={subscription.id}>
                    <input
                      checked={
                        selectedMealCount === subscription.max_selections
                      }
                      onChange={(event) => {
                        setSelectedMealCount(+event.target.value)
                      }}
                      type="radio"
                      value={subscription.max_selections}
                    />
                    <span className="pl-2">
                      {subscription.max_selections} Meals
                    </span>
                  </label>
                )
              })}
            </div>

            <p>
              Note: The number of meal selections must match the meal count in
              order to save the customer's edited meal selections.
            </p>
          </div>
          <div className="flex items-center justify-between">
            <Button buttonStyle="stroke" onClick={onClickClose} size="large">
              Cancel
            </Button>
            <Button
              onClick={() => {
                onClickConfirm(selectedMealCount)
              }}
              size="large"
            >
              Save
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

const ConfirmSubmitDialog = ({
  onClickClose,
  onClickConfirm,
  orderDetails,
}: {
  onClickClose(): void
  onClickConfirm(notes: string): void
  orderDetails: {
    mealCount: number
    mealSelections: {
      meal: MealSummary
      quantity: number
    }[]
    totalCents: number
    paymentStatus: OrderHistoryReceipt['paymentStatus']
    originalTotalCents: number
  }
}) => {
  const [notes, setNotes] = useState('')
  const [notesError, setNotesError] = useState(false)

  const {
    mealCount,
    mealSelections,
    paymentStatus,
    totalCents,
    originalTotalCents,
  } = orderDetails

  const orderDifferenceCents = totalCents - originalTotalCents

  return (
    <Modal onCloseModal={onClickClose}>
      <ModalBody>
        <ModalHeader onClickClose={onClickClose}>
          Confirm Save Meal Selections
        </ModalHeader>
        <div className="max-w-[645px] min-w-[400px] w-full">
          <div className="space-y-4 p-4 pb-12">
            <div className="space-y-2">
              {originalTotalCents !== totalCents && (
                <>
                  <div>
                    <span className="font-bold">Original Total:</span>{' '}
                    {formatCentsToDollars(originalTotalCents)}
                  </div>

                  <div>
                    <span className="font-bold">Estimated New Total:</span>{' '}
                    {formatCentsToDollars(totalCents)} (+ tax where applicable)
                  </div>

                  {paymentStatus === 'paid' && (
                    <>
                      {orderDifferenceCents > 0 ? (
                        <div>
                          <span className="font-bold">
                            Estimated Additional Charge:
                          </span>{' '}
                          {formatCentsToDollars(orderDifferenceCents)} (+ tax
                          where applicable)
                        </div>
                      ) : orderDifferenceCents < 0 ? (
                        <div>
                          <span className="font-bold">Estimated Refund:</span>{' '}
                          {formatCentsToDollars(Math.abs(orderDifferenceCents))}{' '}
                          (+ tax where applicable)
                        </div>
                      ) : null}
                    </>
                  )}
                </>
              )}

              <p className="font-bold">{mealCount} Meals</p>

              {mealSelections.map(({ meal, quantity }) => {
                const { title, subtitle, surchargeCents, totalPriceCents } =
                  meal

                return (
                  <SummaryItem
                    key={meal.id}
                    id={meal.id}
                    priceString={getMealPriceString({
                      surchargeCents,
                      totalPriceCents,
                    })}
                    quantity={quantity}
                    subtitle={subtitle}
                    title={title}
                  />
                )
              })}
            </div>

            <div>
              <label className="font-bold block" htmlFor="notes">
                Notes*
              </label>
              <Textarea
                id="notes"
                onChange={(event) => {
                  setNotes(event.target.value)

                  if (notesError) {
                    setNotesError(false)
                  }
                }}
                required
                value={notes}
              />
              {notesError && (
                <div className="text-red">Please enter notes.</div>
              )}
            </div>
          </div>
          <div className="flex items-center justify-between">
            <Button buttonStyle="stroke" onClick={onClickClose} size="large">
              Cancel
            </Button>
            <Button
              onClick={() => {
                if (notes) {
                  onClickConfirm(notes)
                } else {
                  setNotesError(true)
                }
              }}
              size="large"
            >
              Save
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

const SummaryItem = ({
  id,
  priceString,
  quantity,
  subtitle,
  title,
}: {
  id?: number
  priceString: string
  quantity: number
  subtitle?: string
  title: string
}) => {
  return (
    <div className="grid grid-cols-[40px_1fr] border-t border-grey-900 pt-1">
      <div>{quantity}</div>

      <div className="space-y-1">
        <p>{title}</p>
        <p className="text-sm text-grey-906">
          {subtitle} {id ? `(#${id})` : ''}
        </p>
        <p className="text-sm">{priceString}</p>
      </div>
    </div>
  )
}

function getOrderPricing({
  listingsReceipt,
  mealIDs,
  mealSummaries,
  selectedSubscription,
}: {
  listingsReceipt: ListingsReceipt | undefined
  mealIDs: number[]
  mealSummaries: MealSummary[]
  selectedSubscription: UserSubscriptionType | undefined
}) {
  const mealsPriceCents = selectedSubscription?.price_cents ?? 0
  const shippingPriceCents = selectedSubscription?.shippingCents ?? 0
  const mealsSurchargePriceCents = mealIDs.reduce((totalCents, mealID) => {
    const meal = mealSummaries.find((meal) => meal.id === mealID)
    const mealSurchargeCents = meal?.surchargeCents ?? 0

    return totalCents + mealSurchargeCents
  }, 0)

  const listingsPriceCents = listingsReceipt?.priceCents ?? 0
  const surchargePriceCents = mealsSurchargePriceCents + listingsPriceCents
  const totalPriceCents =
    mealsPriceCents + shippingPriceCents + surchargePriceCents

  return {
    mealsPriceCents,
    shippingPriceCents,
    surchargePriceCents,
    taxPriceCents: 0,
    totalPriceCents,
  }
}

function createMealSelectionsMachine({
  mealCount,
  mealIDs,
}: {
  mealCount: number
  mealIDs: number[]
}) {
  return createMachine(
    {
      context: {
        mealCount,
        mealIDs,
      },
      id: 'mealSelectionsMachine',
      initial: 'idle',
      schema: {
        context: {} as {
          mealCount: number
          mealIDs: number[]
        },
        events: {} as
          | {
              mealID: number
              type: 'ADD_MEAL'
            }
          | {
              mealID: number
              type: 'REMOVE_MEAL'
            }
          | {
              notes: string
              type: 'SAVE_MEAL_SELECTIONS'
            }
          | {
              mealCount: number
              type: 'UPDATE_MEAL_COUNT'
            },

        services: {} as {
          saveMealSelections: { data: UpdateOrderMealSelectionsResponse }
        },
      },
      tsTypes: {} as import('./CurrentTermEditMealsV2.typegen').Typegen0,

      states: {
        checkingSelectionsState: {
          always: [
            {
              cond: 'hasMaxAndModifiedSelections',
              target: 'readyToSave',
            },
            {
              cond: 'hasModifiedSelections',
              target: 'modifyingSelections',
            },
            { target: 'idle' },
          ],
        },
        idle: {
          on: {
            ADD_MEAL: {
              actions: ['addToMealIDs'],
              target: 'checkingSelectionsState',
            },
            REMOVE_MEAL: {
              actions: ['removeFromMealIDs'],
              target: 'checkingSelectionsState',
            },
            UPDATE_MEAL_COUNT: {
              actions: ['updateMealCount'],
              target: 'checkingSelectionsState',
            },
          },
        },
        modifyingSelections: {
          on: {
            ADD_MEAL: {
              actions: ['addToMealIDs'],
              target: 'checkingSelectionsState',
            },
            REMOVE_MEAL: {
              actions: ['removeFromMealIDs'],
              target: 'checkingSelectionsState',
            },
            UPDATE_MEAL_COUNT: {
              actions: ['updateMealCount'],
              target: 'checkingSelectionsState',
            },
          },
        },
        readyToSave: {
          on: {
            REMOVE_MEAL: {
              actions: ['removeFromMealIDs'],
              target: 'checkingSelectionsState',
            },
            SAVE_MEAL_SELECTIONS: { target: 'savingMealSelections' },
            UPDATE_MEAL_COUNT: {
              actions: ['updateMealCount'],
              target: 'checkingSelectionsState',
            },
          },
        },
        savingMealSelections: {
          invoke: {
            src: 'saveMealSelections',
            onDone: [
              {
                target: 'saved',
              },
            ],
            onError: {
              actions: ['showSaveMealSelectionsError'],
              target: 'readyToSave',
            },
          },
        },
        saved: {
          type: 'final',
        },
      },
    },
    {
      actions: {
        addToMealIDs: assign({
          mealIDs: (ctx, event) => {
            return [...ctx.mealIDs, event.mealID]
          },
        }),
        removeFromMealIDs: assign({
          mealIDs: (ctx, event) => {
            const newMealIDs = [...ctx.mealIDs]

            const lastIndexOfMealID = findLastIndex(newMealIDs, (mealID) => {
              return mealID === event.mealID
            })

            if (lastIndexOfMealID !== -1) {
              newMealIDs.splice(lastIndexOfMealID, 1)
            }

            return newMealIDs
          },
        }),
        updateMealCount: assign({
          mealCount: (_ctx, event) => event.mealCount,
        }),
      },
    }
  )
}
