import { MenuComponentStandardizedTextBanner } from '@tovala/browser-apis-menu-components'

const TextBanner = ({
  properties,
}: {
  properties: MenuComponentStandardizedTextBanner['properties']
}) => {
  return (
    <div className="text-h/14_110 bg-white p-4 text-center font-semibold">
      {properties.title}
    </div>
  )
}

export default TextBanner
