import { useFormikContext } from 'formik'

import normalizePhone from '../../utils/normalizePhone'
import { SHIPPING_STATE_OPTIONS } from 'utils/users'

import InputField from '../common/InputField'
import SelectInput from 'components/common/SelectInput'

export interface FormData {
  city: string
  line1: string
  line2: string
  name: string
  notes: string
  phone: string
  state: string
  zipCode: string
}

const validateField = (value: string) => {
  let error: string | undefined
  if (!value) {
    error = 'Required'
  }

  return error
}

const ShippingFormFields = ({ showNotes }: { showNotes?: boolean }) => {
  const { setFieldValue } = useFormikContext()

  return (
    <div className="grid grid-cols-2 gap-4">
      <div className="col-span-2">
        <InputField
          label="Full Name"
          name="name"
          placeholder="First and Last Name"
          validate={validateField}
        />
      </div>

      <InputField
        label="Address Line 1"
        name="line1"
        placeholder="Address Line 1"
        validate={validateField}
      />

      <InputField
        label="Address Line 2"
        name="line2"
        placeholder="Address Line 2"
      />

      <InputField
        label="City"
        name="city"
        placeholder="City"
        validate={validateField}
      />

      <SelectInput
        isClearable
        label="State"
        name="state"
        options={SHIPPING_STATE_OPTIONS.map((option) => {
          return { label: option, value: option }
        })}
        placeholder="State"
        validate={validateField}
      />

      <InputField
        label="Zip Code"
        name="zipCode"
        placeholder="Zip Code"
        validate={validateField}
      />

      <InputField
        label="Phone"
        name="phone"
        onChange={(e) => {
          setFieldValue('phone', normalizePhone(e.target.value))
        }}
        placeholder="Phone"
        type="tel"
        validate={validateField}
      />

      {showNotes && (
        <div className="col-span-2">
          <InputField label="Notes*" name="notes" validate={validateField} />
        </div>
      )}
    </div>
  )
}

export default ShippingFormFields
