import { useIntentRoutinesForBarcode } from 'hooks/combinedAPI/routines'
import { Link } from 'react-router-dom'
import { getWholeMinutesRangeFromSeconds } from 'utils/general'
import QRCode from './QRCode'

const RoutineBarcodeDetails = ({
  qrCodeValue,
  routineBarcode,
  title,
}: {
  qrCodeValue: string | undefined
  routineBarcode: string | undefined
  title: string
}) => {
  const { routines, isLoading } = useIntentRoutinesForBarcode({
    routineBarcode,
  })

  const cookTimesInSeconds = routines
    .map((routine) => {
      return routine.cookTimeInSeconds
    })
    .filter(
      (cookTimeInSeconds): cookTimeInSeconds is number => !!cookTimeInSeconds
    )

  const cookTimesRange = getWholeMinutesRangeFromSeconds({
    timeInSeconds: cookTimesInSeconds,
  })

  if (!routineBarcode || !qrCodeValue) {
    return null
  }

  return (
    <div className="space-y-4">
      <QRCode value={qrCodeValue} />

      <div className="font-bold">QR Code Text</div>
      <Link className="hover:text-grey-909" target="_blank" to={qrCodeValue}>
        {qrCodeValue}
      </Link>

      <div className="font-bold">{title}</div>

      {isLoading ? (
        <>
          <div className="h-4 w-60 animate-pulse bg-gray-300" />
          <div className="h-4 w-60 animate-pulse bg-gray-300" />
          <div className="h-4 w-60 animate-pulse bg-gray-300" />
        </>
      ) : (
        <>
          <CookTime
            formattedCookTime={
              routines.find((routine) => routine.routine?.oven_model === 'gen2')
                ?.formattedCookTime ?? 'Unavailable due to no routine'
            }
            title="Steam Oven"
          />
          <CookTime
            formattedCookTime={
              routines.find(
                (routine) => routine.routine?.oven_model === 'airvala'
              )?.formattedCookTime ?? 'Unavailable due to no routine'
            }
            title="Air Fry Oven"
          />

          <div>
            Insert Cook Time Range:{' '}
            {cookTimesRange
              ? `Cooks in ${cookTimesRange} min`
              : 'Unavailable due to no routine(s)'}
          </div>
        </>
      )}
    </div>
  )
}

export default RoutineBarcodeDetails

const CookTime = ({
  formattedCookTime,
  title,
}: {
  formattedCookTime: string
  title: string
}) => {
  return (
    <div>
      {title} Cook Time: {formattedCookTime}
    </div>
  )
}
