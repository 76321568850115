import { createReducer } from '@reduxjs/toolkit'
import { SubscriptionPreferences } from '@tovala/browser-apis-combinedapi'

import * as types from '../actions/types'

const INITIAL_STATE: {
  planPreferences: SubscriptionPreferences | ''
} = {
  planPreferences: '',
}

export const user = createReducer(INITIAL_STATE, {
  [types.SET_PLAN_PREFERENCES]: (state, action) => {
    state.planPreferences = action.payload
  },
})
