import { AxiosRequestConfig } from 'axios'

import { getCookie } from 'utils/storage'
import { getEnvVar } from 'utils/env'

export const API_URL = getEnvVar('API_HOST')
export const API_URL_V1 = getEnvVar('API_HOST_V1')
export const APP_ID = 'adminwebapp'

export const headers = {
  headers: {
    Authorization: 'Bearer ' + getCookie('JWT_TOKEN'),
    'X-Tovala-AppID': APP_ID,
  },
}

export function getHeaders(upload = false) {
  const headers: AxiosRequestConfig['headers'] = {}

  if (upload) {
    headers['Content-Type'] = 'multipart/form-data'
  }

  // Only add Auth header if we have a cookie
  const userCookie = getCookie('JWT_TOKEN')
  if (userCookie) {
    headers.Authorization = 'Bearer ' + getCookie('JWT_TOKEN')
  }
  headers['X-Tovala-AppID'] = APP_ID

  return { headers }
}
