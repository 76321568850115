import { Field, FieldInputProps, Form, Formik } from 'formik'
import { ReactNode, SelectHTMLAttributes, useEffect } from 'react'

import { useAppDispatch } from 'hooks'
import Button from 'components/common/Button'
import FormLabel from 'components/common/FormLabel'
import H3 from 'components/common/H3'
import H4 from 'components/common/H4'
import Select from 'components/common/Select'
import TabGroup, {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
} from 'components/common/TabGroup'
import {
  setCheckoutShipPeriodDefault,
  useNextTerm,
  useProspectTerms,
} from '@tovala/browser-apis-combinedapi'
import { successHandler } from 'actions/notifications'

interface FormData {
  shipPeriod: string
}

const MDDLevers = (): JSX.Element => {
  useEffect(() => {
    document.title = `Glaze | MDD Levers`
  }, [])

  const dispatch = useAppDispatch()

  const { data: term } = useNextTerm()
  const { data: prospectTerms, isLoading: isLoadingProspectTerms } =
    useProspectTerms({
      termID: term?.id,
      zipCode: '84122',
    })
  const { marketingPreferredShipPeriod } = prospectTerms || {}

  return (
    <div>
      <H3>MDD Marketing Levers</H3>

      <TabGroup>
        <TabList>
          <Tab>Global</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Formik<FormData>
              enableReinitialize
              initialValues={{
                shipPeriod: marketingPreferredShipPeriod
                  ? marketingPreferredShipPeriod.toString()
                  : '',
              }}
              onSubmit={async (values) => {
                const res = await setCheckoutShipPeriodDefault({
                  shipPeriod: Number.parseInt(values.shipPeriod, 10),
                })

                if (res) {
                  successHandler(dispatch, `Success! Default Cycle updated.`)
                }
              }}
            >
              <Form>
                <div className="w-1/2">
                  <H4>Default First Delivery Cycle</H4>

                  <div className="space-y-4">
                    <p>
                      Set the delivery cycle that should appear as the default
                      option when customers choose their first meal delivery
                      date in checkout
                    </p>

                    <div>
                      <FormLabel>Default cycle</FormLabel>
                      <div className="flex space-x-4">
                        <div className="w-1/2">
                          {isLoadingProspectTerms ? (
                            <div className="h-10 bg-grey-905 animate-pulse rounded-md" />
                          ) : (
                            <Field component={SelectInternal} name="shipPeriod">
                              <option value="1">Cycle 1</option>
                              <option value="2">Cycle 2</option>
                            </Field>
                          )}
                        </div>

                        <div>
                          <Button
                            disabled={isLoadingProspectTerms}
                            size="large"
                            type="submit"
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </div>
  )
}

export default MDDLevers

const SelectInternal = ({
  children,
  field,
  ...props
}: SelectHTMLAttributes<HTMLSelectElement> & {
  children: ReactNode
  field: FieldInputProps<string>
}): JSX.Element => {
  return (
    <Select {...field} {...props}>
      {children}
    </Select>
  )
}
