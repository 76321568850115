import { put } from './baseAPI'

export interface EditUserReferralCode {
  data: {
    code: string
  }
  userID: number
}

export interface EditUserReferralCodeResponse {
  TimesUsed: number
  code: string
  referralType: {
    coupon: {
      appliedList: null
      code: string
      created: string
      credit_amount: number
      description: string
      dollar_amount: number
      dollar_percent: number
      max_food_orders: null
      max_redemptions: null
      meals_amount: number
      notes: string
      objectsIndex: { code: string; couponCodeAppliedIDs: null }
      promoDuration: number
      promoExpiration: number
      promoInterval: string
      promoType: string
      redeem_by: null
      sku: null
      updated: string
      userid: null
    }
    defaultType: boolean
    description: string
    maxRedemptions: number
    referrerPromotion: {
      centsAmount: number
      creditAmount: number
      mealsAmount: number
    }
  }
  referralTypeID: string
  userid: number
}

export const ENDPOINTS = {
  EDIT_USER_REFERRAL_CODE: ({
    userID,
  }: Pick<EditUserReferralCode, 'userID'>) => ({
    path: `/users/${userID}/referralCode`,
    version: 'v1' as const,
  }),
}

export async function editUserReferralCode({
  data,
  userID,
}: EditUserReferralCode): Promise<EditUserReferralCodeResponse> {
  return put({
    // We are going to stop hardcoding this in https://tovala.atlassian.net/browse/WAT-520
    // after the backend updates the endpoint logic.
    data: { ...data, referralTypeID: '647f5c2a-2259-4f48-8011-7352746657dc' },
    ...ENDPOINTS.EDIT_USER_REFERRAL_CODE({ userID }),
  })
}
