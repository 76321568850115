import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import {
  GetIntentRoutineByOvenModel,
  getIntentRoutineByOvenModel,
  GetIntentRoutineResponse,
} from 'services/combinedAPI/routines'
import { getFormattedCookTime, getTotalCookTimeInSeconds } from 'utils/routines'
import { minutesToMs } from 'utils/general'

export function useIntentRoutineByOvenModel({
  ovenModel,
  routineBarcode,
  ...rest
}: Partial<GetIntentRoutineByOvenModel> &
  Omit<
    UseQueryOptions<GetIntentRoutineResponse, Error>,
    'enabled' | 'queryFn' | 'queryKey' | 'staleTime'
  >) {
  return useQuery<GetIntentRoutineResponse, Error>({
    ...rest,
    enabled: !!(routineBarcode && ovenModel),
    queryFn: () => {
      if (!routineBarcode) {
        throw new Error(
          'Cannot get intent routine because a routineBarcode was not provided'
        )
      }

      if (!ovenModel) {
        throw new Error(
          'Cannot get intent routine because an oven model was not provided'
        )
      }

      return getIntentRoutineByOvenModel({ ovenModel, routineBarcode })
    },
    queryKey: ['intent-routine-oven-model', ovenModel, routineBarcode],
    staleTime: minutesToMs(5),
  })
}

export function useIntentRoutinesForBarcode({
  routineBarcode,
}: {
  routineBarcode: string | undefined
}) {
  const { data: intentRoutineAirvala, isLoading: isLoadingAirvala } =
    useIntentRoutineByOvenModel({
      ovenModel: 'airvala',
      routineBarcode,
    })

  const { data: intentRoutineGen2, isLoading: isLoadingGen2 } =
    useIntentRoutineByOvenModel({
      ovenModel: 'gen2',
      routineBarcode,
    })

  return {
    isLoading: isLoadingAirvala || isLoadingGen2,
    routines: [intentRoutineAirvala, intentRoutineGen2].map((intentRoutine) => {
      const cookTimeInSeconds = intentRoutine?.routine.routine
        ? getTotalCookTimeInSeconds({
            routineSteps: intentRoutine.routine.routine,
          })
        : null
      return {
        ...intentRoutine,
        cookTimeInSeconds,
        formattedCookTime: cookTimeInSeconds
          ? getFormattedCookTime({ cookTimeInSeconds })
          : '',
      }
    }),
  }
}
