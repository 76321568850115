import { get } from './baseAPI'
import { GuidanceEvents } from 'types/domainModels'

export type GetAllGuidanceEventsResponse = GuidanceEvents

export type GetUserGuidanceEventResponse = GuidanceEvents

export type GetUserGuidanceEvent = {
  userId: number
}

export type UserInfo = {
  userId: number
  extraInfo: string
}

export const ENDPOINTS = {
  GET_USER_GUIDANCE: ({ userId }: Pick<GetUserGuidanceEvent, 'userId'>) => ({
    path: `/tools/realTimeGuidance/user/${userId}`,
    version: 'v1' as const,
  }),
  GET_ALL_GUIDANCE_EVENTS: () => ({
    path: `/tools/realTimeGuidance`,
    version: 'v1' as const,
  }),
}

export async function getUserGuidanceEvent({
  userId,
}: GetUserGuidanceEvent): Promise<GetUserGuidanceEventResponse> {
  return get(ENDPOINTS.GET_USER_GUIDANCE({ userId }))
}

export async function getAllGuidanceEvents(): Promise<GetAllGuidanceEventsResponse> {
  return get(ENDPOINTS.GET_ALL_GUIDANCE_EVENTS())
}
