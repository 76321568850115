import {
  MealSelection,
  MealSummary,
  UserV1,
  useCreateUserTermStatus,
  useEditUserTermStatus,
  useInvalidateListings,
  useInvalidateTermStatuses,
  useMealSummaries,
  useUserSubscriptionTypes,
} from '@tovala/browser-apis-combinedapi'
import { Button, ButtonLoading } from '@tovala/component-library'
import { clsx } from 'clsx'
import AlertInline from 'components/common/AlertInline'
import { FormRadioRHF } from 'components/common/FormRadioRHF'
import { uniq } from 'lodash-es'
import moment from 'moment'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { UserTerm } from 'types/internal'
import { formatDate, DATE_FORMATS } from 'utils/dates'

interface UserTermStatusFormData {
  subscriptionTypeID: string | undefined
  subTermID: string
}

const EditUserTermStatus = ({
  selectedUserTerm,
  user,
}: {
  selectedUserTerm: UserTerm
  user: UserV1
}) => {
  const [isEditingTermStatus, setIsEditingTermStatus] = useState(false)

  const { isLoading: isCreatingUserTermStatus, mutate: createUserTermStatus } =
    useCreateUserTermStatus()
  const { isLoading: isUpdatingUserTermStatus, mutate: updateUserTermStatus } =
    useEditUserTermStatus()

  const { invalidateUserTermStatuses } = useInvalidateTermStatuses()
  const { invalidateAllListingSelections } = useInvalidateListings()

  const { data: getUserSubscriptionTypesResponse } = useUserSubscriptionTypes({
    userID: user.id,
  })
  const userSubscriptionTypes =
    getUserSubscriptionTypesResponse?.subscription_types ?? []

  const deliveryOptions = [...selectedUserTerm.subTerms]
    .filter((subTerm) => subTerm.isAvailable)
    .sort((a, b) => a.shipPeriod - b.shipPeriod)

  const { control, handleSubmit, reset, watch } =
    useForm<UserTermStatusFormData>({
      defaultValues: {
        subscriptionTypeID: selectedUserTerm.subscriptionType?.id,
        subTermID: selectedUserTerm.selectedSubTermID,
      },
    })

  const selectedSubscriptionTypeID = watch('subscriptionTypeID')
  const selectedSubTermID = watch('subTermID')

  const selectedSubscription = userSubscriptionTypes.find(
    (subscriptionType) => subscriptionType.id === selectedSubscriptionTypeID
  )

  const onSubmit = (formData: UserTermStatusFormData) => {
    if (!formData.subscriptionTypeID) {
      throw new Error('Cannot edit delivery without a subscription type.')
    }

    const updatedTermStatus = {
      notes: '',
      subscriptionTypeID: formData.subscriptionTypeID,
      subTermID: formData.subTermID,
      termID: selectedUserTerm.termID,
      userID: user.id,
    }

    if (selectedUserTerm.id) {
      updateUserTermStatus(
        {
          data: updatedTermStatus,
          termStatusID: selectedUserTerm.id,
          userID: user.id,
        },
        {
          onSuccess: () => {
            setIsEditingTermStatus(false)
            invalidateUserTermStatuses(user.id)
            invalidateAllListingSelections(user.id)
          },
        }
      )
    } else {
      createUserTermStatus(
        { data: updatedTermStatus, userID: user.id },
        {
          onSuccess: () => {
            setIsEditingTermStatus(false)
            invalidateUserTermStatuses(user.id)
            invalidateAllListingSelections(user.id)
          },
        }
      )
    }
  }

  const utcOffset = moment().isDST() ? -5 : -6

  if (isEditingTermStatus) {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-between items-center ">
          <div>
            <h1 className="text-k/28_110">
              Term #{selectedUserTerm.termID} - Order by{' '}
              {moment(selectedUserTerm.orderByDate)
                .utcOffset(utcOffset)
                .format('dddd, M/D')}{' '}
              at 6 p.m. CT
            </h1>
          </div>
          <div>
            <Button
              buttonStyle="white"
              onClick={() => {
                setIsEditingTermStatus(false)
                reset({
                  subscriptionTypeID: selectedUserTerm.subscriptionType?.id,
                  subTermID: selectedUserTerm.selectedSubTermID,
                })
              }}
              size="large"
            >
              Cancel
            </Button>
          </div>
        </div>
        <div className="flex">
          <div className="w-1/2 max-w-sm">
            <div className="font-bold">Meals this week</div>
            <div className="space-y-4 mt-4">
              {userSubscriptionTypes.map((subscription) => {
                let shipping = ''
                if (subscription.shippingCents > 0) {
                  shipping = ` + $${(subscription.shippingCents / 100).toFixed(
                    2
                  )} shipping`
                }

                return (
                  <div key={subscription.id}>
                    <FormRadioRHF
                      checked={selectedSubscriptionTypeID === subscription.id}
                      control={control}
                      id={subscription.id}
                      label={`${subscription.max_selections} Meals ($${(
                        subscription.price_cents / 100
                      ).toFixed(2)}${shipping})${
                        user.subscription.subscriptionType &&
                        subscription.max_selections ===
                          user.subscription.subscriptionType.maxSelections
                          ? ' - Default'
                          : ''
                      }`}
                      name="subscriptionTypeID"
                      value={subscription.id}
                    />
                  </div>
                )
              })}
            </div>
          </div>

          <div>
            <div className="font-bold">Delivery Day</div>
            <div className=" space-y-4 mt-4">
              {deliveryOptions.map((option) => {
                return (
                  <div key={option.subTermID}>
                    <FormRadioRHF
                      checked={selectedSubTermID === option.subTermID}
                      control={control}
                      id={option.subTermID}
                      label={formatDate(new Date(option.deliveryDate), {
                        format: DATE_FORMATS.DOW_MONTH_ABBR_DAY,
                      })}
                      name="subTermID"
                      value={option.subTermID}
                    />
                  </div>
                )
              })}
            </div>
          </div>
        </div>

        <div className="my-2 space-y-2 text-center">
          {selectedSubscription &&
            selectedUserTerm.mealSelections.length >
              selectedSubscription.max_selections && (
              <AlertInline alertStyle="warning">
                If you save this change, the last meal{' '}
                {selectedUserTerm.mealSelections.length -
                  selectedSubscription.max_selections}{' '}
                selections will be deleted.
              </AlertInline>
            )}

          {selectedSubTermID !== selectedUserTerm.selectedSubTermID && (
            <UnavailableMealSelections
              mealSelections={selectedUserTerm.mealSelections}
              newSubTermID={selectedSubTermID}
              subTermID={selectedUserTerm.selectedSubTermID}
            />
          )}
        </div>

        <div className="mt-6">
          <ButtonLoading
            isLoading={isCreatingUserTermStatus || isUpdatingUserTermStatus}
            size="large"
            type="submit"
          >
            Save Changes
          </ButtonLoading>
        </div>
      </form>
    )
  }

  return (
    <div>
      <div className="flex justify-between items-center ">
        <div>
          <h1 className="text-k/28_110">
            Term #{selectedUserTerm.termID} - Order by{' '}
            {moment(selectedUserTerm.orderByDate)
              .utcOffset(utcOffset)
              .format('dddd, M/D')}{' '}
            at 6 p.m. CT
          </h1>
        </div>
        <div>
          <Button
            buttonStyle="white"
            onClick={() => {
              setIsEditingTermStatus(true)
            }}
            size="large"
          >
            Edit Delivery
          </Button>
        </div>
      </div>
      <div className="flex">
        <div className="w-1/2 max-w-xs">
          <div className="font-bold">Meals this week</div>
          <div className="space-x-2">
            {user.subscription.subscriptionType && (
              <span
                className={clsx({
                  'line-through':
                    !selectedUserTerm.selectedSubscriptionTypeIsDefault,
                })}
              >
                {user.subscription.subscriptionType.maxSelections} Meal Plan
              </span>
            )}
            {!selectedUserTerm.selectedSubscriptionTypeIsDefault && (
              <span>
                {selectedUserTerm.subscriptionType?.maxSelections} Meals
              </span>
            )}
          </div>
        </div>
        <div>
          <div className="font-bold">Delivery Day</div>
          {selectedUserTerm.selectedSubTerm ? (
            <div>
              {formatDate(
                new Date(selectedUserTerm.selectedSubTerm.deliveryDate),
                {
                  format: DATE_FORMATS.DOW_MONTH_ABBR_DAY,
                }
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default EditUserTermStatus

const UnavailableMealSelections = ({
  mealSelections,
  newSubTermID,
  subTermID,
}: {
  mealSelections: MealSelection[]
  newSubTermID: string
  subTermID: string
}) => {
  const unavailableMeals = useUnavailableMealsforDeliveryDayChange({
    subTermID,
    newSubTermID,
    mealSelections,
  })

  if (unavailableMeals.length === 0) {
    return null
  }

  return (
    <AlertInline alertStyle="warning">
      <p className="font-bold">
        Some meals are unavailable or sold out for the selected delivery date.
        If you save this change, the following meal selections will be deleted:
      </p>
      {unavailableMeals.map((meal) => {
        return <p key={meal.id}>{meal.title}</p>
      })}
    </AlertInline>
  )
}

function useUnavailableMealsforDeliveryDayChange({
  mealSelections,
  newSubTermID,
  subTermID,
}: {
  mealSelections: MealSelection[]
  newSubTermID: string
  subTermID: string
}) {
  const { data: mealSummaries = [] } = useMealSummaries({
    subTermID,
  })
  const { data: altMealSummaries = [] } = useMealSummaries({
    subTermID: newSubTermID,
  })

  const uniqueSelectedMealIDs = uniq(
    mealSelections.map((selection) => selection.mealID)
  )
  const alternateSubTermAvailableMealIDs = altMealSummaries
    .filter((meal) => !meal.isSoldOut)
    .map((meal) => meal.id)
  const unavailableMealSelectionIDs = uniqueSelectedMealIDs.filter(
    (mealID) => !alternateSubTermAvailableMealIDs.includes(mealID)
  )

  return unavailableMealSelectionIDs
    .map((mealID) => mealSummaries.find((meal) => meal.id === mealID))
    .filter((mealSummary): mealSummary is MealSummary => !!mealSummary)
}
