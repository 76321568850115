import { isArray } from 'lodash-es'
import { RoutineStepIntent } from '@tovala/browser-apis-combinedapi'

export function getFormattedCookTime({
  cookTimeInSeconds,
}: {
  cookTimeInSeconds: number
}) {
  const minutes = Math.floor(cookTimeInSeconds / 60)
  const seconds = cookTimeInSeconds - minutes * 60

  const totalTimeInMinsAndSeconds =
    minutes +
    ':' +
    seconds.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })

  return totalTimeInMinsAndSeconds
}

export function getTotalCookTimeInSeconds({
  routineSteps,
}: {
  routineSteps: RoutineStepIntent[]
}) {
  const routineStepsArr: RoutineStepIntent[] = isArray(routineSteps)
    ? routineSteps
    : [routineSteps]

  return routineStepsArr.reduce((time, step) => {
    const stepTime = step.time

    return time + stepTime
  }, 0)
}
