import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { FormEvent } from 'react'
import { UserV1, useAddPaymentSource } from '@tovala/browser-apis-combinedapi'

import {
  errorHandler,
  showErrorNotification,
  successHandler,
} from '../../actions/notifications'
import { getUserInfo } from 'actions/auth'
import { isAxiosErrorWithExtra } from 'utils/api'

import { useAppDispatch } from 'hooks'
import ButtonLoading from 'components/common/ButtonLoading'

const CcForm = ({ user }: { user: UserV1 }) => {
  const dispatch = useAppDispatch()

  const stripe = useStripe()
  const elements = useElements()

  const { isLoading: isAddingPaymentSource, mutate: addPaymentSource } =
    useAddPaymentSource({
      onError: (error) => {
        errorHandler(
          dispatch,
          error,
          isAxiosErrorWithExtra(error)
            ? error.response?.data.extra.message ?? null
            : null
        )
      },
      onSuccess: (_, { userID }) => {
        successHandler(dispatch, 'Payment source added.')
        dispatch(getUserInfo(userID))
      },
    })

  const handleSubmit = async (ev: FormEvent<HTMLFormElement>) => {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault()

    if (!stripe || !elements) {
      return
    }

    const cardElement = elements.getElement('card')
    if (!cardElement) {
      return
    }

    const { error, token } = await stripe.createToken(cardElement)

    if (error || !token) {
      const errorMessage = error
        ? error.message
        : 'Failed to create Stripe token'

      dispatch(showErrorNotification(errorMessage))
    } else {
      addPaymentSource({
        customerID: user.subscription.customerID,
        data: { setAsDefault: true, stripeToken: token.id },
        userID: user.id,
      })
    }
  }

  return (
    <form className="space-y-4" onSubmit={handleSubmit}>
      <div className="py-5">
        <CardElement options={{ style: { base: { fontSize: '18px' } } }} />
      </div>
      <div className="flex space-x-4">
        <ButtonLoading
          isLoading={isAddingPaymentSource}
          size="large"
          type="submit"
        >
          Save Card
        </ButtonLoading>
      </div>
    </form>
  )
}

export default CcForm
