import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import {
  RemoveMealCash,
  removeMealCash,
  RemoveMealCashResponse,
} from 'services/combinedAPI/mealCash'

export function useRemoveMealCash(
  opts?: Omit<
    UseMutationOptions<RemoveMealCashResponse, Error, RemoveMealCash>,
    'mutationFn'
  >
) {
  return useMutation({
    ...opts,
    mutationFn: (opts: RemoveMealCash) => {
      return removeMealCash(opts)
    },
  })
}
