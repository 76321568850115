import { useAppSelector } from 'hooks'

const Alert = () => {
  const error = useAppSelector((state) => state.notifications.error)
  const success = useAppSelector((state) => state.notifications.success)

  const isMobile = window.innerWidth <= 990

  let bgColor = 'transparent'

  if (error) {
    bgColor = '#f2dede'
  }

  if (success) {
    bgColor = '#E6F0D7'
  }

  const fontColor = error ? '#4A4A4A' : '#3c763d'

  return (
    <div
      style={{
        transition: 'all 0.5s linear',
        opacity: success || error ? 1 : 0,
        display: 'flex',
        maxHeight: success || error ? '200px' : '1px',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: bgColor,
        color: fontColor,
        position: 'fixed',
        top: isMobile ? '67px' : '50px',
        left: 0,
        right: 0,
        width: '100%',
        padding: success || error ? '15px' : '0px',
        zIndex: success || error ? 99999 : 0,
        margin: 'auto',
        borderRadius: '0px',
      }}
    >
      {success && (
        <span>
          <strong>{success}</strong>
        </span>
      )}
      {error && (
        <span>
          <strong>Oops!</strong> {error}
        </span>
      )}
    </div>
  )
}

export default Alert
