import { post } from './baseAPI'

export interface CreateExchangeOvenShipment {
  data: {
    address: {
      userName: string
      phone: string
      line1: string
      line2: string
      city: string
      state: string
      zipCode: string
    }
    notes: string
    purchaseID: string | null
    reason: string
  }
  productID: string
  userID: number
}

export interface CreateExchangeOvenShipmentResponse {
  error: false
  message: 'OK'
}

const ENDPOINTS = {
  CREATE_EXCHANGE_OVEN_SHIPMENT: ({
    productID,
    userID,
  }: Pick<CreateExchangeOvenShipment, 'productID' | 'userID'>) => ({
    path: `/users/${userID}/exchangeOven/${productID}`,
    version: 'v1' as const,
  }),
}

export async function createExchangeOvenShipment({
  data,
  productID,
  userID,
}: CreateExchangeOvenShipment): Promise<CreateExchangeOvenShipmentResponse> {
  return post({
    data,
    ...ENDPOINTS.CREATE_EXCHANGE_OVEN_SHIPMENT({ productID, userID }),
  })
}
