import { MealAdmin, useTerm } from '@tovala/browser-apis-combinedapi'

import { getAdminScope, MEALS_WRITE } from '../../utils/getAdminScope'

import { useAppSelector } from 'hooks'
import Button from 'components/common/Button'
import MealForm from './MealForm'

const UpdateMeal = ({
  meal,
  mealid,
  onDuplicateMeal,
  toggleRemoveFromTermModal,
}: {
  meal: MealAdmin
  mealid: string
  onDuplicateMeal: () => void
  toggleRemoveFromTermModal: () => void
}): JSX.Element => {
  const { data: term } = useTerm({
    termID: meal.termid && meal.termid > 0 ? meal.termid : undefined,
  })
  const allMealTags = useAppSelector((state) => state.mealTags.allMealTags)

  return (
    <MealForm
      allMealTags={allMealTags}
      form="updateMeal"
      meal={meal}
      mealid={mealid}
      readOnly={!getAdminScope(MEALS_WRITE)}
      selectedTerm={term}
    >
      {getAdminScope(MEALS_WRITE) && (
        <div className="space-y-2">
          <div className="h-10">
            <Button onClick={onDuplicateMeal} size="fluid">
              Duplicate Meal
            </Button>
          </div>
          {meal.termid && meal.termid !== 0 && (
            <div className="h-10">
              <Button
                buttonStyle="grey"
                onClick={toggleRemoveFromTermModal}
                size="fluid"
              >
                Remove from Term
              </Button>
            </div>
          )}
        </div>
      )}
    </MealForm>
  )
}

export default UpdateMeal
