import { post } from './baseAPI'

export interface RemoveMealCash {
  cashID: string
  data: {
    csAgent: string
    internalCashType: string
    notes: string
    reason: string
    zendeskTicket: string
  }
  userID: number
}

export interface RemoveMealCashResponse {
  action: 'cs_mealcash'
  amount: number
  couponCode: string
  created: string
  csAffectedMeals: []
  csAgent: string
  csReason: 'oven-issues'
  csZendeskTicket: string
  financeType: 'marketing'
  giftCardID: string
  id: string
  internalCashType: 'gift_card'
  isVoided: boolean
  notes: string
  paymentID: null
  purchaseID: null
  termID: null
  updated: string
  userID: number
}

export const ENDPOINTS = {
  REMOVE_MEAL_CASH: ({
    cashID,
    userID,
  }: Pick<RemoveMealCash, 'cashID' | 'userID'>) => ({
    path: `/tools/users/${userID}/mealCash/${cashID}/remove`,
    version: 'v1' as const,
  }),
}

export async function removeMealCash({
  cashID,
  data,
  userID,
}: RemoveMealCash): Promise<RemoveMealCashResponse> {
  return post({
    data,
    ...ENDPOINTS.REMOVE_MEAL_CASH({ cashID, userID }),
  })
}
