import { QRCodeCanvas } from 'qrcode.react'
import { useEffect, useRef } from 'react'

const QRCode = ({ value }: { value: string }) => {
  const canvasParentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // Grayscale conversion taken from
    // https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Pixel_manipulation_with_canvas#grayscaling_and_inverting_colors
    const canvas = canvasParentRef.current?.querySelector('canvas')
    if (canvas) {
      const context = canvas.getContext('2d', { willReadFrequently: true })
      if (context) {
        const imageData = context.getImageData(
          0,
          0,
          canvas.width,
          canvas.height
        )
        const data = imageData.data
        for (let i = 0; i < data.length; i += 4) {
          const avg =
            data[i] * 0.299 + data[i + 1] * 0.587 + data[i + 2] * 0.114
          data[i] = avg // red
          data[i + 1] = avg // green
          data[i + 2] = avg // blue
        }
        context.clearRect(0, 0, canvas.width, canvas.height)
        context.putImageData(imageData, 0, 0)
      }
    }
  })

  return (
    <div ref={canvasParentRef}>
      <QRCodeCanvas
        bgColor="#FFFFFF"
        fgColor="#000000"
        level="Q"
        size={180}
        value={value}
      />
    </div>
  )
}

export default QRCode
