import { capitalize } from 'lodash-es'
import { Link, useParams } from 'react-router-dom'
import { ReactNode, useEffect } from 'react'
import {
  TermStatus,
  UserV1,
  UserV1ShipPeriodInfo,
  UserV1ShippingAddress,
  useOvens,
  useSkippedWeeks,
  useUserTermStatuses,
  useUserV0,
} from '@tovala/browser-apis-combinedapi'

import { getOvenModelMarketingName } from 'utils/ovens'
import { useHandleSkipChange } from './hooks'
import { CalendarTermDetails } from './menu/UserCalendar'
import Card, { CardBody } from 'components/common/Card'
import UserGuidanceEventCard from 'components/user/GuidanceEvent'
import H2 from 'components/common/H2'
import H4 from 'components/common/H4'
import OvenOrderHistory from './OvenOrderHistory'
import OverviewLine from './OverviewLine'
import OvenChannel from './OvenChannel'
import { getAdminScope } from 'utils/getAdminScope'

const AccountOverview = ({ user }: { user: UserV1 }): JSX.Element => {
  const { userid } = useParams<{ userid: string }>()

  useEffect(() => {
    document.title = `Glaze | User #${userid} - Account Overview`
  }, [userid])

  let shippingAddress: UserV1ShippingAddress | '' = ''
  let availableShipPeriods: UserV1ShipPeriodInfo[] = []
  let defaultShipPeriod: UserV1ShipPeriodInfo | '' | undefined = ''
  let otherShipPeriod: UserV1ShipPeriodInfo | '' | undefined = ''
  let facilityNetwork: string | undefined = ''

  if (user.shippingAddresses.length && user.subscription) {
    shippingAddress = user.shippingAddresses[0]
    defaultShipPeriod = shippingAddress.shipPeriodInfo.find(
      (s) => s.shipPeriod === user.subscription.defaultShipPeriod
    )
    facilityNetwork = defaultShipPeriod && defaultShipPeriod.facilityNetwork
    availableShipPeriods = shippingAddress.shipPeriodInfo
      .filter((s) => s.isAvailable)
      .sort((a, b) => a.shipPeriod - b.shipPeriod)

    if (availableShipPeriods.length > 1) {
      otherShipPeriod = availableShipPeriods.find(
        (s) => s.shipPeriod !== user.subscription.defaultShipPeriod
      )
    }
  }

  let activePlanFlag = true
  if (user.subscription && user.subscription.status !== 'active') {
    activePlanFlag = false
  }

  const { data: ovens = [] } = useOvens({ userID: user.id })
  const { data: userV0 } = useUserV0({ userID: user.id })

  const { data: userTermStatuses = [] } = useUserTermStatuses({
    userID: user.id,
  })

  return (
    <div>
      <H2>User #{user.id}</H2>
      {
        <Card>
          <CardBody>
            <UserGuidanceEventCard userId={user.id} />
          </CardBody>
        </Card>
      }
      <div className="mt-4 flex space-x-4">
        <div className="w-1/2 space-y-4">
          {userV0?.notes && (
            <AccountOverviewCard linkText="User Notes" to="../edit">
              {userV0.notes}
            </AccountOverviewCard>
          )}

          {shippingAddress && (
            <AccountOverviewCard
              linkText="Shipping Information"
              to="../shipping"
            >
              <div className="space-y-2">
                <OverviewLine label="Address">
                  <p>{shippingAddress.userName}</p>
                  <p>{shippingAddress.line1}</p>
                  <p>{shippingAddress.line2}</p>
                  <p>
                    {shippingAddress.city}, {shippingAddress.state}{' '}
                    {shippingAddress.zipCode}
                  </p>
                  <p>{shippingAddress.phone}</p>
                </OverviewLine>

                <OverviewLine label="Shipping Carrier">
                  <p>
                    {userV0 ? userV0.shipping_address.shipping_company : '---'}
                  </p>
                </OverviewLine>
              </div>
            </AccountOverviewCard>
          )}

          <AccountOverviewCard
            linkText="Account Balance"
            to="../account-balance/balance"
          >
            <div className="space-y-2">
              <div>
                <p className="font-bold">
                  Tovala Cash:{' '}
                  {user ? `${(user.cashCents / 100).toFixed(2)}` : '---'}
                </p>
                <p className="text-xs">
                  Gift cards, refunds, or referral bonuses
                </p>
              </div>

              <div>
                <p className="font-bold">
                  Discounts:{' '}
                  {user ? `${(user.discountCents / 100).toFixed(2)}` : '---'}
                </p>
                <p className="text-xs">Coupons, discounts, and promotions</p>
              </div>
            </div>
          </AccountOverviewCard>

          {ovens.length > 0 && (
            <Card>
              <CardBody>
                <H4>Synced Oven</H4>
                {ovens.map((oven) => {
                  return (
                    <div key={oven.id}>
                      <OverviewLine label={'Oven Name'}>
                        <Link to={`/user/${oven.userid}/oven/${oven.id}`}>
                          {oven.name}
                        </Link>
                      </OverviewLine>
                      <OverviewLine label={'Oven Model'}>
                        {getOvenModelMarketingName(oven)}
                      </OverviewLine>
                      {getAdminScope('PRODUCTS_READ') && (
                        <OvenChannel oven={oven} ovens={ovens} />
                      )}
                    </div>
                  )
                })}
              </CardBody>
            </Card>
          )}

          <AccountOverviewCard
            linkText="Tovala.com Oven Purchase History"
            to="../meal-plan"
          >
            <OvenOrderHistory isAccountOverview user={user} />
          </AccountOverviewCard>
        </div>

        <div className="w-1/2 space-y-4">
          <AccountOverviewCard linkText="Meal Plan" to="../meal-plan">
            <div className="space-y-2">
              <OverviewLine label="Subscription">
                {capitalize(user.subscription.status)}
              </OverviewLine>

              {user.subscription.status === 'active' && (
                <OverviewLine label="Plan">
                  {user.subscription.subscriptionType && (
                    <span>{user.subscription.subscriptionType.typeName}</span>
                  )}
                </OverviewLine>
              )}
            </div>

            {userV0?.subscription.commitment_details &&
              userV0.subscription.commitment_details.commitment_weeks > 0 && (
                <OverviewLine label="Commitment Deliveries">
                  {userV0.subscription.commitment_details.commitment_weeks -
                    userV0.subscription.commitment_details.weeks_remaining}
                  &nbsp;of{' '}
                  {userV0.subscription.commitment_details.commitment_weeks}{' '}
                  ordered
                </OverviewLine>
              )}
          </AccountOverviewCard>

          {activePlanFlag && (
            <AccountOverviewCard linkText="Meal Box" to="../meal-plan">
              {defaultShipPeriod && (
                <div className="space-y-2">
                  <OverviewLine label="Facility Network">
                    {facilityNetwork && (
                      <span>{facilityNetwork.toUpperCase()}</span>
                    )}
                  </OverviewLine>
                  <OverviewLine label="Cycle">
                    {defaultShipPeriod.shipPeriod}
                  </OverviewLine>
                  <OverviewLine label="Delivery Day">
                    {defaultShipPeriod.deliveryDayOfTheWeek}s
                  </OverviewLine>
                  <OverviewLine label="Optional Delivery Day">
                    {otherShipPeriod ? (
                      <span>{otherShipPeriod.deliveryDayOfTheWeek}s</span>
                    ) : (
                      <span>N/A</span>
                    )}
                  </OverviewLine>
                </div>
              )}
            </AccountOverviewCard>
          )}

          {activePlanFlag && userTermStatuses.length > 0 && (
            <AccountOverviewCard linkText="Current Term Order" to="../calendar">
              <CurrentTermStatus term={userTermStatuses[0]} user={user} />
            </AccountOverviewCard>
          )}
        </div>
      </div>
    </div>
  )
}

export default AccountOverview

const AccountOverviewCard = ({
  children,
  linkText,
  to,
}: {
  children: ReactNode
  linkText: string
  to: string
}) => {
  return (
    <Card>
      <CardBody>
        <H4>
          <Link className="hover:text-grey-909" to={to}>
            {linkText}
          </Link>
        </H4>

        {children}
      </CardBody>
    </Card>
  )
}

const CurrentTermStatus = ({
  term,
  user,
}: {
  term: TermStatus
  user: UserV1
}) => {
  const { data: skippedWeeks } = useSkippedWeeks({
    userID: user.id,
  })

  const { handleSkipChange } = useHandleSkipChange()

  return (
    <CalendarTermDetails
      handleSkipChange={handleSkipChange}
      skippedWeeks={skippedWeeks}
      term={term}
      user={user}
    />
  )
}
