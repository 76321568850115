import {
  ErrorCodeMessageMapCombinedAPI,
  UserV1,
  useAddListingSelection,
  useAddMealSelection,
  useDeleteListingSelection,
  useDeleteMealSelection,
  useListingSelections,
  useMealSummaries,
  useMenuListingsLayout,
  useSkippedWeeks,
  useUserTermStatuses,
} from '@tovala/browser-apis-combinedapi'
import { errorHandler, successHandler } from 'actions/notifications'
import TabGroup, {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
} from 'components/common/TabGroup'
import ConfirmationModal from 'components/modals/ConfirmationModal'
import { useAppDispatch } from 'hooks'
import { debounce, sortBy } from 'lodash-es'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { isAxiosResponseError } from 'utils/api'
import EditUserTermStatus from './EditUserTermStatus'
import ExtrasGrid from './ExtrasGrid'
import MealsGrid from './MealsGrid'
import OrderSummary from './OrderSummary'
import { getOrderPricing } from './helpers'
import { getUserTerm } from 'utils/terms'
import { APIErrorDisplay } from '@tovala/component-library'

const LOAD_USER_TERM_STATUSES_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please reload the page to try again.',
    whatHappened: 'Unable to Load User Menu',
    why: "We couldn't load the user menu due to a technical issue on our end.",
  },
}

const UserMenu = ({ user }: { user: UserV1 }) => {
  const { termid: termID, userid: userID } = useParams()

  const [showSheetTrayMessage, setShowSheetTrayMessage] = useState(false)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    document.title = `Glaze | User #${userID} - T${termID} Menu`
  }, [termID, userID])

  const {
    error: hasLoadSelectedUserTermError,
    isLoading: isLoadingSelectedUserTerm,
    selectedUserTerm,
  } = useSelectedUserTerm({
    selectedUserTermID: termID,
    user,
  })

  const selectedMenuID = selectedUserTerm?.selectedSubTerm?.mainMenu.id

  const { data: mealSummaries = [] } = useMealSummaries({
    subTermID: selectedUserTerm?.selectedSubTermID,
  })
  const sortedMealSummaries = sortBy(mealSummaries, 'id')

  const { isLoading: isAddingMealSelection, mutate: addSelection } =
    useAddMealSelection({
      onError: (err) => {
        if (isAxiosResponseError(err) && err.response?.data.message) {
          const message = err.response.data.message

          if (message === 'BlackSheetTrayRequired') {
            setShowSheetTrayMessage(true)
          } else {
            errorHandler(
              dispatch,
              null,
              'Unable to add this meal. Please try again.'
            )
          }
        }
      },
      onSuccess: (...args) => {
        const { termStatus } = args[0]

        const mealSelectionsCount = termStatus.mealSelections.length
        const maxSelections = termStatus.subscriptionType?.maxSelections

        if (mealSelectionsCount === maxSelections) {
          successHandler(
            dispatch,
            `${mealSelectionsCount}/${maxSelections} meals selected. Edit this week's delivery if the user would like more meals.`
          )
        }
      },
    })
  const { isLoading: isDeletingMealSelection, mutate: deleteSelection } =
    useDeleteMealSelection({
      onError: (err) => {
        if (isAxiosResponseError(err) && err.response?.data.message) {
          errorHandler(
            dispatch,
            null,
            'Unable to remove this meal. Please try again.'
          )
        }
      },
    })

  const addMealSelection = debounce(addSelection, 250)
  const deleteMealSelection = debounce(deleteSelection, 250)

  const isUpdatingMealSelections =
    isAddingMealSelection || isDeletingMealSelection

  const { data: menuListingsLayout } = useMenuListingsLayout({
    menuID: selectedMenuID,
  })
  const menuHasListings = menuListingsLayout?.sections.some(
    (section) => section?.listings.length > 0
  )

  const { data: listingSelectionsResponse } = useListingSelections({
    menuID: selectedMenuID,
    userID: user.id,
  })

  const listingSelections = listingSelectionsResponse?.listingsSelected ?? []
  const listingSelectionsPricing =
    listingSelectionsResponse?.estimatedTotalCents ?? 0

  const {
    isLoading: isAddingListingSelection,
    mutate: addListingSelectionMutation,
  } = useAddListingSelection({
    onError: (err) => {
      if (isAxiosResponseError(err) && err.response?.data.message) {
        let message = 'Unable to add this extra. Please try again.'

        if (err.response.data.message === 'listing is sold out') {
          message = 'Unable to add this extra, it is sold out.'
        }
        errorHandler(dispatch, null, message)
      }
    },
  })
  const {
    isLoading: isDeletingListingSelection,
    mutate: deleteListingSelectionMutation,
  } = useDeleteListingSelection({
    onError: (err) => {
      if (isAxiosResponseError(err) && err.response?.data.message) {
        errorHandler(
          dispatch,
          null,
          'Unable to remove this extra. Please try again.'
        )
      }
    },
  })

  const isUpdatingListingSelections =
    isAddingListingSelection || isDeletingListingSelection

  const addListingSelection = debounce(addListingSelectionMutation, 250)
  const deleteListingSelection = debounce(deleteListingSelectionMutation, 250)

  if (hasLoadSelectedUserTermError) {
    return (
      <div>
        <APIErrorDisplay
          error={hasLoadSelectedUserTermError}
          errorCodeMessageMap={LOAD_USER_TERM_STATUSES_ERRORS}
        />
      </div>
    )
  }

  if (isLoadingSelectedUserTerm) {
    return null
  }

  if (selectedUserTerm && selectedUserTerm.selectedSubTerm && selectedMenuID) {
    const activePlanFlag = user.subscription.status === 'active'
    const isReceivingMeals = !selectedUserTerm.isSkipped && activePlanFlag

    if (!isReceivingMeals) {
      return (
        <div className="font-sans-new">
          {!activePlanFlag ? (
            <div>Subscription is inactive.</div>
          ) : (
            <div>User is skipped this week.</div>
          )}
        </div>
      )
    }

    return (
      <div className="font-sans-new space-y-6">
        <div className="bg-grey-2 rounded-xl p-6">
          <EditUserTermStatus selectedUserTerm={selectedUserTerm} user={user} />
        </div>

        <p>
          <span className="font-bold">Menu</span>{' '}
          {selectedUserTerm.selectedSubTerm.mainMenu.name}
        </p>

        <div className="flex">
          {selectedUserTerm.readyForView ? (
            <div className="w-8/12 space-y-8">
              <TabGroup>
                <TabList>
                  <Tab>Meals</Tab>
                  {menuHasListings && <Tab>Extras</Tab>}
                </TabList>

                <TabPanels>
                  <div className="flex h-[calc(100vh_-_100px)] overflow-auto">
                    <TabPanel>
                      <MealsGrid
                        canDecrement={!isUpdatingMealSelections}
                        canIncrement={
                          !selectedUserTerm.hasSelectedAllMeals &&
                          !isUpdatingMealSelections
                        }
                        mealSummaries={sortedMealSummaries}
                        onDecrement={(mealID) => {
                          const selections = [
                            ...selectedUserTerm.mealSelections,
                          ].sort((a, b) => {
                            return +new Date(b.created) - +new Date(a.created)
                          })
                          const mealSelectionToDelete = selections.find(
                            (selection) => selection.mealID === mealID
                          )

                          if (mealSelectionToDelete) {
                            deleteMealSelection({
                              selectionID: mealSelectionToDelete.id,
                              userID: user.id,
                            })
                          }
                        }}
                        onIncrement={(mealID) => {
                          addMealSelection({
                            userID: user.id,
                            data: {
                              mealid: mealID,
                              termid: selectedUserTerm.termID,
                              userid: user.id,
                            },
                          })
                        }}
                        selectionsMealIDs={selectedUserTerm.mealSelections.map(
                          (selection) => selection.mealID
                        )}
                      />
                    </TabPanel>
                    {menuHasListings && (
                      <TabPanel>
                        <ExtrasGrid
                          canDecrement={!isUpdatingListingSelections}
                          canIncrement={!isUpdatingListingSelections}
                          listingSelections={listingSelections}
                          menuListingsLayout={menuListingsLayout}
                          onDecrementListing={(listingID) => {
                            deleteListingSelection({
                              listingID,
                              menuID: selectedMenuID,
                              userID: user.id,
                            })
                          }}
                          onIncrementListing={(listingID) => {
                            addListingSelection({
                              data: { listingID },
                              listingID,
                              menuID: selectedMenuID,
                              userID: user.id,
                            })
                          }}
                        />
                      </TabPanel>
                    )}
                  </div>
                </TabPanels>
              </TabGroup>
            </div>
          ) : (
            <div className="w-8/12 text-xl font-bold">Menu Coming Soon</div>
          )}

          <div className="w-4/12 pl-6">
            <div className="text-xl font-bold">Order Summary</div>

            <OrderSummary
              listingSelections={listingSelections}
              listings={
                menuListingsLayout
                  ? menuListingsLayout.sections
                      .map((section) => section.listings)
                      .flat()
                  : []
              }
              maxSelections={selectedUserTerm.subscriptionType?.maxSelections}
              maxSelectionsDefaultSubscription={
                user.subscription.subscriptionType?.maxSelections
              }
              mealSummaries={sortedMealSummaries}
              orderPricing={getOrderPricing({
                listingSelectionsPricing,
                subscriptionPricing:
                  selectedUserTerm.selectedSubTerm.subscriptionPricing,
              })}
              selectedSubscriptionTypeIsDefault={
                selectedUserTerm.selectedSubscriptionTypeIsDefault
              }
              selectionsMealIDs={selectedUserTerm.mealSelections.map(
                (selection) => selection.mealID
              )}
            />
          </div>
        </div>

        <SheetTrayConfirmationModal
          isOpen={showSheetTrayMessage}
          onClickClose={() => {
            setShowSheetTrayMessage(false)
          }}
          onClickConfirm={() => {
            navigate(`/user/${user.id}/meal-plan`)
          }}
        />
      </div>
    )
  }

  return null
}

export default UserMenu

const SheetTrayConfirmationModal = ({
  isOpen,
  onClickClose,
  onClickConfirm,
}: {
  isOpen: boolean
  onClickClose(): void
  onClickConfirm(): void
}) => {
  return (
    <ConfirmationModal
      buttonText="Go to meal plan + oven order"
      handleClick={() => {
        onClickConfirm()
      }}
      heading="This meal requires a black sheet tray"
      isOpen={isOpen}
      onCloseModal={() => {
        onClickClose()
      }}
    >
      <div className="w-[500px]">
        This meal cannot be added until the user confirms they still have a
        black sheet tray. You can change this setting on the Meal Plan + Oven
        Order tab for the user if you have confirmed they still have a black
        sheet tray.
      </div>
    </ConfirmationModal>
  )
}

function useSelectedUserTerm({
  selectedUserTermID,
  user,
}: {
  selectedUserTermID: string | undefined
  user: UserV1
}) {
  const {
    data: userTermStatuses = [],
    error: loadUserTermStatusesError,
    isError: hasLoadUserTermStatusesError,
    isLoading: isLoadingUserTermStatuses,
  } = useUserTermStatuses({ userID: user.id })

  const { data: skippedWeeks, isLoading: isLoadingSkippedWeeks } =
    useSkippedWeeks({
      userID: user.id,
    })

  const selectedTerm =
    userTermStatuses.find(
      (term) => term.termID.toString() === selectedUserTermID
    ) ||
    (userTermStatuses.length && userTermStatuses[0])

  return {
    error: hasLoadUserTermStatusesError ? loadUserTermStatusesError : null,
    isLoading: isLoadingUserTermStatuses || isLoadingSkippedWeeks,
    selectedUserTerm: selectedTerm
      ? getUserTerm({ skippedWeeks, term: selectedTerm, user })
      : undefined,
  }
}
