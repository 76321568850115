import {
  APIErrorDisplay,
  Button,
  ButtonLoading,
  FormGroup,
  Input,
  Modal,
  ModalHeader,
  Radio,
} from '@tovala/component-library'
import {
  CustomerCreditCard,
  ErrorCodeMessageMapCombinedAPI,
  useAddPaymentSource,
  useCustomer,
  useDeletePaymentSource,
  useEditDefaultPaymentSource,
  useRedeemGiftCard,
  UserV1,
} from '@tovala/browser-apis-combinedapi'
import { Elements } from '@stripe/react-stripe-js'
import { ReactNode, useEffect, useState } from 'react'

import { getAdminScope, USERS_WRITE } from '../../utils/getAdminScope'
import { getElementsConfiguration, stripePromise } from '../../utils/stripe'
import { getUserInfo } from '../../actions/auth'
import { REDEEM_GIFT_CARD_ERRORS, makeGenericFallbackError } from 'utils/errors'
import { successHandler } from 'actions/notifications'

import { useAppDispatch } from 'hooks'
import CreditCardInput from 'components/common/CreditCardInput'

const UserPayment = ({ user }: { user: UserV1 }): JSX.Element => {
  const [selectedPaymentIndex, setSelectedPaymentIndex] = useState(0)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showPaymentForm, setShowPaymentForm] = useState(false)

  const { data: customer, isLoading: isLoadingCustomer } = useCustomer({
    customerID: user.subscription.customerID,
    userID: user.id,
  })

  const paymentSources = customer?.sources?.data ?? []
  const currentDefaultPaymentSource = customer?.default_source ?? ''

  useEffect(() => {
    document.title = `Glaze | User #${user.id} - Payment`
  }, [user.id])

  return (
    <div>
      <div className="flex space-x-8 font-sans-new">
        <div className="w-8/12">
          <div className="mb-8 flex justify-between">
            <h2 className="text-k/28_130">Payment</h2>
            {getAdminScope(USERS_WRITE) && (
              <>
                {showPaymentForm ? (
                  <Button
                    buttonStyle="stroke"
                    onClick={() => {
                      setShowPaymentForm(false)
                    }}
                    size="medium"
                  >
                    Cancel
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      setShowPaymentForm(true)
                    }}
                    size="medium"
                  >
                    Add Payment
                  </Button>
                )}
              </>
            )}
          </div>

          {showPaymentForm ? (
            <AddPaymentSource
              onPaymentSourceAdded={() => {
                setShowPaymentForm(false)
              }}
              user={user}
            />
          ) : (
            <div className="space-y-5">
              {!isLoadingCustomer && paymentSources.length === 0 && (
                <p>No payment information found.</p>
              )}

              {paymentSources.map((card, index) => {
                let cardText = `${card.brand} ending in ${card.last4}`

                if (card.tokenization_method === 'android_pay') {
                  cardText = 'Google Pay'
                } else if (card.tokenization_method === 'apple_pay') {
                  cardText = 'Apple Pay'
                }
                return (
                  <div
                    key={card.id}
                    className="flex justify-between rounded-xl bg-grey-2 p-6"
                  >
                    <div className="space-y-2">
                      <div className="flex items-center space-x-2">
                        <span className="text-k/20_125">{cardText}</span>

                        {card.id === currentDefaultPaymentSource && (
                          <div className="rounded-lg bg-black px-2 py-1 text-k/12_120 text-white">
                            Default
                          </div>
                        )}
                      </div>

                      <p className="text-k/14_120">
                        expires {card.exp_month}/{card.exp_year}
                      </p>
                    </div>

                    {getAdminScope(USERS_WRITE) && (
                      <Button
                        buttonStyle="white"
                        onClick={() => {
                          setShowDeleteModal(true)
                          setSelectedPaymentIndex(index)
                        }}
                        size="small"
                      >
                        Remove
                      </Button>
                    )}
                  </div>
                )
              })}
            </div>
          )}
        </div>

        <div className="w-4/12 divide-y divide-grey-2">
          {user && (
            <div className="space-y-6">
              <div className="space-y-2">
                <h2 className="text-k/20_125">Account Balance</h2>
                <p className="text-k/13_120">
                  Discounts and Tovala Cash are automatically applied towards
                  the user&apos;s next order.
                </p>
              </div>

              <div className="space-y-1">
                <p className="text-k/14_120 font-bold">
                  Tovala Cash: ${(user.cashCents / 100).toFixed(2)}
                </p>
                <p className="text-k/13_120">
                  Gift cards, refunds, or referral bonuses
                </p>
              </div>

              <div className="space-y-1">
                <p className="text-k/14_120 font-bold">
                  Discounts: ${(user.discountCents / 100).toFixed(2)}
                </p>
                <p className="text-k/13_120">
                  Coupons, discounts, and promotions
                </p>
              </div>

              {getAdminScope(USERS_WRITE) && <ApplyGiftCard user={user} />}
            </div>
          )}

          {getAdminScope(USERS_WRITE) &&
            paymentSources.length > 1 &&
            currentDefaultPaymentSource && (
              <div className="mt-6 pt-6">
                <EditDefaultPaymentSource
                  initialDefaultPaymentSource={currentDefaultPaymentSource}
                  paymentSources={paymentSources}
                  user={user}
                />
              </div>
            )}
        </div>
      </div>

      {showDeleteModal && (
        <RemovePaymentSourceDialog
          onClose={() => {
            setShowDeleteModal(false)
          }}
          onRemovePaymentSource={() => {
            setShowDeleteModal(false)
          }}
          paymentSource={paymentSources[selectedPaymentIndex]}
          user={user}
        />
      )}
    </div>
  )
}

export default UserPayment

const AddPaymentSource = ({
  onPaymentSourceAdded,
  user,
}: {
  onPaymentSourceAdded(): void
  user: UserV1
}) => {
  const {
    error: addPaymentSourceError,
    isError: hasAddPaymentSourceError,
    isLoading: isAddingPaymentSource,
    mutateAsync: addPaymentSource,
  } = useAddPaymentSource({
    onSuccess: () => {
      onPaymentSourceAdded()
    },
  })

  return (
    <Elements options={getElementsConfiguration()} stripe={stripePromise}>
      <FormGroup label="Add a New Card">
        <CreditCardInput
          addPaymentSourceError={
            hasAddPaymentSourceError ? addPaymentSourceError : null
          }
          isAddingPaymentSource={isAddingPaymentSource}
          onSubmit={(stripeToken) => {
            return addPaymentSource({
              customerID: user.subscription.customerID,
              data: { setAsDefault: true, stripeToken },
              userID: user.id,
            })
          }}
        />
      </FormGroup>
    </Elements>
  )
}

const EDIT_DEFAULT_PAYMENT_SOURCE_ERRORS: ErrorCodeMessageMapCombinedAPI<ReactNode> =
  {
    Fallback: makeGenericFallbackError({
      action: 'edit the default payment source',
    }),
  }

const EditDefaultPaymentSource = ({
  initialDefaultPaymentSource,
  paymentSources,
  user,
}: {
  initialDefaultPaymentSource: string
  paymentSources: CustomerCreditCard[]
  user: UserV1
}) => {
  const dispatch = useAppDispatch()

  const [defaultPaymentSourceID, setDefaultPaymentSourceID] = useState(
    initialDefaultPaymentSource
  )

  const {
    error: editDefaultPaymentSourceError,
    isError: hasEditDefaultPaymentSourceError,
    isLoading: isEditingDefaultPaymentSource,
    mutate: editDefaultPaymentSource,
  } = useEditDefaultPaymentSource({
    onSuccess: () => {
      successHandler(dispatch, 'Default payment source saved!')
    },
  })

  return (
    <div className="space-y-4">
      <h2 className="text-k/20_125">Set Default Payment</h2>

      <div className="space-y-2">
        {paymentSources.map((card) => {
          return (
            <div key={card.id} className="flex">
              <Radio
                checked={card.id === defaultPaymentSourceID}
                label={` ${card.brand} x${card.last4}`}
                name="radio"
                onChange={(e) => {
                  setDefaultPaymentSourceID(e.target.value)
                }}
                value={card.id}
              />
            </div>
          )
        })}
      </div>

      {hasEditDefaultPaymentSourceError && (
        <APIErrorDisplay
          error={editDefaultPaymentSourceError}
          errorCodeMessageMap={EDIT_DEFAULT_PAYMENT_SOURCE_ERRORS}
        />
      )}

      <div className="mt-4">
        <ButtonLoading
          isLoading={isEditingDefaultPaymentSource}
          onClick={() => {
            editDefaultPaymentSource({
              customerID: user.subscription.customerID,
              data: { sourceid: defaultPaymentSourceID },
              userID: user.id,
            })
          }}
          size="medium"
        >
          Save
        </ButtonLoading>
      </div>
    </div>
  )
}

const REMOVE_PAYMENT_SOURCE_ERRORS: ErrorCodeMessageMapCombinedAPI<ReactNode> =
  {
    Fallback: makeGenericFallbackError({
      action: 'remove the payment source',
    }),
  }

const RemovePaymentSourceDialog = ({
  onClose,
  onRemovePaymentSource,
  paymentSource,
  user,
}: {
  onClose(): void
  onRemovePaymentSource(): void
  paymentSource: CustomerCreditCard
  user: UserV1
}) => {
  const dispatch = useAppDispatch()

  const {
    error: removePaymentSourceError,
    isError: hasRemovePaymentSourceError,
    isLoading: isRemovingPaymentSource,
    mutate: removePaymentSource,
  } = useDeletePaymentSource({
    onSuccess: () => {
      successHandler(dispatch, 'Payment source removed.')

      onRemovePaymentSource()
    },
  })

  return (
    <Modal onCloseModal={onClose}>
      <div className="w-[500px] font-sans-new">
        <ModalHeader onClickClose={onClose}>Remove Payment</ModalHeader>
        <div className="p-6">
          <div className="space-y-4">
            <div>
              {user.info.name && <p>{user.info.name}</p>}
              <p>{user.info.email}</p>
              <p>
                {paymentSource.brand} ending in {paymentSource.last4}
              </p>
            </div>

            <p className="font-bold">
              Are you sure you want to remove this payment source?
            </p>
          </div>

          <div className="mt-8 space-y-4">
            {hasRemovePaymentSourceError && (
              <APIErrorDisplay
                error={removePaymentSourceError}
                errorCodeMessageMap={REMOVE_PAYMENT_SOURCE_ERRORS}
              />
            )}

            <div className="flex justify-end space-x-4">
              <Button
                buttonStyle="stroke"
                onClick={() => {
                  onClose()
                }}
                size="large"
              >
                Cancel
              </Button>
              <ButtonLoading
                isLoading={isRemovingPaymentSource}
                onClick={() => {
                  removePaymentSource({
                    customerID: user.subscription.customerID,
                    paymentSourceID: paymentSource.id,
                    userID: user.id,
                  })
                }}
                size="large"
              >
                Remove Payment Source
              </ButtonLoading>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

const ApplyGiftCard = ({ user }: { user: UserV1 }) => {
  const dispatch = useAppDispatch()

  const [giftCardCode, setGiftCardCode] = useState({ error: '', value: '' })

  const {
    error: redeemGiftCardError,
    isError: hasRedeemGiftCardError,
    isLoading: isRedeemingGiftCard,
    mutate: redeemGiftCard,
  } = useRedeemGiftCard({
    onSuccess: () => {
      successHandler(
        dispatch,
        `Hooray! The gift card balance has been applied to ${user.info.email}.`
      )
      dispatch(getUserInfo(user.id))

      setGiftCardCode({ error: '', value: '' })
    },
  })

  return (
    <>
      <form
        onSubmit={(event) => {
          event.preventDefault()

          if (giftCardCode.value) {
            redeemGiftCard({
              cardID: giftCardCode.value,
              data: { cardID: giftCardCode.value },
              userID: user.id,
            })
          } else {
            setGiftCardCode({
              error: 'Please provide a gift card code.',
              value: '',
            })
          }
        }}
      >
        <FormGroup
          error={giftCardCode.error}
          label="Apply Gift Card"
          labelFor="gift-card-code"
        >
          <div className="flex items-end space-x-4">
            <Input
              hasError={!!giftCardCode.error}
              id="gift-card-code"
              name="gift-card-code"
              onChange={(e) => {
                setGiftCardCode({ error: '', value: e.target.value })
              }}
              placeholder="Gift Card Code"
              type="text"
              value={giftCardCode.value}
            />

            <ButtonLoading
              isLoading={isRedeemingGiftCard}
              size="medium"
              type="submit"
            >
              Apply
            </ButtonLoading>
          </div>
        </FormGroup>
      </form>

      {hasRedeemGiftCardError && (
        <APIErrorDisplay
          error={redeemGiftCardError}
          errorCodeMessageMap={REDEEM_GIFT_CARD_ERRORS}
        />
      )}
    </>
  )
}
