import invariant from 'tiny-invariant'

type EnvVars = {
  AIRVALA_OVEN_COMMITMENT_ID_299_MSRP: string
  AIRVALA_OVEN_REFERRAL_ID_299_MSRP: string
  AIRVALA_OVEN_STANDALONE_ID_299_MSRP: string
  APP_ENV: 'development' | 'develop-preview' | 'preview' | 'production' | 'test'
  API_HOST: string
  API_HOST_V1: string
  COMBINED_API_URL: string
  JWT_COOKIE_NAME: string
  PUSHER_APP_KEY: string
  SEGMENT_WRITE_KEY: string
  SENTRY_DSN: string
  STRIPE_KEY: string
  TOVALA_OVEN_AIRFRY_GRAY_REBOX_ID: string
  TOVALA_IQ_STANDALONE_OVEN_BACK_ORDER: string
  TOVALA_IQ_WEEKS_COMMMITMENT_BACK_ORDER: string
  TOVALA_OVEN_COMMITMENT_ID_349_MSRP: string
  TOVALA_OVEN_REFERRAL_ID_349_MSRP: string
  TOVALA_OVEN_STANDALONE_ID_349_MSRP: string
  TOVALA_OVEN_STEAM_BLACK_REBOX_ID: string
  TOVALA_OVEN_STEAM_REBOX_ID: string
  VERCEL_GIT_COMMIT_SHA: string
}

const ALLOWED_APP_ENVS = [
  'development',
  'develop-preview',
  'preview',
  'production',
  'test',
] as const

function isAllowedAppEnv(appEnv: string): appEnv is EnvVars['APP_ENV'] {
  return ALLOWED_APP_ENVS.includes(appEnv as EnvVars['APP_ENV'])
}

invariant(
  import.meta.env.VITE_AIRVALA_OVEN_COMMITMENT_ID_299_MSRP,
  'AIRVALA_OVEN_COMMITMENT_ID_299_MSRP must be set'
)
invariant(
  import.meta.env.VITE_AIRVALA_OVEN_REFERRAL_ID_299_MSRP,
  'AIRVALA_OVEN_REFERRAL_ID_299_MSRP must be set'
)
invariant(
  import.meta.env.VITE_AIRVALA_OVEN_STANDALONE_ID_299_MSRP,
  'AIRVALA_OVEN_STANDALONE_ID_299_MSRP must be set'
)
invariant(
  import.meta.env.VITE_APP_ENV && isAllowedAppEnv(import.meta.env.VITE_APP_ENV),
  `APP_ENV must be one of: ${ALLOWED_APP_ENVS.join(', ')}`
)
invariant(import.meta.env.VITE_API_HOST, 'API_HOST must be set')
invariant(import.meta.env.VITE_API_HOST_V1, 'API_HOST_V1 must be set')
invariant(import.meta.env.VITE_COMBINED_API_URL, 'COMBINED_API_URL must be set')
invariant(import.meta.env.VITE_JWT_COOKIE_NAME, 'JWT_COOKIE_NAME must be set')
invariant(
  import.meta.env.VITE_SEGMENT_WRITE_KEY,
  'SEGMENT_WRITE_KEY must be set'
)
invariant(import.meta.env.VITE_STRIPE_KEY, 'STRIPE_KEY must be set')
invariant(
  import.meta.env.VITE_TOVALA_IQ_STANDALONE_OVEN_BACK_ORDER,
  'TOVALA_IQ_STANDALONE_OVEN_BACK_ORDER must be set'
)
invariant(
  import.meta.env.VITE_TOVALA_IQ_WEEKS_COMMMITMENT_BACK_ORDER,
  'TOVALA_IQ_WEEKS_COMMMITMENT_BACK_ORDER must be set'
)
invariant(
  import.meta.env.VITE_TOVALA_OVEN_AIRFRY_GRAY_REBOX_ID,
  'TOVALA_OVEN_AIRFRY_GRAY_REBOX_ID must be set'
)
invariant(
  import.meta.env.VITE_TOVALA_OVEN_COMMITMENT_ID_349_MSRP,
  'TOVALA_OVEN_COMMITMENT_ID_349_MSRP must be set'
)
invariant(
  import.meta.env.VITE_TOVALA_OVEN_REFERRAL_ID_349_MSRP,
  'TOVALA_OVEN_REFERRAL_ID_349_MSRP must be set'
)
invariant(
  import.meta.env.VITE_TOVALA_OVEN_STANDALONE_ID_349_MSRP,
  'TOVALA_OVEN_STANDALONE_ID_349_MSRP must be set'
)
invariant(
  import.meta.env.VITE_TOVALA_OVEN_STEAM_BLACK_REBOX_ID,
  'TOVALA_OVEN_STEAM_BLACK_REBOX_ID must be set'
)
invariant(
  import.meta.env.VITE_TOVALA_OVEN_STEAM_REBOX_ID,
  'TOVALA_OVEN_STEAM_REBOX_ID must be set'
)

const ENV_VARS: {
  [key in keyof EnvVars]: EnvVars[key]
} = {
  AIRVALA_OVEN_COMMITMENT_ID_299_MSRP: import.meta.env
    .VITE_AIRVALA_OVEN_COMMITMENT_ID_299_MSRP,
  AIRVALA_OVEN_REFERRAL_ID_299_MSRP: import.meta.env
    .VITE_AIRVALA_OVEN_REFERRAL_ID_299_MSRP,
  AIRVALA_OVEN_STANDALONE_ID_299_MSRP: import.meta.env
    .VITE_AIRVALA_OVEN_STANDALONE_ID_299_MSRP,
  APP_ENV: import.meta.env.VITE_APP_ENV,
  API_HOST: import.meta.env.VITE_API_HOST,
  API_HOST_V1: import.meta.env.VITE_API_HOST_V1,
  COMBINED_API_URL: import.meta.env.VITE_COMBINED_API_URL,
  JWT_COOKIE_NAME: import.meta.env.VITE_JWT_COOKIE_NAME,
  PUSHER_APP_KEY: import.meta.env.VITE_PUSHER_APP_KEY,
  SEGMENT_WRITE_KEY: import.meta.env.VITE_SEGMENT_WRITE_KEY,
  SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN ?? '',
  STRIPE_KEY: import.meta.env.VITE_STRIPE_KEY,
  TOVALA_IQ_STANDALONE_OVEN_BACK_ORDER: import.meta.env
    .VITE_TOVALA_IQ_STANDALONE_OVEN_BACK_ORDER,
  TOVALA_IQ_WEEKS_COMMMITMENT_BACK_ORDER: import.meta.env
    .VITE_TOVALA_IQ_WEEKS_COMMMITMENT_BACK_ORDER,
  TOVALA_OVEN_AIRFRY_GRAY_REBOX_ID: import.meta.env
    .VITE_TOVALA_OVEN_AIRFRY_GRAY_REBOX_ID,
  TOVALA_OVEN_COMMITMENT_ID_349_MSRP: import.meta.env
    .VITE_TOVALA_OVEN_COMMITMENT_ID_349_MSRP,
  TOVALA_OVEN_REFERRAL_ID_349_MSRP: import.meta.env
    .VITE_TOVALA_OVEN_REFERRAL_ID_349_MSRP,
  TOVALA_OVEN_STANDALONE_ID_349_MSRP: import.meta.env
    .VITE_TOVALA_OVEN_STANDALONE_ID_349_MSRP,
  TOVALA_OVEN_STEAM_BLACK_REBOX_ID: import.meta.env
    .VITE_TOVALA_OVEN_STEAM_BLACK_REBOX_ID,
  TOVALA_OVEN_STEAM_REBOX_ID: import.meta.env.VITE_TOVALA_OVEN_STEAM_REBOX_ID,
  VERCEL_GIT_COMMIT_SHA: import.meta.env.VITE_VERCEL_GIT_COMMIT_SHA ?? '',
}

export const TOVALA_APIS = [
  ENV_VARS.API_HOST,
  ENV_VARS.API_HOST_V1,
  ENV_VARS.COMBINED_API_URL,
]

export function getEnvVar<EnvVar extends keyof EnvVars>(
  envVar: EnvVar
): EnvVars[EnvVar] {
  return ENV_VARS[envVar]
}
