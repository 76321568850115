import { Form, Formik, FormikErrors } from 'formik'
import { MealTagSingle } from '@tovala/browser-apis-combinedapi'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

import { addMealTag, updateMealTag } from '../../actions/meal-tags'
import { getAdminScope, MEALS_WRITE } from '../../utils/getAdminScope'

import { useAppDispatch } from 'hooks'
import Button from 'components/common/Button'
import InputField from 'components/common/InputField'
import SelectInput from 'components/common/SelectInput'

interface FormData {
  active_until: string
  collapsedTitle: string
  color: string
  description: string
  description_internal: string
  display_mode: string
  image: string
  priority: string
  title: string
}

const MealTagForm = ({ mealTag }: { mealTag?: MealTagSingle }): JSX.Element => {
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const readOnly = !getAdminScope(MEALS_WRITE)

  const handleSubmit = (values: FormData) => {
    const tag = {
      active_until: values.active_until
        ? moment(values.active_until).toISOString()
        : null,
      collapsedTitle: values.collapsedTitle,
      color: values.color,
      description: values.description,
      description_internal: values.description_internal,
      display_mode: values.display_mode,
      image: values.image,
      priority: values.priority ? Number.parseInt(values.priority, 10) : 0,
      title: values.title,
    }

    if (mealTag) {
      dispatch(updateMealTag(mealTag.id, tag))
    } else {
      dispatch(addMealTag(tag)).then((wasMealTagAdded) => {
        if (wasMealTagAdded) {
          navigate('/meal-tags')
        }
      })
    }
  }

  const displayModes = [
    'Banner Text',
    'Customize It Icon',
    'Hidden',
    'Icon',
    'Meal Banner Top Center',
    'Meal Banner Top Jumbo',
    'Meal Banner Top Leading',
    'Meal Banner Top Trailing',
    'Menu Subtitle',
    'Menu Category',
    'Nutrition Note',
    'Warning',
  ]

  const initialValues: FormData = mealTag
    ? {
        active_until: mealTag.active_until ?? '',
        collapsedTitle: mealTag.collapsedTitle,
        color: mealTag.color,
        description: mealTag.description,
        description_internal: mealTag.description_internal,
        display_mode: mealTag.display_mode,
        image: mealTag.image,
        priority: `${mealTag.priority}`,
        title: mealTag.title,
      }
    : {
        title: '',
        collapsedTitle: '',
        description: '',
        image: '',
        priority: '',
        display_mode: '',
        description_internal: '',
        active_until: '',
        color: '',
      }

  return (
    <Formik<FormData>
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validateForm}
    >
      <Form className="mb-28 space-y-4">
        <InputField disabled={readOnly} label="Title" name="title" />

        <InputField
          description="Used for collapsed view of Menu Category tags"
          disabled={readOnly}
          label="Collapsed Title"
          name="collapsedTitle"
        />

        <InputField
          disabled={readOnly}
          label="Description"
          name="description"
        />

        <InputField
          disabled={readOnly}
          label="Internal Description"
          name="description_internal"
        />

        <InputField disabled={readOnly} label="Image Source" name="image" />

        <div className="flex space-x-4">
          <div className="w-1/2">
            <SelectInput
              label="Display Mode"
              name="display_mode"
              options={displayModes.map((mode) => ({
                label: mode,
                value: mode.toLowerCase().replace(/ /g, '_'),
              }))}
              readOnly={readOnly}
            />
          </div>
          <div className="w-1/2">
            <InputField
              disabled={readOnly}
              label="Priority"
              name="priority"
              type="number"
            />
          </div>
        </div>

        <div className="flex space-x-4">
          <div className="w-1/2">
            <InputField
              disabled={readOnly}
              label="Active Until"
              name="active_until"
              type="datetime-local"
            />
          </div>
          <div className="w-1/2">
            <InputField disabled={readOnly} label="Color" name="color" />
          </div>
        </div>

        {getAdminScope(MEALS_WRITE) && (
          <div className="h-10 w-full">
            <Button size="fluid" type="submit">
              Save
            </Button>
          </div>
        )}
      </Form>
    </Formik>
  )
}

export default MealTagForm

function validateForm(values: FormData) {
  const errors: FormikErrors<FormData> = {}

  if (!values.title) {
    errors.title = 'Please enter title.'
  }

  if (!values.display_mode) {
    errors.display_mode = 'Please select a display mode.'
  }

  if (values.display_mode === 'icon' && !values.image) {
    errors.image = 'Please enter an image URL.'
  }

  if (values.display_mode === 'menu_category' && !values.collapsedTitle) {
    errors.collapsedTitle =
      'Please enter a collapsed title for Menu Category tag'
  }

  return errors
}
