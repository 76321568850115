import { ReactSelectOptionGroup, ReactSelectValue } from 'types/internal'

export const MARKETING_REASON_OPTIONS = [
  {
    label: 'Add Promo Credit',
    value: 'add-promo-credit',
  },
  {
    label: 'Less than one meal credit',
    value: 'less-than-one-meal-credit',
  },
  {
    label: 'Multiple Delivery Issues',
    value: 'multiple-delivery-issues',
  },
  { label: 'Other Non-Meal Issue', value: 'other-non-meal-issues' },
  { label: 'Oven Damage Credit', value: 'oven-damage-credit' },
  { label: 'Partner / Affiliate', value: 'partner-affiliate' },
  { label: 'Referral Credit', value: 'referral-credit' },
  { label: 'Retention', value: 'retention' },
] satisfies ReactSelectValue<string>[]

export const MEAL_CASH_REFUND_REASON_OPTIONS = [
  {
    options: [
      {
        label: 'Disliked Automatic Selection',
        value: 'automatic-selection',
      },
      {
        label: 'Skip/Pause/Cancel Confusion',
        value: 'skip-pause-cancel-confusion',
      },
    ],
    label: 'Customer Error',
  },
  {
    options: [
      {
        label: 'Food Quality',
        value: 'food-quality',
      },
      {
        label: 'Multiple Vac-Pack Damaged',
        value: 'multiple-vacpack-damaged',
      },
      {
        label: 'Single Vac-Pack Damaged',
        value: 'single-vacpack-damaged',
      },
    ],
    label: 'Food Quality Error',
  },
  {
    options: [
      {
        label: 'Foreign Object',
        value: 'foreign-material',
      },
      {
        label: 'Other',
        value: 'other-meal-issues',
      },
    ],
    label: 'Other - Meal Order',
  },
  {
    options: [
      {
        label: 'Cooking Issue',
        value: 'cooking-issue',
      },
      {
        label: 'Pending Exchange/Return',
        value: 'pending-exchange-return',
      },
    ],
    label: 'Oven Error',
  },
  {
    options: [
      {
        label: 'Damaged Meal Component (not vacpack)',
        value: 'damaged-meal-component-not-vacpack',
      },
      {
        label: 'On Time Meals - Temperature Concerns',
        value: 'on-time-meals-melted-ice',
      },
      {
        label: 'Open Tray',
        value: 'tray-seal-failure',
      },
    ],
    label: 'Packaging Quality Error',
  },
  {
    options: [
      {
        label: 'Missing/Wrong Component',
        value: 'missing-wrong-component',
      },
      {
        label: 'Missing/Wrong Meal Sleeve',
        value: 'missing-wrong-meal',
      },
      {
        label: 'Missing/Wrong Protein',
        value: 'missing-wrong-protein',
      },
    ],
    label: 'Packout Error',
  },
  {
    options: [
      {
        label: 'Carrier Misdelivery',
        value: 'carrier-misdelivery',
      },
      {
        label: 'Delayed Meals - Temperature Concern',
        value: 'delayed-meals-melted-ice',
      },
      {
        label: 'Delayed Oven',
        value: 'delayed-oven',
      },
      {
        label: 'Meals damaged (shipping box OK)',
        value: 'meals-damaged-shipping-box-ok',
      },
      {
        label: 'Missing Full Shipping Box',
        value: 'missing-full-box',
      },
      {
        label: 'Shipping Box Damaged',
        value: 'shipping-box-damaged',
      },
    ],
    label: 'Shipping Error',
  },
] satisfies ReactSelectOptionGroup<string>[]
