import {
  GuidanceEvents,
  GuidanceEvent,
  UserGuidanceEventInfo,
} from 'types/domainModels'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import Table, { TableRow, TBody, TD, TH, THead } from 'components/common/Table'
import { useReadAllGuidanceEvent } from 'hooks/combinedAPI/realTimeGuidanceEvent'

const RealTimeGuidance = (): JSX.Element => {
  const { data: guidanceEventsResponse = [] } = useReadAllGuidanceEvent()
  const guidanceEvents = guidanceEventsResponse as unknown as GuidanceEvents
  return (
    <>
      <h1 className="font-serif text-3xl font-bold">Real Time Guidance</h1>
      <AllGuidanceEventsTable guidanceEvents={guidanceEvents} />
    </>
  )
}

const AllGuidanceEventsTable = ({
  guidanceEvents,
}: {
  guidanceEvents: GuidanceEvents
}) => {
  const [results, setResults] = useState<UserGuidanceEventInfo[]>([])

  const guidanceTable = (
    <table className="table-fixed w-full">
      <colgroup>
        <col width={'110px'}></col>
        <col width={'115px'}></col>
        <col width={'20%'}></col>
        <col width={'40%'}></col>
        <col width={'80px'}></col>
        <col width={'10%'}></col>
        <col width={'20%'}></col>
      </colgroup>
      <thead>
        <tr key={'guidance-events-header-row'}>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Title</th>
          <th>Problem Description</th>
          <th>Resolution</th>
          <th>Extra Links</th>
          <th>Users list</th>
        </tr>
      </thead>
      <tbody>
        {guidanceEvents.map(
          ({
            id,
            eventStartDate,
            eventEndDate,
            title,
            problemDescription,
            resolutionDescription,
            resolutionLink,
            extraLinks,
            usersInfo,
          }: GuidanceEvent) => {
            return (
              <tr key={id + 'tr'} className={'odd:bg-grey-901'}>
                <td>
                  <div className="p-2 h-14 overflow-auto hover:text-grey-909">
                    {eventStartDate.split('T')[0] ?? eventStartDate}
                  </div>
                </td>
                <td>
                  <div className="p-2 h-14 overflow-auto hover:text-grey-909">
                    {eventEndDate.split('T')[0] ?? eventEndDate}
                  </div>
                </td>
                <td>
                  <div className="p-2 h-14 text-center overflow-auto hover:text-grey-909">
                    {title}
                  </div>
                </td>
                <td>
                  <div className="p-2 h-14 overflow-auto hover:text-grey-909">
                    {problemDescription}
                  </div>
                </td>
                <td>
                  <div className="p-2 h-14 overflow-auto">
                    <Link
                      className="truncate hover:text-grey-909"
                      to={resolutionLink}
                    >
                      {resolutionDescription}
                    </Link>
                  </div>
                </td>
                <td>
                  <div className="p-2 h-14 overflow-auto">
                    {/* if there are multiple extra links  */}
                    <Link
                      className="truncate hover:text-grey-909"
                      to={extraLinks}
                    >
                      {extraLinks !== '' ? 'link' : ''}
                    </Link>
                  </div>
                </td>
                <td>
                  <div className="p-2 h-14 overflow-auto hover:text-grey-909">
                    <button
                      onClick={() => {
                        setResults(usersInfo)
                      }}
                    >
                      Click here to add users to search below
                    </button>
                  </div>
                </td>
              </tr>
            )
          }
        )}
      </tbody>
    </table>
  )

  const usersSearchAndTable = (
    <div className="my-20">
      {results.length > 0 && (
        <div className="mb-4">
          <strong>{results.length}</strong> results
        </div>
      )}

      <Table>
        <THead>
          <TableRow>
            <TH>User ID</TH>
            <TH>User Specific Info</TH>
          </TableRow>
        </THead>
        <TBody>
          {results.map(({ userId, extraInfo }) => {
            const accountOverviewURL = `/user/${userId}/account-overview`

            return (
              <TableRow key={userId} highlight>
                <TD width="w-[10%]">
                  <Link className="hover:text-grey-909" to={accountOverviewURL}>
                    {userId}
                  </Link>
                </TD>

                <TD width="w-[42%]">
                  <Link className="hover:text-grey-909" to={accountOverviewURL}>
                    {extraInfo}
                  </Link>
                </TD>
              </TableRow>
            )
          })}
        </TBody>
      </Table>
    </div>
  )
  return (
    <div className="overflow-auto">
      {guidanceTable}
      {usersSearchAndTable}
    </div>
  )
}

export default RealTimeGuidance
