import {
  AdminScope,
  AdminScopeListItem,
  useAddAdminScopes,
  useAdminScopes,
  useEditEmployeeStatus,
  useRemoveAdminScopes,
  useUserV0,
  UserV1,
} from '@tovala/browser-apis-combinedapi'
import { Checkbox } from '@tovala/component-library'
import { ReactNode, useEffect, useState } from 'react'
import { sortBy } from 'lodash-es'

import { errorHandler, successHandler } from 'actions/notifications'
import {
  getAdminScope,
  USERS_ADMIN,
  USERS_WRITE,
  VENGEFUL_GOD,
} from '../../utils/getAdminScope'
import { getUserInfo } from 'actions/auth'

import { useAppDispatch } from 'hooks'
import Button from 'components/common/Button'
import ButtonLoading from 'components/common/ButtonLoading'
import H2 from 'components/common/H2'

const AdminPermissions = ({ user }: { user: UserV1 }) => {
  const [formType, setFormType] = useState<'add' | 'remove' | null>(null)

  const { data: getAdminScopesResponse } = useAdminScopes()
  const adminScopesList = getAdminScopesResponse?.scopes ?? []

  const { data: userV0 } = useUserV0({ userID: user.id })
  const userAdminScopes = userV0?.admin_scope ?? []

  useEffect(() => {
    document.title = `Glaze | User #${user.id} - Admin Permissions`
  }, [user.id])

  return (
    <div className="flex space-x-4">
      <div className="w-8/12">
        {formType === 'add' ? (
          <AddPermissions
            adminScopes={adminScopesList}
            onClickCancel={() => {
              setFormType(null)
            }}
            onSave={() => {
              setFormType(null)
            }}
            user={user}
            userAdminScopes={userAdminScopes}
          />
        ) : formType === 'remove' ? (
          <RemovePermissions
            adminScopes={userAdminScopes}
            onClickCancel={() => {
              setFormType(null)
            }}
            onSave={() => {
              setFormType(null)
            }}
            user={user}
          />
        ) : (
          <>
            <div className="mb-5">
              <H2>Admin Permissions</H2>
            </div>
            <div className="space-y-2">
              {userAdminScopes.length === 0 ? (
                <div>This user has no admin permissions.</div>
              ) : (
                <ul className="space-y-1">
                  {sortBy(userAdminScopes).map((scope) => (
                    <li key={scope}>{scope}</li>
                  ))}
                </ul>
              )}
            </div>
          </>
        )}
      </div>

      {formType === null && (
        <div className="mt-5 w-4/12">
          <AdminPermissionsButtons
            adminScopes={userAdminScopes}
            isEmployee={user.info.isEmployee}
            onClickAddPermissions={() => {
              setFormType('add')
            }}
            onClickRemovePermissions={() => {
              setFormType('remove')
            }}
            userID={user.id}
          />
        </div>
      )}
    </div>
  )
}

export default AdminPermissions

const AddPermissions = ({
  adminScopes,
  onClickCancel,
  onSave,
  user,
  userAdminScopes,
}: {
  adminScopes: AdminScopeListItem[]
  onClickCancel(): void
  onSave(): void
  user: UserV1
  userAdminScopes: AdminScope[]
}) => {
  const dispatch = useAppDispatch()

  const { isLoading: isAddingAdminScopes, mutate: addAdminScopes } =
    useAddAdminScopes({
      onError: (err) => {
        errorHandler(dispatch, err)
      },
      onSuccess: (_data, { userID }) => {
        dispatch(getUserInfo(userID))
        successHandler(dispatch, 'Boom! Admin permissions have been added.')
        onSave()
      },
    })

  const adminScopesWithoutGod = adminScopes
    .filter(
      (item) =>
        item.scope !== 'VENGEFUL_GOD' && !userAdminScopes.includes(item.scope)
    )
    .map((item) => item.scope)

  return (
    <div className="space-y-5">
      <H2>Add Admin Permissions</H2>

      <div>
        <p>Existing Permissions</p>
        <ul className="list-disc pl-8">
          {sortBy(userAdminScopes).map((scope) => (
            <li key={scope}>{scope}</li>
          ))}
        </ul>
      </div>

      <p>Select the permissions that should be added.</p>

      <ModifyAdminPermissionsForm
        adminScopes={adminScopesWithoutGod}
        isSaving={isAddingAdminScopes}
        onClickCancel={onClickCancel}
        onSubmit={(adminScopesToModify) => {
          addAdminScopes({ data: adminScopesToModify, userID: user.id })
        }}
      />
    </div>
  )
}

const RemovePermissions = ({
  adminScopes,
  onClickCancel,
  onSave,
  user,
}: {
  adminScopes: AdminScope[]
  onClickCancel(): void
  onSave(): void
  user: UserV1
}) => {
  const dispatch = useAppDispatch()

  const { isLoading: isRemovingAdminScopes, mutate: removeAdminScopes } =
    useRemoveAdminScopes({
      onError: (err) => {
        errorHandler(dispatch, err)
      },
      onSuccess: (_data, { userID }) => {
        dispatch(getUserInfo(userID))
        successHandler(dispatch, 'Boom! Admin permissions have been removed.')
        onSave()
      },
    })

  return (
    <>
      <div className="mb-5">
        <H2>Remove Admin Permissions</H2>
        <p>Select the permissions that should be removed.</p>
      </div>

      <ModifyAdminPermissionsForm
        adminScopes={adminScopes}
        isSaving={isRemovingAdminScopes}
        onClickCancel={onClickCancel}
        onSubmit={(adminScopesToModify) => {
          removeAdminScopes({ data: adminScopesToModify, userID: user.id })
        }}
      />
    </>
  )
}

const ModifyAdminPermissionsForm = ({
  adminScopes,
  isSaving,
  onClickCancel,
  onSubmit,
}: {
  adminScopes: AdminScope[]
  isSaving: boolean
  onClickCancel(): void
  onSubmit(adminScopesToModify: AdminScope[]): void
}) => {
  const [adminScopesToModify, setAdminScopesToModify] = useState(
    new Set<AdminScope>()
  )

  return (
    <form
      className="space-y-4"
      onSubmit={(event) => {
        event.preventDefault()

        onSubmit(Array.from(adminScopesToModify))
      }}
    >
      <div className="space-y-2">
        {sortBy(adminScopes).map((scope) => (
          <Checkbox
            key={scope}
            checked={adminScopesToModify.has(scope)}
            label={scope}
            name={scope}
            onChange={() => {
              setAdminScopesToModify((scopes) => {
                const newScopes = new Set(scopes)

                if (newScopes.has(scope)) {
                  newScopes.delete(scope)
                } else {
                  newScopes.add(scope)
                }

                return newScopes
              })
            }}
          />
        ))}
      </div>

      <button
        className="cursor-pointer text-sm font-semibold uppercase tracking-widest"
        onClick={() => {
          setAdminScopesToModify(new Set(adminScopes))
        }}
        type="button"
      >
        Select All
      </button>

      <div className="mt-8 flex space-x-2">
        <ButtonLoading isLoading={isSaving} size="large">
          Save
        </ButtonLoading>
        <Button
          buttonStyle="cancel"
          onClick={() => {
            onClickCancel()
          }}
          size="large"
        >
          Cancel
        </Button>
      </div>
    </form>
  )
}

const AdminPermissionsButtons = ({
  adminScopes,
  isEmployee,
  onClickAddPermissions,
  onClickRemovePermissions,
  userID,
}: {
  adminScopes: AdminScope[]
  isEmployee: boolean
  onClickAddPermissions(): void
  onClickRemovePermissions(): void
  userID: number
}) => {
  const dispatch = useAppDispatch()

  const { isLoading: isEditingEmployeeStatus, mutate: editEmployeeStatus } =
    useEditEmployeeStatus({
      onError: (err) => {
        errorHandler(dispatch, err)
      },
      onSuccess: (_data, { data, userID }) => {
        dispatch(getUserInfo(userID))
        successHandler(
          dispatch,
          data.is_employee
            ? `Boom! User #${userID} has been marked as an employee.`
            : `Boom! User #${userID} is no longer an employee.`
        )
      },
    })

  const buttons: ReactNode[] = []

  if (getAdminScope(VENGEFUL_GOD) || getAdminScope(USERS_ADMIN)) {
    buttons.push(
      <div key="add-permissions" className="h-10">
        <Button
          buttonStyle="grey"
          onClick={() => {
            onClickAddPermissions()
          }}
          size="fluid"
        >
          Add Permissions
        </Button>
      </div>
    )

    if (adminScopes.length > 0) {
      buttons.push(
        <div key="remove-permissions" className="h-10">
          <Button
            buttonStyle="grey"
            onClick={() => {
              onClickRemovePermissions()
            }}
            size="fluid"
          >
            Remove Permissions
          </Button>
        </div>
      )
    }
  }

  if (getAdminScope(USERS_WRITE)) {
    buttons.push(
      <div key="modify-employee" className="h-10">
        {isEmployee ? (
          <ButtonLoading
            isLoading={isEditingEmployeeStatus}
            onClick={() => {
              editEmployeeStatus({
                data: { is_employee: false },
                userID,
              })
            }}
            size="fluid"
          >
            Remove Employee Status
          </ButtonLoading>
        ) : (
          <ButtonLoading
            isLoading={isEditingEmployeeStatus}
            onClick={() => {
              editEmployeeStatus({
                data: { is_employee: true },
                userID,
              })
            }}
            size="fluid"
          >
            Mark as Employee
          </ButtonLoading>
        )}
      </div>
    )
  }

  return <div className="space-y-3">{buttons}</div>
}
