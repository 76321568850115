import { useState } from 'react'

import {
  createOvenFulfillments,
  generateMealboxCsv,
  processOrderFilesQVC,
  uploadLargeQvcPackslip,
  uploadTrackingFile,
} from '../../actions/packslips'
import { getAdminScope, ORDER_OVERRIDE } from '../../utils/getAdminScope'

import { useAppDispatch } from 'hooks'
import AlertInline from 'components/common/AlertInline'
import Button from 'components/common/Button'
import FileDropzone from '../common/FileDropzone'
import H1 from 'components/common/H1'
import H3 from 'components/common/H3'

const Qvc = (): JSX.Element => {
  const dispatch = useAppDispatch()

  const [generatingMealboxCsv, setGeneratingMealboxCsv] = useState(false)
  const [orderFilesProcessed, setOrderFilesProcessed] = useState(false)
  const [packslip, setPackslip] = useState<File | ''>('')
  const [trackingFile, setTrackingFile] = useState<File | ''>('')

  const setQvcPackslipFile = (files: File[]) => {
    setPackslip(files[0])
  }

  const submitTrackingUpload = () => {
    const formData = new FormData()

    formData.append('file', trackingFile)
    formData.append('name', 'Tovala Admin')

    const payload = {
      formData,
    }

    dispatch(uploadTrackingFile(payload)).then((fileWasUploaded) => {
      if (fileWasUploaded) {
        setTrackingFile('')
      }
    })
  }

  const submitUpload = () => {
    const formData = new FormData()

    formData.append('file', packslip)
    formData.append('name', 'Tovala Admin')

    dispatch(uploadLargeQvcPackslip({ formData })).then((response) => {
      if (response) {
        setPackslip('')
      }
    })
  }

  const onGenerateMealboxCsv = () => {
    setGeneratingMealboxCsv(true)

    dispatch(generateMealboxCsv()).then((response) => {
      if (response) {
        setGeneratingMealboxCsv(false)
      }
    })
  }

  const onSetTrackingFile = (files: File[]) => {
    setTrackingFile(files[0])
  }

  const handleProcessOrderFiles = () => {
    dispatch(processOrderFilesQVC()).then((response) => {
      if (response && !response.error) {
        setOrderFilesProcessed(true)
      }
    })
  }

  if (getAdminScope(ORDER_OVERRIDE)) {
    return (
      <div>
        <H1>QVC</H1>
        <div>
          {!orderFilesProcessed ? (
            <div>
              <H3>Process Order Files</H3>
              <p className="mb-4">
                Process order files before uploading packslips and completing
                other tasks.
              </p>
              <Button onClick={handleProcessOrderFiles} size="large">
                Process Order Files
              </Button>
            </div>
          ) : (
            <AlertInline alertStyle="success">
              Success! Order files processed.
            </AlertInline>
          )}
        </div>

        {orderFilesProcessed && (
          <div className="space-y-8">
            <div>
              <H3>Process Large Packslip PDF</H3>
              <div className="space-y-4">
                {packslip ? (
                  <p>{packslip.name}</p>
                ) : (
                  <FileDropzone
                    accept={{ 'application/pdf': ['.pdf'] }}
                    maxFiles={1}
                    onDrop={setQvcPackslipFile}
                  />
                )}
                <Button
                  disabled={!packslip}
                  onClick={submitUpload}
                  size="large"
                >
                  Submit
                </Button>
              </div>
            </div>

            <div>
              <H3>Generate Mealbox CSV</H3>
              <Button
                disabled={generatingMealboxCsv}
                onClick={onGenerateMealboxCsv}
                size="large"
              >
                {generatingMealboxCsv ? 'Please wait..' : 'Generate Meal CSV'}
              </Button>
            </div>

            <div>
              <H3>Upload Mealbox Tracking Numbers</H3>
              <div>
                {trackingFile ? (
                  <p className="mb-4">{trackingFile.name}</p>
                ) : (
                  <FileDropzone maxFiles={1} onDrop={onSetTrackingFile} />
                )}
                <Button
                  disabled={!trackingFile}
                  onClick={submitTrackingUpload}
                  size="large"
                >
                  Submit
                </Button>
              </div>
            </div>

            <div>
              <H3>Create Oven Fulfillments</H3>
              <Button
                onClick={() => {
                  dispatch(createOvenFulfillments())
                }}
                size="large"
              >
                Create
              </Button>
            </div>
          </div>
        )}
      </div>
    )
  }

  return <div>Sorry Charlie, you don&apos;t have permission to do this.</div>
}

export default Qvc
