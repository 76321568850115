import TabGroup, {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
} from 'components/common/TabGroup'
import { findKey } from 'lodash-es'
import { ReactNode, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import MenuProductImages from './MenuProductImages'
import MenuProductQRCode from './MenuProductQRCode'
import MenuProductSlotMapGroups from './MenuProductSlotMapGroups'

type Tab = 'images' | 'info' | 'qr-code' | 'tags'

const TAB_INDEX_ROUTES: Record<number, Tab> = {
  0: 'info',
  1: 'images',
  2: 'qr-code',
  3: 'tags',
}

const MenuProductDetails = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate()

  const {
    productid: productID,
    tab: tabParam,
    termid: termID,
  } = useParams<{
    productid: string
    tab?: Tab
    termid?: string
  }>()

  const baseURL = termID
    ? `../terms/${termID}/menu-products/${productID}/`
    : `../menu-products/${productID}/`

  const [selectedTabIndex, setSelectedTabIndex] = useState(() => {
    const tabIndex = findKey(TAB_INDEX_ROUTES, (tab) => tab === tabParam)

    return tabIndex ? Number(tabIndex) : 0
  })

  return (
    <TabGroup
      onChange={(index) => {
        navigate(`${baseURL}${TAB_INDEX_ROUTES[index]}`)
        setSelectedTabIndex(index)
      }}
      selectedIndex={selectedTabIndex}
    >
      <TabList>
        <Tab>Info</Tab>
        <Tab>Images</Tab>
        <Tab>QR Code</Tab>
        <Tab>Tags</Tab>
      </TabList>

      <TabPanels>
        <TabPanel>{children}</TabPanel>
        <TabPanel>
          <MenuProductImages productID={productID} />
        </TabPanel>
        <TabPanel>
          <MenuProductQRCode productID={productID} />
        </TabPanel>
        <TabPanel>
          <MenuProductSlotMapGroups productID={productID} />
        </TabPanel>
      </TabPanels>
    </TabGroup>
  )
}

export default MenuProductDetails
