import { useDeleteDefaultMenu } from '@tovala/browser-apis-menu-delivery'
import {
  Modal,
  ModalHeader,
  APIErrorDisplay,
  Button,
  ButtonLoading,
} from '@tovala/component-library'
import { DELETE_DEFAULT_MENU_ERRORS } from 'utils/errors'

const DeleteMenuDialog = ({
  data,
  onClose,
  onDelete,
}: {
  data: {
    menu: string
    subTermID: string
  }
  onClose(): void
  onDelete(): void
}) => {
  const {
    mutate: deleteDefaultMenu,
    error: deleteDefaultMenuError,
    isError: hasDeleteDefaultMenuError,
    isLoading: isDeletingDefaultMenu,
  } = useDeleteDefaultMenu()

  return (
    <Modal onCloseModal={onClose}>
      <ModalHeader onClickClose={onClose}>Delete Menu</ModalHeader>
      <div className="space-y-10 p-6 w-[500px]">
        <div className="text-center">
          Are you sure you want to delete the menu for{' '}
          <span className="font-bold uppercase">{data.menu}</span>?
        </div>

        {hasDeleteDefaultMenuError && (
          <APIErrorDisplay
            error={deleteDefaultMenuError}
            errorCodeMessageMap={DELETE_DEFAULT_MENU_ERRORS}
          />
        )}

        <div className="flex justify-between">
          <Button buttonStyle="stroke" onClick={onClose} size="large">
            Cancel
          </Button>
          <ButtonLoading
            buttonStyle="dark"
            isLoading={isDeletingDefaultMenu}
            onClick={() => {
              deleteDefaultMenu(
                { subTermID: data.subTermID },
                {
                  onSuccess: () => {
                    onDelete()
                  },
                }
              )
            }}
            size="large"
          >
            Delete Menu
          </ButtonLoading>
        </div>
      </div>
    </Modal>
  )
}

export default DeleteMenuDialog
