import { TovalaProduct } from '@tovala/browser-apis-combinedapi'

import { getEnvVar } from './env'

const VALID_PRODUCT_IDS_TO_BUY = new Set([
  getEnvVar('AIRVALA_OVEN_COMMITMENT_ID_299_MSRP'),
  getEnvVar('AIRVALA_OVEN_REFERRAL_ID_299_MSRP'),
  getEnvVar('AIRVALA_OVEN_STANDALONE_ID_299_MSRP'),
  getEnvVar('TOVALA_OVEN_COMMITMENT_ID_349_MSRP'),
  getEnvVar('TOVALA_OVEN_REFERRAL_ID_349_MSRP'),
  getEnvVar('TOVALA_OVEN_STANDALONE_ID_349_MSRP'),
])

const VALID_PRODUCT_IDS_TO_RELEASE_COMMITMENT = new Set([
  getEnvVar('AIRVALA_OVEN_STANDALONE_ID_299_MSRP'),
  getEnvVar('TOVALA_OVEN_STANDALONE_ID_349_MSRP'),
])

export function getAvailableProductsToBuy(products: TovalaProduct[]) {
  return products.filter((product) => VALID_PRODUCT_IDS_TO_BUY.has(product.id))
}

export function getAvailableProductsToReleaseCommitment(
  products: TovalaProduct[]
) {
  return products.filter((product) =>
    VALID_PRODUCT_IDS_TO_RELEASE_COMMITMENT.has(product.id)
  )
}
