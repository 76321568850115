import axios from 'axios'
import { hideLoading, showLoading } from 'react-redux-loading-bar'
import {
  LeveratorAction,
  LeveratorInAppOption,
  LeveratorPayload,
} from '@tovala/browser-apis-combinedapi'
import { PayloadAction } from '@reduxjs/toolkit'

import * as types from './types'
import { API_URL, API_URL_V1, getHeaders } from './index'
import { AppThunk } from 'store'
import { errorHandler, successHandler } from './notifications'

// ================================
// Coupon Code actions
// ================================

// GET https://api.tovala.com/v0/coupons
export function getAllCouponCodes(): AppThunk<void> {
  return function (dispatch) {
    axios
      .get(`${API_URL}/coupons`, getHeaders())
      .then((response) => {
        dispatch({
          type: types.GET_ALL_COUPON_CODES,
          payload: response.data,
        })
      })

      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

// GET https://api.tovala.com/v0/coupons/:code
export function getCouponCode(code: string): AppThunk<void> {
  return function (dispatch) {
    axios
      .get(`${API_URL}/coupons/${code}`, getHeaders())
      .then((response) => {
        dispatch({
          type: types.GET_COUPON_CODE,
          payload: response.data,
        })
      })

      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

// POST https://api.tovala.com/v1/coupons/multiple
export function addMultipleCouponCodes(
  data: unknown
): AppThunk<
  Promise<PayloadAction<unknown, typeof types.ADD_COUPON_CODES> | void>
> {
  return function (dispatch) {
    dispatch(showLoading())

    return axios
      .post(`${API_URL_V1}/coupons/multiple`, data, getHeaders())
      .then((response) => {
        dispatch(hideLoading())

        successHandler(dispatch, `Coupon(s) created.`)

        return dispatch({
          type: types.ADD_COUPON_CODES,
          payload: response.data,
        })
      })

      .catch((error) => {
        dispatch(hideLoading())

        errorHandler(dispatch, error)
      })
  }
}

// POST https://api.tovala.com/v0/coupon
export function addCouponCode(formProps: {
  code: string
  credit_amount: number
  description: string
  notes: string
  userid: number
}): AppThunk<
  Promise<PayloadAction<unknown, typeof types.GET_COUPON_CODE> | void>
> {
  return function (dispatch) {
    dispatch(showLoading())

    return axios
      .post(`${API_URL}/coupons`, formProps, getHeaders())
      .then((response) => {
        if (response.status === 200) {
          dispatch(hideLoading())

          successHandler(dispatch, `Coupon ${formProps.code} added.`)

          return dispatch({
            type: types.GET_COUPON_CODE,
            payload: response.data,
          })
        }
      })

      .catch((error) => {
        dispatch(hideLoading())

        errorHandler(dispatch, error)
      })
  }
}

// POST https://api.tovala.com/v0/coupon
export function updateCouponCode(
  code: string,
  data: unknown
): AppThunk<Promise<true | void>> {
  return function (dispatch) {
    dispatch(showLoading())

    return axios
      .post(`${API_URL}/coupons/${code}`, data, getHeaders())
      .then(() => {
        successHandler(dispatch, `Coupon ${code} updated.`)

        dispatch(hideLoading())

        return true as const
      })

      .catch((error) => {
        dispatch(hideLoading())

        errorHandler(dispatch, error)
      })
  }
}

export function clearGetCouponCode(): AppThunk<void> {
  return function (dispatch) {
    dispatch({
      type: types.GET_COUPON_CODE,
      payload: '',
    })
  }
}

// POST /tools/shippingDelaysForTerm/:termID
export function uploadOvenShippingDelayCSV(
  termID: number,
  file: unknown
): AppThunk<Promise<void>> {
  return function (dispatch) {
    return axios
      .post(
        `${API_URL_V1}/tools/shippingDelaysForTerm/${termID}`,
        file,
        getHeaders()
      )
      .then(() => {
        successHandler(dispatch, 'Success! CSV uploaded.')
      })
      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

// Leverator https://tovala.atlassian.net/wiki/spaces/API/pages/1926562058/Leverator+Endpoints

// GET v1/leverator/payloads
export function getAllLeveratorPayloads(): AppThunk<
  Promise<PayloadAction<
    LeveratorPayload[],
    typeof types.GET_ALL_LEVERATOR_PAYLOADS
  > | void>
> {
  return function (dispatch) {
    return axios
      .get(`${API_URL_V1}/leverator/payloads`, getHeaders())
      .then((response) => {
        return dispatch({
          type: types.GET_ALL_LEVERATOR_PAYLOADS,
          payload: response.data,
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

export function createLeveratorPayload(payload: {
  alertTitle: string
  alertBody: string
  badge: 1
  contentAvailable: 1
  showPopUp: boolean
  sound: 'default'
  requiresInteraction: boolean
}): AppThunk<
  Promise<PayloadAction<
    LeveratorPayload,
    typeof types.GET_LEVERATOR_PAYLOAD
  > | void>
> {
  return function (dispatch) {
    return axios
      .post(`${API_URL_V1}/leverator/payloads`, payload, getHeaders())
      .then((response) => {
        successHandler(dispatch, 'Success! Push notification content created.')
        return dispatch({
          type: types.GET_LEVERATOR_PAYLOAD,
          payload: response.data,
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

// POST v1/leverator/payloads/:payloadID/action
export function addActionToLeveratorPayload(
  payloadID: string,
  actionID: string
): AppThunk<
  Promise<PayloadAction<
    LeveratorPayload,
    typeof types.GET_LEVERATOR_ACTION
  > | void>
> {
  return function (dispatch) {
    return axios
      .post(
        `${API_URL_V1}/leverator/payloads/${payloadID}/action`,
        { actionID },
        getHeaders()
      )
      .then((response) => {
        successHandler(dispatch, 'Success! Action added.')
        dispatch(getAllLeveratorPayloads())
        return dispatch({
          type: types.GET_LEVERATOR_ACTION,
          payload: response.data,
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

// DELETE v1/leverator/payloads/:payloadID/action
export function removeActionFromLeveratorPayload(
  payloadID: string,
  actionID: string
): AppThunk<
  Promise<PayloadAction<
    LeveratorPayload,
    typeof types.GET_LEVERATOR_ACTION
  > | void>
> {
  const headers = getHeaders()
  return function (dispatch) {
    return axios
      .delete(`${API_URL_V1}/leverator/payloads/${payloadID}/action`, {
        data: { actionID },
        headers: headers.headers,
      })
      .then((response) => {
        successHandler(dispatch, 'Success! Action removed.')
        dispatch(getAllLeveratorPayloads())
        return dispatch({
          type: types.GET_LEVERATOR_ACTION,
          payload: response.data,
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

// GET v1/leverator/actions
export function getAllLeveratorActions(): AppThunk<
  Promise<PayloadAction<
    LeveratorAction[],
    typeof types.GET_ALL_LEVERATOR_ACTIONS
  > | void>
> {
  return function (dispatch) {
    return axios
      .get(`${API_URL_V1}/leverator/actions`, getHeaders())
      .then((response) => {
        return dispatch({
          type: types.GET_ALL_LEVERATOR_ACTIONS,
          payload: response.data,
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

// DELETE v1/leverator/actions/:actionID
export function deleteLeveratorAction(
  actionID: string
): AppThunk<Promise<void>> {
  return function (dispatch) {
    return axios
      .delete(`${API_URL_V1}/leverator/actions/${actionID}`, getHeaders())
      .then(() => {
        successHandler(dispatch, 'Success! Action deleted.')
        dispatch(getAllLeveratorActions())
      })
      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

export function createLeveratorWebAction(payload: {
  body?: string
  headers?: Record<string, string>
  isContained: boolean
  title: string
  url: string
}): AppThunk<
  Promise<PayloadAction<
    LeveratorAction,
    typeof types.GET_LEVERATOR_ACTION
  > | void>
> {
  return function (dispatch) {
    return axios
      .post(`${API_URL_V1}/leverator/actions/web`, payload, getHeaders())
      .then((response) => {
        successHandler(dispatch, 'Success! Action created.')
        return dispatch({
          type: types.GET_LEVERATOR_ACTION,
          payload: response.data,
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

export function createLeveratorDirectAction(payload: {
  body?: string
  headers?: Record<string, string>
  httpMethod?: string
  title: string
  url?: string
}): AppThunk<
  Promise<PayloadAction<
    LeveratorAction,
    typeof types.GET_LEVERATOR_ACTION
  > | void>
> {
  return function (dispatch) {
    return axios
      .post(`${API_URL_V1}/leverator/actions/direct`, payload, getHeaders())
      .then((response) => {
        successHandler(dispatch, 'Success! Action created.')
        return dispatch({
          type: types.GET_LEVERATOR_ACTION,
          payload: response.data,
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

export function createLeveratorInAppAction(payload: {
  inAppOptionsID: string
  title: string
}): AppThunk<
  Promise<PayloadAction<
    LeveratorAction,
    typeof types.GET_LEVERATOR_ACTION
  > | void>
> {
  return function (dispatch) {
    return axios
      .post(`${API_URL_V1}/leverator/actions/inapp`, payload, getHeaders())
      .then((response) => {
        successHandler(dispatch, 'Success! Action created.')
        return dispatch({
          type: types.GET_LEVERATOR_ACTION,
          payload: response.data,
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

// POST v1/leverator/actions/inapp/options
export function createLeveratorInAppOption(payload: {
  notes: string
  storyboardName: string
  title: string
  view_controller: string
}): AppThunk<Promise<void>> {
  return function (dispatch) {
    return axios
      .post(
        `${API_URL_V1}/leverator/actions/inapp/options`,
        payload,
        getHeaders()
      )
      .then(() => {
        successHandler(dispatch, 'Success! In app option created')
        dispatch(getAllLeveratorInAppOptions())
      })
      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

// DELETE v1/leverator/actions/inapp/options/:optionID
export function deleteLeveratorInAppOption(
  optionID: string
): AppThunk<Promise<void>> {
  return function (dispatch) {
    return axios
      .delete(
        `${API_URL_V1}/leverator/actions/inapp/options/${optionID}`,
        getHeaders()
      )
      .then(() => {
        successHandler(dispatch, 'Success! In app option deleted.')
        dispatch(getAllLeveratorInAppOptions())
      })
      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

// GET v1/leverator/actions/inapp/options
export function getAllLeveratorInAppOptions(): AppThunk<
  Promise<PayloadAction<
    LeveratorInAppOption[],
    typeof types.GET_ALL_LEVERATOR_INAPP_OPTIONS
  > | void>
> {
  return function (dispatch) {
    return axios
      .get(`${API_URL_V1}/leverator/actions/inapp/options`, getHeaders())
      .then((response) => {
        return dispatch({
          type: types.GET_ALL_LEVERATOR_INAPP_OPTIONS,
          payload: response.data,
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

// POST v1/leverator/push
export function pushLeveratorPayloadToUsers(payload: {
  payloadID: string
  userIDs: number[]
}): AppThunk<
  Promise<PayloadAction<boolean, typeof types.LEVERATOR_PUSH_SUCCESS> | void>
> {
  return function (dispatch) {
    dispatch({
      type: types.SENDING_LEVERATOR_PUSH,
      payload: true,
    })
    return axios
      .post(`${API_URL_V1}/leverator/push`, payload, getHeaders())
      .then((response) => {
        dispatch({
          type: types.SENDING_LEVERATOR_PUSH,
          payload: false,
        })

        return dispatch({
          type: types.LEVERATOR_PUSH_SUCCESS,
          payload: response.data,
        })
      })
      .catch((error) => {
        dispatch({
          type: types.SENDING_LEVERATOR_PUSH,
          payload: false,
        })

        errorHandler(dispatch, error)
      })
  }
}
