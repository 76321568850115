import { Field, formValueSelector, reduxForm } from 'redux-form'
import { FocusEvent, FormEventHandler } from 'react'
import { MealTagList } from '@tovala/browser-apis-combinedapi'
import Select from 'react-select'

import { addMealTagtoMeal } from '../../actions/meals'
import { ReactSelectValue } from 'types/internal'

import { useAppDispatch, useAppSelector } from 'hooks'
import Button from 'components/common/Button'
import InputRF from '../common/InputRF'

interface FormData {
  main_dish: string
  side_dish: string
  tag: ReactSelectValue<string> | null
}

interface Props {
  allMealTags: MealTagList[]
  mealid: string
}

/* Old tags that we no longer use, but can't delete since they're on old meals,
and will be filtered out from the tag select on the meal form */
const oldVegetarianID = 1
const oldGlutenFreeID = 3
const chefsTableID = 32
const oldTagIDs = [oldVegetarianID, oldGlutenFreeID, chefsTableID]

const form = reduxForm<FormData, Props>({
  form: 'add-tag-to-meal',
})

const selector = formValueSelector('add-tag-to-meal')

const AddTagToMealForm = ({
  allMealTags,
  handleSubmit,
  mealid,
  reset,
}: Props & {
  handleSubmit(onSubmit: (formData: FormData) => void): FormEventHandler
  reset(): void
}): JSX.Element => {
  const dispatch = useAppDispatch()

  const tag = useAppSelector((state) => selector(state, 'tag'))

  function handleMealTagSelection(formData: FormData) {
    if (!formData.tag) {
      return
    }

    const tagId = formData.tag.value
    const dishSpecification: { main_dish?: string; side_dish?: string } = {}
    if (formData.main_dish) {
      dishSpecification.main_dish = formData.main_dish
    }

    if (formData.side_dish) {
      dishSpecification.side_dish = formData.side_dish
    }

    dispatch(addMealTagtoMeal(mealid, tagId, dishSpecification))

    reset()
  }

  const dishSpecificationTag = 21

  // Don't include the old tags so they don't get accidently used
  const options = allMealTags
    .filter((tag) => !oldTagIDs.includes(tag.id))
    .map((tag) => {
      return {
        value: tag.id,
        label: `${tag.title} (Tag ID #${tag.id}) ${tag.description_internal}`,
      }
    })
    .sort((a, b) => {
      if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1
      }
      if (a.label.toLowerCase() > b.label.toLowerCase()) {
        return 1
      }
      return 0
    })

  // Something about redux-form requires that this component be declared in the scope of the parent
  // component. Don't want to spend the time figuring out why this is required for redux-form since
  // we're moving away from it anyway.
  const RenderSelectInput = ({
    id,
    input,
    name,
    options,
    placeholder,
  }: {
    input: {
      onBlur(event: FocusEvent<HTMLInputElement>): void
      onChange(value: ReactSelectValue<string> | null): void
      value: ReactSelectValue<string> | null
    }
    id?: string
    name: string
    options: ReactSelectValue<string>[]
    placeholder: string
  }): JSX.Element => {
    return (
      <Select
        {...input}
        id={id}
        name={name}
        onBlur={(event) => input.onBlur(event)}
        onChange={(value) => {
          input.onChange(value)
        }}
        options={options}
        placeholder={placeholder}
        value={input.value}
      />
    )
  }

  return (
    <form
      className="space-y-4"
      name="add-tag-to-meal"
      onSubmit={handleSubmit(handleMealTagSelection)}
    >
      <Field
        component={RenderSelectInput}
        name="tag"
        options={options}
        placeholder="Add a meal tag"
      />

      {tag && tag.value === dishSpecificationTag && (
        <div className="space-y-2">
          <Field component={InputRF} label="Main Dish" name="main_dish" />
          <Field component={InputRF} label="Side Dish" name="side_dish" />
        </div>
      )}

      <div className="mt-4">
        <Button size="large" type="submit">
          Add Tag to Meal
        </Button>
      </div>
    </form>
  )
}

export default form(AddTagToMealForm)
