import { useTerms } from '@tovala/browser-apis-combinedapi'
import { useState } from 'react'

const LAST_TERMID = 18

export const useAllTerms = ({
  opts,
  quantity = 5,
}: {
  opts:
    | {
        initialTermIDs: number[]
      }
    | {
        startTermID: number
      }
  quantity?: number
}) => {
  const [termIDs, setTermIDs] = useState<number[]>(() => {
    if ('initialTermIDs' in opts) {
      return opts.initialTermIDs
    }

    return []
  })
  const termsResponse = useTerms({ termIDs, retry: false })

  const loadMoreTerms = () => {
    setTermIDs((prevTermIDs) => {
      const nextTermIDs = Array.from({ length: quantity }, (_i, index) => {
        if (prevTermIDs.length > 0) {
          const lastTermID = prevTermIDs[prevTermIDs.length - 1]
          return lastTermID - (index + 1)
        } else if ('startTermID' in opts) {
          return opts.startTermID - index
        }

        throw new Error(
          'Cannot load more terms without a start termID or initial termIDs'
        )
      })

      const updatedPastTermIDs = [...prevTermIDs, ...nextTermIDs].filter(
        (termID) => termID >= LAST_TERMID
      )

      return updatedPastTermIDs
    })
  }

  return {
    hasMoreTermsAvailable:
      termIDs.length === 0 || termIDs[termIDs.length - 1] >= LAST_TERMID,
    loadMoreTerms,
    termsResponse,
    termIDs,
  }
}
