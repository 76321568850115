import { ComponentProps } from 'react'
import { FormGroup, Input } from '@tovala/component-library'
import { useField } from 'formik'

type Props = Pick<ComponentProps<typeof FormGroup>, 'label' | 'labelFor'> &
  Omit<ComponentProps<typeof Input>, 'hasError'> & { name: string }

const FormikInput = ({ label, labelFor, ...rest }: Props) => {
  const [field, meta] = useField({
    name: rest.name,
    type: rest.type,
    value: rest.value,
  })

  return (
    <FormGroup error={meta.error} label={label} labelFor={labelFor}>
      <Input {...field} {...rest} hasError={!!meta.error} />
    </FormGroup>
  )
}

export default FormikInput
