import { SelectHTMLAttributes } from 'react'
import { clsx } from 'clsx'

export type Props = SelectHTMLAttributes<HTMLSelectElement> & {
  hasError?: boolean
}

export const SELECT_CLASSES = 'h-10 w-full rounded border bg-white-900 px-2'

const Select = ({ hasError = false, ...rest }: Props): JSX.Element => {
  return (
    <select
      {...rest}
      className={clsx(SELECT_CLASSES, {
        'border-red-902': hasError,
        'border-grey-901': !hasError,
      })}
    />
  )
}

export default Select
