import { Field, Form, Formik, FormikErrors, useField } from 'formik'
import ReactSelect from 'react-select'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  UserV1,
  useInvalidateMealCash,
  useMealCashHistory,
} from '@tovala/browser-apis-combinedapi'

import { addCouponCode, clearGetCouponCode } from '../../actions/marketing'
import { addMealCash } from '../../actions/user'
import { getUserInfo } from 'actions/auth'

import { formatCentsToDollars } from 'utils/currency'
import {
  getAdminScope,
  USERS_WRITE,
  PRODUCTS_WRITE,
} from '../../utils/getAdminScope'
import { getUserId } from '../../utils/getUserId'
import { ReactSelectValue } from 'types/internal'
import { showSuccessNotification } from '../../actions/notifications'

import { useAppDispatch, useAppSelector } from 'hooks'
import AddMarketingCreditForm, {
  Props as AddMarketingCreditFormProps,
} from './AddMarketingCreditForm'
import AlertInline from 'components/common/AlertInline'
import Button from 'components/common/Button'
import FormLabel from 'components/common/FormLabel'
import H4 from 'components/common/H4'
import Hr from 'components/common/Hr'
import { INPUT_CLASSES } from 'components/common/Input'
import TabGroup, {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
} from 'components/common/TabGroup'
import { TEXTAREA_CLASSES } from 'components/common/Textarea'

interface FormData {
  code: string
  credit_amount: ReactSelectValue<number> | null
  description: string
  notes: string
  userid: number
}

interface FormDataValidated extends FormData {
  credit_amount: ReactSelectValue<number>
}

type Tab = 'balance' | 'coupon'

function validateFormData(formData: FormData) {
  const errors: FormikErrors<FormData> = {}

  if (!formData.code) {
    errors.code = 'Required'
  }

  if (!formData.credit_amount) {
    errors.credit_amount = 'Required'
  }

  return errors
}

const Select = (props: {
  name: string
  onChange(option: ReactSelectValue<number> | null): void
  options: ReactSelectValue<number>[]
  value: ReactSelectValue<number> | null
}) => {
  const [field] = useField(props.name)

  return <ReactSelect {...field} {...props} />
}

const UserMealCredits = ({ user }: { user: UserV1 }) => {
  const { tab } = useParams<{ tab?: Tab }>()

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const couponCode = useAppSelector((state) => state.marketing.couponCode)
  const { data: mealCashHistory } = useMealCashHistory({ userID: user.id })

  const { invalidateMealCashHistory } = useInvalidateMealCash()

  const [selectedTabIndex, setSelectedTabIndex] = useState(
    tab === 'coupon' ? 1 : 0
  )

  const formRef: AddMarketingCreditFormProps['innerRef'] = useRef(null)

  const userID = user.id
  const { isEmployee } = user.info

  const handleSubmit: AddMarketingCreditFormProps['handleSubmit'] = (
    values
  ) => {
    const csAgent = getUserId()

    const payload = {
      amount: values.amount * 100,
      csAgent: csAgent ? `${csAgent}` : '',
      notes: values.notes,
      reason: values.reason.value,
      zendeskTicket: values.zendeskTicket,
    }

    return dispatch(addMealCash(userID, payload)).then((response) => {
      if (response && response.payload) {
        dispatch(
          showSuccessNotification(
            `Success! $${(response.payload.amount / 100).toFixed(2)} added.`
          )
        )

        invalidateMealCashHistory(user.id)
        dispatch(getUserInfo(user.id))

        formRef.current?.resetForm()

        // return response to handle in AddMarketingCreditForm
        return response
      }
    })
  }

  const handleCreateMarketingCoupon = (values: FormDataValidated) => {
    const coupon = {
      code: values.code,
      credit_amount: values.credit_amount.value,
      description: values.description,
      notes: values.notes,
      userid: values.userid,
    }

    return dispatch(addCouponCode(coupon))
  }

  useEffect(() => {
    document.title = `Glaze | User #${userID} - Tovala Cash`
  }, [userID])

  useEffect(() => {
    return () => {
      dispatch(clearGetCouponCode())
    }
  }, [dispatch])

  const couponAmountOptions: number[] = []
  let couponAmount = 5
  while (couponAmount <= 100) {
    couponAmountOptions.push(couponAmount)
    couponAmount += 5
  }

  const couponInitialValues: FormData = {
    code: '',
    description: '',
    userid: userID,
    credit_amount: null,
    notes: '',
  }

  return (
    <TabGroup
      onChange={(index) => {
        if (index === 0) {
          navigate('../account-balance/balance')
        } else if (index === 1) {
          navigate('../account-balance/coupon')
        }

        setSelectedTabIndex(index)
      }}
      selectedIndex={selectedTabIndex}
    >
      <TabList>
        <Tab>Balance / Add Credit</Tab>
        {getAdminScope(PRODUCTS_WRITE) && !isEmployee && (
          <Tab>Create Marketing Coupon</Tab>
        )}
      </TabList>

      <TabPanels>
        <TabPanel>
          {mealCashHistory && (
            <div>
              <H4>Account Balance</H4>
              <div className="space-y-4">
                <p className="text-xs">
                  Discounts and Tovala Cash are automatically applied towards
                  the user&apos;s next order.
                </p>

                <div>
                  <p className="font-bold">
                    Tovala Cash: {formatCentsToDollars(user.cashCents)}
                  </p>
                  <p className="text-xs">
                    Gift cards, refunds, or referral bonuses
                  </p>
                </div>

                <div>
                  <p className="font-bold">
                    Discounts: {formatCentsToDollars(user.discountCents)}
                  </p>
                  <p className="text-xs">Coupons, discounts, and promotions</p>
                </div>
              </div>
            </div>
          )}

          {getAdminScope(USERS_WRITE) && !isEmployee && (
            <div className="max-w-2xl">
              <Hr />

              <H4>Add Marketing Credits (Discount)</H4>

              <AddMarketingCreditForm
                handleSubmit={handleSubmit}
                innerRef={formRef}
              />
            </div>
          )}
        </TabPanel>

        {getAdminScope(USERS_WRITE) && !isEmployee && (
          <TabPanel>
            <Formik<FormData>
              enableReinitialize
              initialValues={couponInitialValues}
              onSubmit={(values, actions) => {
                handleCreateMarketingCoupon(values as FormDataValidated).then(
                  (response) => {
                    if (response) {
                      actions.setSubmitting(false)
                      actions.resetForm()
                      window.scrollTo(0, 0)
                    }
                  }
                )
              }}
              validate={validateFormData}
            >
              {({ errors, setFieldValue, values }) => {
                return (
                  <Form className="max-w-2xl space-y-4">
                    {couponCode && (
                      <AlertInline alertStyle="success">
                        <span className="font-bold">Success!</span> Coupon code{' '}
                        <span className="font-bold">
                          {couponCode.code.toUpperCase()}
                        </span>{' '}
                        ($
                        {(couponCode.credit_amount / 100).toFixed(2)}) created
                        for User #{userID}.
                      </AlertInline>
                    )}

                    <p className="font-bold">
                      Create Marketing Coupon for User #{userID}
                    </p>

                    <div>
                      <FormLabel>Coupon Code*</FormLabel>
                      <Field
                        className={INPUT_CLASSES}
                        name="code"
                        type="text"
                      />
                      {errors.code && (
                        <div className="text-red-901">{errors.code}</div>
                      )}
                    </div>

                    <div>
                      <FormLabel>Description</FormLabel>
                      <Field
                        className={INPUT_CLASSES}
                        name="description"
                        type="text"
                      />
                    </div>

                    <div>
                      <FormLabel>Tovala Cash Amount*</FormLabel>
                      <Select
                        name="credit_amount"
                        onChange={(option) =>
                          setFieldValue('credit_amount', option)
                        }
                        options={couponAmountOptions.map((option) => ({
                          label: `$${option.toFixed(2)}`,
                          value: option * 100,
                        }))}
                        value={values.credit_amount}
                      />
                      {errors.credit_amount && (
                        <div className="text-red-901">
                          {errors.credit_amount}
                        </div>
                      )}
                    </div>

                    <div>
                      <FormLabel>Notes</FormLabel>
                      <Field
                        as="textarea"
                        className={TEXTAREA_CLASSES}
                        name="notes"
                        rows={5}
                      />
                    </div>

                    <Button size="large" type="submit">
                      Submit
                    </Button>
                  </Form>
                )
              }}
            </Formik>
          </TabPanel>
        )}
      </TabPanels>
    </TabGroup>
  )
}

export default UserMealCredits
