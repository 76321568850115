import { Term, TermMenu } from '@tovala/browser-apis-combinedapi'
import {
  ListingFormData,
  MenuProductFormData,
} from 'components/menuProducts/helpers'
import { cloneDeep, compact, forEach } from 'lodash-es'
import moment from 'moment'

/*
  Example meal doc
  https://docs.google.com/spreadsheets/d/1n0AapRBM65hte5-N9OwrleLCS8y3cufYrublbt7_NWs/edit#gid=83554870
*/

export interface MealDocDataCells {
  allergens: string[]
  created_by: string
  expiration: {
    chicago: {
      1: string
      2: string
    }
    slc: {
      1: string
      2: string
    }
  }
  garnishes: string[][]
  ingredients: string
  mealPrepSteps: string
  mealTags: string[]
  nutritionalInfo: {
    dailyValuePercentage: string
    itemNumber: number
    key: string
    name: string
    qty: string
    unit: string
  }[]
  premium: string
  prepSteps: string[]
  shortSubtitle: string
  story: string
  subtitle: string
  surchargeCents: string
  title: string
}

export const mealDoc: MealDocDataCells = {
  title: 'F2',
  subtitle: 'F3',
  shortSubtitle: 'F5',
  story: 'F20',
  ingredients: 'D104',
  mealPrepSteps: 'F72',
  created_by: 'F4',
  nutritionalInfo: [
    {
      name: 'Serving Size',
      qty: 'E92',
      unit: 'g',
      key: 'sub',
      dailyValuePercentage: '',
      itemNumber: 2,
    },
    {
      name: 'Servings per Container',
      qty: 'E93',
      unit: '',
      key: 'sub',
      dailyValuePercentage: '',
      itemNumber: 1,
    },
    {
      name: 'Calories',
      qty: 'B92',
      unit: '',
      key: 'golden',
      dailyValuePercentage: '',
      itemNumber: 3,
    },
    {
      name: 'Total Fat',
      qty: 'B93',
      unit: 'g',
      key: 'golden',
      dailyValuePercentage: '',
      itemNumber: 4,
    },
    {
      name: 'Saturated Fat',
      qty: 'B94',
      unit: 'g',
      key: 'sub',
      dailyValuePercentage: '',
      itemNumber: 5,
    },
    {
      name: 'Trans Fat',
      qty: 'E94',
      unit: 'g',
      key: 'sub',
      dailyValuePercentage: '',
      itemNumber: 6,
    },
    {
      name: 'Cholesterol',
      qty: 'B95',
      unit: 'mg',
      key: 'sub',
      dailyValuePercentage: '',
      itemNumber: 7,
    },
    {
      name: 'Sodium',
      qty: 'B96',
      unit: 'mg',
      key: 'sub',
      dailyValuePercentage: '',
      itemNumber: 8,
    },
    {
      name: 'Carbs',
      qty: 'B97',
      unit: 'g',
      key: 'golden',
      dailyValuePercentage: '',
      itemNumber: 9,
    },
    {
      name: 'Sugar',
      qty: 'B98',
      unit: 'g',
      key: 'sub',
      dailyValuePercentage: '',
      itemNumber: 11,
    },
    {
      name: 'Added Sugars',
      qty: 'E96',
      unit: 'g',
      key: 'sub',
      dailyValuePercentage: '',
      itemNumber: 12,
    },
    {
      name: 'Protein',
      qty: 'B99',
      unit: 'g',
      key: 'golden',
      dailyValuePercentage: '',
      itemNumber: 13,
    },
    {
      name: 'Dietary Fiber',
      qty: 'E95',
      unit: 'g',
      key: 'sub',
      dailyValuePercentage: '',
      itemNumber: 10,
    },
    {
      name: 'Vitamin D',
      qty: 'E97',
      unit: 'mcg',
      key: 'sub',
      dailyValuePercentage: '',
      itemNumber: 14,
    },
    {
      name: 'Calcium',
      qty: 'E98',
      unit: 'mg',
      key: 'sub',
      dailyValuePercentage: '',
      itemNumber: 15,
    },
    {
      name: 'Iron',
      qty: 'E99',
      unit: 'mg',
      key: 'sub',
      dailyValuePercentage: '',
      itemNumber: 16,
    },
    {
      name: 'Potassium',
      qty: 'E100',
      unit: 'mg',
      key: 'sub',
      dailyValuePercentage: '',
      itemNumber: 17,
    },
    {
      name: 'SmartPoints',
      qty: 'B100',
      unit: '',
      key: 'sub',
      dailyValuePercentage: '',
      itemNumber: 18,
    },
  ],
  garnishes: [
    ['B108', 'C108'],
    ['B109', 'C109'],
    ['B110', 'C110'],
    ['B111', 'C111'],
  ],
  allergens: [
    'D76',
    'D77',
    'D78',
    'D79',
    'D80',
    'D81',
    'D83',
    'D84',
    'D85',
    'D86',
    'D87',
    'D88',
  ],
  expiration: {
    chicago: {
      1: 'B8',
      2: 'B9',
    },
    slc: {
      1: 'C8',
      2: 'C9',
    },
  },
  surchargeCents: 'B77',
  mealTags: [
    'B79',
    'B80',
    'B81',
    'B82',
    'B83',
    'B84',
    'B85',
    'B86',
    'B87',
    'B88',
  ],
  premium: 'B76',
  prepSteps: [
    'F46',
    'F47',
    'F48',
    'F49',
    'F50',
    'F51',
    'F52',
    'F53',
    'F54',
    'F55',
    'F56',
    'F57',
    'F58',
    'F59',
    'F60',
    'F61',
    'F62',
    'F63',
    'F64',
    'F65',
    'F66',
    'F67',
    'F68',
    'F69',
    'F70',
    'F71',
  ],
}

// Expiration dates are entered as day of week on meal doc
// Meal needs to be added to a term before we can calculate the expiration date
export function getExpiration(
  expDayOfWeek: string,
  shipPeriod: number,
  shipDate: string
) {
  let weeksToAdd = 1
  // Meals expiring on a Saturday (for shipPeriod 1) will expire on the same week
  if (shipPeriod === 1 && expDayOfWeek === 'Saturday') {
    weeksToAdd = 0
  }
  return moment(shipDate).day(expDayOfWeek).add(weeksToAdd, 'weeks')
}

export function getMealDocDataForMenuProduct({
  initialData,
  mealDocProperties,
  parsedData,
}: {
  initialData: MenuProductFormData
  mealDocProperties: Partial<MealDocDataCells>
  parsedData: string[][]
}) {
  const formData = cloneDeep(initialData)
  formData.allergens = []
  formData.nutritionalInfo = []
  formData.prepSteps = []
  const tagsToAdd: string[] = []

  forEach(mealDocProperties, (_value, key) => {
    switch (key) {
      case 'nutritionalInfo': {
        const value = getPropertyMealDocConfig(key)

        value
          .sort((a, b) => a.itemNumber - b.itemNumber)
          .forEach((item) => {
            const itemValue = getValueFromParsedData(parsedData, item.qty)

            if (itemValue) {
              formData.nutritionalInfo.push({
                key: item.key,
                name: item.name,
                unit: item.unit,
                value: itemValue,
              })
            }
          })
        break
      }

      case 'garnishes': {
        const value = getPropertyMealDocConfig(key)

        value.forEach((cell) => {
          const name = getValueFromParsedData(parsedData, cell[0])
          const value = getValueFromParsedData(parsedData, cell[1])

          if (name) {
            formData.nutritionalInfo.push({
              key: 'garnish',
              name,
              unit: 'CAL',
              value: value ?? '',
            })
          }
        })
        break
      }

      case 'allergens': {
        const value = getPropertyMealDocConfig(key)

        const allergens = value
          .map((cell) => getValueFromParsedData(parsedData, cell))
          .filter(
            (value): value is string => value !== undefined && value !== ''
          )
        allergens.forEach((allergen) => {
          formData.allergens.push({ value: allergen })
        })
        break
      }

      case 'prepSteps': {
        const value = getPropertyMealDocConfig(key)

        const prepSteps = value
          .map((cell) => getValueFromParsedData(parsedData, cell))
          .filter(
            (value): value is string => value !== undefined && value !== ''
          )

        prepSteps.forEach((prepStep) => {
          formData.prepSteps.push({ value: prepStep })
        })
        break
      }

      case 'mealTags': {
        const value = getPropertyMealDocConfig(key)
        const mealTags = value
          .map((cell) => getValueFromParsedData(parsedData, cell))
          .filter(
            (value): value is string => value !== undefined && value !== ''
          )

        mealTags.forEach((mealTag) => {
          if (mealTag.toLowerCase().includes('boxextra')) {
            tagsToAdd.push(mealTag)
          }
        })
        break
      }

      case 'ingredients':
      case 'story':
      case 'subtitle':
      case 'title': {
        const value = getPropertyMealDocConfig(key)
        formData[key] = getValueFromParsedData(parsedData, value) ?? ''
        break
      }

      default:
        return
    }
  })

  return { formData, tagsToAdd }
}

export function getMealDocDataForListings({
  initialData,
  mealDocProperties,
  menusWithListingIDs,
  parsedData,
  term,
}: {
  initialData: ListingFormData
  mealDocProperties: Partial<MealDocDataCells>
  menusWithListingIDs: {
    listingID: string | undefined
    menu: TermMenu
  }[]
  parsedData: string[][]
  term: Term
}) {
  const formData = cloneDeep(initialData)

  forEach(mealDocProperties, (_value, key) => {
    switch (key) {
      case 'expiration': {
        const value = getPropertyMealDocConfig(key)

        const expirationDates = term.subTerms
          ?.map((subTerm) => {
            const facilityNetwork = subTerm.facilityNetwork.toLowerCase()
            const shipPeriod = subTerm.shipPeriod.toString()
            if (subTerm.menus) {
              return subTerm.menus.map((menu) => {
                const expirationDayOfWeek = getValueFromParsedData(
                  parsedData,
                  value[facilityNetwork][shipPeriod]
                )

                if (!expirationDayOfWeek) {
                  return
                }

                const expiration = getExpiration(
                  expirationDayOfWeek,
                  subTerm.shipPeriod,
                  subTerm.shipDate
                )

                return {
                  menuID: menu.id,
                  expiration: expiration.format('YYYY-MM-DD'),
                }
              })
            }
          })
          .flat()

        compact(expirationDates).forEach((expirationInfo) => {
          const listingID = menusWithListingIDs.find(
            (menuWithListing) =>
              menuWithListing.menu.id === expirationInfo.menuID
          )?.listingID
          const listingsIndex = formData.listings
            ? formData.listings.findIndex(
                (listing) => listing.listingID === listingID
              )
            : -1

          if (listingsIndex > -1) {
            formData.listings[listingsIndex].expirationDate =
              expirationInfo.expiration
          }
        })

        break
      }

      case 'surchargeCents': {
        const value = getPropertyMealDocConfig(key)
        const priceString = getValueFromParsedData(parsedData, value)

        const price = priceString?.split(' - $')[1]
        formData.listings = formData.listings.map((listing) => {
          const priceCents = price
            ? { label: `$${price}`, value: `${parseFloat(price) * 100}` }
            : undefined

          return {
            ...listing,
            priceCents,
          }
        })

        break
      }

      default:
        return
    }
  })

  return formData
}

export function getPropertyMealDocConfig<T extends keyof MealDocDataCells>(
  property: T
): MealDocDataCells[T] {
  return mealDoc[property]
}

export function getValueFromParsedData(parsedData: string[][], cell: string) {
  // Split the cell address into an array of the letter and number (i.e., A1 becomes [A, 1])
  const splitCell = cell.match(/[a-zA-Z]+|[0-9]+(?:\.[0-9]+)?|\.[0-9]+/g)
  if (!splitCell) {
    return
  }

  const column = splitCell[0]
  const row = splitCell[1]

  const firstIndex = Number.parseInt(row, 10) - 1
  // convert cell letter to index (A = 65)
  const secondIndex = column.charCodeAt(0) - 65

  if (parsedData[firstIndex]) {
    return parsedData[firstIndex][secondIndex]
  }
}
