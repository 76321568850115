import { useEffect } from 'react'
import { Field, Form, Formik } from 'formik'
import { Link, useNavigate } from 'react-router-dom'

import {
  clearUserSearchResults,
  getDefaultSearchResults,
  getUserSearchResults,
} from '../../actions/user-search'
import { getAdminScope, USERS_WRITE } from '../../utils/getAdminScope'
import { SearchTerm } from 'types/internal'

import { useAppDispatch, useAppSelector } from 'hooks'
import Button from 'components/common/Button'
import Table, { TableRow, TBody, TD, TH, THead } from 'components/common/Table'

interface FormData {
  searchParam: string
  searchTerm: SearchTerm
}

const Dashboard = (): JSX.Element => {
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const defaultSearch = useAppSelector(
    (state) => state.userSearch.defaultSearch
  )
  const userSearch = useAppSelector((state) => state.userSearch.userSearch)
  const searchTerm = useAppSelector((state) => state.userSearch.searchTerm)
  const searchParam = useAppSelector((state) => state.userSearch.searchParam)

  useEffect(() => {
    document.title = 'Glaze | User Management'
  }, [])

  useEffect(() => {
    // Populate dashboard with most recently created users as default search
    if (!defaultSearch.length) {
      dispatch(getDefaultSearchResults())
    }
  }, [dispatch, defaultSearch])

  const handleGetUserSearchResults = (values: FormData) => {
    if (values.searchParam) {
      dispatch(getUserSearchResults(values)).then((users) => {
        if (users && users.length === 1) {
          const userid = users[0].id
          navigate(`/user/${userid}/account-overview`)
        }
      })
    } else {
      clearSearch()
    }
  }

  const clearSearch = () => {
    dispatch(clearUserSearchResults())
  }

  const results = searchParam ? userSearch : defaultSearch

  return (
    <div>
      <div className="mb-4 flex items-center justify-between">
        <h1 className="font-serif text-3xl font-bold">User Management</h1>
        {getAdminScope(USERS_WRITE) && (
          <Link to="/users/new">
            <Button buttonStyle="stroke" size="large">
              Create New User
            </Button>
          </Link>
        )}
      </div>

      <div>
        <Formik<FormData>
          enableReinitialize
          initialValues={{
            searchParam: searchParam || '',
            searchTerm,
          }}
          onSubmit={handleGetUserSearchResults}
        >
          {({ resetForm }) => {
            return (
              <Form>
                <div className="mb-2 flex space-x-4">
                  <div className="relative grow">
                    <Field
                      className="h-full w-full rounded-lg border border-grey-901 px-4 text-xl"
                      name="searchParam"
                      placeholder="Search users"
                      type="text"
                    />
                    <span
                      className="absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer text-4xl"
                      onClick={() => {
                        resetForm()
                        clearSearch()
                      }}
                    >
                      ×
                    </span>
                  </div>
                  <div className="h-14 w-32">
                    <Button buttonStyle="red" size="fluid" type="submit">
                      Search
                    </Button>
                  </div>
                </div>
                <div className="mt-4 mb-8 flex space-x-8">
                  <label>
                    <Field
                      className="peer"
                      name="searchTerm"
                      type="radio"
                      value="nameOrEmail"
                    />{' '}
                    <span className="peer-checked:font-bold">Name/Email</span>
                  </label>
                  <label>
                    <Field
                      className="peer"
                      name="searchTerm"
                      type="radio"
                      value="address"
                    />{' '}
                    <span className="peer-checked:font-bold">
                      Address/Phone
                    </span>
                  </label>
                  <label>
                    <Field
                      className="peer"
                      name="searchTerm"
                      type="radio"
                      value="id"
                    />{' '}
                    <span className="peer-checked:font-bold">User ID</span>
                  </label>
                </div>
              </Form>
            )
          }}
        </Formik>

        {userSearch.length >= 0 && searchParam && (
          <div className="mb-4">
            <strong>{userSearch.length}</strong> results
          </div>
        )}

        <Table>
          <THead>
            <TableRow>
              <TH>User ID</TH>
              <TH>Name</TH>
              <TH>Email</TH>
            </TableRow>
          </THead>
          <TBody>
            {results.map(({ email, id, name }) => {
              const accountOverviewURL = `/user/${id}/account-overview`

              return (
                <TableRow key={id} highlight>
                  <TD width="w-[10%]">
                    <Link
                      className="hover:text-grey-909"
                      to={accountOverviewURL}
                    >
                      {id}
                    </Link>
                  </TD>

                  <TD width="w-[18%]">
                    <Link
                      className="hover:text-grey-909"
                      to={accountOverviewURL}
                    >
                      {name}
                      {name === '' && <span>—</span>}
                    </Link>
                  </TD>

                  <TD width="w-[42%]">
                    <Link
                      className="hover:text-grey-909"
                      to={accountOverviewURL}
                    >
                      {email}
                    </Link>
                  </TD>
                </TableRow>
              )
            })}
          </TBody>
        </Table>
      </div>
    </div>
  )
}

export default Dashboard
