import { Link, useParams } from 'react-router-dom'
import ReactTable, { TableProps } from 'react-table'
import { TermSubTerm, useTerm } from '@tovala/browser-apis-combinedapi'
import { useEffect } from 'react'

import { getAdminScope, MEALS_WRITE } from '../../utils/getAdminScope'

import H1 from 'components/common/H1'

const TermMenus = (): JSX.Element => {
  const { termID: termIDParam } = useParams<{ termID: string }>()
  const termID = termIDParam ? Number.parseInt(termIDParam, 10) : undefined

  const { data: term } = useTerm({ termID })

  useEffect(() => {
    document.title = `Glaze | Term #${termID} Menus`
  }, [termID])

  const columns: TableProps<TermSubTerm>['columns'] = [
    {
      Header: 'Name',
      accessor: 'defaultMenu.name',
      width: 300,
    },
    {
      Header: 'Facility Network',
      accessor: 'facilityNetwork',
      width: 300,
    },
    {
      Header: 'Cycle',
      accessor: 'shipPeriod',
      width: 100,
    },
    {
      accessor: 'defaultMenu.id',
      width: 70,
      show: getAdminScope(MEALS_WRITE),
      Cell: (props: { value: string }) => (
        <Link
          className="ml-2 text-sm font-semibold uppercase tracking-widest text-red-901"
          to={`/terms/${termID}/menus/edit/${props.value}`}
        >
          Edit
        </Link>
      ),
    },
  ]

  return (
    <div>
      {term && (
        <div>
          <H1>Term #{term.id} Menus</H1>

          {term.subTerms && (
            <ReactTable
              className="-striped -highlight"
              columns={columns}
              data={term.subTerms}
              defaultSorted={[
                {
                  id: 'created',
                  desc: true,
                },
              ]}
              filterable
              pageSize={4}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default TermMenus
