import moment from 'moment'

const utcOffset = moment().isDST() ? -5 : -6

export function getCurrentTermMSRDeadlineDate(currentTermOrderBy: string) {
  return moment(currentTermOrderBy)
    .utcOffset(utcOffset)
    .add(1, 'days')
    .set({ hour: 12 })
    .toDate()
}

export function isCurrentTimeBeforeMSRDeadline(currentTermOrderBy: string) {
  const deadline = getCurrentTermMSRDeadlineDate(currentTermOrderBy)
  return moment().utcOffset(utcOffset).isBefore(deadline)
}

export function isOrderRefundAvailable(
  currentTermOrderBy: string,
  isCurrentTerm: boolean
) {
  // For current term, allow refunds starting Saturday at 9AM CT after the order by date
  const refundsAvailableDate = moment(currentTermOrderBy)
    .utcOffset(utcOffset)
    .add(3, 'days')
    .set({ hour: 9 })
    .toDate()

  return !!(
    !isCurrentTerm ||
    moment().utcOffset(utcOffset).isSameOrAfter(refundsAvailableDate)
  )
}

export function isRemoveExtrasAvailable(currentTermOrderBy: string) {
  const availableFrom = moment(currentTermOrderBy)
    .utcOffset(utcOffset)
    .add(2, 'hours')
    .toDate()

  return moment().utcOffset(utcOffset).isSameOrAfter(availableFrom)
}
